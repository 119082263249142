import { ImagenDataDTO } from "./ImagenDataDTO";

/**
 *
 * @author jpardal
 */
 export class NoticiaDataDTO {
    id: string;
    titulo: string;
    detalle: string;
    categoria: string;
    subcategoria: string;
    destacado: boolean;
    idImagen: string;
    imagen: ImagenDataDTO = new ImagenDataDTO();
}
