import { Component, AfterViewInit } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';
import { PolicyService } from 'app/services/policy-service';
import { DasLexService } from 'app/services/daslex-service';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'app/services/event-service';
import { WordpressService } from 'app/services/wordpress-service';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';
import { AuthService } from 'app/security/auth.service';
import { ClientService } from '../../../../services/client-service';
import { CommonsText } from 'app/commons/CommonsText';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-carousel-container',
    templateUrl: './carousel-container.component.html',
    styleUrls: ['./carousel-container.component.scss']
})
export class CarouselContainerComponent implements AfterViewInit {

    receipts = [{}];
    loadingReceipts: Boolean = true;

    products = [{}];
    loadingProducts: Boolean = true;

    openEvents = [{}];
    loadingOpenEvents: Boolean = true;

    onlygalServices = [{}];
    loadingonlygalServices: Boolean = true;
    paramsonlygalServices: DasLexParamDTO;

    integration: any = undefined;

    showDirectAreas: Boolean = true;

    constructor(private receiptService: ReceiptService,
        private policyService: PolicyService,
        private eventService: EventService,
        private toastr: ToastrService,
        private wordpressService: WordpressService,
        private dasLexService: DasLexService,
        private authService: AuthService,
        private clientService: ClientService) { }

    ngAfterViewInit(): void {

        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }

        this.wordpressService.loadWidget()
            .then(any => {
                this.integration = this.filterCardAdvertisement(this.wordpressService.getWidget('card-advertisement'));
            }).catch(error => {
                this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            })

        if (this.showDirectAreas) {
            this.receiptService.getPending()
                .then(success => {
                    this.loadingReceipts = false;
                    this.receipts = success.response;
                }).catch(error => {
                    this.loadingReceipts = false;
                    this.receipts = [];

                    this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                        timeOut: 5000
                    });

                });

            this.policyService.getCurrent()
                .then(success => {
                    this.loadingProducts = false;
                    this.products = success.response;
                }).catch(error => {
                    this.loadingProducts = false;
                    this.products = [];
                    this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                        timeOut: 5000
                    });
                });

            this.paramsonlygalServices = new DasLexParamDTO();
            this.paramsonlygalServices.pageOffset = 0;
            this.paramsonlygalServices.pageSize = 9999;

            this.dasLexService.search(this.paramsonlygalServices)
                .then(success => {
                    this.loadingonlygalServices = false;
                    success.content && success.content.length > 0 ? this.onlygalServices = success.content : this.onlygalServices = [];
                }).catch(error => {
                    this.loadingonlygalServices = false;
                    this.onlygalServices = null;
                    this.toastr.error(CommonsText.PROBLEMA_SERVICIO_DASLEX, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                        timeOut: 5000
                    });
                });
        }
        this.eventService.getOpenEvents()
            .then(success => {
                this.loadingOpenEvents = false;
                this.openEvents = success.response;
            }).catch(error => {
                this.loadingOpenEvents = false;
                this.openEvents = [];
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    filterCardAdvertisement(widgetList) {
        this.clientService.getBannersInfo()
            .then(response => {
                const r = response.response;
                if (widgetList && widgetList.length > 0) {
                    let fil = null;
                    if (r && r.policyIds) {
                        fil = widgetList.filter(w => w.id == this.evaluatePoliciesAdvertisement(r.policyIds, r.isCompany));
                        let arrayPolicy: String[] = r.policyIds;
                        if (!fil || fil.length <= 0) {
                             fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));                        
                        }

                        if (arrayPolicy.find(x => ("PP09" == x || "PP11" == x || "DJ13" == x)) != undefined){
                            fil = [ {  "id": "PP09", 
                                        "title": "Preguntas Frecuentes en expedientes de impago de alquiler",
                                        "message": "Consulta toda la información relativa a los procedimientos judiciales de desahucio y reclamación de rentas. Resolvemos las dudas más frecuentes y te explicamos cómo se aplica la Ley de Vivienda en estos casos.",
                                        "link": environment.urlWordpressClubPropietario,
                                        "priority": "true"
                                    }
                                   ];                            
                        }
                        this.integration = fil[0];                        
                    } else {
                        fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));
                        this.integration = fil[0];
                    }
                }
            })
            .catch(err => {
                let fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));
                this.integration = fil[0];
                console.error(err);
            })
    }

    evaluatePoliciesAdvertisement(policyIds, isCompany): string {

        if (this.existsPolicyType(policyIds, 'AS05')) {
            if (this.existsPolicyType(policyIds, 'DJ14')) {
                if (this.existsPolicyType(policyIds, 'AS06')) {
                    if (isCompany && !this.existsPolicyType(policyIds, 'DJ20')) {
                        return 'DJ20';
                    } else if (!isCompany && !this.existsPolicyType(policyIds, 'DJ21')) {
                        return 'DJ21';
                    }
                } else {
                    return 'AS06';
                }
            } else {
                return 'DJ14';
            }
        }


        if (this.existsPolicyType(policyIds, 'AS06')) {
            if (this.existsPolicyType(policyIds, 'DJ21')) {
                if (this.existsPolicyType(policyIds, 'AS05')) {
                    if (!(this.existsPolicyType(policyIds, 'PP01') ||
                        this.existsPolicyType(policyIds, 'PP05') ||
                        this.existsPolicyType(policyIds, 'PP09') ||
                        this.existsPolicyType(policyIds, 'PP11'))) {
                        return 'PP01';
                    }
                } else {
                    return 'AS05';
                }
            } else {
                return 'DJ21';
            }
        }



        if (this.existsPolicyType(policyIds, 'AU01')) {
            if (this.existsPolicyType(policyIds, 'PP07') || this.existsPolicyType(policyIds, 'PP02')) {
                if (this.existsPolicyType(policyIds, 'AS04')) {
                    if (!this.existsPolicyType(policyIds, 'AS06')) {
                        return 'AS06';
                    }
                } else {
                    return 'AS04';
                }
            } else {
                return 'PP07';
            }
        }


        if (this.existsPolicyType(policyIds, 'DJ07')) {
            if (this.existsPolicyType(policyIds, 'AS06')) {
                if (!this.existsPolicyType(policyIds, 'DJ21')) {
                    return 'DJ21';
                }
            } else {
                return 'AS06';
            }
        }


        if (this.existsPolicyType(policyIds, 'DJ10')) {
            if (this.existsPolicyType(policyIds, 'DJ24')) {
                if (this.existsPolicyType(policyIds, 'AS06')) {
                    if (!this.existsPolicyType(policyIds, 'AS05')) {
                        return 'AS05';
                    }
                } else {
                    return 'AS06';
                }
            } else {
                return 'DJ24';
            }

        }


        if (this.existsPolicyType(policyIds, 'DJ12')) {
            if ((this.existsPolicyType(policyIds, 'PP01') ||
                this.existsPolicyType(policyIds, 'PP05') ||
                this.existsPolicyType(policyIds, 'PP09') ||
                this.existsPolicyType(policyIds, 'PP11'))) {
                if (!this.existsPolicyType(policyIds, 'DJ13')) {
                    return 'DJ13';
                }
            } else {
                return 'PP01';
            }
        }




        if (this.existsPolicyType(policyIds, 'DJ13')) {
            if ((this.existsPolicyType(policyIds, 'PP01') ||
                this.existsPolicyType(policyIds, 'PP05') ||
                this.existsPolicyType(policyIds, 'PP09') ||
                this.existsPolicyType(policyIds, 'PP11'))) {
                if (this.existsPolicyType(policyIds, 'DJ21')) {
                    if (!this.existsPolicyType(policyIds, 'AS06')) {
                        return 'AS06';
                    }
                } else {
                    return 'DJ21';
                }
            } else {
                return 'PP01';
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ14')) {
            if (this.existsPolicyType(policyIds, 'AS05')) {
                if (this.existsPolicyType(policyIds, 'PP07') || this.existsPolicyType(policyIds, 'PP02')) {
                    if (!this.existsPolicyType(policyIds, 'AS04')) {
                        return 'AS04';
                    }
                } else {
                    return 'PP07';
                }
            } else {
                return 'AS05';
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ15')) {
            if (this.existsPolicyType(policyIds, 'AS06')) {
                if (!this.existsPolicyType(policyIds, 'AS05')) {
                    return 'AS05';
                }
            } else {
                return 'AS06';
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ18') || this.existsPolicyType(policyIds, 'DJ20')) {
            if (this.existsPolicyType(policyIds, 'AS06')) {
                if (!this.existsPolicyType(policyIds, 'DJ21')) {
                    return 'DJ21';
                }
            } else {
                return 'AS06';
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ24')) {
            if (this.existsPolicyType(policyIds, 'DJ21')) {
                if (!this.existsPolicyType(policyIds, 'AS06')) {
                    return 'AS06';
                }
            } else {
                return 'DJ21';
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ22')) {
            if (!isCompany && this.existsPolicyType(policyIds, 'DJ21')) {
                if (!this.existsPolicyType(policyIds, 'AS06')) {
                    return 'AS06';
                }
            } else if (isCompany && this.existsPolicyType(policyIds, 'DJ20')) {
                if (!this.existsPolicyType(policyIds, 'AS05')) {
                    return 'AS05';
                }
            } else {
                if (isCompany) { return 'DJ20'; } else { return 'DJ21'; }
            }
        }



        if (this.existsPolicyType(policyIds, 'DJ21')) {
            if (this.existsPolicyType(policyIds, 'DJ24')) {
                if (this.existsPolicyType(policyIds, 'AS06')) {
                    if (!this.existsPolicyType(policyIds, 'AS05')) {
                        return 'AS05';
                    }
                } else {
                    return 'AS06';
                }
            } else {
                return 'DJ24';
            }
        }



        if (this.existsPolicyType(policyIds, 'PP01') ||
            this.existsPolicyType(policyIds, 'PP05') ||
            this.existsPolicyType(policyIds, 'PP09') ||
            this.existsPolicyType(policyIds, 'PP11')) {
            if (this.existsPolicyType(policyIds, 'DJ21')) {
                if (this.existsPolicyType(policyIds, 'AS06')) {
                    if (!this.existsPolicyType(policyIds, 'AS05')) {
                        return 'AS05';
                    }
                } else {
                    return 'AS06';
                }
            } else {
                return 'DJ21';
            }
        }



        if (this.existsPolicyType(policyIds, 'PP03') || this.existsPolicyType(policyIds, 'PP06')) {
            if (!isCompany && this.existsPolicyType(policyIds, 'DJ21')) {
                if (!this.existsPolicyType(policyIds, 'AS06')) {
                    return 'AS06';
                }
            } else if (isCompany && this.existsPolicyType(policyIds, 'DJ20')) {
                if (!this.existsPolicyType(policyIds, 'AS05')) {
                    return 'AS05';
                }
            } else {
                if (isCompany) { return 'DJ20'; } else { return 'DJ21'; }
            }
        }


        if (this.existsPolicyType(policyIds, 'PP07') || this.existsPolicyType(policyIds, 'PP02')) {
            if (this.existsPolicyType(policyIds, 'DJ14')) {
                if (this.existsPolicyType(policyIds, 'AS05')) {
                    if (!this.existsPolicyType(policyIds, 'AS04')) {
                        return 'AS04';
                    }
                } else {
                    return 'AS05';
                }
            } else {
                return 'DJ14';
            }
        }


        if (this.existsPolicyType(policyIds, 'PP08')) {
            if (this.existsPolicyType(policyIds, 'PP07') || this.existsPolicyType(policyIds, 'PP02')) {
                if (this.existsPolicyType(policyIds, 'DJ14')) {
                    if (!this.existsPolicyType(policyIds, 'AS05')) {
                        return 'AS05';
                    }
                } else {
                    return 'DJ14';
                }
            } else {
                return 'PP07';
            }
        }

        return '';
    }

    existsPolicyType(policyIds, type) {
        const filtered = policyIds.filter(p => p == type)
        let exists = false;
        filtered.length > 0 ? exists = true : exists = false;
        return exists;
    }


    filterCardAdvertisementOld(widgetList) {
        this.clientService.getBannersInfo()
            .then(response => {
                const r = response.response;
                if (widgetList && widgetList.length > 0) {
                    let fil = null;
                    if (r.provinceCode) {
                        fil = widgetList.filter(w => w.id == r.provinceCode);
                        if (!fil || fil.length <= 0) {
                            fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));
                        }
                        this.integration = fil[0];
                    } else {
                        fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));
                        this.integration = fil[0];
                    }
                }
            })
            .catch(err => {
                let fil = widgetList.filter(w => (w.id == null || w.id == 'Id'));
                this.integration = fil[0];
                console.error(err);
            })
    }

    integrationVisible(): Boolean {
        return (this.integration) && (this.receipts != null) && (this.products != null) && (this.openEvents != null);
    }

}
