import { Component, Input, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-carousel-service',
  templateUrl: './carousel-service.component.html',
  styleUrls: ['./carousel-service.component.scss']
})
export class CarouselServiceComponent {

  @Input() data;
  @Input() loading;

  constructor(private router: Router, private route: ActivatedRoute) { }

  getServiceOption(expediente, type) {
    this.router.navigate(['/productService/' + expediente + type], { relativeTo: this.route.root });
  }

  getLawyerName(data) {
    let completeName = '';
    if (data && data.letradoApiResponse) {
      if (data.letradoApiResponse.nombre) {
        completeName += ' ' + data.letradoApiResponse.nombre;
      }

      if (data.letradoApiResponse.apellidos) {
        completeName += ' ' + data.letradoApiResponse.apellidos;
      }
    }
    return completeName;
  }
}
