import { Component, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-google-recaptcha',
    templateUrl: './google-recaptcha.component.html',
    styleUrls: ['./google-recaptcha.component.scss']
})
export class GoogleRecaptchaComponent {

    @Output() onRecaptcha = new EventEmitter();

    public getSiteKey(): String {
        return environment.siteKey;
    }

    _resolved($event) {
        this.onRecaptcha.emit($event);
    }

}
