/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-sms-register-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/confirm-sms-register-form/confirm-sms-register-form.component.ngfactory";
import * as i3 from "../../components/confirm-sms-register-form/confirm-sms-register-form.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/user-service";
import * as i6 from "../../../security/auth.service";
import * as i7 from "../../../services/audity-service";
import * as i8 from "../../components/confirm-sms-resend-register-form/confirm-sms-register-resend-form.component.ngfactory";
import * as i9 from "../../components/confirm-sms-resend-register-form/confirm-sms-register-resend-form.component";
import * as i10 from "@angular/forms";
import * as i11 from "@angular/common";
import * as i12 from "../../components/picture-with-text/picture-with-text.component.ngfactory";
import * as i13 from "../../components/picture-with-text/picture-with-text.component";
import * as i14 from "./confirm-sms-register-page.component";
var styles_ConfirmSmsRegisterPageComponent = [i0.styles];
var RenderType_ConfirmSmsRegisterPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmSmsRegisterPageComponent, data: {} });
export { RenderType_ConfirmSmsRegisterPageComponent as RenderType_ConfirmSmsRegisterPageComponent };
function View_ConfirmSmsRegisterPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-confirm-sms-register-form", [], null, [[null, "onConfirmSmsRegisterSubmit"], [null, "onResendSmsRegisterSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onConfirmSmsRegisterSubmit" === en)) {
        var pd_0 = (_co.onConfirmSmsRegisterSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("onResendSmsRegisterSubmit" === en)) {
        var pd_1 = (_co.onResendSmsRegisterSubmit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ConfirmSmsRegisterFormComponent_0, i2.RenderType_ConfirmSmsRegisterFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.ConfirmSmsRegisterFormComponent, [i4.ActivatedRoute, i5.UserService, i6.AuthService, i7.AudityService], null, { onConfirmSmsRegisterSubmit: "onConfirmSmsRegisterSubmit", onResendSmsRegisterSubmit: "onResendSmsRegisterSubmit" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ConfirmSmsRegisterPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-confirm-sms-register-resend-form", [], null, [[null, "onReceiveNewSmsCode"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onReceiveNewSmsCode" === en)) {
        var pd_0 = (_co.onReceiveNewSmsCode($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ConfirmSmsRegisterResendFormComponent_0, i8.RenderType_ConfirmSmsRegisterResendFormComponent)), i1.ɵdid(2, 49152, null, 0, i9.ConfirmSmsRegisterResendFormComponent, [i4.ActivatedRoute, i5.UserService, i10.FormBuilder, i11.Location], null, { onReceiveNewSmsCode: "onReceiveNewSmsCode" })], null, null); }
export function View_ConfirmSmsRegisterPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container sbs"]], [[4, "--entity", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i11.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmSmsRegisterPageComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmSmsRegisterPageComponent_2)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "section", [["class", "side-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-picture-with-text", [], null, null, null, i12.View_PictureWithTextComponent_0, i12.RenderType_PictureWithTextComponent)), i1.ɵdid(9, 114688, null, 0, i13.PictureWithTextComponent, [], { isReinsured: [0, "isReinsured"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "container sbs"; var currVal_2 = _ck(_v, 2, 0, _co.getEntityClass()); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = !_co.resend; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.resend; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.reinsured; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reinsured; _ck(_v, 0, 0, currVal_0); }); }
export function View_ConfirmSmsRegisterPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-sms-register-page", [], null, null, null, View_ConfirmSmsRegisterPageComponent_0, RenderType_ConfirmSmsRegisterPageComponent)), i1.ɵdid(1, 49152, null, 0, i14.ConfirmSmsRegisterPageComponent, [i4.Router, i4.ActivatedRoute], null, null)], null, null); }
var ConfirmSmsRegisterPageComponentNgFactory = i1.ɵccf("app-confirm-sms-register-page", i14.ConfirmSmsRegisterPageComponent, View_ConfirmSmsRegisterPageComponent_Host_0, {}, {}, []);
export { ConfirmSmsRegisterPageComponentNgFactory as ConfirmSmsRegisterPageComponentNgFactory };
