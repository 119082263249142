<div class="carousel-container container">

    <app-carousel type="RECEPT" titleText="Mis recibos pendientes" [items]="receipts" *ngIf="showDirectAreas && receipts.length > 0 && !loadingReceipts" url="/receipts" [loading]=loadingReceipts>
    </app-carousel>

    <app-carousel type="OPEN_EVENT" titleText="Partes abiertos" [items]="openEvents" *ngIf="openEvents.length > 0 && !loadingOpenEvents" url="/events/history" [loading]=loadingOpenEvents>
    </app-carousel>

    <app-carousel type="PRODUCT" titleText="Mis seguros en vigor" [items]="products" *ngIf="showDirectAreas && products.length > 0 && !loadingProducts" 
        url="/products" paramValue='products' [loading]=loadingProducts>
    </app-carousel>

    <app-carousel type="SERVICE" titleText="Mis servicios contratados" [items]="onlygalServices" *ngIf="showDirectAreas && onlygalServices && onlygalServices.length > 0 && !loadingonlygalServices" 
        url="/products" paramValue='services' [loading]=loadingonlygalServices>
    </app-carousel>

    <app-carousel-card-wp-integration [data]="integration" *ngIf="integrationVisible() && showDirectAreas"></app-carousel-card-wp-integration>

</div>