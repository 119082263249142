import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var UrlService = /** @class */ (function () {
    function UrlService(document) {
        this.document = document;
        var window = this.document.defaultView;
        this._backendUrlWithContext = environment.backendUrlWithContext;
        this._wordpressUrl = environment.wordpressUrl;
        this._frontEndUrl = environment.frontEndUrl;
    }
    Object.defineProperty(UrlService.prototype, "backendUrlWithContext", {
        get: function () {
            return this._backendUrlWithContext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "frontEndUrl", {
        get: function () {
            return this._frontEndUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlService.prototype, "wordpressUrl", {
        get: function () {
            return this._wordpressUrl;
        },
        enumerable: true,
        configurable: true
    });
    UrlService.ngInjectableDef = i0.defineInjectable({ factory: function UrlService_Factory() { return new UrlService(i0.inject(i1.DOCUMENT)); }, token: UrlService, providedIn: "root" });
    return UrlService;
}());
export { UrlService };
