import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-collapse-container',
    templateUrl: './collapse-container.component.html',
    styleUrls: ['./collapse-container.component.scss']
})
export class CollapseContainerComponent {

    @Input() checked: Boolean = false;
    @Input() slimStyle: Boolean = false;
    @Input() caption: String;
    @Input() subcaption: String;
    @Input() loading: Boolean = false;

    onClick() {
        this.checked = !this.checked;
    }

}
