/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./label-text.component";
var styles_LabelTextComponent = [i0.styles];
var RenderType_LabelTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelTextComponent, data: {} });
export { RenderType_LabelTextComponent as RenderType_LabelTextComponent };
export function View_LabelTextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "label-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.getLabelClass(), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getLabel(); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getTextClass(), ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.getText(); _ck(_v, 4, 0, currVal_3); }); }
export function View_LabelTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-label-text", [], null, null, null, View_LabelTextComponent_0, RenderType_LabelTextComponent)), i1.ɵdid(1, 49152, null, 0, i2.LabelTextComponent, [], null, null)], null, null); }
var LabelTextComponentNgFactory = i1.ɵccf("app-label-text", i2.LabelTextComponent, View_LabelTextComponent_Host_0, { label: "label", text: "text", textClass: "textClass", labelClass: "labelClass", loading: "loading" }, {}, []);
export { LabelTextComponentNgFactory as LabelTextComponentNgFactory };
