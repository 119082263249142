import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from 'app/services/client-service';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';
import { SubCategoriaDataDTO } from 'app/shared/models/wordpress/SubCategoriaDataDTO';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';
import { MenuHorizontalDTO } from 'app/modules/club-propietario/model/MenuHorizontalDTO';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetalleComponent implements OnInit {
  
  categoria: string;
  idnoticia: string;

  categoriaInicio: string = "00";
  literalInicio: string = "Inicio club del propietario";
  loadingDatosNoticia: Boolean = true;

  categoriaDetalle: CategoriaDataDTO;
  subcategoriaDetalle:SubCategoriaDataDTO;
  noticiaDetalle: NoticiaDataDTO;
  lstCategoriaWP: CategoriaDataDTO[] = [];
  menus: MenuHorizontalDTO[];
  

  lstOtrasAyudas : NoticiaDataDTO[] = [];
  cargarOtrasAyudas:boolean;
  hayOtrasAyudas:boolean = false;


  lstCategoriaDestacados: CategoriaDataDTO[];
  showModal: boolean = false;


  constructor(private route: ActivatedRoute,
    private clientService: ClientService,
     private toastr: ToastrService,
     private clubPropietarioService: ClubPropietarioService,
     private router: Router) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ffffff';

    var client: ClientDataDTO = JSON.parse(localStorage.getItem('clientData'));

    if(client.condicionesClub && client.politicaClub){
      this.showModal = true;
    }

    if(this.categoria===undefined && this.idnoticia===undefined){
      this.route.paramMap.subscribe(params => { 
        this.categoria = params.get('categoria');
        this.idnoticia = params.get('idnoticia');
      });
    }
    this.lstCategoriaWP = this.clubPropietarioService.getCategoriasCompletas();
    if(this.lstCategoriaWP == undefined) {
        this.lstCategoriaWP = this.clubPropietarioService.getCategoriasCompletas(); 
        this.loadDetalle();
    } else {
      this.loadDetalle();
    } 
  }

  ngOnDestroy (){
    document.getElementsByTagName('body')[0].style.backgroundColor = '';
  }

  ngAfterViewInit() {
      this.clientService.getBannersInfo()
          .then(success => {
          }).catch(error => {
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });
    }

  construirArrayOtrasAyudas(){
    if(this.subcategoriaDetalle !== undefined && this.subcategoriaDetalle !== null ){
      if(this.subcategoriaDetalle.noticiaList != undefined && this.subcategoriaDetalle.noticiaList != null){
        for(let i = 0 ; i < this.subcategoriaDetalle.noticiaList.length ; i++){
          if(this.subcategoriaDetalle.noticiaList[i].id!=this.idnoticia){
            this.lstOtrasAyudas.push(this.subcategoriaDetalle.noticiaList[i]);
            this.hayOtrasAyudas = true;
          }
        }
      }
    }
  }

  establecerMenusHorizontales(){
    this.menus = [];
    this.menus.push(new MenuHorizontalDTO(this.categoriaInicio, this.literalInicio, this.establecerClassTab(this.categoriaInicio)));
    this.lstCategoriaWP.forEach(cat =>{
      this.menus.push(new MenuHorizontalDTO(cat.categoria, cat.titulo, this.establecerClassTab(cat.categoria)));
    });
  }

  establecerClassTab(param:string):string{
    if(this.categoriaDetalle.categoria == param){
      return 'enlaceMenuHorizontalChecked';
    }else{
      return 'enlaceMenuHorizontal';
    }
  }

  private loadDetalle() {
    this.categoriaDetalle = this.clubPropietarioService.getCategoriaStorage(this.categoria);
    if(this.categoriaDetalle !== undefined){
      this.noticiaDetalle = this.categoriaDetalle.noticiasListAll.find(x => x.id == this.idnoticia);
        if(this.noticiaDetalle != undefined) {
        this.categoriaDetalle.subCategoriaList.forEach(subCategoria => {
          subCategoria.noticiaList.forEach(noticia => {
            if(noticia.id == this.idnoticia){
              this.noticiaDetalle = noticia;
              return;
            }
          });       
        });
        this.subcategoriaDetalle = this.categoriaDetalle.subCategoriaList.find(x => x.id == this.noticiaDetalle.subcategoria);
        this.cargarOtrasAyudas = true;
      } else {
        this.subcategoriaDetalle = this.categoriaDetalle.subCategoriaList.find(x => x.id == this.noticiaDetalle.subcategoria);
        if(this.subcategoriaDetalle == undefined) {
          this.subcategoriaDetalle = new SubCategoriaDataDTO();
          this.cargarOtrasAyudas = false;
        }
      }
      if(this.cargarOtrasAyudas){
        this.construirArrayOtrasAyudas();
      }
      this.establecerMenusHorizontales();
      this.loadingDatosNoticia = false;
    } else {
      this.router.navigate(['/club-del-propietario'])
    }
  }

  cargarNoticia(categoriaParam:string, idNoticiaParam:string){
    this.categoria = categoriaParam;
    this.idnoticia = idNoticiaParam;
    this.lstOtrasAyudas = [];
    window.scroll(0,0);
    this.ngOnInit();
  }
}