/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-bank.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./profile-bank.component";
var styles_ProfileBankComponent = [i0.styles];
var RenderType_ProfileBankComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileBankComponent, data: {} });
export { RenderType_ProfileBankComponent as RenderType_ProfileBankComponent };
function View_ProfileBankComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "bank-form-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "bank-form-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["p\u00F3liza"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "bank-form-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["cuenta bancaria"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "bank-form-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["tipo de pago"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.number; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.bankAccount; _ck(_v, 10, 0, currVal_1); var currVal_2 = _v.context.$implicit.periodicity; _ck(_v, 15, 0, currVal_2); }); }
export function View_ProfileBankComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "bank no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "bank-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mis datos bancarios"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "bank-form"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileBankComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "bank-modify"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵted(-1, null, ["Modificar datos bancarios"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 6, 0, currVal_0); var currVal_3 = _ck(_v, 10, 0, "/modify/payment"); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 9).target; var currVal_2 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_ProfileBankComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-bank", [], null, null, null, View_ProfileBankComponent_0, RenderType_ProfileBankComponent)), i1.ɵdid(1, 49152, null, 0, i4.ProfileBankComponent, [], null, null)], null, null); }
var ProfileBankComponentNgFactory = i1.ɵccf("app-profile-bank", i4.ProfileBankComponent, View_ProfileBankComponent_Host_0, { data: "data" }, {}, []);
export { ProfileBankComponentNgFactory as ProfileBankComponentNgFactory };
