import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { ClientService } from 'app/services/client-service';
import { LegalibooService } from 'app/services/legaliboo-service'

@Component({
  selector: 'app-product-legaliboo',
  templateUrl: './product-legaliboo.component.html',
  styleUrls: ['./product-legaliboo.component.scss']
})
export class ProductLegalibooComponent implements OnInit {

  @ViewChild('hiddenForm') private hiddenForm: ElementRef;

  @Input() legalibooConfig;
  show: Boolean = false;

  constructor(private legalibooService: LegalibooService,
              private clientService: ClientService
  ) { }

  ngOnInit() {

  }

  submitLegaliboo() {
    this.clientService.showLegalibooModal();
    // this.legalibooService.legalibooAccess();
    // this.hiddenForm.nativeElement.submit();
  }


}
