import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-split-button',
    templateUrl: './split-button.component.html',
    styleUrls: ['./split-button.component.scss']
})
export class SplitButtonComponent {
    @Input() label: String;
    @Input() direction: String;
    @Input() anulado: boolean;

    _toggle: Boolean = false;

    @Input() set close(value: any) {
        if (value === true) {
            this._toggle = false;
        }
    }

    toggleMenu() {
        this._toggle = !this._toggle;
    }

}
