/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-last-conection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header-last-conection.component";
import * as i4 from "../../../security/auth.service";
var styles_HeaderLastConectionComponent = [i0.styles];
var RenderType_HeaderLastConectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderLastConectionComponent, data: {} });
export { RenderType_HeaderLastConectionComponent as RenderType_HeaderLastConectionComponent };
function View_HeaderLastConectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "last-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00DAltima conexi\u00F3n: "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lastConnection; _ck(_v, 4, 0, currVal_0); }); }
export function View_HeaderLastConectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderLastConectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (undefined !== _co.lastConnection); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderLastConectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-last-conection", [], null, null, null, View_HeaderLastConectionComponent_0, RenderType_HeaderLastConectionComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderLastConectionComponent, [i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderLastConectionComponentNgFactory = i1.ɵccf("app-header-last-conection", i3.HeaderLastConectionComponent, View_HeaderLastConectionComponent_Host_0, {}, {}, []);
export { HeaderLastConectionComponentNgFactory as HeaderLastConectionComponentNgFactory };
