import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-text',
    templateUrl: './label-text.component.html',
    styleUrls: ['./label-text.component.scss']
})
export class LabelTextComponent {

    @Input() label: string;
    @Input() text: string;
    @Input() textClass: string;
    @Input() labelClass: string;
    @Input() loading: Boolean = false;

    getLabel(): String {
        return this.loading ? '.' : this.label;
    }

    getText(): String {
        return this.loading ? '.' : this.text;
    }

    getTextClass(): String {
        let toReturn = (undefined !== this.textClass) ? this.textClass : '';
        toReturn += (false !== this.loading) ? ' loading' : '';
        return toReturn;
    }

    getLabelClass(): String {
        let toReturn = (undefined !== this.labelClass) ? this.labelClass : '';
        toReturn += (false !== this.loading) ? ' loading' : '';
        return toReturn;
    }


}
