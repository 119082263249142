import { Component } from '@angular/core';

@Component({
  selector: 'app-header-profile-bar',
  templateUrl: './header-profile-bar.component.html',
  styleUrls: ['./header-profile-bar.component.scss']
})
export class HeaderProfileBarComponent {

}
