/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./picture-with-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./picture-with-text.component";
var styles_PictureWithTextComponent = [i0.styles];
var RenderType_PictureWithTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PictureWithTextComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: null, timings: 450 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 600 }, options: null }], options: {} }] } });
export { RenderType_PictureWithTextComponent as RenderType_PictureWithTextComponent };
function View_PictureWithTextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "content-right-blue"]], [[24, "@fadeIn", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFQu\u00E9 puedes hacer desde tu \u00C1rea Cliente?"])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Consultar tus seguros y servicios contratados. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Abrir un parte y hacer su seguimiento. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Consultar y pagar tus recibos. "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Modificar tus datos personales y bancarios. "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A1Y mucho m\u00E1s! "]))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_PictureWithTextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content-right no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PictureWithTextComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isReinsured; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PictureWithTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-picture-with-text", [], null, null, null, View_PictureWithTextComponent_0, RenderType_PictureWithTextComponent)), i1.ɵdid(1, 114688, null, 0, i3.PictureWithTextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PictureWithTextComponentNgFactory = i1.ɵccf("app-picture-with-text", i3.PictureWithTextComponent, View_PictureWithTextComponent_Host_0, { isReinsured: "isReinsured" }, {}, []);
export { PictureWithTextComponentNgFactory as PictureWithTextComponentNgFactory };
