import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from "@angular/animations";
import { IsMobileService } from 'app/commons/components/isMobile.service';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {

    @Input() titleText;
    @Input() visibleItems = [{}];
    @Input() type: CarouselType;
    @Input() url;
    @Input() paramValue;
    @Input() loading;

    @ViewChild('carouselWheels') private carousel: ElementRef;

    @Input() timing = '250ms ease-in';
    @Input() showControls = true;
    private player: AnimationPlayer;
    private itemWidth: number;
    private currentSlide = 0;
    private moving: Boolean = false;
    private isMobile: Boolean = false;

    carouselWrapperStyle = {}

    _items: any[];

    get items(): any[] {
        return this._items;
    }
    @Input() set items(value: any[]) {
        this._items = value;
        this.visibleItems = [this._items[0]];
    }

    constructor(private builder: AnimationBuilder, private elementRef: ElementRef, isMobileService: IsMobileService) {
        this.isMobile = isMobileService.isMobile
    }

    ngAfterViewInit() {
        this.detect();
    }

    detect() {
        setTimeout(() => {
            this.itemWidth = this.elementRef.nativeElement.offsetWidth - 2;

            if (this.itemWidth < 300) {
                this.itemWidth = 300;
            }

            this.carouselWrapperStyle = { width: `${this.itemWidth}px` }
        });
    }

    next() {

        if (this.moving) {
            return;
        }

        this.moving = true;

        if (this.currentSlide + 1 === this._items.length) {
            return;
        }

        this.visibleItems.push(this.items[this.currentSlide + 1]);

        this.currentSlide = (this.currentSlide + 1) % this.items.length;
        const offset = this.currentSlide * this.itemWidth;

        const myAnimation: AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();

        setTimeout(() => {
            this.moving = false;
        }, 250);

    }

    prev() {

        if (this.moving) {
            return;
        }

        if (this.currentSlide === 0) {
            return;
        }

        this.moving = true;
        const temp = ((this.currentSlide - 1) + this.visibleItems.length) % this.visibleItems.length;
        const offset = temp * this.itemWidth;

        const myAnimation: AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();

        setTimeout(() => {
            this.moving = false;
            this.currentSlide = temp;
            this.visibleItems.pop();
        }, 250);

    }

    private buildAnimation(offset) {
        return this.builder.build([
            animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
        ]);
    }

    resolveRouterLink(param) {
        return param;
    }

    onSwipeLeft($event) {
        this.next();
    }
    onSwipeRight($event) {
        this.prev();
    }

    getStyle(): any {
        if (!this.isMobile) {
            return {
                'width': (this.itemWidth * (this.currentSlide + 1)) + 'px'
            }
        } else {
            return;
        }

    }

}
