import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';
import { DasLexService } from 'app/services/daslex-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-product-service-detail-page',
    templateUrl: './product-service-detail-page.component.html',
    styleUrls: ['./product-service-detail-page.component.scss']
})
export class ProductServiceDetailPageComponent implements OnInit {

    expediente: any;
    //typeDetail: any;
    description: any;
    packDetalleApiresponse: any;
    gastosDetalleApiResponse: any;
    precioDetalleApiResponse: any;
    service: any;
    loadingDetDas: Boolean = true;
    urlOnlygal: any;

    constructor(private route: ActivatedRoute, 
                private dasLexService: DasLexService, 
                private toastr: ToastrService, 
                private router: Router) {}

    ngOnInit() {
        this.loadDetail();
    }

    private loadDetail() {
        this.expediente = this.route.snapshot.params['expediente'];
        //this.typeDetail = this.route.snapshot.params['typeDetail'];
        this.packDetalleApiresponse = null;
        this.precioDetalleApiResponse = null;
        this.gastosDetalleApiResponse = null;

        this.dasLexService.getService(this.expediente)
            .then(success => {
                this.service = success.response;
                if (success.response) {
                    this.urlOnlygal = success.response.urlDas;
                }
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

        this.dasLexService.getDetailService(this.expediente)
            .then(success => {
                this.loadingDetDas = false;
                this.description = success.descripcion;
                this.packDetalleApiresponse = success.packDetalleApiresponse;
                this.gastosDetalleApiResponse = success.gastosDetalleApiResponse;
                this.precioDetalleApiResponse = success.precioDetalleApiResponse;
                if (null != this.precioDetalleApiResponse.extra) {
                    const extra: String = this.precioDetalleApiResponse.extra;
                    this.precioDetalleApiResponse.extra = extra.replace(/<p>/g, ' ').replace(/<\/p>/g, ' ').replace(/<br>/g, ' ');
                }
                //}
            }).catch(error => {
                this.loadingDetDas = false;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    serviceAccess(){
        this.dasLexService.serviceAccess();
    }
}
