/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./noticias-club.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "./noticias-club.component";
import * as i6 from "@angular/router";
var styles_NoticiasClubComponent = [i0.styles];
var RenderType_NoticiasClubComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NoticiasClubComponent, data: {} });
export { RenderType_NoticiasClubComponent as RenderType_NoticiasClubComponent };
function View_NoticiasClubComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [["class", "mat-tab-labe"], ["role", "button"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "subcategoria", _v.parent.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.titulo; _ck(_v, 2, 0, currVal_1); }); }
function View_NoticiasClubComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "parrafo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A1Lo sentimos! Ahora mismo no hay ning\u00FAn consejo sobre este tema."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prueba con otra pesta\u00F1a"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_NoticiasClubComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "noticia"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "imagenPostDiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "img", [["class", "imagenPost"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "enlacePost"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.detallePost(_v.context.$implicit.categoria, _v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyle((_v.context.$implicit.imagen ? _v.context.$implicit.imagen.source_url : null)); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_1); }); }
function View_NoticiasClubComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "gridNoticia"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NoticiasClubComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, [])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.parent.context.$implicit.noticiasListAll, 0, 4)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NoticiasClubComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 12, "mat-tab", [], [[1, "data-index", 0], [8, "title", 0]], null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], null, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NoticiasClubComponent_2)), i1.ɵdid(5, 16384, [[2, 4]], 0, i4.MatTabLabel, [i1.TemplateRef, i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 6, "div", [["class", "content"], ["id", "parent"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "minTamanio": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticiasClubComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticiasClubComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = "content"; var currVal_3 = _ck(_v, 8, 0, !_co.expan); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.noticiasListAll.length == 0); _ck(_v, 10, 0, currVal_4); var currVal_5 = (_v.context.$implicit.noticiasListAll.length > 0); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.index; var currVal_1 = i1.ɵinlineInterpolate(1, "subcategoria", _v.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_NoticiasClubComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "blog no-text-select wow fadeInUp"], ["data-wow-duration", "1s"]], [[1, "data-wow-delay", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "clubPropietario"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "container no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "positionVer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "tituloClub"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["El Club del Propietario"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "pestania"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["id", "categoria"], ["mat-align-tabs", "left"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i1.ɵdid(9, 3325952, null, 1, i4.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG]], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NoticiasClubComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.categoria; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDelay(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 9).headerPosition === "below"); _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_NoticiasClubComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-noticias-club", [], null, null, null, View_NoticiasClubComponent_0, RenderType_NoticiasClubComponent)), i1.ɵdid(1, 4308992, null, 0, i5.NoticiasClubComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoticiasClubComponentNgFactory = i1.ɵccf("app-noticias-club", i5.NoticiasClubComponent, View_NoticiasClubComponent_Host_0, { delay: "delay", categoria: "categoria" }, {}, []);
export { NoticiasClubComponentNgFactory as NoticiasClubComponentNgFactory };
