<app-header>
        <div class="inner-content-header container">
            <div class="profile">
                <app-header-breadcrumb text="Historial de Partes"></app-header-breadcrumb>
            </div>
            <div class="last-conect">
                <app-header-last-conection></app-header-last-conection>
            </div>
        </div>
    </app-header>
    
    <div class=" container">
        <div class="events-container">
            <app-event-filter [loading]="loading" [response]="response" [policyNumber]="this.params.policyNumber"></app-event-filter>
    
            <div *ngIf="undefined === response" class="pre-loading">
                <p>Cargando<span>.</span><span>.</span><span>.</span></p>
            </div>

            <div class="pagination wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length > 0">
                <app-event-pagination [loading]="loading" [response]="response">
                </app-event-pagination>
            </div>
    
            <div class="list wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length > 0">
                <div *ngFor="let item of response?.content">
                    <app-event-item [data]=item></app-event-item>
                </div>
            </div>
    
            <div class="pagination-bottom" *ngIf="response?.content?.length > 0">
                <app-event-pagination [loading]="loading" [response]="response">
                </app-event-pagination>
            </div>
    
            <div class="no-results wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length === 0">
                No hay partes disponibles.
            </div>
    
        </div>
    
    </div>
    
    <app-footer></app-footer>