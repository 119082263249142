import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { APP_DATE_FORMATS } from '../format-datepicker';
import * as moment from 'moment';
var ɵ0 = APP_DATE_FORMATS;
var InputDatepickerBoxComponent = /** @class */ (function () {
    function InputDatepickerBoxComponent(ngControl, elementRef, _adapter) {
        this.ngControl = ngControl;
        this.elementRef = elementRef;
        this._adapter = _adapter;
        this.disabled = false;
        this.onPaste = new EventEmitter();
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }
    InputDatepickerBoxComponent.prototype.capturePaste = function (event) {
        this.onPaste.emit(event);
    };
    InputDatepickerBoxComponent.prototype.ngOnInit = function () {
        moment.locale('es');
        this._adapter.setLocale('es');
    };
    InputDatepickerBoxComponent.prototype._openCalendar = function (picker) {
        var _this = this;
        picker.open();
        setTimeout(function () { return _this.edit.nativeElement.focus(); });
    };
    InputDatepickerBoxComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    InputDatepickerBoxComponent.prototype.writeValue = function (value) {
        this.parseValue(value);
    };
    InputDatepickerBoxComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InputDatepickerBoxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    InputDatepickerBoxComponent.prototype.onChange = function (event) {
    };
    InputDatepickerBoxComponent.prototype.onTouched = function () { };
    InputDatepickerBoxComponent.prototype.parseValue = function (selected) {
        if (!selected) {
            this.value = null;
        }
        else if (typeof selected === 'string') {
            if (selected.length >= 9) {
                var dateMomentObject = moment(selected, "DD/MM/YYYY");
                this.value = dateMomentObject.toDate();
            }
        }
        else {
            this.value = selected;
            //this.value = `${this.addLeadingZero(selected.getDate())}-${this.addLeadingZero(selected.getMonth() + 1)}-${selected.getFullYear()}`;
        }
    };
    InputDatepickerBoxComponent.prototype.onSelectCallback = function (selected) {
        this.parseValue(selected);
        this.onChange(this.value);
    };
    InputDatepickerBoxComponent.prototype.onFocusOut = function (value) {
        if (value) {
            this.parseValue(value);
            this.onChange(this.value);
        }
    };
    InputDatepickerBoxComponent.prototype.addLeadingZero = function (value) {
        if (value < 10) {
            return "0" + value.toString();
        }
        return value.toString();
    };
    return InputDatepickerBoxComponent;
}());
export { InputDatepickerBoxComponent };
export { ɵ0 };
