/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../blog/blog.component.ngfactory";
import * as i3 from "../blog/blog.component";
import * as i4 from "@angular/common";
import * as i5 from "./blog-container.component";
import * as i6 from "../../../../services/wp-blog-service";
import * as i7 from "../../../../security/auth.service";
import * as i8 from "ngx-toastr";
var styles_BlogContainerComponent = [i0.styles];
var RenderType_BlogContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogContainerComponent, data: {} });
export { RenderType_BlogContainerComponent as RenderType_BlogContainerComponent };
function View_BlogContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blog", [], null, null, null, i2.View_BlogComponent_0, i2.RenderType_BlogComponent)), i1.ɵdid(1, 49152, null, 0, i3.BlogComponent, [], { content: [0, "content"], delay: [1, "delay"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BlogContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "blog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "blog-container-wrapper container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Noticias onLygal"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BlogContainerComponent_2)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.blog, 0, 3)); _ck(_v, 7, 0, currVal_0); }, null); }
export function View_BlogContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlogContainerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDirectAreas; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BlogContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blog-container", [], null, null, null, View_BlogContainerComponent_0, RenderType_BlogContainerComponent)), i1.ɵdid(1, 114688, null, 0, i5.BlogContainerComponent, [i6.WpBlogService, i7.AuthService, i8.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlogContainerComponentNgFactory = i1.ɵccf("app-blog-container", i5.BlogContainerComponent, View_BlogContainerComponent_Host_0, {}, {}, []);
export { BlogContainerComponentNgFactory as BlogContainerComponentNgFactory };
