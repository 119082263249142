<div class="container sbs" [ngClass]="[getEntityClass()]" [style.--entity]="reinsured">

    <section class="side-left" *ngIf="!resend">
        <app-confirm-sms-register-form (onConfirmSmsRegisterSubmit)="onConfirmSmsRegisterSubmit($event)"
            (onResendSmsRegisterSubmit)="onResendSmsRegisterSubmit($event)">
        </app-confirm-sms-register-form>
    </section>

    <section class="side-left" *ngIf="resend">
        <app-confirm-sms-register-resend-form (onReceiveNewSmsCode)="onReceiveNewSmsCode($event)"></app-confirm-sms-register-resend-form>
    </section>

    <section class="side-right">
        <app-picture-with-text [isReinsured] = "reinsured"></app-picture-with-text>
    </section>

</div>