<div class="container-bar">
    <div class="progress-title">
        <a href="">{{percent/100 | percent:'1.1-5' }} completado</a>
    </div>
    <div class="progress-inner">
        <div class="progress-inner-bar">
            <div class="bar" [ngStyle]="{'width': percent + '%'}"></div>
        </div>
    </div>

</div>