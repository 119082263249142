import { Component, Input, OnInit} from '@angular/core';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home-categoria',
  templateUrl: './home-categoria.component.html',
  styleUrls: ['./home-categoria.component.scss']
})
export class HomeCategoriaComponent implements OnInit {  
  
  @Input() categoria: CategoriaDataDTO;
  loadCatEnCurso: boolean = false;
  isMobilePhone: boolean;
  
  constructor(private isMobileService: IsMobileService,
              private viewportScroller: ViewportScroller) { 
    this.isMobilePhone = isMobileService.isMobile;
  }

  ngOnInit() {    
  } 

  ancla(id: any){
    this.viewportScroller.scrollToAnchor(id);
    window.scrollTo(0, window.scrollY - 80);
  }
}
