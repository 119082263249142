import { Component, HostListener, OnInit } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';


@Component({
  selector: 'app-check-browser-modal',
  templateUrl: './check-browser-modal.component.html',
  styleUrls: ['./check-browser-modal.component.scss']
})
export class CheckBrowserModalComponent implements OnInit {

  private restrictedBrowsers: string[] = [
    'Chrome',
    'Firefox',
    'MS-Edge-Chromium'
  ];

  deviceInfo: DeviceInfo = null;

  show: Boolean = true;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit() {

    this.deviceInfo = this.deviceService.getDeviceInfo();

    if (!this.deviceService.isDesktop()) {
      this.show = false;
    } else if (this.deviceInfo && this.deviceInfo.browser) {
      this.restrictedBrowsers.forEach(n => {
        if (n === this.deviceInfo.browser) {
          return this.show = false;
        }
      })
    }

  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (event.target.innerHTML.includes('loading-container-cont wow fadeInUp')) {
      // clicked outside => close modal
      this.show = false;
    }
  }

  closeModal() {
    this.show = false;
  }



}
