import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-profile-complete-bar',
    templateUrl: './profile-complete-bar.component.html',
    styleUrls: ['./profile-complete-bar.component.scss']
})
export class ProfileCompleteBarComponent {

    @Input() percent = 0;

}
