<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Detalle de póliza {{policyYear}}/{{policyNumber}}"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>


<div class="container">
    <div *ngIf="undefined === policy" class="products-detail-container pre-loading">
        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
    </div>

    <div class="no-text-select products-detail-container ">

        <div *ngIf="undefined !== policy">

            <div class="products-detail-container-product">
                <app-product-item [data]=policy></app-product-item>
            </div>

            <app-collapse-container *ngIf="policy.coverage !== undefined" caption="Coberturas contratadas">
                <div class="contracted-cont">
                    <div class="contracted-cont">
                        <div class="contracted-cont-title">
                            <div class="left">
                                <p>COBERTURA</p>
                            </div>

                            <div class="center">
                                <p *ngIf="policy.productId !== 'DJ21'">CAPITAL</p>
                            </div>
                            <div class="right">
                                <p *ngIf="policy.productId !== 'DJ21'">CAPITAL <br> LIBRE ELECCIÓN</p>
                            </div>

                        </div>
                    </div>
                    <div class="contracted-inner-app">
                        <div *ngFor="let item of policy.coverage">
                            <app-product-coverage [data]="item"></app-product-coverage>
                        </div>
                    </div>
                </div>
            </app-collapse-container>

            <app-collapse-container *ngIf="policy.insured !== undefined" caption="Datos del asegurado">
                <app-product-data *ngFor="let insured of policy.insured; let i = index" [insured]="insured" [first]="i===0"></app-product-data>
            </app-collapse-container>

            <app-collapse-container *ngIf="(policy.building !== undefined) || (policy.vehicle !== undefined)" caption="Datos del objeto asegurado">

                <div *ngFor="let b of policy.building">
                    <app-product-insured-building [data]="b"></app-product-insured-building>
                </div>

                <div *ngFor="let v of policy.vehicle">
                    <app-product-insured-vehicle [data]="v"></app-product-insured-vehicle>
                </div>

            </app-collapse-container>

            <app-collapse-container *ngIf=" ((policy.occupier !== undefined) || (policy.guarantor !== undefined)) " caption="Inquilino / Avalista">
                <app-product-tenant [occupier]="policy.occupier" [guarantor]="policy.guarantor"></app-product-tenant>
            </app-collapse-container>

            <app-collapse-container *ngIf="policy.receipt !== undefined" caption="Último recibo">
                <app-product-last-receipt [data]="policy.receipt"></app-product-last-receipt>
            </app-collapse-container>
            <!--<app-collapse-container caption="Comunidad">
                <app-product-comunity></app-product-comunity>
                <div class="contracted-link">
                    <a href="">Modificar datos</a>
                </div>
            </app-collapse-container>-->

            <!--TODO CLAÚSULAS-->
            <app-collapse-container *ngIf="policy.clause !== undefined" caption="Cláusulas">
                <div class="contracted-cont">
                    <div class="contracted-cont">
                        <div class="contracted-cont-title">
                            <div class="left">
                                <p>NOMBRE</p>
                            </div>

                            <div class="left">
                                <p>DESCRIPCIÓN</p>
                            </div>
                        </div>
                    </div>

                    <div class="contracted-inner-app">
                        <div *ngFor="let item of policy.clause">
                            <app-product-clause [data]="item"></app-product-clause>
                        </div>
                    </div>
                </div>
            </app-collapse-container>
            
        </div>

    </div>
</div>

<app-footer></app-footer>