var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Http } from '@angular/http';
import { DomainService } from './domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "./domain-service";
var WordpressService = /** @class */ (function () {
    function WordpressService(http, domainService) {
        this.http = http;
        this.domainService = domainService;
        this.widgets = null;
        this.menus = null;
        this.pages = null;
        this.categorias = null;
        this.imagen = null;
    }
    WordpressService.prototype.loadWidget = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.domainService.wordpressUrl + '/wp-json/widgets/get';
                        if (!(this.widgets === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.get(url).toPromise()];
                    case 1:
                        response = _a.sent();
                        this.widgets = response.json();
                        return [2 /*return*/, Promise.resolve(true)];
                    case 2: return [2 /*return*/, Promise.resolve(true)];
                }
            });
        });
    };
    WordpressService.prototype.getWidget = function (slug) {
        for (var widget in this.widgets) {
            if (widget === slug) {
                var item = this.widgets[widget];
                return null != item ? item : undefined;
            }
        }
    };
    WordpressService.prototype.loadMenu = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.domainService.wordpressUrl + '/wp-json/menus/get';
                        if (!(this.menus === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.get(url).toPromise()];
                    case 1:
                        response = _a.sent();
                        this.menus = response.json();
                        return [2 /*return*/, Promise.resolve(true)];
                    case 2: return [2 /*return*/, Promise.resolve(true)];
                }
            });
        });
    };
    WordpressService.prototype.getMenu = function (slug) {
        for (var menu in this.menus) {
            if (menu === slug) {
                return this.menus[menu];
            }
        }
    };
    WordpressService.prototype.loadPage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.domainService.wordpressUrl + '/wp-json/wp/v2/pages';
                        if (!(this.pages === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.get(url).toPromise()];
                    case 1:
                        response = _a.sent();
                        this.pages = response.json();
                        return [2 /*return*/, Promise.resolve(true)];
                    case 2: return [2 /*return*/, Promise.resolve(true)];
                }
            });
        });
    };
    WordpressService.prototype.getPage = function (slug) {
        for (var i = 0; i < this.pages.length; i++) {
            if (this.pages[i].slug === slug) {
                return this.pages[i];
            }
        }
    };
    WordpressService.prototype.loadCategories = function (catNumber) {
        var url = this.domainService.wordpressUrl + '/wp-json/wp/v2/posts?categories=' + catNumber;
        //const url = 'https://preclientes.onlygal.es' + '/wp-json/wp/v2/posts?categories=' + catNumber;
        return this.http.get(url).toPromise();
    };
    WordpressService.prototype.getCategorias = function () {
        if (this.categorias !== null) {
            return this.categorias;
        }
    };
    WordpressService.prototype.loadImages = function (url) {
        return this.http.get(url).toPromise();
    };
    WordpressService.prototype.getImage = function () {
        if (this.imagen !== null) {
            return this.imagen;
        }
    };
    WordpressService.ngInjectableDef = i0.defineInjectable({ factory: function WordpressService_Factory() { return new WordpressService(i0.inject(i1.Http), i0.inject(i2.DomainService)); }, token: WordpressService, providedIn: "root" });
    return WordpressService;
}());
export { WordpressService };
