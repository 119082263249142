import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, transition, useAnimation, state, style, animate } from '@angular/animations';

import { AuthenticationDTO } from '../../model/AuthenticationDTO';
import { AuthService } from '../../../security/auth.service';
import { AudityService } from 'app/services/audity-service';
import { CommonsUtils } from '../../../commons/CommonsUtils';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { Router } from '@angular/router';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-authentication-form',
    templateUrl: './authentication-form.component.html',
    styleUrls: ['./authentication-form.component.scss'],
    animations: [
        trigger('fadeInUp', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class AuthenticationFormComponent implements OnInit {

    @Output() onAuthenticated = new EventEmitter();

    authenticationForm: FormGroup;
    authenticationDTO: AuthenticationDTO;
    messages: String[] = null;
    recaptchaResolved: Boolean = false;
    running: Boolean = false;
    audity: AudityDTO = new AudityDTO();
    ipAddress: string;

    constructor(private formBuilder: FormBuilder,
        private authService: AuthService,
        private audityService: AudityService,
        private clubPropietarioService: ClubPropietarioService,
        private router: Router,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.authenticationDTO = new AuthenticationDTO();
        this.authenticationForm = this.formBuilder.group({
            username: [this.authenticationDTO.username, [Validators.required]],
            password: [this.authenticationDTO.password, [Validators.required, Validators.pattern(CommonsUtils.PASSWORD_REGEX)]]
        })

        this.authenticationForm.valueChanges.subscribe(data => { this.messages = null; });
        this.authService.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res._body;
        });
    }

    onAuthenticate() {

        this.messages = [];
        if (this.submitEnabled()) {
            this.running = true;
            this.authService.onAuthenticate(this.authenticationForm.value)
                .then(success => {
                    this.audity.audityDocument = this.authenticationForm.value.username;
                    this.audity.audityIp = this.ipAddress;
                    this.audityService.saveLogin(this.audity)
                        .catch(error => {
                            this.toastr.error(CommonsText.ERROR_SERVICIO_AUDITORIA, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                                timeOut: 5000
                            });
                        })
                    this.authService.innovaLogin(this.authenticationForm.value)
                        .then(success => {
                            localStorage.setItem('categorias', JSON.stringify(this.clubPropietarioService.getCategoriaToSave()));
                            this.running = false;
                            this.onAuthenticated.emit(true);
                        })
                        .catch(error => {
                            this.audity.audityDocument = this.authenticationForm.value.username;
                            this.audity.audityIp = this.ipAddress;

                            this.running = false;
                            const error_description = error.error.error_description;

                            switch (error_description) {
                                case 'BLOCKED_BY_RGPD': {
                                    this.audityService.saveLoginBlockedByRgpd(this.audity);
                                    this.messages = [CommonsText.BLOCKED_BY_RGPD];
                                    break;
                                }
                                case 'WITHOUT_POLICIES': {
                                    this.audityService.saveLoginWithoutPolicies(this.audity);
                                    this.messages = [CommonsText.WITHOUT_POLICIES];
                                    break;
                                }
                                default: {
                                    this.audityService.saveLoginIncorrectParameter(this.audity);
                                    this.messages = [CommonsText.PARAMETROS_INCORRECTOS];
                                    break;
                                }
                            }
                        })




                }).catch(error => {
                    this.audity.audityDocument = this.authenticationForm.value.username;
                    this.audity.audityIp = this.ipAddress;

                    this.running = false;
                    const error_description = error.error.error_description;

                    switch (error_description) {
                        case 'DISABLED': {
                            this.audityService.saveLoginDisabled(this.audity);
                            this.messages = [CommonsText.DISABLED];
                            break;
                        }
                        case 'BLOCKED': {
                            this.audityService.saveLoginBlocked(this.audity);
                            this.messages = [CommonsText.BLOCKED];
                            break;
                        }
                        case 'BLOCKED_BY_RENEWAL': {
                            this.audityService.saveLoginBlockedByRenewal(this.audity);
                            this.messages = [CommonsText.BLOCKED_BY_RENEWAL];
                            break;
                        }
                        default: {
                            this.audityService.saveLoginIncorrectParameter(this.audity);
                            this.messages = [CommonsText.PARAMETROS_INCORRECTOS];
                            break;
                        }
                    }

                })

        }

    }

    submitEnabled() {
        return this.authenticationForm.valid && !(this.running);
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.ENTRAR;
    }

}
