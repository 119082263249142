<div class="checkballs">
    <div class="checkballs-cont">
        <div class="checkballs-cont-step" [ngClass]="getProgressStep()">
            <div class="title">
                <p>Paso 1</p>
                <p>declaración</p>
            </div>
            <div class="check">
                <div [ngClass]="getStatus1()"></div>
            </div>
        </div>
        <div class="checkballs-cont-step">
            <div class="title">
                <p>Paso 2</p>
                <p>datos del asegurado</p>
            </div>
            <div class="check">
                <div [ngClass]="getStatus2()"></div>
            </div>
        </div>
        <div class="checkballs-cont-step">
            <div class="title">
                <p>Paso 3</p>
                <p>datos contacto</p>
                <p>para gestionar este parte</p>
            </div>
            <div class="check">
                <div [ngClass]="getStatus3()"></div>
            </div>
        </div>
    </div>
</div>