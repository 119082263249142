<div class="fandit">
  <div class="fandit-box"></div>
  <div class="fandit-icon">

      <div class="fandit-icon-img">
      </div>
  </div>
  <div class="fandit-policy">
      <div class="sup">
          &nbsp;
      </div>
      <div class="sup-1">
          <p>Tus ayudas y subvenciones más cerca</p>
      </div>

      <div class="inf">
          <p>Obtén información y apoyo sobre las ayudas y subvenciones a las que puedes optar con tu negocio fácilmente. Con esta herramienta tienes acceso a todas las ayudas disponibles actualmente (aprox. 18.000 al año) y actualizadas cuando se publican nuevas.</p>
      </div>

      <div class="inf-1">
          &nbsp;
      </div>

  </div>
  <div class="fandit-button">
    <form [target]="'_blank'" [method]="'POST'">
        <app-spinner-button [label]="'ACCEDER'"  [enabled]="true" [customSpinnerButton]="'hire-more'" (click)="openFandit()">
        </app-spinner-button>
    </form>
      
  </div>
</div>