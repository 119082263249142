/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-notify.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../commons/components/contact-line/contact-line.component.ngfactory";
import * as i3 from "../../../commons/components/contact-line/contact-line.component";
import * as i4 from "./email-notify.component";
var styles_EmailNotifyComponent = [i0.styles];
var RenderType_EmailNotifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailNotifyComponent, data: { "animation": [{ type: 7, name: "fadeInUp", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(5%)" }, offset: null }, { type: 4, styles: null, timings: 450 }], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 600 }, options: null }], options: {} }] } });
export { RenderType_EmailNotifyComponent as RenderType_EmailNotifyComponent };
export function View_EmailNotifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container-send no-text-select"]], [[24, "@fadeInUp", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comprueba tu correo"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Te hemos enviado un correo electr\u00F3nico. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Comprueba tu buz\u00F3n de entrada."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-contact-line", [], null, null, null, i2.View_ContactLineComponent_0, i2.RenderType_ContactLineComponent)), i1.ɵdid(10, 49152, null, 0, i3.ContactLineComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_EmailNotifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-notify", [], null, null, null, View_EmailNotifyComponent_0, RenderType_EmailNotifyComponent)), i1.ɵdid(1, 49152, null, 0, i4.EmailNotifyComponent, [], null, null)], null, null); }
var EmailNotifyComponentNgFactory = i1.ɵccf("app-email-notify", i4.EmailNotifyComponent, View_EmailNotifyComponent_Host_0, { email: "email" }, {}, []);
export { EmailNotifyComponentNgFactory as EmailNotifyComponentNgFactory };
