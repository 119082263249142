import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ControlContainer, FormControl, Validators } from '@angular/forms';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';
@Component({
    selector: 'app-modify-contact',
    templateUrl: './modify-contact.component.html',
    styleUrls: ['./modify-contact.component.scss']
})
export class ModifyContactComponent implements OnInit {

    @Input() modifyContactForm: FormGroup;
    @Input() contactProgress: any;
    @Input() addressProgress: any;
    @Input() roadTypeList: any[] = [];

    documentType: string;
    documentNumber: string;
    actualPostalCode: String = '00000';

    messages: String[] = null;
    running: Boolean;
    loadingCities: Boolean = false;
    patching: Boolean = false;
    contactFactor: any;
    addressFactor: any;

    roadTypeSettings: any;
    province: string;
    citySettings: any;
    cityList: any = [];

    prefixData: any[];
    prefixSettings: any;

    showDirectAreas: Boolean = true;

    maxDate: Date;

    isCompany: boolean = false;

    private CODE_ERROR_EMAIL_ALREADY_REGISTERED: string = 'email.already_registered';
    private ERROREMAILACONT: string = 'ERROREMAILACONT';
    private ERRORMOBILECONT: string = 'ERRORMOBILECONT';
    private ERRORPHONECONT: string = 'ERRORPHONECONT';
    private prefijoPhoneFlag: any = null;
    private prefijoFijoPhoneFlag: any = null;

    @Input() set client(value: ClientDataDTO) {
        if (undefined !== value) {
            this.patching = true;
            this.modifyContactForm.patchValue({ name: this.parseValue(value.name) });
            this.modifyContactForm.patchValue({ surname: this.parseValue(value.surname) });
            this.modifyContactForm.patchValue({ lastName: this.parseValue(value.lastName) });
            this.modifyContactForm.patchValue({
                birthDate: (
                    value.birthDate ?
                        this.parseValue(value.birthDate.replace(/\//g, "-"))
                        : null)
            }
            );
            this.modifyContactForm.patchValue({ email: this.parseValue(value.email) });
            this.modifyContactForm.patchValue({ phone: this.parseValue(value.phone) });

            this.modifyContactForm.patchValue({ phonePrefix: this.parsePrefixValue(value.phonePrefix) });
            this.modifyContactForm.patchValue({ mobilePhone: this.parseValue(value.mobilePhone) });

            this.modifyContactForm.patchValue({ mobilePhonePrefix: this.parsePrefixValue(value.mobilePhonePrefix) });
            this.modifyContactForm.patchValue({ address: this.parseValue(value.address) });

            this.modifyContactForm.patchValue({ province: this.parseValue(value.province) });
            this.province = this.parseValue(value.province);

            this.modifyContactForm.patchValue({ postalCode: this.parseValue(value.postalCode) });
            this.modifyContactForm.patchValue({ cityCb: this.parseCityValue(value) });
            this.modifyContactForm.patchValue({ roadType: this.parseSelectValue(value.roadType) });
            this.modifyContactForm.patchValue({ number: this.parseValue(value.number) });
            this.modifyContactForm.patchValue({ portal: this.parseValue(value.portal) });
            this.modifyContactForm.patchValue({ stair: this.parseValue(value.stair) });
            this.modifyContactForm.patchValue({ floor: this.parseValue(value.floor) });
            this.modifyContactForm.patchValue({ door: this.parseValue(value.door) });

            this.modifyContactForm.patchValue({ notificationChannel: this.parseValueRadioButton(value.notifications) });

            this.patching = false;

            this.contactProgress = this.clientService.getContactPercent(value);
            this.addressProgress = this.clientService.getAddressPercent(value);
            this.documentType = value.documentType;
            this.documentNumber = value.documentNumber;

        }
    }

    constructor(private controlContainer: ControlContainer,
        private clientService: ClientService,
        private toastr: ToastrService,
        private authService: AuthService) {

        this.prefixData = CommonsUtils.phonePrefix();

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };


    }


    get name() { return this.modifyContactForm.get('name'); }

    checkValidatorErrors() {
        if (this.modifyContactForm.get('name').errors.checkUserAge) {
            return "(*) Tiene que ser mayor de edad";
        }
        if (this.modifyContactForm.get('name').errors.minLength) {
            return "(*) Campo obligatorio";
        }
    }

    ngOnInit() {

        this.setMaxDate();

        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
            const user = this.authService.getUser();
            user.documentType && user.documentType == 'CIF' ? this.isCompany = true : this.isCompany = false;
        }
        this.modifyContactForm = <FormGroup>this.controlContainer.control;

        this.modifyContactForm.valueChanges.subscribe(data => {
            if (!this.patching) {
                this.checkContactPercent();
                this.checkAddressPercent();
            }
            this.messages = null;
        });

        this.contactFactor = this.clientService.getContactFactor();
        this.addressFactor = this.clientService.getAddressFactor();

        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
    }


    onModifyContact() {
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.modifyContactForm.valid) {
                this.running = true;
                this.changeFormatBirthDate();
                this.clientService.onModifyContact(this.modifyContactForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.PERFIL_ACTUALIZADO_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });
                    }).catch(error => {
                        this.running = false;
                        if (error.error && error.error.developerResponse && error.error.developerResponse === this.CODE_ERROR_EMAIL_ALREADY_REGISTERED) {
                            this.toastr.error(error.response, CommonsText.ERROR_OPERACION_CORREO_EN_USO, {
                                timeOut: 5000
                            });
                            this.messages = ['Este correo electrónico ya está en uso.'];
                            this.modifyContactForm.get("email").setErrors({ 'incorrect': true });
                        } else {
                            this.messages = error.error.response;
                            if (undefined === error.error.response) {
                                this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                            } else {

                                if (error.error && error.error.developerResponse && error.error.developerResponse === this.ERROREMAILACONT) {
                                    this.modifyContactForm.get("email").setErrors({ 'incorrect': true });
                                    this.modifyContactForm.get("email").markAsTouched({ onlySelf: true });
                                }

                                if (error.error && error.error.developerResponse && error.error.developerResponse === this.ERRORMOBILECONT) {
                                    this.modifyContactForm.get("mobilePhone").setErrors({ 'incorrect': true });
                                    this.modifyContactForm.get("mobilePhone").markAsTouched({ onlySelf: true });
                                }

                                if (error.error && error.error.developerResponse && error.error.developerResponse === this.ERRORPHONECONT) {
                                    this.modifyContactForm.get("phone").setErrors({ 'incorrect': true });
                                    this.modifyContactForm.get("phone").markAsTouched({ onlySelf: true });

                                }

                                this.toastr.error(error.error.response, CommonsText.ERROR_DE_OPERACION, {
                                    timeOut: 5000
                                });
                            }
                        }
                    })

            } else {

                Object.keys(this.modifyContactForm.controls).forEach(field => {
                    this.modifyContactForm.get(field).markAsTouched({ onlySelf: true });
                });

                this.modifyContactForm.controls['cityCb'].markAsTouched({ onlySelf: true });

            }
        }
    }

    setMaxDate() {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        this.maxDate = date;
    }

    changeFormatBirthDate() {
        if (this.isCompany){
            this.modifyContactForm.value.birthDate = null;
        } else if(this.modifyContactForm.value.birthDate) {
            if(this.modifyContactForm.value.birthDate instanceof Date){
                this.modifyContactForm.value.birthDate = `${this.addLeadingZero(this.modifyContactForm.value.birthDate.getDate())}/${this.addLeadingZero(this.modifyContactForm.value.birthDate.getMonth() + 1)}/${this.modifyContactForm.value.birthDate.getFullYear()}`
            } else {
                this.modifyContactForm.value.birthDate = this.modifyContactForm.value.birthDate.replace(/\//g, "-")
            }
        }
    }

    private addLeadingZero(value: number): string {
        if (value < 10) {
            return `0${value.toString()}`;
        }
        return value.toString();
    }

    submitEnabled() {
        return !this.running;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.GUARDAR_CAMBIOS_MINUSCULA;
    }

    getRadioButtonNotificationClass() {
        return this.isInvalid('notificationChannel') ? 'invalid' : '';
    }

    checkContactPercent() {
        this.contactProgress = 100;
        this.checkContactControl('name');
        this.checkContactControl('surname');
        this.checkContactControl('email');
        this.checkContactControl('mobilePhone');
        this.checkContactControl('birthDate');
    }

    checkAddressPercent() {
        this.addressProgress = 100;
        this.checkAddressControl('postalCode');
        this.checkAddressControl('cityCb');
        this.checkAddressControl('roadType');
        this.checkAddressControl('address');
        this.checkAddressControl('number');
    }

    checkContactControl(controlName: string) {
        const control = this.modifyContactForm.controls[controlName];
        if (control.invalid) {
            this.contactProgress = this.contactProgress - this.contactFactor;
        }
    }

    checkAddressControl(controlName: string) {
        const control = this.modifyContactForm.controls[controlName];
        if (control.invalid) {
            this.addressProgress = this.addressProgress - this.addressFactor;
        }
    }

    parseValue(value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        } else {
            return value;
        }
    }

    parseSelectValue(value) {
        if (null === value || undefined === value) {
            return [];
        } else {
            return value;
        }
    }

    parsePrefixValue(value) {
        let prefix = undefined;
        if (null !== value && undefined !== value) {
            this.prefixData.forEach(number => {
                if (number.id === value) {
                    prefix = number;
                }
            })
        }

        if (undefined !== prefix) {
            return [prefix];
        } else {
            return null;
        }

    }

    parseValueRadioButton(value) {
        if (value && value.length > 0) {
            return value[0];
        } else {
            return null;
        }
    }

    parseCityValue(value) {
        if (null === value.city || undefined === value.city || '' === value.city.trim()) {
            return null;
        } else {
            this.cityList.push({
                id: value.city, itemName: value.cityDescription, province: value.province
            });
            return [{ id: value.city, itemName: value.cityDescription }];
        }
    }

    parseCheckBoxValue(value, key) {
        if (null === value || undefined === value) {
            return false;
        } else {
            return value.some(e => e === key);
        }
    }

    searchCity() {
        const postalCode = this.modifyContactForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(success => {
                    this.actualPostalCode = postalCode;

                    this.loadingCities = false;
                    this.cityList = [];
                    this.modifyContactForm.controls['cityCb'].patchValue(null);

                    for (const city of success.response) {
                        this.cityList.push({
                            id: city.id, itemName: city.name, province: city.province
                        });
                    }

                    if (success.response.length === 1) {
                        const city = success.response[0];
                        this.modifyContactForm.patchValue({ cityCb: [{ id: city.id, itemName: city.name, province: city.province }] });

                        this.modifyContactForm.patchValue({ province: this.parseValue(city.province) });
                        this.province = this.parseValue(city.province);

                    }

                }).catch(error => {
                    this.loadingCities = false;
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                })
        }
    }

    onChangePostalCode(data) {
        const v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    }

    onCitySelect(item: any) {
        this.province = this.parseValue(item.province);
        this.modifyContactForm.patchValue({ province: this.parseValue(item.province) });
    }

    isInvalid(controlName: string): boolean {
        const control = this.modifyContactForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    }

    cambioPrefijo(controlName: string, event){

        if(controlName !== null && controlName === 'phone'){
            if(event.length > 0 && event[0].id !== this.prefijoFijoPhoneFlag ){
                let variable1 = this.modifyContactForm.get(controlName).value;
                this.modifyContactForm.get(controlName).setErrors(null);
                this.prefijoFijoPhoneFlag = this.modifyContactForm.get("phonePrefix").value[0].id;
                this.modifyContactForm.get(controlName).setValue(null);
                this.modifyContactForm.get(controlName).setValue(variable1);
            }
        }

        if(controlName !== null && controlName === 'mobilePhone'){
            if(event.length > 0 && event[0].id !== this.prefijoPhoneFlag ){
                let variable2 = this.modifyContactForm.get(controlName).value;
                this.modifyContactForm.get(controlName).setErrors(null);
                this.prefijoPhoneFlag = this.modifyContactForm.get("mobilePhonePrefix").value[0].id;
                this.modifyContactForm.get(controlName).setValue(null);
                this.modifyContactForm.get(controlName).setValue(variable2);
            }
        }

    }

}
