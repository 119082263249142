import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';

declare function resetChat(): any;
declare function desloadChat(): any;

@Injectable()
export class LogoutGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        this.authService.logout();
        resetChat();
        desloadChat();
        return false;
    }
}
