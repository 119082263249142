<div class="modify-container" [formGroup]="modifyContactForm">
    <div class="personals">
        <div class="title">
            <p>Datos personales</p>
        </div>
        <div class="bar">
            <app-profile-complete-bar [percent]="contactProgress"></app-profile-complete-bar>
        </div>
    </div>

    <div class="form">
        <div class="row row-5050">
            <div class="inner-5050">
                <app-input-box type="text" label="NOMBRE" formControlName="name" maxlength="160"
                    errorDescription="(*) Campo obligatorio"></app-input-box>
            </div>
            <div class="inner-5050">
                <app-input-box type="text" label="PRIMER APELLIDO" formControlName="surname" maxlength="60"
                    errorDescription="(*) Campo obligatorio"></app-input-box>
            </div>
        </div>
        <div class="row row-5050">
            <div class="inner-5050">
                <app-input-box type="text" label="SEGUNDO APELLIDO" formControlName="lastName" maxlength="60">
                </app-input-box>
            </div>
            <div class="row-5050 inner-5050">
                <div class="disabledField select">
                    <label for="">DOCUMENTO</label>
                    <P>{{documentType}}</P>
                </div>
                <div class="disabledField select">
                    <label for="">Nº DE DOCUMENTO</label>
                    <p>{{documentNumber}}</p>
                </div>
            </div>
        </div>
        <!-- <div class=" row-100">
            <app-input-box type="text" label="E-MAIL" formControlName="email" errorDescription="(*) Campo obligatorio"
                maxlength="128">
            </app-input-box>
        </div> -->

        <div class="row row-5050">
            <div class="inner-5050">
                <app-input-box type="text" label="E-MAIL" formControlName="email"
                    errorDescription="(*) Campo obligatorio" maxlength="128">
                </app-input-box>
            </div>
            <div class="inner-5050 mat-app-background ">
                <!--<app-date-picker label="FECHA DE NACIMIENTO" formControlName="birthDate"
                    minAgeErrorDescription="(*) Debe ser mayor de edad" errorDescription="(*) Campo obligatorio">
                </app-date-picker>-->

                <app-input-datepicker-box *ngIf="!isCompany" label="FECHA DE NACIMIENTO" formControlName="birthDate" [maxDate]="maxDate"
                    minAgeErrorDescription="(*) Debe ser mayor de edad" errorDescription="(*) Campo obligatorio">
                </app-input-datepicker-box>

            </div>
        </div>


        <div class="row row-5050">

            <div class="row inner-5050 row-3070">

                <div class="cont" style="padding-bottom: 25px;">
                    <angular2-multiselect [data]="prefixData" [settings]="prefixSettings" 
                        formControlName="phonePrefix" (ngModelChange)="cambioPrefijo('phone', $event)">
                        <c-badge>
                            <ng-template let-item="item">
                                <label class="phoneBadge">
                                    <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                    <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                    <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-badge>

                        <c-item>
                            <ng-template let-item="item">
                                <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                    <span class="flags flag{{item.id}}"></span>
                                    <span>+{{item.id}}</span>
                                    <span>{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>

                <div class="cont">
                    <app-input-box type="text" label="TELÉFONO FIJO" formControlName="phone" maxlength="128"
                        mask="000000000000000000" 
                        isValidPhoneErrorDescription="Número no válido" 
                        errorDescription="(*) Campo obligatorio">
                    </app-input-box>
                </div>

            </div>

            <div class="row inner-5050 row-3070">

                <div class="cont" style="padding-bottom: 25px;">
                    <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                        formControlName="mobilePhonePrefix" (ngModelChange)="cambioPrefijo('mobilePhone', $event)">
                        <c-badge>
                            <ng-template let-item="item">
                                <label class="phoneBadge">
                                    <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                    <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                    <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-badge>

                        <c-item>
                            <ng-template let-item="item">
                                <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';" >
                                    <span class="flags flag{{item.id}}"></span>
                                    <span>+{{item.id}}</span>
                                    <span>{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>

                <div class="cont">
                    <app-input-box type="text" label="TELÉFONO MOVIL" formControlName="mobilePhone" maxlength="128"
                        mask="000000000000000000"
                        isValidPhoneErrorDescription="Número no válido"
                        errorDescription="(*) Campo obligatorio">
                    </app-input-box>
                </div>

            </div>
        </div>
    </div>
    <div class="address">
        <div class="title">
            <p>Domicilio</p>
        </div>
        <div class="bar">
            <app-profile-complete-bar [percent]="addressProgress"></app-profile-complete-bar>
        </div>
    </div>
    <div class="form">

        <div class="row row-302050">
            <div class="cont">
                <app-input-box type="text" label="C. POSTAL" formControlName="postalCode" mask="00000"
                    errorDescription="(*) Campo obligatorio" maxlength="5" (keyup)="onChangePostalCode($event)">
                </app-input-box>
            </div>
            <div class="cont searchButton">
                <a (click)="searchCity()">Buscar población</a>
            </div>

            <div class="cont">
                <angular2-multiselect [data]="cityList" [settings]="citySettings" formControlName="cityCb"
                    (onSelect)="onCitySelect($event)"></angular2-multiselect>

                <span *ngIf="loadingCities" class="spinner"></span>

                <div class="description">
                    <p *ngIf="isInvalid('cityCb')" class="text-red text-bold">
                        <label>
                            (*) Campo obligatorio
                        </label>
                    </p>
                </div>

            </div>
        </div>
        <div class=" row-100 row-province">
            <div class="disabledField select">
                <label for="">Provincia</label>
                <p>{{province}}</p>
            </div>
        </div>
        <div class="row row-3070">
            <div class="cont">
                <angular2-multiselect [data]="roadTypeList" [settings]="roadTypeSettings" formControlName="roadType">
                </angular2-multiselect>

                <div class="description">
                    <p *ngIf="isInvalid('roadType')" class="text-red text-bold">
                        <label>
                            (*) Campo obligatorio
                        </label>
                    </p>
                </div>

            </div>
            <div class="cont">
                <app-input-box type="text" label="DOMICILIO" formControlName="address"
                    errorDescription="(*) Campo obligatorio" maxlength="80"></app-input-box>
            </div>
        </div>
        <div class="row">
            <div class="row-20">
                <app-input-box type="text" label="NÚMERO" formControlName="number"
                    errorDescription="(*) Campo obligatorio" maxlength="5"></app-input-box>
            </div>
            <div class="row-20">
                <app-input-box type="text" label="PORTAL" formControlName="portal" maxlength="32"></app-input-box>
            </div>
            <div class="row-20">
                <app-input-box type="text" label="ESCALERA" formControlName="stair" maxlength="16"></app-input-box>
            </div>
            <div class="row-20">
                <app-input-box type="text" label="PISO" formControlName="floor" maxlength="20"></app-input-box>
            </div>
            <div class="row-20">
                <app-input-box type="text" label="PUERTA" formControlName="door" mask="AA" maxlength="16"></app-input-box>
            </div>
        </div>
    </div>
    <div class="notify" *ngIf="showDirectAreas">
        <div class="title">
            <p>Canal de notificaciones</p>
        </div>
    </div>
    <div class="form" *ngIf="showDirectAreas">
        <div class="row row-3070">
            <div class="cont cont-notify">
                <div class="row">
                    <label for="" [ngClass]="getRadioButtonNotificationClass()">
                        Canal de contacto preferido:
                    </label>
                </div>
            </div>

            <div class="cont cont-notify">
                <div class="row">
                    <div class="inner-input">
                        <label [ngClass]="getRadioButtonNotificationClass()">
                            <input type="radio" formControlName="notificationChannel" value="SMS">SMS
                        </label>
                        <label [ngClass]="getRadioButtonNotificationClass()">
                            <input type="radio" formControlName="notificationChannel" value="EMAIL">Email
                        </label>
                        <label [ngClass]="getRadioButtonNotificationClass()">
                            <input type="radio" formControlName="notificationChannel" value="NINGUNO">Ninguno
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-3070">
            <div class="cont cont-notify">
                <div class="row invalid-message" *ngIf="isInvalid('notificationChannel')">
                    <label>
                        (*) Campo obligatorio
                    </label>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="buttons">
        <div class="message">{{messages}}</div>
        <app-spinner-button [enabled]="modifyContactForm.valid" [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"
            (click)="onModifyContact()">
        </app-spinner-button>
    </div>
</div>