import { Component, HostListener, ElementRef, Input, Self, Optional, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IsMobileService } from '../isMobile.service';

@Component({
    selector: 'app-time-picker',
    templateUrl: './timePicker.component.html',
    styleUrls: ['./timePicker.component.scss']
})

export class TimePickerComponent implements OnInit, ControlValueAccessor {

    @Input() label: string;
    @Input() disabledHours: [];
    @Input() description: String;
    @Input() errorDescription: String;

    pickerVisible: Boolean = false;
    isMobile: boolean;
    hour: number;
    minute: number;
    disabled: Boolean = false;

    value: any = '';

    @HostListener('document:click', ['$event'])
    offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.pickerVisible = false;
        }
    }

    constructor(@Self() @Optional() public ngControl: NgControl, isMobileService: IsMobileService, private elementRef: ElementRef) {
        this.isMobile = isMobileService.isMobile;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() { }

    writeValue(value: any): void {
        this.parseValue(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange(event) {
    }

    onTouched() { }

    parseValue(value: string) {
        if ((undefined !== value) && (null !== value)) {
            if (value.trim() === '') {
                this.value = '';
            } else {
                const hour = value.split(':')[0];
                const minute = value.split(':')[1];
                if (parseInt(hour, 0)) {
                    this.hour = parseInt(hour, 0);
                    if (this.hour > 23) { this.hour = 0; }

                } else {
                    this.hour = 0;
                }
                if (parseInt(minute, 0)) {
                    this.minute = parseInt(minute, 0);
                    if (this.minute > 59) { this.minute = 0; }
                } else {
                    this.minute = 0;
                }
                this.value = `${hour}:${minute}`
            }
        }
    }

    onSetTimeToNow(): void {
        const now = new Date();
        this.value = `${this.formatNumber(now.getHours())}:${this.formatNumber(now.getMinutes())}`;
        this.hour = now.getHours();
        this.minute = now.getMinutes();
        this.onChange(this.value);
    }

    onSelectCallback(value) {
        this.onChange(value);
        this.parseValue(value);
    }

    formatNumber(number: any): string {
        if (!(typeof (number) === 'string' || number instanceof String)) {
            const n: number = number;
            return <string>(n <= 9 ? '0' + n : n)
        }
        return number.toString();
    }

    onFocusOut(value) {

        if ((undefined !== value) && (null !== value) && (value.trim() !== '')) {
            const hour = value.split(':')[0];
            const minute = value.split(':')[1];
            if (parseInt(hour, 0)) {
                this.hour = parseInt(hour, 0);

            } else {
                this.hour = 0;
            }
            if (parseInt(minute, 0)) {
                this.minute = parseInt(minute, 0);
            } else {
                this.minute = 0;
            }
        }

        if (((this.hour > 23) || (this.minute > 59)) ||
            ((undefined !== this.disabledHours) && this.disabledHours.some(e => e === (this.hour < 10 ? '0' + this.hour : this.hour)))) {
            this.hour = 0;
            this.minute = 0;
            this.value = '';
            this.onChange('');
        }

    }

}
