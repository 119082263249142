import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-event-register-check-balls',
    templateUrl: './event-register-check-balls.component.html',
    styleUrls: ['./event-register-check-balls.component.scss']
})
export class EventRegisterCheckBallsComponent {

    @Input() page: number;

    getProgressStep(): any {
        return 'progress-step-' + (this.page + 1);
    }

    getStatus1() {
        if (this.page > 0) {
            return 'finished';
        } else {
            return 'start';
        }
    }


    getStatus2() {
        if (this.page > 1) {
            return 'finished';
        } else if (this.page === 1) {
            return 'start';
        } else {
            return '';
        }
    }

    getStatus3() {
        if (this.page > 2) {
            return 'finished';
        } else if (this.page === 2) {
            return 'start';
        } else {
            return '';
        }
    }

}
