<div class="send-box" *ngIf="message.messageType == 'CLIEACIA'">

    <div class="message-client">
        <div class="message-author-client">
            {{message.name}}
        </div>
    </div>


    <div class="message-content">
        <div class="message-content-client">
            {{message.message}}

            <div *ngIf="attachedDocument">
                <p>
                    Documentación anexada:
                </p>
                <span (click)="onGetEventFile()">
                    {{attachedDocument.name}}
                </span>
            </div>
        </div>
        <div class="message-author-status">
            {{message.creationDate | date:'dd-MM-yyyy HH:mm:ss'}} -
            {{message.status == 'LEIDO' ? 'Leído por el tramitador' : 'Pendiente de lectura'}}
            {{message.status == 'LEIDO' ? (message.readDate | date:'dd-MM-yyyy HH:mm:ss') : ''}}
        </div>
    </div>



</div>

<div class="send-box" *ngIf="message.messageType == 'CIAACLIE'">

    <div class="message-content-main">
        <div class="message-content-manager">
            {{message.message}}
            <div *ngIf="attachedDocument">
                <p>
                    Documentación anexada:
                </p>
                <span (click)="onGetEventFile()">
                    {{attachedDocument.name}}
                </span>
            </div>
        </div>
        <div class="message-author-status">
            Enviado: {{message.creationDate | date:'dd-MM-yyyy HH:mm:ss'}} -
            {{message.status == 'LEIDO' ? 'Leído' : 'Pendiente de lectura'}}
            {{message.status == 'LEIDO' ? 
                (message.readDate ?  (message.readDate | date:'dd-MM-yyyy HH:mm:ss') 
                    :  (currentDate | date:'dd-MM-yyyy HH:mm:ss'))
                : ''}}
        </div>
    </div>


    <div class="message-manager">
        <div class="message-author-manager">
            <div class="message-author-manager-logo">
                <img src="/resources/assets/images/bitmapOnLy.png">
            </div>
            <div class="message-author-manager-name">{{message.name}}</div>
        </div>

    </div>

</div>