import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-club-politica',
  templateUrl: './club-politica.component.html',
  styleUrls: ['./club-politica.component.scss']
})
export class ClubPoliticaComponent implements OnInit {
  

  constructor(private route: ActivatedRoute,
    private clientService: ClientService,
     private toastr: ToastrService,
     private router: Router) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].style.backgroundColor = '#ffffff';
  }

  ngOnDestroy (){
    document.getElementsByTagName('body')[0].style.backgroundColor = '';
  }

  ngAfterViewInit() {
      this.clientService.getBannersInfo()
          .then(success => {
          }).catch(error => {
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });
    }

}