import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ReceiptService } from 'app/services/receipt-service';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-receipt-filter',
    templateUrl: './receipt-filter.component.html',
    styleUrls: ['./receipt-filter.component.scss']
})
export class ReceiptFilterComponent implements OnInit {

    filterForm: FormGroup;

    statusTypeList = [];
    productList = [];
    policyList = [];
    statusTypeSettings = {};
    productSettings = {};
    policySettings = {};

    @Input() set response(value: any) {

        if (undefined !== value) {
            this.statusTypeList = value.statusType;
            this.productList = value.product;
            this.policyList = value.policyItems;
        }
    }

    @Input() loading: Boolean = false;

    constructor(private receiptService: ReceiptService,
        private formBuilder: FormBuilder) { }

    ngOnInit(): void {

        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.productSettings = {
            singleSelection: false,
            text: 'Todos los seguros',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.policySettings = {
            singleSelection: false,
            text: 'Todas las pólizas',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.filterForm = this.formBuilder.group({
            startDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            endDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            statusType: [[], []],
            policyNumber: ['', []],
            policyCollective: ['', []],
            product: [[], []],
            policyItems: [[], []]
        });

    }

    getButtonLabel() {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    }

    onFilter() {
        this.receiptService.setPageFilter(this.filterForm.value);
    }

    comprobarDesde(event){
        if(event !== "" && this.filterForm.value.endDate !== ""){

            let dateParts1 = event.split("-");
            let date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]); 

            let dateParts2 = this.filterForm.value.endDate.split("-")
            let date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]); 

            if(date1.getTime() > date2.getTime()){
                this.filterForm.controls['startDate'].setErrors({ErrorStartDay: true});
            }
            else if(date1.getTime() < date2.getTime()){
                this.filterForm.controls['endDate'].setErrors(null);
            } 
            else if(date1.getTime() == date2.getTime()){
                this.filterForm.controls['endDate'].setErrors(null);
            }
        }

    }

    comprobarHasta(event){
        if(event !== "" && this.filterForm.value.startDate !== ""){

            let dateParts1 = event.split("-");
            let date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]); 

            let dateParts2 = this.filterForm.value.startDate.split("-")
            let date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]); 

            if(date1.getTime() < date2.getTime()){
                this.filterForm.controls['endDate'].setErrors({ErrorEndDay: true});
            }
            else if(date1.getTime() > date2.getTime()){
                this.filterForm.controls['startDate'].setErrors(null);
            } 
            else if(date1.getTime() == date2.getTime()){
                this.filterForm.controls['startDate'].setErrors(null);
            }
        }

    }

}
