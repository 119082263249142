import { OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PolicyService } from 'app/services/policy-service';
import { EventService } from 'app/services/event-service';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';
var EventRegisterFirstStepComponent = /** @class */ (function () {
    function EventRegisterFirstStepComponent(route, controlContainer, toastr, eventService, policyService) {
        this.route = route;
        this.controlContainer = controlContainer;
        this.toastr = toastr;
        this.eventService = eventService;
        this.policyService = policyService;
        this.selected = new EventEmitter();
        this.declarationType = new EventEmitter();
        this.loadingPolicies = false;
        this.dropdownList = [];
        this.selectedItems = [];
        this.dropdownSettings = {};
    }
    Object.defineProperty(EventRegisterFirstStepComponent.prototype, "policy", {
        set: function (data) {
            this.data = data;
        },
        enumerable: true,
        configurable: true
    });
    EventRegisterFirstStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        var policyNumber = this.route.snapshot.params['policyYear'] + '/' + this.route.snapshot.params['policyNumber'];
        this.firstStepForm = this.controlContainer.control;
        this.dropdownSettings = {
            singleSelection: true,
            text: 'Selecciona un Seguro',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.loadingPolicies = true;
        this.policyService.getCurrent()
            .then(function (success) {
            _this.loadingPolicies = false;
            for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                var policy = _a[_i];
                var policyItem = { id: policy.number, itemName: policy.number + ' - ' + policy.product };
                _this.dropdownList.push(policyItem);
                if ((undefined !== policyNumber) && (null !== policyNumber)) {
                    if (policyItem.id === policyNumber) {
                        _this.firstStepForm.patchValue({ policyNumber: [policyItem] });
                        _this.onPolicySelect(policyItem);
                    }
                    if (success.response.length === 1) {
                        _this.firstStepForm.patchValue({ policyNumber: [policyItem] });
                        _this.onPolicySelect(policyItem);
                    }
                }
            }
        }).catch(function (error) {
            _this.loadingPolicies = false;
            _this.dropdownList = [];
            _this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
    };
    EventRegisterFirstStepComponent.prototype.onPolicySelect = function (item) {
        var _this = this;
        this.loadingPolicies = true;
        this.firstStepForm.controls['risk'].reset();
        this.eventService.getPolicyEventByNumber(item.id)
            .then(function (success) {
            _this.loadingPolicies = false;
            _this.selected.emit(success.response);
        }).catch(function (error) {
            _this.loadingPolicies = false;
            _this.selected.emit(undefined);
            _this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
    };
    EventRegisterFirstStepComponent.prototype.errorDescription = function (controlName) {
        var control = this.firstStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    };
    EventRegisterFirstStepComponent.prototype.onChangeDeclaration = function (event) {
        this.declarationType.emit(event.target.value);
    };
    return EventRegisterFirstStepComponent;
}());
export { EventRegisterFirstStepComponent };
