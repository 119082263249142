<form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" class="no-text-select form">

    <div class="title row">
        <p>Cambio de contraseña Clientes Onlygal</p>
    </div>

    <div class="row">
        <app-input-box type="password" label="Contraseña" description="Informe una nueva contraseña" autocomplete="nope" errorDescription="Informe una nueva contraseña" formControlName="password" maxlength="16"  tooltip="Utiliza ocho caracteres como mínimo combinando letras mayúsculas y minúsculas, números y símbolos (€-.,´`+¡'}{][¬~€#@|\;:_^*¿?=)(/&%$·&quot;!)"></app-input-box>
    </div>

    <div class="row">
        <app-input-box type="password" label="Confirmación de Contraseña" description="Confirmación de contraseña" autocomplete="nope" errorDescription="La contraseña debe ser la misma que la confirmación" formControlName="password2" maxlength="16"></app-input-box>
    </div>

    <div class="row reca">
        <app-google-recaptcha (onRecaptcha)="onRecaptcha($event)"></app-google-recaptcha>
    </div>

    <div class="row">
        <app-error-messages [(messages)]=messages></app-error-messages>
    </div>

    <div class="row">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"></app-spinner-button>
    </div>

</form>