<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-main-profile></app-header-main-profile>
        </div>
        <div class="sub-nav">
            <app-header-main-center-navigation></app-header-main-center-navigation>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="body">

    <div class="wow fadeInUp" data-wow-duration="1s" style="min-height: 57vh;">
        <app-carousel-container></app-carousel-container>
    </div>

    <div *ngIf="!showClubAreas">
        <app-blog-container></app-blog-container>
    </div>
    <div *ngIf="showClubAreas && showCategoria">
        <app-noticias-club [categoria]="categorias"></app-noticias-club>
    </div>

</div>

<app-footer></app-footer>