import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'app/security/auth.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./domain-service";
var DasLexService = /** @class */ (function () {
    function DasLexService(http, authService, toastr, domainService) {
        this.http = http;
        this.authService = authService;
        this.toastr = toastr;
        this.domainService = domainService;
    }
    DasLexService.prototype.setPageSize = function (value) {
        this.pageSizeChange.next(value);
    };
    DasLexService.prototype.setPageOffset = function (value) {
        this.pageOffsetChange.next(value);
    };
    DasLexService.prototype.setPageFilter = function (value) {
        this.pageFilterChange.next(value);
    };
    DasLexService.prototype.observerPageSizeChange = function () {
        this.pageSizeChange = new Subject();
        return this.pageSizeChange.asObservable();
    };
    DasLexService.prototype.observerPageOffset = function () {
        this.pageOffsetChange = new Subject();
        return this.pageOffsetChange.asObservable();
    };
    DasLexService.prototype.observerPageFilter = function () {
        this.pageFilterChange = new Subject();
        return this.pageFilterChange.asObservable();
    };
    DasLexService.prototype.getDasLexServices = function () {
        var url = this.domainService.backendUrlWithContext + '/dasLex/apiListadoServicios/';
        return this.http.get(url).toPromise();
    };
    DasLexService.prototype.getDetailService = function (numEspediente) {
        var url = this.domainService.backendUrlWithContext + '/dasLex/apiDetalleServicio/' + numEspediente + '/';
        return this.http.get(url).toPromise();
    };
    DasLexService.prototype.getService = function (numEspediente) {
        var url = this.domainService.backendUrlWithContext + '/dasLex/apiServicio/' + numEspediente + '/';
        return this.http.get(url).toPromise();
    };
    DasLexService.prototype.search = function (params) {
        var httpParams = new HttpParams();
        if (undefined !== params.pageOffset) {
            httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
        }
        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }
        if (undefined !== params.statusType) {
            for (var i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }
        if (undefined !== params.service) {
            for (var i = 0; i < params.service.length; i++) {
                httpParams = httpParams.append('product', params.service[i].id);
            }
        }
        if (undefined !== params.contractItems) {
            for (var i = 0; i < params.contractItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.contractItems[i].id);
            }
        }
        var url = this.domainService.backendUrlWithContext + '/dasLex/search/';
        return this.http.get(url, { params: httpParams }).toPromise();
    };
    DasLexService.prototype.view = function () {
        var url = this.domainService.backendUrlWithContext + '/dasLex/view/';
        var form = new FormData();
        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post(url, form).toPromise();
    };
    DasLexService.prototype.serviceAccess = function () {
        var url = this.domainService.backendUrlWithContext + '/dasLex/access/';
        var form = new FormData();
        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post(url, form).toPromise();
    };
    DasLexService.ngInjectableDef = i0.defineInjectable({ factory: function DasLexService_Factory() { return new DasLexService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.ToastrService), i0.inject(i4.DomainService)); }, token: DasLexService, providedIn: "root" });
    return DasLexService;
}());
export { DasLexService };
