import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-messenger-history',
    templateUrl: './event-messenger-history.component.html',
    styleUrls: ['./event-messenger-history.component.scss']
})
export class EventMessengerHistoryComponent implements OnInit, OnDestroy {

    FormData = require('formdata-polyfill')

    @Input() data: any;
    messages = [];

    loadingMessages = false;

    messageSubscription;

    @ViewChild('messagelist') messageListDiv: ElementRef;

    constructor(private toastr: ToastrService, 
        private eventService: EventService) { }


    ngOnInit() {
        this.messageSubscription = this.eventService.expedientMessageSent.subscribe(r => {
            this.getMessages();
        });
        this.getMessages();
    }

    getMessages() {

        const eventNumber = this.data.eventNumber.substring(5, this.data.eventNumber.length);
        this.loadingMessages = true;
        this.eventService.getExpedientMessagesByNumber(eventNumber)
            .then(success => {
                if(success.response !== null && success.response[0].error !== null){
                    if(success.response[0].error === 'invalid.messages'){
                        this.messages = [];
                        this.loadingMessages = false;
    
                        this.toastr.error(CommonsText.PROBLEMA_OBTENER_MENSAJE_EXPENDIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                            timeOut: 5000
                        });
                    }

                } else {
                    this.messages = success.response;
                    this.loadingMessages = false;
                    this.goToBottom();
                }

            }).catch(error => {
                this.messages = [];
                this.loadingMessages = false;

                this.toastr.error(CommonsText.PROBLEMA_OBTENER_MENSAJE_EXPENDIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    goToBottom() {
        setTimeout(() => {
            if (this.messageListDiv) {
                this.messageListDiv.nativeElement.scrollTop = this.messageListDiv.nativeElement.scrollHeight;
            }
        }, 500)

    }

    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
            this.messageSubscription = null;
        }

    }



}
