<div class="forgot-password-form no-text-select">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()">

        <div class="title row">
            <p>Recuperación de contraseña</p>
        </div>

        <div class="row">
            <app-input-box type="text" label="Correo electrónico" description="Lo necesitamos para confirmar tu registro" errorDescription="Informe un correo eletrónico válido" formControlName="email" maxlength="255"></app-input-box>
        </div>

        <div class="row reca">
            <app-google-recaptcha (onRecaptcha)="onRecaptcha($event)"></app-google-recaptcha>
        </div>

        <div class="row">
            <app-error-messages [(messages)]=messages></app-error-messages>
        </div>

        <div class="row">
            <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"></app-spinner-button>
        </div>

        <div class="row">
            <app-contact-line></app-contact-line>
        </div>
    </form>
</div>