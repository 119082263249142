import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';

@Component({
  selector: 'app-recomendacion',
  templateUrl: './recomendacion.component.html',
  styleUrls: ['./recomendacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecomendacionComponent implements OnInit {

 //categorias: CategoriaDataDTO[];
  @Input() lstNoticiasDestacadas: NoticiaDataDTO[] = [];
  @Input() delay;
  loadingProfile: Boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    /*this.categorias.forEach(categoria => {
        if(categoria.destacado) {
          categoria.noticasList.forEach(noticia => {
            this.lstNoticiasDestacadas.push(noticia);
          })
        }
      });*/
  }  

  getDelay(): String {
      return (this.delay * 0.2) + 's';
  }

  getStyle(imagen: string) {
      if (imagen !== null) {
          return {
              'background-image': 'url(' + imagen + ')'
          }
      }
      return '';
  }

  detallePost(categoria:string ,id: string){
      this.router.navigate(['/club-del-propietario/detalle/' + categoria + '/' + id]);
  }
}

