/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-hire-service.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../commons/components/icon-type/icon-type.component.ngfactory";
import * as i3 from "../../../../commons/components/icon-type/icon-type.component";
import * as i4 from "../../../../services/domain-service";
import * as i5 from "../../../../commons/components/spinner-button/spinner-button.component.ngfactory";
import * as i6 from "../../../../commons/components/spinner-button/spinner-button.component";
import * as i7 from "./product-hire-service.component";
import * as i8 from "../../../../services/policy-service";
import * as i9 from "@angular/router";
var styles_ProductHireServiceComponent = [i0.styles];
var RenderType_ProductHireServiceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductHireServiceComponent, data: {} });
export { RenderType_ProductHireServiceComponent as RenderType_ProductHireServiceComponent };
export function View_ProductHireServiceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "products"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "products-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "products-icon-img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-icon-type", [], null, null, null, i2.View_IconTypeComponent_0, i2.RenderType_IconTypeComponent)), i1.ɵdid(5, 49152, null, 0, i3.IconTypeComponent, [i4.DomainService], { icon: [0, "icon"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "products-policy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "sup"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "sup-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "inf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "inf-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "products-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-spinner-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openExternalLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SpinnerButtonComponent_0, i5.RenderType_SpinnerButtonComponent)), i1.ɵdid(19, 49152, null, 0, i6.SpinnerButtonComponent, [], { enabled: [0, "enabled"], label: [1, "label"], customSpinnerButton: [2, "customSpinnerButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.icon; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getIconColor(), ""); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_5 = true; var currVal_6 = "Contratar"; var currVal_7 = "hire-more"; _ck(_v, 19, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "products-box ", _co.getBackgroundColor(), ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.data.title; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.data.description; _ck(_v, 14, 0, currVal_4); }); }
export function View_ProductHireServiceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-hire-service", [], null, null, null, View_ProductHireServiceComponent_0, RenderType_ProductHireServiceComponent)), i1.ɵdid(1, 49152, null, 0, i7.ProductHireServiceComponent, [i8.PolicyService, i9.Router, i9.ActivatedRoute], null, null)], null, null); }
var ProductHireServiceComponentNgFactory = i1.ɵccf("app-product-hire-service", i7.ProductHireServiceComponent, View_ProductHireServiceComponent_Host_0, { data: "data" }, {}, []);
export { ProductHireServiceComponentNgFactory as ProductHireServiceComponentNgFactory };
