<div class="carousel-content">

    <div class="left">
        <app-icon-type color="red" [icon]="data.branchType"></app-icon-type>
    </div>

    <div class="right no-text-select">
        <div class="row row-7030">
            <div *ngIf="data.docCobroNumber">
                <app-label-text label="Nº de póliza / contrato:" text="{{data.docCobroNumber}}" [loading]=loading></app-label-text>
            </div>
            <div *ngIf="!data.docCobroNumber">
                <app-label-text label="Nº de póliza / contrato:" text="{{data.policyNumber}}" [loading]=loading></app-label-text>
            </div>
            <app-label-text label="Fecha inicio:" text="{{data.receiptStartDate | date: 'dd/MM/yyyy' }}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-7030">
            <app-label-text label="Producto:" text="{{data.policyProduct}}" [loading]=loading></app-label-text>
            <app-label-text label="Estado:" text="{{data.receiptSituation}}" textClass="red" [loading]=loading></app-label-text>
        </div>
        <div class="row row-7030">
            <app-label-text label="Tipo de cobro:" text="{{data.receiptPaymentMethod}}" [loading]=loading></app-label-text>
            <div class="infDoc" *ngIf="data.docCobroNumber">
                <span class="docCobro">Doc. Cobro</span>
            </div>
        </div>
        <div class="row row-7030">
            <div class="inner-label">
                <app-label-text label="Importe" text="{{data.receiptAmount | currency:'EUR' }}" textClass="blue-large" [loading]=loading></app-label-text>
            </div>
            <div class="split-button">
                <app-split-button *ngIf=!loading label="Quiero..." direction=top>
                    <app-split-button-item *ngIf="'E' !== data.receiptType || data.docCobroNumber" label="Pagar con tarjeta de crédito" (click)="pay()"></app-split-button-item>
                    <app-split-button-item *ngIf="'E' !== data.receiptType || data.docCobroNumber" label="Pagar con otros medios de pago" route="/receipt/nw/{{data.receiptNumber}}"></app-split-button-item>
                    <app-split-button-item label="Consultar mis recibos" route="/receipts"></app-split-button-item>
                </app-split-button>
            </div>
        </div>
    </div>

</div>