/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inicio-club-propietario.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../categoria/categoria.component.ngfactory";
import * as i3 from "../categoria/categoria.component";
import * as i4 from "../../../../commons/components/isMobile.service";
import * as i5 from "@angular/router";
import * as i6 from "../recomendacion/recomendacion.component.ngfactory";
import * as i7 from "../recomendacion/recomendacion.component";
import * as i8 from "@angular/common";
import * as i9 from "./inicio-club-propietario.component";
import * as i10 from "../../../../services/client-service";
import * as i11 from "ngx-toastr";
import * as i12 from "../../../../shared/services/wordpress/club-propietario.service";
var styles_InicioClubPropietarioComponent = [i0.styles];
var RenderType_InicioClubPropietarioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InicioClubPropietarioComponent, data: {} });
export { RenderType_InicioClubPropietarioComponent as RenderType_InicioClubPropietarioComponent };
function View_InicioClubPropietarioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container no-text-select pre-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cargando"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], null, null); }
function View_InicioClubPropietarioComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-categoria", [["delay", "5"]], null, [[null, "refrescarHome"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refrescarHome" === en)) {
        var pd_0 = (_co.refrescarHome($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CategoriaComponent_0, i2.RenderType_CategoriaComponent)), i1.ɵdid(2, 4308992, null, 0, i3.CategoriaComponent, [i4.IsMobileService, i5.Router], { delay: [0, "delay"], categoria: [1, "categoria"] }, { refrescarHome: "refrescarHome" })], function (_ck, _v) { var currVal_0 = "5"; var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InicioClubPropietarioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-recomendacion", [["delay", "5"]], null, null, null, i6.View_RecomendacionComponent_0, i6.RenderType_RecomendacionComponent)), i1.ɵdid(2, 114688, null, 0, i7.RecomendacionComponent, [i5.Router], { lstNoticiasDestacadas: [0, "lstNoticiasDestacadas"], delay: [1, "delay"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InicioClubPropietarioComponent_3)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lstNoticiasDestacadas; var currVal_1 = "5"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.categoriasInicio; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_InicioClubPropietarioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", " container "], ["style", "min-height: 400px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InicioClubPropietarioComponent_1)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InicioClubPropietarioComponent_2)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingProfile; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.loadingProfile && _co.showCategoria); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InicioClubPropietarioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inicio-club-propietario", [], null, null, null, View_InicioClubPropietarioComponent_0, RenderType_InicioClubPropietarioComponent)), i1.ɵdid(1, 4308992, null, 0, i9.InicioClubPropietarioComponent, [i10.ClientService, i11.ToastrService, i12.ClubPropietarioService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InicioClubPropietarioComponentNgFactory = i1.ɵccf("app-inicio-club-propietario", i9.InicioClubPropietarioComponent, View_InicioClubPropietarioComponent_Host_0, {}, { refrescarHomeDos: "refrescarHomeDos" }, []);
export { InicioClubPropietarioComponentNgFactory as InicioClubPropietarioComponentNgFactory };
