/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-messenger-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../event-messenger-message/event-messenger-message.component.ngfactory";
import * as i3 from "../event-messenger-message/event-messenger-message.component";
import * as i4 from "ngx-toastr";
import * as i5 from "../../../../../services/event-service";
import * as i6 from "@angular/common";
import * as i7 from "./event-messenger-history.component";
var styles_EventMessengerHistoryComponent = [i0.styles];
var RenderType_EventMessengerHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventMessengerHistoryComponent, data: {} });
export { RenderType_EventMessengerHistoryComponent as RenderType_EventMessengerHistoryComponent };
function View_EventMessengerHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-event-messenger-message", [], null, null, null, i2.View_EventMessengerMessageComponent_0, i2.RenderType_EventMessengerMessageComponent)), i1.ɵdid(2, 114688, [[1, 4], ["messagelist", 4]], 0, i3.EventMessengerMessageComponent, [i4.ToastrService, i5.EventService], { message: [0, "message"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.data; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EventMessengerHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["messagelist", 1]], null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMessengerHistoryComponent_2)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 3, 0, currVal_0); }, null); }
function View_EventMessengerHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "no-messages"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No hay mensajes "]))], null, null); }
function View_EventMessengerHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "pre-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cargando"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."]))], null, null); }
export function View_EventMessengerHistoryComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { messageListDiv: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMessengerHistoryComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMessengerHistoryComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventMessengerHistoryComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.loadingMessages && _co.messages); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.loadingMessages && !_co.messages); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.loadingMessages; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_EventMessengerHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-messenger-history", [], null, null, null, View_EventMessengerHistoryComponent_0, RenderType_EventMessengerHistoryComponent)), i1.ɵdid(1, 245760, null, 0, i7.EventMessengerHistoryComponent, [i4.ToastrService, i5.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventMessengerHistoryComponentNgFactory = i1.ɵccf("app-event-messenger-history", i7.EventMessengerHistoryComponent, View_EventMessengerHistoryComponent_Host_0, { data: "data" }, {}, []);
export { EventMessengerHistoryComponentNgFactory as EventMessengerHistoryComponentNgFactory };
