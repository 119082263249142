import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, useAnimation, state, style, animate } from '@angular/animations';

@Component({
    selector: 'app-taker-block-notify',
    templateUrl: './taker-block-notify.component.html',
    styleUrls: ['./taker-block-notify.component.scss'],
    animations: [
        trigger('fadeInUp', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class TakerBlockNotifyComponent {

    @Input() email: String;

}
