/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-complete-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-complete-bar.component";
var styles_ProfileCompleteBarComponent = [i0.styles];
var RenderType_ProfileCompleteBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileCompleteBarComponent, data: {} });
export { RenderType_ProfileCompleteBarComponent as RenderType_ProfileCompleteBarComponent };
export function View_ProfileCompleteBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.PercentPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "container-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "progress-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " completado"])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "progress-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "progress-inner-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "bar"]], null, null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { "width": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 10, 0, (_co.percent + "%")); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), (_co.percent / 100), "1.1-5")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ProfileCompleteBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-profile-complete-bar", [], null, null, null, View_ProfileCompleteBarComponent_0, RenderType_ProfileCompleteBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProfileCompleteBarComponent, [], null, null)], null, null); }
var ProfileCompleteBarComponentNgFactory = i1.ɵccf("app-profile-complete-bar", i3.ProfileCompleteBarComponent, View_ProfileCompleteBarComponent_Host_0, { percent: "percent" }, {}, []);
export { ProfileCompleteBarComponentNgFactory as ProfileCompleteBarComponentNgFactory };
