import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from 'app/security/auth.service';
import { Subject } from 'rxjs';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { CommonsText } from 'app/commons/CommonsText';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./domain-service";
var PolicyService = /** @class */ (function () {
    function PolicyService(http, authService, toastr, domainService) {
        this.http = http;
        this.authService = authService;
        this.toastr = toastr;
        this.domainService = domainService;
        this.oldParams = new PolicyParamDTO();
    }
    PolicyService.prototype.setPageSize = function (value) {
        this.pageSizeChange.next(value);
    };
    PolicyService.prototype.setPageOffset = function (value) {
        this.pageOffsetChange.next(value);
    };
    PolicyService.prototype.setPageFilter = function (value) {
        this.pageFilterChange.next(value);
    };
    PolicyService.prototype.observerPageSizeChange = function () {
        this.pageSizeChange = new Subject();
        return this.pageSizeChange.asObservable();
    };
    PolicyService.prototype.observerPageOffset = function () {
        this.pageOffsetChange = new Subject();
        return this.pageOffsetChange.asObservable();
    };
    PolicyService.prototype.observerPageFilter = function () {
        this.pageFilterChange = new Subject();
        return this.pageFilterChange.asObservable();
    };
    PolicyService.prototype.getCurrent = function () {
        var url = this.domainService.backendUrlWithContext + '/policies/current/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    PolicyService.prototype.getRelation = function () {
        var _this = this;
        this.getCurrent()
            .then(function (success) {
            var local = 'ASEG';
            for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                var policy = _a[_i];
                if (policy.isTaker) {
                    local = 'TOMA';
                    break;
                }
            }
            localStorage.setItem('relationType', local);
        }).catch(function (error) {
            _this.toastr.error(CommonsText.ERROR_SERVICIO_POLIZAS, CommonsText.ERROR_DE_OPERACION, {
                timeOut: 5000
            });
        });
    };
    PolicyService.prototype.getPolicyByNumber = function (policyNumber) {
        var url = this.domainService.backendUrlWithContext + '/policies/policy/' + policyNumber;
        return this.http.get(url).toPromise();
    };
    PolicyService.prototype.getMediators = function () {
        var url = this.domainService.backendUrlWithContext + '/policies/mediators/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    PolicyService.prototype.getMediator = function (mediatorId) {
        var url = this.domainService.backendUrlWithContext + '/policies/mediator/' + mediatorId;
        return this.http.get(url).toPromise();
    };
    PolicyService.prototype.haveParameterChanges = function (params) {
        if ((undefined !== this.oldParams.statusType &&
            undefined !== params.statusType &&
            this.oldParams.statusType.length !== params.statusType.length) ||
            (this.oldParams.policyCollective !== params.policyCollective) ||
            (this.oldParams.policyNumber !== params.policyNumber) ||
            (undefined !== this.oldParams.product &&
                undefined !== params.product &&
                this.oldParams.product.length !== params.product.length)) {
            this.oldParams.statusType = Object.assign([], params.statusType);
            this.oldParams.policyItems = Object.assign([], params.policyItems);
            this.oldParams.policyCollective = params.policyCollective;
            this.oldParams.policyNumber = params.policyNumber;
            this.oldParams.product = Object.assign([], params.product);
            return true;
        }
        return false;
    };
    PolicyService.prototype.search = function (params) {
        var httpParams = new HttpParams();
        if (undefined !== params.pageOffset) {
            if (!this.haveParameterChanges(params)) {
                httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
            }
            else {
                httpParams = httpParams.append('pageOffset', '0');
            }
        }
        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }
        if ((undefined !== params.startDate) && (null !== params.startDate) && ('' !== params.startDate)) {
            httpParams = httpParams.append('startDate', params.startDate);
        }
        if ((undefined !== params.endDate) && (null !== params.endDate) && ('' !== params.endDate)) {
            httpParams = httpParams.append('endDate', params.endDate);
        }
        if ((undefined !== params.policyNumber) && (null !== params.policyNumber) && ('' !== params.policyNumber.trim())) {
            httpParams = httpParams.append('policyNumber', params.policyNumber);
        }
        if ((undefined !== params.policyCollective) && (null !== params.policyCollective) && ('' !== params.policyCollective.trim())) {
            httpParams = httpParams.append('policyCollective', params.policyCollective);
        }
        if (undefined !== params.statusType) {
            for (var i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }
        if (undefined !== params.product) {
            for (var i = 0; i < params.product.length; i++) {
                httpParams = httpParams.append('product', params.product[i].id);
            }
        }
        if (undefined !== params.policyItems) {
            for (var i = 0; i < params.policyItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.policyItems[i].id);
            }
        }
        var url = this.domainService.backendUrlWithContext + '/policies/search/' + this.authService.getSelectedEntity();
        return this.http.get(url, { params: httpParams }).toPromise();
    };
    PolicyService.prototype.getContractFile = function (policyNumber) {
        var _this = this;
        this.toastr.info(CommonsText.PREPARANDO_ARCHIVO_DESCARGA, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 12000
        });
        var url = this.domainService.backendUrlWithContext + '/policies/document/' + policyNumber;
        this.http.get(url, { observe: 'response', responseType: 'blob' }).subscribe(function (resp) {
            _this.saveToFileSystem(resp);
        }, function (err) {
            _this.toastr.error(CommonsText.FALLO_AL_DESCARGAR, CommonsText.SERVICIO_DE_DOCUMENTOS, {
                timeOut: 5000
            });
        });
    };
    PolicyService.prototype.saveToFileSystem = function (response) {
        var contentType = response.headers.get('Content-Type');
        var contentDispositionHeader = response.headers.get('Content-Disposition');
        var parts = contentDispositionHeader.split(';');
        var filename = parts[1].split('=')[1].split('\"').join('');
        var blob = new Blob([response.body], { type: contentType });
        saveAs(blob, filename);
        this.toastr.success(CommonsText.ARCHIVO_DESCARGADO_CORRECTAMENTE, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 5000
        });
    };
    PolicyService.prototype.getHirePolicyDataByNumber = function (policyYear, policyNumber) {
        var url = this.domainService.backendUrlWithContext + '/policies/hireMoreInformation/' + policyYear + '/' + policyNumber;
        return this.http.get(url).toPromise();
    };
    PolicyService.prototype.onHireMoreFormSubmit = function (params) {
        var form = new FormData();
        if ((undefined !== params.policyNumber) && (null !== params.policyNumber)) {
            form.append('policyNumber', params.policyNumber);
        }
        if ((undefined !== params.policyYear) && (null !== params.policyYear)) {
            form.append('policyYear', params.policyYear);
        }
        if ((undefined !== params.modificationDate) && (null !== params.modificationDate)) {
            form.append('modificationDate', params.modificationDate);
        }
        if ((undefined !== params.observation) && (null !== params.observation)) {
            form.append('observation', params.observation);
        }
        var url = this.domainService.backendUrlWithContext + '/policies/hireMore';
        return this.http.post(url, form).toPromise();
    };
    PolicyService.prototype.changeIdentifier = function (params) {
        var form = new FormData();
        form.append('polizsec', params.policyNumber);
        form.append('oldIdentifiyer', params.identifier);
        form.append('newIdentifiyer', params.newIdentifier);
        form.append('modificationDate', params.modificationDate);
        var url = this.domainService.backendUrlWithContext + '/policies/changeIdentifier';
        return this.http.post(url, form).toPromise();
    };
    PolicyService.ngInjectableDef = i0.defineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.ToastrService), i0.inject(i4.DomainService)); }, token: PolicyService, providedIn: "root" });
    return PolicyService;
}());
export { PolicyService };
