var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Platform } from '@angular/cdk/platform';
import { NativeDateAdapter } from '@angular/material';
import * as moment from 'moment';
var AppDateAdapter = /** @class */ (function (_super) {
    __extends(AppDateAdapter, _super);
    function AppDateAdapter(matDateLocale) {
        var _this = _super.call(this, matDateLocale, new Platform()) || this;
        _this.momentLocaleData = moment.localeData('es-Es');
        _this.momentLocaleData = moment.localeData(matDateLocale);
        _this.selectedCustomLocale = matDateLocale;
        return _this;
    }
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'input') {
            var day = date.getDate().toString();
            day = +day < 10 ? '0' + day : day;
            var month = (date.getMonth() + 1).toString();
            month = +month < 10 ? '0' + month : month;
            var year = date.getFullYear();
            return day + "-" + month + "-" + year;
        }
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(this.selectedCustomLocale, options);
    };
    AppDateAdapter.prototype.getFirstDayOfWeek = function () {
        return 1;
    };
    AppDateAdapter.prototype.getMonthNames = function () {
        return this.momentLocaleData.monthsShort();
    };
    AppDateAdapter.prototype.getDayOfWeekNames = function () {
        return this.momentLocaleData.weekdaysShort();
    };
    AppDateAdapter.prototype.getDayOfWeek = function () {
        return this.momentLocaleData.firstDayOfWeek();
    };
    AppDateAdapter.prototype.setLocale = function () {
        _super.prototype.setLocale.call(this, 'es-Es');
    };
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
