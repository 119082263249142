import { environment } from '../../environments/environment';
import { RecaptchaSettings } from 'ng-recaptcha';
var ɵ0 = environment, ɵ1 = {
    siteKey: environment.reCaptchaKey,
};
var CommonsModule = /** @class */ (function () {
    function CommonsModule() {
    }
    return CommonsModule;
}());
export { CommonsModule };
export { ɵ0, ɵ1 };
