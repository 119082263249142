/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./blog.component";
var styles_BlogComponent = [i0.styles];
var RenderType_BlogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlogComponent, data: {} });
export { RenderType_BlogComponent as RenderType_BlogComponent };
export function View_BlogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "blog no-text-select wow fadeInUp"], ["data-wow-duration", "1s"]], [[1, "data-wow-delay", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "blog-image"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "blog-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "blog-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "blog-reveal"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "blog-reveal-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "blog-reveal-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Leer m\u00E1s"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getStyle(); _ck(_v, 2, 0, currVal_1); var currVal_3 = "blog-reveal"; var currVal_4 = ((_co.visible === true) ? "show" : ""); _ck(_v, 7, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDelay(); _ck(_v, 0, 0, currVal_0); var currVal_2 = ((_co.content == null) ? null : ((_co.content.title == null) ? null : _co.content.title.rendered)); _ck(_v, 5, 0, currVal_2); var currVal_5 = ((_co.content == null) ? null : ((_co.content.title == null) ? null : _co.content.title.rendered)); _ck(_v, 9, 0, currVal_5); var currVal_6 = ((_co.content == null) ? null : ((_co.content.excerpt == null) ? null : _co.content.excerpt.rendered)); _ck(_v, 10, 0, currVal_6); var currVal_7 = i1.ɵinlineInterpolate(1, "", ((_co.content == null) ? null : _co.content.link), ""); _ck(_v, 12, 0, currVal_7); }); }
export function View_BlogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blog", [], null, null, null, View_BlogComponent_0, RenderType_BlogComponent)), i1.ɵdid(1, 49152, null, 0, i3.BlogComponent, [], null, null)], null, null); }
var BlogComponentNgFactory = i1.ɵccf("app-blog", i3.BlogComponent, View_BlogComponent_Host_0, { content: "content", delay: "delay" }, {}, []);
export { BlogComponentNgFactory as BlogComponentNgFactory };
