<div class="receipt no-text-select">
    <div class="receipt-form">
        <div class="receipt-form-box background-{{ data.receiptStatusColor }}"></div>
        <div class="receipt-form-img">
            <app-icon-type [icon]="data.branchType" color="{{ data.receiptStatusColor }}"></app-icon-type>
            <p>Estado: <span>{{ data.receiptStatus }}</span></p>
        </div>
        <div class="receipt-form-number">
            <div class="sup">
                <div class="sup-izq" *ngIf="!data.docCobroNumber">
                    <p>Nº de póliza / contrato:</p>
                    <span>{{ data.policyNumber }}</span> <span style="color:gray; font-size: 13px;" *ngIf="undefined !== data.policyCollective"> (Colectivo {{ data.policyCollective }} ) </span>
                </div>
                <div class="sup-izq" *ngIf="data.docCobroNumber">
                    <p>Doc. Cobro:</p>
                    <span>{{ data.docCobroNumber }}</span> <span style="color:gray; font-size: 13px;" *ngIf="undefined !== data.policyCollective"> (Colectivo {{ data.policyCollective }} ) </span>
                </div>
                <div class="sup-der">
                    <p>Nº recibo:</p>
                    <span>{{ data.receiptNumber }}</span>                   
                    
                </div>
            </div>
            <div class="inf">
                <div class="inf-izq">
                    <p>Vigencia:</p>
                    <span>{{ data.receiptStartDate | date: 'dd/MM/yyyy' }} - {{data.receiptEndDate | date: 'dd/MM/yyyy' }}</span>
                </div>
                <div class="inf-der">
                    <p>Periocidad:</p>
                    <span>{{ data.policyPeriodicity }}</span>
                </div>
            </div>
            <div class="inf" *ngIf="'E' === data.receiptType && !data.docCobroNumber">
                <span class="cashBack">Operación de Extorno</span>
            </div>
            <div class="infDoc" *ngIf="data.docCobroNumber">
                <span class="docCobro">Documento de Cobro</span>
            </div>
        </div>
        <div class="receipt-form-product">
            <div class="sup">
                <p>Producto:</p>
                <span>{{ data.policyProduct }}</span>
            </div>
            <div class="inf">
                <p>Tipo de cobro:</p>
                <span>{{ data.receiptPaymentMethod }}</span>
            </div>

           
        </div>
        <div class="receipt-form-import">
            <div class="receipt-form-import-number">
                <p>Importe</p>
                <span>{{ data.receiptAmount | currency:'EUR' }}</span>                                
            </div>
            <div class="receipt-form-import-button">
                <app-split-button *ngIf="'red' === data.receiptStatusColor && 'E' !== data.receiptType || data.docCobroNumber && 'red' === data.receiptStatusColor" label="PAGAR">
                    <app-split-button-item label="Pagar con tarjeta de crédito" (click)="pay()"></app-split-button-item>
                    <app-split-button-item label="Pagar con otros medios de pago" route="/receipt/nw/{{data.receiptNumber}}"></app-split-button-item>
                </app-split-button>
            </div>
        </div>


    </div>
</div>