import { Component, Input } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';

@Component({
    selector: 'app-receipt-item',
    templateUrl: './receipt-item.component.html',
    styleUrls: ['./receipt-item.component.scss']
})
export class ReceiptItemComponent {

    @Input() data;

    constructor(private receiptService: ReceiptService) { }

    pay() {
        this.receiptService.payment(this.data);
    }
}
