<app-header>
  <div class="inner-content-header container">
    <div class="profile">
      <app-header-breadcrumb crumb="Club del propietario" text="Condiciones de uso"></app-header-breadcrumb>
    </div>
    <div class="last-conect">
      <app-header-last-conection></app-header-last-conection>
    </div>
  </div>
</app-header>

<div class="container" style="min-height: 400px;">

  <h1>Información General:</h1>

  <p>onLygal Seguros y Reaseguros, S.A., en adelante, “onLygal Seguros”, es el titular de este sitio web y de las presentes condiciones de uso.</p>

  <p>El servicio denominado “Club del Propietario” es un beneficio que onLygal Seguros ofrece a aquellos de sus clientes que
    tengan contratado un seguro de protección de rentas (es decir, un seguro que garantiza al arrendador el pago de una
    prestación en caso de que el inquilino dejara de pagar la renta acordada) o un seguro de defensa jurídica para el
    arrendador (es decir, un seguro que cubre los gastos legales en caso de que el arrendador tuviera un conflicto
    legal). Tu acceso, por tanto, está supeditado a que tengas un seguro de esta tipología.</p>

  <p>El acceso, registro y uso al portal del “Club del Propietario” es voluntario y sin coste para el Usuario.</p>

  <p>El portal está sujeto a las normas y condiciones que se especifican a continuación. El registro implica la
    aceptación expresa de estas condiciones.</p>

  <h1>Registro</h1>

  <p>El Usuario se compromete a hacer un uso adecuado de los contenidos y servicios que se ofrecen en el presente sitio
    web. Para poder usar los servicios del sitio es necesario que el Usuario se registre, llevando a cabo los siguientes
    pasos:</p>

  <ul class="numeral">
    <li>Para poder acceder a los servicios del “Club del Propietario” es preciso estar dado de alta en el “área cliente”
      de onLygal Seguros y disponer, por tanto, de Usuario y contraseña.</li>
    <li>Leer y aceptar las presentes condiciones de uso antes de proceder al envío de sus datos personales para el alta
      en el servicio</li>
    <li>Leer y aceptar la política de privacidad del sitio, antes de proceder al envío de sus datos personales para el
      alta en el servicio.</li>
  </ul>

  <p>Tanto el acceso al sitio web como el uso o suministro no consentido de la información contenida en el mismo es
    responsabilidad exclusiva de la persona que lo realiza.</p>

  <h1>Objeto del servicio:</h1>

  <p>Como Usuario tendrás una zona privada con acceso a una serie de artículos con información relacionada con el
    alquiler de viviendas y que puedan resultar de interés a aquellas personas que sean propietarias de una vivienda y
    hayan decidido alquilarla.</p>

  <p>También podrás encontrar ofertas y promociones de proveedores asociados, que prestarán, bajo su exclusiva
    responsabilidad, el servicio al Usuario. Podrás conocer los detalles de cada servicio haciendo clic en el propio
    título o en la imagen del servicio ofertado.</p>

  <h1>Uso del servicio</h1>

  <p>onLygal Seguros pone a disposición del Usuario, a través del sitio web, ofertas y promociones de proveedores asociados,
    a las cuales el Usuario deberá acceder para su compra siempre a través de los enlaces, códigos promocionales que le
    han sido suministrados o información de contacto de los proveedores, con el fin de poder disfrutar de la promoción o
    descuento indicado en el sitio web.</p>

  <p>onLygal Seguros selecciona proveedores asociados de confianza. No obstante, onLygal Seguros no se hace responsable de los
    posibles incumplimientos o incidencias en que pudiera incurrir cualquier proveedor asociados con los Usuarios, al
    tratarse de una relación comercial entre el proveedor y el Usuario. Cada proveedor dispone de sus propias
    condiciones de venta, por lo que recomendamos al Usuario una lectura atenta de ellas antes de proceder a realizar
    cualquier operación.</p>

  <p>En caso de detectar cualquier posible mala práctica o abuso por parte de un proveedor asociado, solicitamos a
    nuestros Usuarios que nos lo comuniquen para investigarlo y llegado el caso, sacar su referencia de nuestra
    plataforma.</p>

  <p>De igual forma, en caso de que el Usuario nos comunique cualquier incidencia, o tenga conocimiento directamente, de
    situaciones que provoquen incumplimientos por parte del proveedor asociado, onLygal Seguros se dirigirá al mismo por vía
    amistosa para tratar de mediar en la situación a favor del Usuario.</p>

  <h1>Obligaciones del Usuario:</h1>

  <p>Como Usuario registrado te comprometes a utilizar de buena fe y de manera adecuada el servicio y contenidos
    facilitados en el sitio web, y a no emplearlos para realizar actividades ilícitas o que puedan causar algún
    perjuicio para terceros.</p>

  <h1>Cancelación de tu cuenta:</h1>

  <p>Tu cuenta se puede cancelar por los siguientes motivos:</p>

  <ul>
    <li>Por voluntad propia, solicitando la cancelación de la cuenta enviando un correo electrónico a la siguiente
      dirección <a href="mailto:lopd@onlygal.es">lopd@onlygal.es</a> o llamando al teléfono 900 828 020 comunicando que deseas
      darte de baja del servicio. Una
      vez recibida la solicitud de baja del Usuario, onLygal Seguros procederá a dar de baja del servicio al Usuario
      solicitante en un plazo de 48h.</li>
    <li>Por la cancelación del seguro de protección de rentas contratado con onLygal Seguros. Como los beneficios están
      vinculados a la pertenencia al colectivo, si dejas de formar parte de él, perderás tu cuenta y la posibilidad de
      disfrutar de estos servicios.</li>
    <li>Por inactividad prolongada, en caso de que no accedieras al espacio privado durante un periodo superior a dos
      años, recibirás un preaviso, y podrás cancelar tu cuenta a partir de ese momento.</li>
    <li>Si, por cualquier circunstancia, onLygal Seguros decide finalizar este programa de servicios y beneficios.</li>
    <li>Por voluntad de onLygal Seguros, en caso de detectar que el Usuario hace un uso inadecuado o fraudulento del
      servicio.</li>
  </ul>

  <h1>Garantía y responsabilidad:</h1>

  <p>En este sitio web el Usuario puede encontrar enlaces a otras páginas web, principalmente de proveedores, mediante
    banners, links u otro tipo de botones que son gestionados por terceros. onLygal Seguros no tiene facultad para controlar
    los contenidos facilitados por otros sitios web a los que se establecen enlaces desde este sitio. Por lo tanto, onLygal Seguros
    no asume responsabilidad alguna sobre cualquier aspecto relativo a dichas páginas web a las que se enlaza
    desde este sitio ni garantiza que dichos enlaces puedan estar exentos de virus u otros elementos que puedan producir
    daños en los sistemas informáticos o archivos del Usuario.</p>
  <p>En sentido contrario, onLygal Seguros tampoco asume responsabilidad alguna por los contenidos de otras páginas web que
    puedan dirigir enlaces hacia este sitio web o hacer referencia al mismo.</p>
  <p>En caso de existir fallos de accesibilidad, onLygal Seguros llevará a cabo todas aquellas acciones tendentes a
    subsanarlos, aunque no garantiza la inexistencia de errores ni la disponibilidad absoluta y continúa del Sitio, que
    puede verse alterada por labores de mantenimiento y mejora.</p>

  <h1>Protección de Datos:</h1>

  <p>Los datos de carácter personal que el Usuario facilite para el registro se tratarán de acuerdo con las finalidades
    establecidas en la política de privacidad que el usuario debe aceptar antes de formar parte del “Club del
    Propietario”. Lee con atención dicho documento antes de registrarte.</p>
</div>

  <app-footer></app-footer>