<div class="showColumns" *ngIf="!isMobilePhone">
  <div class="noticia" *ngFor="let noticia of subcategoria.noticiaList">
    <img src="{{noticia.imagen.source_url}}">
    <br>
    <br>
    <a [routerLink]="[ '/club-del-propietario/detalle/', noticia.categoria, noticia.id]" class="enlacePost">
      {{ noticia.titulo }}
    </a>
  </div>
</div>

<div class="showColumns" *ngIf="isMobilePhone">
  <div class="noticiaMobile" *ngFor="let noticia of subcategoria.noticiaList | slice:0:4;">
    <img src="{{noticia.imagen.source_url}}">
    <a [routerLink]="[ '/club-del-propietario/detalle/', noticia.categoria, noticia.id]" class="enlacePost">
      {{ noticia.titulo }}
    </a>
  </div>
</div>




<!--max-width: 25%;max-height: 25%;width: 25%;height: 10%;min-width: 25%;min-height: 10%;-->