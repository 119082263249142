<div class="send-box" [formGroup]="sendMessageForm">

    <div class="input-group">
        <textarea formControlName="message" placeholder="Envía un mensaje" class="message-content" cols="30" maxlength="2042" 
        (keyup)="contador($event)"
        rows="5"></textarea>
        <div>{{contadorInit}}/2042</div>
    </div>

    <div class="documents-line" *ngIf="false && attaching">

        <div class="attachment-title">
            <span>Seleccione documentos a adjuntar en el mensaje</span>
        </div>
        <div class="documents-content">
            <div class="documents" *ngFor="let doc of data.document; index as i ">

                <label class="btn">
                    <input type="radio" formControlName="documentId" value="{{doc.id}}" (click)="addDocument(doc.id)">
                    <span>
                        <i class="check-icon"></i>
                        {{doc.name}}
                    </span>
                </label>
            </div>
        </div>

        <div class="attachment-info">
            <span>* Si necesita subir un documento nuevo, utilice el apartado <a href="javascript: void(0);"
                    [routerLink]="['./']" fragment="attach"><b>Documentación adjunta</b></a></span>
        </div>

    </div>

    <div class="button-line">
        <div class="buttons">
            <div class="attach-button">
                <div class="fileUpload">
                    <ngfFormData [files]="files" postName="file" [(FormData)]="uploadFormData" ></ngfFormData>
                    <div [hidden]="files.length > 0">
                        <div ngfDrop selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
                             [(dragFiles)]="dragFiles" class="well my-drop-zone"
                            [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
                            (filesChange)="lastFileAt = getDate()">
                            <p>
                                <app-spinner-button [label]="'Adjuntar Documento'" [enabled]="!sending"
                                    [customSpinnerButton]="!sending ?'white-border' : ''">
                                </app-spinner-button>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="files.length > 0 && checkFile()" class="files">
                        <div class="files-main" *ngFor="let item of files; let i = index">
                            <div class="name" *ngIf="checkFiles(i)">
                                <div class="img">
                                    <div class="img-inner"></div>
                                </div>
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="size">
                                <p>{{ item.size / 1024 / 1024 | number: ".2" }} MB</p>
                            </div>
                            <div class="erase">
                                <button type="button" class="btn btn-danger btn-xs"
                                    (click)="files.splice(i, 1)"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="send-button">
                    <app-spinner-button class="send-button" [label]="'ENVIAR'"
                        [enabled]="sendMessageForm.valid && !sending" [running]="sending" (click)="sendMessage()"
                        [customSpinnerButton]="sendMessageForm.valid && !sending ?'white-border' : ''">
                    </app-spinner-button>
                </div>
            </div>
        </div>
    </div>