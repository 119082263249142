import { EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { AuthService } from 'app/security/auth.service';
import { EventParamDTO } from 'app/header-footer-template/model/EventParamDTO';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { saveAs } from 'file-saver';
import { CommonsText } from 'app/commons/CommonsText';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./domain-service";
var EventService = /** @class */ (function () {
    function EventService(http, authService, toastr, domainService) {
        this.http = http;
        this.authService = authService;
        this.toastr = toastr;
        this.domainService = domainService;
        this.expedientMessageSent = new EventEmitter();
        this.oldParams = new EventParamDTO();
    }
    EventService.prototype.getServices = function (eventNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/getServices/' + eventNumber + '/';
        return this.http.get(url).toPromise();
    };
    EventService.prototype.getPayments = function (eventNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/getPayments/' + eventNumber + '/';
        return this.http.get(url).toPromise();
    };
    EventService.prototype.getIndemnizaciones = function (eventNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/getIndemnizaciones/' + eventNumber + '/';
        return this.http.get(url).toPromise();
    };
    EventService.prototype.setPageSize = function (value) {
        this.pageSizeChange.next(value);
    };
    EventService.prototype.setPageOffset = function (value) {
        this.pageOffsetChange.next(value);
    };
    EventService.prototype.setPageFilter = function (value) {
        this.pageFilterChange.next(value);
    };
    EventService.prototype.observerPageSizeChange = function () {
        this.pageSizeChange = new Subject();
        return this.pageSizeChange.asObservable();
    };
    EventService.prototype.observerPageOffset = function () {
        this.pageOffsetChange = new Subject();
        return this.pageOffsetChange.asObservable();
    };
    EventService.prototype.observerPageFilter = function () {
        this.pageFilterChange = new Subject();
        return this.pageFilterChange.asObservable();
    };
    EventService.prototype.getOpenEvents = function () {
        var url = this.domainService.backendUrlWithContext + '/events/open/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    EventService.prototype.haveParameterChanges = function (params) {
        if ((undefined !== this.oldParams.statusType &&
            undefined !== params.statusType &&
            this.oldParams.statusType.length !== params.statusType.length) ||
            (this.oldParams.policyNumber !== params.policyNumber) ||
            (this.oldParams.endDate !== params.endDate) ||
            (this.oldParams.startDate !== params.startDate) ||
            (undefined !== this.oldParams.product &&
                undefined !== params.product &&
                this.oldParams.product.length !== params.product.length)) {
            this.oldParams.statusType = Object.assign([], params.statusType);
            this.oldParams.policyItems = Object.assign([], params.policyItems);
            this.oldParams.policyNumber = params.policyNumber;
            this.oldParams.product = Object.assign([], params.product);
            this.oldParams.endDate = params.endDate;
            this.oldParams.startDate = params.startDate;
            return true;
        }
        return false;
    };
    EventService.prototype.search = function (params) {
        var httpParams = new HttpParams();
        if (undefined !== params.pageOffset) {
            if (!this.haveParameterChanges(params)) {
                httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
            }
            else {
                httpParams = httpParams.append('pageOffset', '0');
            }
        }
        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }
        if ((undefined !== params.startDate) && (null !== params.startDate) && ('' !== params.startDate)) {
            httpParams = httpParams.append('startDate', params.startDate);
        }
        if ((undefined !== params.endDate) && (null !== params.endDate) && ('' !== params.endDate)) {
            httpParams = httpParams.append('endDate', params.endDate);
        }
        if ((undefined !== params.policyNumber) && (null !== params.policyNumber)) {
            httpParams = httpParams.append('policyNumber', params.policyNumber);
        }
        if ((undefined !== params.statusType) && (null !== params.statusType)) {
            for (var i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }
        if ((undefined !== params.product) && (null !== params.product)) {
            for (var i = 0; i < params.product.length; i++) {
                httpParams = httpParams.append('product', params.product[i].id);
            }
        }
        if (undefined !== params.policyItems) {
            for (var i = 0; i < params.policyItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.policyItems[i].id);
            }
        }
        if (undefined !== params.eventItems) {
            for (var i = 0; i < params.eventItems.length; i++) {
                httpParams = httpParams.append('eventItems', params.eventItems[i].id);
            }
        }
        var url = this.domainService.backendUrlWithContext + '/events/search/' + this.authService.getSelectedEntity();
        return this.http.get(url, { params: httpParams }).toPromise();
    };
    EventService.prototype.getEventByNumber = function (eventNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/event/' + eventNumber;
        return this.http.get(url).toPromise();
    };
    EventService.prototype.onGetEventFile = function (document) {
        var _this = this;
        this.toastr.info(CommonsText.PREPARANDO_ARCHIVO_DESCARGA, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 10000
        });
        var url = this.domainService.backendUrlWithContext + '/events/document/' + document.id + "/" + document.format;
        this.http.get(url, { observe: 'response', responseType: 'blob' }).subscribe(function (resp) {
            if (resp.body.size > 0) {
                _this.saveToFileSystem(resp);
            }
            else {
                _this.toastr.error(CommonsText.FALLO_AL_DESCARGAR, CommonsText.ERROR_DE_FORMATO, {
                    timeOut: 5000
                });
            }
        }, function (err) {
            _this.toastr.error(CommonsText.FALLO_AL_DESCARGAR, CommonsText.SERVICIO_DE_DOCUMENTOS, {
                timeOut: 5000
            });
        });
    };
    EventService.prototype.saveToFileSystem = function (response) {
        var contentType = response.headers.get('Content-Type');
        var contentDispositionHeader = response.headers.get('Content-Disposition');
        var parts = contentDispositionHeader.split(';');
        var filename = parts[1].split('=')[1].split('\"').join('');
        var blob = new Blob([response.body], { type: contentType });
        saveAs(blob, filename);
        this.toastr.success(CommonsText.ARCHIVO_DESCARGADO_CORRECTAMENTE, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 5000
        });
    };
    EventService.prototype.uploadFileData = function (event, file) {
        var url = this.domainService.backendUrlWithContext + '/events/upload/' + event.eventNumber;
        var form = new FormData();
        form.append('file', file.get('file'));
        var req = new HttpRequest('POST', url, form, { reportProgress: true });
        return this.http.request(req);
    };
    EventService.prototype.getPolicyEventByNumber = function (policyNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/policyEvent/' + policyNumber;
        return this.http.get(url).toPromise();
    };
    EventService.prototype.onRegisterEvent = function (firstParam, thirdParam, fourthParam) {
        var url = this.domainService.backendUrlWithContext + '/events/registerEvent/' + firstParam.policyNumber[0].id;
        var form = new FormData();
        if (null !== firstParam.declarationObservation) {
            form.append('declarationObservation', firstParam.declarationObservation);
        }
        if (null !== firstParam.risk) {
            form.append('risk', firstParam.risk);
        }
        if (null !== firstParam.eventTime && firstParam.eventTime !== "") {
            form.append('eventTime', firstParam.eventTime);
        }
        if (null !== firstParam.eventDate) {
            form.append('eventDate', firstParam.eventDate);
        }
        if (null !== thirdParam.documentType) {
            form.append('insured.documentType', thirdParam.documentType[0].id);
        }
        if (null !== thirdParam.bornDate) {
            thirdParam.documentType[0].id && thirdParam.documentType[0].id == 'CIF' ? this.isCompany = true : this.isCompany = false;
            if (this.isCompany) {
                thirdParam.bornDate = null;
            }
            else if (typeof thirdParam.bornDate === 'string') {
                thirdParam.bornDate = thirdParam.bornDate.replace(/\-/g, "/");
            }
            else {
                thirdParam.bornDate = this.addLeadingZero(thirdParam.bornDate.getDate()) + "/" + this.addLeadingZero(thirdParam.bornDate.getMonth() + 1) + "/" + thirdParam.bornDate.getFullYear();
            }
            form.append('insured.bornDate', thirdParam.bornDate);
        }
        if (null !== thirdParam.documentNumber) {
            form.append('insured.documentNumber', thirdParam.documentNumber);
        }
        if (null !== thirdParam.name) {
            form.append('insured.name', thirdParam.name);
        }
        if (null !== thirdParam.surname) {
            form.append('insured.surname', thirdParam.surname);
        }
        if (null !== thirdParam.lastName) {
            form.append('insured.lastName', thirdParam.lastName);
        }
        if (null !== thirdParam.email) {
            form.append('insured.email', thirdParam.email);
        }
        if (null !== thirdParam.phone) {
            form.append('insured.phone', thirdParam.phone);
        }
        if (null !== thirdParam.phonePrefix) {
            form.append('insured.phonePrefix', thirdParam.phonePrefix[0].id);
        }
        if (null !== thirdParam.mobilePhone) {
            form.append('insured.mobilePhone', thirdParam.mobilePhone);
        }
        if (null !== thirdParam.mobilePhonePrefix) {
            form.append('insured.mobilePhonePrefix', thirdParam.mobilePhonePrefix[0].id);
        }
        if (null !== thirdParam.postalCode) {
            form.append('insured.postalCode', thirdParam.postalCode);
        }
        if (null !== thirdParam.city[0].itemName) {
            form.append('insured.city', thirdParam.city[0].itemName);
        }
        if (null !== thirdParam.address) {
            form.append('insured.address', thirdParam.address);
        }
        form.append('insured.roadType', thirdParam.roadType[0].id);
        if (null !== thirdParam.number) {
            form.append('insured.number', thirdParam.number);
        }
        if (null !== thirdParam.portal) {
            form.append('insured.portal', thirdParam.portal);
        }
        if (null !== thirdParam.door) {
            form.append('insured.door', thirdParam.door);
        }
        if (null !== thirdParam.stair) {
            form.append('insured.stair', thirdParam.stair);
        }
        if (null !== thirdParam.floor) {
            form.append('insured.floor', thirdParam.floor);
        }
        if (null !== thirdParam.files && thirdParam.files.length > 0) {
            thirdParam.files.forEach(function (element) {
                form.append('insured.files', element);
            });
        }
        if (null !== fourthParam.name) {
            form.append('contact.name', fourthParam.name);
        }
        if (null !== fourthParam.surname) {
            form.append('contact.surname', fourthParam.surname);
        }
        if (null !== fourthParam.lastName) {
            form.append('contact.lastName', fourthParam.lastName);
        }
        if (null !== fourthParam.email) {
            form.append('contact.email', fourthParam.email);
        }
        if (null !== fourthParam.phone) {
            form.append('contact.phone', fourthParam.phone);
        }
        if (null !== fourthParam.phonePrefix) {
            form.append('contact.phonePrefix', fourthParam.phonePrefix[0].id);
        }
        if (null !== fourthParam.mobilePhone) {
            form.append('contact.mobilePhone', fourthParam.mobilePhone);
        }
        if (null !== fourthParam.mobilePhonePrefix) {
            form.append('contact.mobilePhonePrefix', fourthParam.mobilePhonePrefix[0].id);
        }
        if (null !== fourthParam.fax) {
            form.append('contact.fax', fourthParam.fax);
        }
        if (null !== fourthParam.faxPrefix) {
            form.append('contact.faxPrefix', fourthParam.faxPrefix[0].id);
        }
        if (null !== fourthParam.postalCode) {
            form.append('contact.postalCode', fourthParam.postalCode);
        }
        if (null !== fourthParam.city[0].itemName) {
            form.append('contact.city', fourthParam.city[0].itemName);
        }
        if (null !== fourthParam.address) {
            form.append('contact.address', fourthParam.address);
        }
        form.append('contact.roadType', fourthParam.roadType[0].id);
        if (null !== fourthParam.number) {
            form.append('contact.number', fourthParam.number);
        }
        if (null !== fourthParam.portal) {
            form.append('contact.portal', fourthParam.portal);
        }
        if (null !== fourthParam.door) {
            form.append('contact.door', fourthParam.door);
        }
        if (null !== fourthParam.stair) {
            form.append('contact.stair', fourthParam.stair);
        }
        if (null !== fourthParam.floor) {
            form.append('contact.floor', fourthParam.floor);
        }
        if (null !== fourthParam.callMorning) {
            form.append('contact.callMorning', fourthParam.callMorning);
        }
        if (null !== fourthParam.callEvening) {
            form.append('contact.callEvening', fourthParam.callEvening);
        }
        if (null !== fourthParam.callNight) {
            form.append('contact.callNight', fourthParam.callNight);
        }
        if (null !== fourthParam.relationShip) {
            form.append('contact.relationShip', fourthParam.relationShip);
        }
        if (null !== fourthParam.idprovince) {
            form.append('contact.province', fourthParam.idprovince);
        }
        return this.http.post(url, form).toPromise();
    };
    EventService.prototype.getExpedientMessagesByNumber = function (eventNumber) {
        var url = this.domainService.backendUrlWithContext + '/events/messages/' + eventNumber;
        return this.http.get(url).toPromise();
    };
    EventService.prototype.sendExpedientMessage = function (eventNumber, message) {
        var form = new FormData();
        if (null !== message.message) {
            form.append('message', message.message);
        }
        if (null !== message.documentId) {
            form.append('documentId', message.documentId.toString());
        }
        var url = this.domainService.backendUrlWithContext + '/events/registerMessage/' + eventNumber + '/';
        return this.http.post(url, form).toPromise();
    };
    EventService.prototype.emitSentMessage = function () {
        this.expedientMessageSent.emit(true);
    };
    EventService.prototype.addLeadingZero = function (value) {
        if (value < 10) {
            return "0" + value.toString();
        }
        return value.toString();
    };
    EventService.ngInjectableDef = i0.defineInjectable({ factory: function EventService_Factory() { return new EventService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.ToastrService), i0.inject(i4.DomainService)); }, token: EventService, providedIn: "root" });
    return EventService;
}());
export { EventService };
