/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-box.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./check-box.component";
var styles_CheckBoxComponent = [i0.styles];
var RenderType_CheckBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckBoxComponent, data: {} });
export { RenderType_CheckBoxComponent as RenderType_CheckBoxComponent };
export function View_CheckBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "input-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "label", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "value", 0], [8, "disabled", 0]], [[null, "click"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "side-right"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "side-right"; var currVal_4 = (!(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)) ? "text-black" : "text-red"); _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value === true); var currVal_1 = _co.value; var currVal_2 = _co.disabled; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CheckBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-box", [], null, null, null, View_CheckBoxComponent_0, RenderType_CheckBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.CheckBoxComponent, [[8, null]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckBoxComponentNgFactory = i1.ɵccf("app-check-box", i3.CheckBoxComponent, View_CheckBoxComponent_Host_0, { disabled: "disabled", label: "label", description: "description", errorDescription: "errorDescription" }, {}, ["*"]);
export { CheckBoxComponentNgFactory as CheckBoxComponentNgFactory };
