<div class="register-form no-text-select">
    <form [formGroup]="registerForm" (ngSubmit)="onRegister()" class="no-text-select">
        <section *ngIf=!started>
            <div class="title row">
                <p class="welcome">Bienvenido</p>
                <p>Tu privacidad es importante, por eso queremos saber primero tus preferencias
                </p>
            </div>

            <div class="row ">
                <app-check-box formControlName="insuranceAdvertising">
                    <div class="inner-check">
                        <p>Publicidad de Seguros: </p>
                        <p>No estoy de acuerdo que, previa evaluación de mis cualidades, se me ofrezca publicidad de seguros.
                        </p>
                    </div>
                </app-check-box>
            </div>

            <div class="row">

                <app-check-box formControlName="legalServicesAdvertising">
                    <div class="inner-check">
                        <p>Publicidad de Servicios Legales: </p>
                        <p>Acepto que onLygal Seguros ceda mis datos a la empresa del Grupo, Onlygal Servicios Legales, S.L., para que esta me remita información publicitaria referida a servicios legales.</p>
                    </div>
                </app-check-box>

            </div>


        </section>

        <section *ngIf=started>
            <div class="title row">
                <p class="welcome">¡Hola!</p>
                <p>Para registrarte en el Área Cliente necesitamos que rellenes los siguientes datos:</p>
            </div>
            <div class="row steps">
                <p class="paso">Paso 1 de 3
                </p>
            </div>
            <div class="row cont-select">
                <div class="inner-select">
                    <select formControlName="documentType" required>
                        <option value="NIF">NIF</option>
                        <option value="NIE">NIE</option>
                        <option value="CIF">CIF</option>
                    </select>
                </div>
                <div class="inner-input">
                    <app-input-box type="text" label="Número de documento" description="Completo con cifras y letra" 
                    errorDescription="Completo con cifras y letra" formControlName="documentNumber" minlength="9" maxlength="9">
                    </app-input-box>
                </div>
            </div>

            <div class="row">
                <app-input-box type="text" label="Correo electrónico" description="Lo necesitamos para confirmar tu registro" 
                errorDescription="Lo necesitamos para confirmar tu registro" formControlName="email" maxlength="255">
                </app-input-box>
            </div>

            <div class="row cont-select">
                <div class="inner-select">
                    <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                        formControlName="mobilePhonePrefix">
                        <c-badge>
                            <ng-template let-item="item">
                                <label class="phoneBadge">
                                    <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                    <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                    <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-badge>

                        <c-item>
                            <ng-template let-item="item">
                                <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                    <span class="flags flag{{item.id}}"></span>
                                    <span>+{{item.id}}</span>
                                    <span>{{item.itemName}}</span>
                                </label>
                            </ng-template>
                        </c-item>
                    </angular2-multiselect>
                </div>

                <div class="inner-input">
                    <app-input-box type="text" label="Teléfono móvil" formControlName="mobilePhone" maxlength="128" mask="000000000000000000"
                        isValidPhoneErrorDescription="Número no válido" description="Lo necesitamos para confirmar tu registro" 
                        errorDescription="Lo necesitamos para confirmar tu registro">
                    </app-input-box>
                </div>

            </div>

            <div class="row">
                <app-input-box *ngIf="!isReinsured && !isInnominado" type="text" label="Número de póliza / contrato" description="Para comprobar que eres cliente nuestro" 
                errorDescription="Para comprobar que eres cliente nuestro" formControlName="policy" maxlength="8" 
                tooltip="Número de 8 dígitos que encontrarás en el apartado “Condiciones particulares” de tu contrato."
                    tooltipDirection="left">
                </app-input-box>

                <app-input-box *ngIf="isReinsured || isInnominado" type="text" label="Número de Siniestro/Parte" description="Para comprobar que eres cliente nuestro" 
                    errorDescription="Para comprobar que eres cliente nuestro" formControlName="event" maxlength="8" 
                    tooltip="Número de 8 dígitos que encontrarás en el correo electrónico que te hemos enviado.">
                </app-input-box>  
            </div>

            <div class="row reca">
                <app-google-recaptcha (onRecaptcha)="onRecaptcha($event)"></app-google-recaptcha>
            </div>

            <div class="row" *ngIf="null === recoveryHtml">
                <app-error-messages [(messages)]=messages></app-error-messages>
            </div>

            <div class="row" class="recovery" *ngIf="null !== recoveryHtml" [innerHtml]="recoveryHtml | safeHtml"></div>

        </section>

        <div class="row">
            <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running">
            </app-spinner-button>
        </div>

        <div class="row">
            <app-contact-line [isReinsuredParam] = "isReinsured"></app-contact-line>
        </div>
    </form>
</div>