<div class="box-transparent" [routerLink]="['/']"></div>
<div [ngClass]="['container',getEntityClass(),'sbs',takerBlocked ? 'back-full':'']" [style.--entity]="reinsured">
    
    <section class="side-left" *ngIf="!takerBlocked">
        <app-register-form (onRegisterSubmit)="onRegisterSubmit($event)" (onTakerBlock)="onTakerBlock($event)" [isInnominado]="innominado" [isReinsured]="reinsured" [formGroup]="registerForm">
        </app-register-form>
    </section>

    <section class="side-right" *ngIf="!takerBlocked">
        <app-picture-with-text [isReinsured]="reinsured"></app-picture-with-text>
    </section>

    <div class="center" *ngIf="takerBlocked">
        <app-taker-block-notify></app-taker-block-notify>
    </div>

</div>