import { NgModule, InjectionToken, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ngfModule } from "angular-file"

import { CommonsModule } from '../commons/commons.module';
import { SecurityModule } from '../security/security.module';

import { AuthRedirectorGuard } from 'app/security/auth.redirector.guard';
import { LogoutGuard } from 'app/security/auth.logout.guard';

import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ModifyPageComponent } from './pages/modify-page/modify-page.component';
import { ReceiptPageComponent } from './pages/receipt-page/receipt-page.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';

import { BlogComponent } from './components/main/blog/blog.component';
import { BlogContainerComponent } from './components/main/blog-container/blog-container.component';
import { CarouselContainerComponent } from './components/main/carousel-container/carousel-container.component';
import { CarouselComponent } from './components/main/carousel/carousel.component';
import { CarouselReceiptComponent } from './components/main/carousel-items/carousel-receipt/carousel-receipt.component';
import { CarouselServiceComponent } from './components/main/carousel-items/carousel-service/carousel-service.component';
import { CarouselProductComponent } from './components/main/carousel-items/carousel-product/carousel-product.component';
import { CarouselOpenEventComponent } from './components/main/carousel-items/carousel-openevent/carousel-openevent.component';


import { ProfilePreferenceComponent } from './components/profile/profile-preference/profile-preference.component';
import { ProfileBankComponent } from './components/profile/profile-bank/profile-bank.component';
import { ProfileContactComponent } from './components/profile/profile-contact/profile-contact.component';

import { ModifyBankComponent } from './components/modify/modify-bank/modify-bank.component';
import { ModifyContactComponent } from './components/modify/modify-contact/modify-contact.component';
import { ModifyPreferenceComponent } from './components/modify/modify-preference/modify-preference.component';
import { ModifyAccessComponent } from './components/modify/modify-access/modify-access.component';

import { CarouselCardWpIntegrationComponent } from './components/main/carousel-items/carousel-card-wp-integration/carousel-card-wp-integration.component';

import { WpFooterIntegrationComponent } from './components/receipt/wp-footer-integration/wp-footer-integration.component';

const redirectProvider = new InjectionToken('externalUrlRedirectResolver');

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { CollapseContainerComponent } from './components/collapse-container/collapse-container.component';
import { CollapseContainerEventComponent } from './components/collapse-container-event/collapse-container-event.component';
import { CollapseProductContainerComponent } from './components/collapse-product-container/collapse-product-container.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { SplitButtonItemComponent } from './components/split-button-item/split-button-item.component';

import { ReceiptFilterComponent } from './components/receipt/receipt-filter/receipt-filter.component';
import { ReceiptPaginationComponent } from './components/receipt/receipt-pagination/receipt-pagination.component';
import { ReceiptItemComponent } from './components/receipt/receipt-item/receipt-item.component';

import { HeaderMainProfileComponent } from './components/header/header-main-profile/header-main-profile.component';

import { ProfileCompleteBarComponent } from './components/profile-complete-bar/profile-complete-bar.component';
import { HeaderProfileProgressBarComponent } from './components/header/header-profile-progress-bar/header-profile-progress-bar.component';
import { HeaderMainCenterNavigationComponent } from './components/header/header-main-center-navigation/header-main-center-navigation.component';
import { HeaderProfileBarComponent } from './components/header/header-profile-bar/header-profile-bar.component';
import { HeaderProfileProgressBarSimpleComponent } from './components/header/header-profile-progress-bar-simple/header-profile-progress-bar-simple.component';

import { ProductDetailPageComponent } from './pages/product-detail-page/product-detail-page.component';
import { ProductCoverageComponent } from './components/product/product-coverage/product-coverage.component';
import { ProductTenantComponent } from './components/product/product-tenant/product-tenant.component';
import { ProductLastReceiptComponent } from './components/product/product-last-receipt/product-last-receipt.component';
import { ProductPaginationComponent } from './components/product/product-pagination/product-pagination.component';
import { ProductItemComponent } from './components/product/product-item/product-item.component';

import { EventsPageComponent } from './pages/event-page/event-page.component';
import { EventLinksComponent } from './components/event/home/event-links/event-links.component';
import { EventHistoryPageComponent } from './pages/event-history-page/event-history-page.component';
import { EventDetailPageComponent } from './pages/event-detail-page/event-detail-page.component';
import { EventAssistanceImageComponent } from './components/event/home/event-assistance-image/event-assistance-image.component';
import { EventCheckBallsComponent } from './components/event/detail/event-check-balls/event-check-balls.component';
import { EventDataComponent } from './components/event/detail/event-data/event-data.component';
import { EventWarrantyComponent } from './components/event/detail/event-warranty/event-warranty.component';
import { EventLawyerComponent } from './components/event/detail/event-lawyer/event-lawyer.component';
import { EventDocumentationComponent } from './components/event/detail/event-documentation/event-documentation.component';
import { EventFilterComponent } from './components/event/history/event-filter/event-filter.component';
import { EventPaginationComponent } from './components/event/history/event-pagination/event-pagination.component';
import { EventItemComponent } from './components/event/history/event-item/event-item.component';
import { EventExpedientComponent } from './components/event/detail/event-expedient/event-expedient.component';
import { PagosParteComponent } from './components/event/detail/pagos-parte/pagos-parte.component';
import { IndemnizacionesParteComponent } from './components/event/detail/indemnizaciones-parte/indemnizaciones-parte.component';
import { ServiciosRelacionadosParteComponent } from './components/event/detail/servicios-relacionados-parte/servicios-relacionados-parte.component';
import { EventRegisterPageComponent } from './pages/event-register-page/event-register-page.component';
import { EventRegisterFirstStepComponent } from './components/event/register/event-register-first-step/event-register-first-step.component';
import { EventRegisterCheckBallsComponent } from './components/event/register/event-register-check-balls/event-register-check-balls.component';
import { ProductComunityComponent } from './components/product/product-comunity/product-comunity.component';
import { ProductDataComponent } from './components/product/product-data/product-data.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ProductInsuredVehicleComponent } from './components/product/product-insured-vehicle/product-insured-vehicle.component';
import { ProductInsuredBuildingComponent } from './components/product/product-insured-building/product-insured-building.component';
import { ContactFormPageComponent } from './pages/contact-form-page/contact-form-page.component';
import { ContactClaimsPageComponent } from './pages/contact-claims-page/contact-claims-page.component';
import { ContactCallmePageComponent } from './pages/contact-callme-page/contact-callme-page.component';
import { CallmeTitleComponent } from './components/contact/callme-title/callme-title.component';
import { ContactBoxesComponent } from './components/contact/contact-boxes/contact-boxes.component';
import { ReceiptPayPageComponent } from './pages/receipt-pay-page/receipt-pay-page.component';

registerLocaleData(localeDe, 'de');

import {
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';

import { HireMorePageComponent } from './pages/hire-more-page/hire-more-page.component';
import { ProductServiceComponent } from './components/product/product-service/product-service.component';
import { ProductOtherComponent } from './components/product/product-other/product-other.component';
import { HireFormPageComponent } from './pages/hire-form-page/hire-form-page.component';
import { ProductFilterComponent } from './components/product/product-filter/product-filter.component';
import { ProductServiceFilterComponent } from './components/product/product-service-filter/product-service-filter.component';
import { EventRegisterSecondStepComponent } from './components/event/register/event-register-second-step/event-register-second-step.component';
import { EventRegisterThirdStepComponent } from './components/event/register/event-register-third-step/event-register-third-step.component';
import { ProductServicePaginationComponent } from './components/product/product-service-pagination/product-service-pagination.component';
import { ProductServiceDetailPageComponent } from './pages/product-service-detail-page/product-service-detail-page.component';
import { ProductClauseComponent } from './components/product/product-clause/product-clause.component';
import { ProductHireServiceComponent } from './components/product/product-hire-service/product-hire-service.component';
import { EventMessengerComponent } from './components/event/detail/event-messenger/event-messenger.component';
import { EventMessengerHistoryComponent } from './components/event/detail/event-messenger-history/event-messenger-history.component';
import { EventMessengerSendComponent } from './components/event/detail/event-messenger-send/event-messenger-send.component';
import { EventMessengerMessageComponent } from './components/event/detail/event-messenger-message/event-messenger-message.component';
import { EventCorrespondenceComponent } from './components/event/detail/event-correspondence/event-correspondence.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { ProductLegalibooComponent } from './components/product/product-legaliboo/product-legaliboo.component';
import { ProductFanditComponent } from './components/product/product-fandit/product-fandit.component';
import { ClubPoliticaComponent } from './pages/club-politica/club-politica.component';
import { ClubCondicionesComponent } from './pages/club-condiciones/club-condiciones.component';

import { MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule, } from '@angular/material';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CoreModule } from 'app/core/core.module';
import { WordpressPageComponent } from './pages/wordpress-page/wordpress-page.component';
import { NoticiasClubComponent } from 'app/modules/club-propietario/components/noticias-club/noticias-club.component';

declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        let mc = new Hammer(element, {
            touchAction: "pan-y"
        });
        return mc;
    }
}

const appRoutes: Routes = [

    {
        path: 'main',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'profile',
        canActivate: [AuthRedirectorGuard],
        component: ProfilePageComponent,
    },
    {
        path: 'modify',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'receipts',
        canActivate: [AuthRedirectorGuard],
        component: ReceiptPageComponent,
    },
    {
        path: 'hire',
        canActivate: [AuthRedirectorGuard],
        component: HireMorePageComponent,
    },
    {
        path: 'hire/extend/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: HireFormPageComponent,
    },
    {
        path: 'receipt/:paymentResponse/:receiptYear/:receiptNumber',
        canActivate: [AuthRedirectorGuard],
        component: ReceiptPayPageComponent,
    },
    {
        path: 'products',
        canActivate: [AuthRedirectorGuard],
        component: ProductPageComponent,
    },
    {
        path: 'productService/:expediente',
        canActivate: [AuthRedirectorGuard],
        component: ProductServiceDetailPageComponent,
    },
    {
        path: 'products/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: ProductDetailPageComponent,
    },

    {
        path: 'events',
        canActivate: [AuthRedirectorGuard],
        component: EventsPageComponent,
    },
    {
        path: 'contact',
        canActivate: [AuthRedirectorGuard],
        component: ContactPageComponent,
    },
    {
        path: 'contact/claims',
        canActivate: [AuthRedirectorGuard],
        component: ContactClaimsPageComponent,
    },
    {
        path: 'contact/form',
        canActivate: [AuthRedirectorGuard],
        component: ContactFormPageComponent,
    },
    {
        path: 'contact/callme',
        canActivate: [AuthRedirectorGuard],
        component: ContactCallmePageComponent,
    },
    {
        path: 'events/history',
        canActivate: [AuthRedirectorGuard],
        component: EventHistoryPageComponent,
    },
    {
        path: 'events/history/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventHistoryPageComponent,
    },
    {
        path: 'event/:eventYear/:eventNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventDetailPageComponent,
    },
    {
        path: 'event/:eventYear/:eventNumber/:show',
        canActivate: [AuthRedirectorGuard],
        component: EventDetailPageComponent,
    },
    {
        path: 'events/register',
        canActivate: [AuthRedirectorGuard],
        component: EventRegisterPageComponent,
    },
    {
        path: 'events/register/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventRegisterPageComponent,
    },
    {
        path: 'modify/contact',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/payment',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/access',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/preferences',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'conditions-of-use',
        canActivate: [AuthRedirectorGuard],
        component: WordpressPageComponent,
        data: { page: 'conditions-of-use' }
    },
    {
        path: 'cookies-policy',
        canActivate: [AuthRedirectorGuard],
        component: WordpressPageComponent,
        data: { page: 'cookies-policy' }
    },
    {
        path: 'services',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'logout',
        canActivate: [LogoutGuard],
        component: MainPageComponent,
    },
    {
        path: 'redirect',
        resolve: {
            url: redirectProvider,
        },
        component: MainPageComponent,
    },
    {
        path: '',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'notifications',
        canActivate: [AuthRedirectorGuard],
        component: NotificationsPageComponent,
    },    
    {
        path: 'club-del-propietario/politica-club',
        canActivate: [AuthRedirectorGuard],
        component: ClubPoliticaComponent,
    }, 
    {
        path: 'club-del-propietario/condiciones-club',
        canActivate: [AuthRedirectorGuard],
        component: ClubCondicionesComponent,
    }, 
];

@NgModule({
    declarations: [MainPageComponent, WordpressPageComponent,
        BlogComponent, BlogContainerComponent, CarouselContainerComponent, CarouselComponent,
        CarouselServiceComponent, CarouselProductComponent, CarouselOpenEventComponent, CarouselServiceComponent,
        WpFooterIntegrationComponent, CarouselCardWpIntegrationComponent, CollapseContainerEventComponent, CollapseContainerComponent, CollapseProductContainerComponent, SplitButtonComponent,
        SplitButtonItemComponent, CarouselReceiptComponent,

        ProfilePageComponent, ProfilePreferenceComponent, ProfileBankComponent, ProfileContactComponent, ProfileCompleteBarComponent,

        ModifyPageComponent, ModifyBankComponent, ModifyContactComponent, ModifyPreferenceComponent, ModifyAccessComponent,

        HeaderMainProfileComponent,  HeaderProfileProgressBarComponent,
        HeaderMainCenterNavigationComponent,  HeaderProfileProgressBarComponent,
        HeaderProfileProgressBarSimpleComponent, HeaderProfileBarComponent,

        ReceiptPayPageComponent, ReceiptPageComponent, ReceiptPageComponent, ReceiptItemComponent, ReceiptFilterComponent,
        ReceiptPaginationComponent,

        ProductInsuredBuildingComponent, ProductTenantComponent, ProductLastReceiptComponent, ProductItemComponent,
        ProductPaginationComponent, ProductServicePaginationComponent, ProductDetailPageComponent, ProductCoverageComponent,
        ProductPageComponent, ProductFilterComponent, ProductServiceFilterComponent, ProductInsuredVehicleComponent,
        ProductComunityComponent, ProductDataComponent, ProductClauseComponent, ProductServiceComponent,
        ProductOtherComponent, ProductServiceDetailPageComponent, ProductHireServiceComponent, ProductServiceFilterComponent,

        EventsPageComponent, EventAssistanceImageComponent, EventLinksComponent, EventHistoryPageComponent,
        EventDetailPageComponent, EventRegisterCheckBallsComponent, EventCheckBallsComponent,
        EventDataComponent, EventWarrantyComponent, EventLawyerComponent, EventDocumentationComponent, EventCorrespondenceComponent,
        EventFilterComponent, EventPaginationComponent, EventItemComponent, EventRegisterPageComponent,
        EventRegisterFirstStepComponent, EventRegisterSecondStepComponent,
        EventRegisterThirdStepComponent, EventMessengerComponent, EventMessengerHistoryComponent, EventMessengerSendComponent,
        EventMessengerMessageComponent, NoticiasClubComponent,

        ContactPageComponent, ContactPageComponent, ContactFormPageComponent, ContactClaimsPageComponent, ContactCallmePageComponent,
        CallmeTitleComponent, ContactBoxesComponent, ClubPoliticaComponent, ClubCondicionesComponent,

        HireMorePageComponent, HireFormPageComponent, EventExpedientComponent, PagosParteComponent, IndemnizacionesParteComponent, ServiciosRelacionadosParteComponent, NotificationsPageComponent, ProductLegalibooComponent, ProductFanditComponent ],

    imports: [

        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'ignore',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            useHash: true
        }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        AngularMultiSelectModule,
        ngfModule,

        SecurityModule,
        CommonsModule,
        
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
        CoreModule
    ],
    providers: [
        {
            provide: redirectProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('url');
                window.open(externalUrl, '_blank');
            },
        },
        {
            provide: LOCALE_ID,
            useValue: 'de'
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
    ],
    exports: [
        RouterModule
    ]

})
export class HeaderFooterTemplateModule {
}

