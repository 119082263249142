import { HttpClient } from '@angular/common/http';
import { DomainService } from './domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./domain-service";
var DeydeService = /** @class */ (function () {
    function DeydeService(http, domainService) {
        this.http = http;
        this.domainService = domainService;
    }
    DeydeService.prototype.onCheckDeydeEmail = function (email) {
        var url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        var form = new FormData();
        form.append('email', email);
        return this.http.post(url, form).toPromise();
    };
    DeydeService.prototype.onCheckDeydePhone = function (phone) {
        var url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        var form = new FormData();
        form.append('phone', phone);
        return this.http.post(url, form).toPromise();
    };
    DeydeService.prototype.onCheckDeydeMobile = function (mobile) {
        var url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        var form = new FormData();
        form.append('mobile', mobile);
        return this.http.post(url, form).toPromise();
    };
    DeydeService.ngInjectableDef = i0.defineInjectable({ factory: function DeydeService_Factory() { return new DeydeService(i0.inject(i1.HttpClient), i0.inject(i2.DomainService)); }, token: DeydeService, providedIn: "root" });
    return DeydeService;
}());
export { DeydeService };
