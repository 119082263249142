<div class="bank no-text-select">
    <div class="bank-title">
        <p>Mis datos bancarios</p>
    </div>
    <div class="bank-form">
        <div class="bank-form-inner" *ngFor="let item of data">
            <div class="bank-form-left">
                <span>póliza</span>
                <p>{{item.number}}</p>
            </div>
            <div class="bank-form-center">
                <span>cuenta bancaria</span>
                <p>{{item.bankAccount}}</p>
            </div>
            <div class="bank-form-right">
                <span>tipo de pago</span>
                <p>{{item.periodicity}}</p>
            </div>

        </div>        
    </div>
    <div class="bank-modify">
        <a [routerLink]="[ '/modify/payment']">Modificar datos bancarios</a>
    </div>
</div>