<form [formGroup]="confirmRegisterForm" (ngSubmit)="onConfirmRegister()" class="no-text-select">

    <div class="title row">
        <p>Registro del Área Cliente</p>
        <p>Este es el último paso para darte de alta</p>
    </div>
    <div class="row steps">
        <p class="paso">Paso 3 de 3
        </p>
    </div>
    <div class="row">
        <app-input-box type="password" label="Contraseña" description="Introduzca una contraseña" errorDescription="Introduzca una contraseña" formControlName="password" autocomplete="nope" maxlength="16" tooltip="Utiliza ocho caracteres como mínimo combinando letras mayúsculas y minúsculas, números y símbolos  (€-.,´`+¡'}{][¬~€#@|\;:_^*¿?=)(/&%$·&quot;!)"
            tooltipDirection="left"></app-input-box>
    </div>

    <div class="row">
        <app-input-box type="password" label="Confirmación de Contraseña" description="Las contraseñas deben coincidir" errorDescription="La contraseña debe ser la misma que la confirmación" formControlName="password2" autocomplete="nope" maxlength="16"></app-input-box>
    </div>

    <div class="row">

        <app-check-box formControlName="termsAndConditions">
            <p>He leído y acepto las <a [routerLink]="[ '/register/conditions-of-use' ]" target="_blank"> condiciones de uso</a> de Grupo Onlygal</p>
        </app-check-box>

    </div>

    <div class="row">
        <app-check-box formControlName="dataPolicy">
            <p>He leído y acepto la <a [routerLink]="[ '/register/privacy-politics' ]" target="_blank">política de privacidad</a> del Grupo Onlygal</p>
        </app-check-box>

    </div>

    <div class="row">
        <app-error-messages [(messages)]=messages></app-error-messages>
    </div>

    <div class="row">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"></app-spinner-button>
    </div>
    <div class="row">
        <app-contact-line></app-contact-line>
    </div>
</form>