<div class="blog no-text-select wow fadeInUp" data-wow-duration="1s" [attr.data-wow-delay]="getDelay()">
    <div class="blog-image" [ngStyle]="getStyle()"></div>
    <div class="blog-content">
        <span class="blog-title" (click)="toggle()">
            {{content?.title?.rendered}}
        
        </span>
    </div>
    <div class="blog-reveal" [ngClass]="(visible === true) ? 'show' : ''">
        <span class="blog-reveal-title" (click)="toggle()">{{content?.title?.rendered}}</span>
        <p [innerHTML]="content?.excerpt?.rendered"></p>
        <div class="blog-reveal-more"><a href="{{content?.link}}" target="_blank">Leer más</a></div>
    </div>
</div>