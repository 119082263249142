/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-correspondence.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./event-correspondence.component";
import * as i4 from "ngx-toastr";
import * as i5 from "../../../../../services/event-service";
var styles_EventCorrespondenceComponent = [i0.styles];
var RenderType_EventCorrespondenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventCorrespondenceComponent, data: {} });
export { RenderType_EventCorrespondenceComponent as RenderType_EventCorrespondenceComponent };
function View_EventCorrespondenceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "correspondence-main-inf "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "img-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGetEventFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGetEventFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "right-format"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGetEventFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.creationDate; _ck(_v, 5, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.format; _ck(_v, 11, 0, currVal_2); }); }
function View_EventCorrespondenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "correspondence"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "correspondence-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comunicaciones"])), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "correspondence-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "correspondence-main-sup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.orderCorrespondence(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fecha inserci\u00F3n \u00A0"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comunicaci\u00F3n:"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "right-format"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Formato:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventCorrespondenceComponent_2)), i1.ɵdid(20, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.orderDesc ? "order-correspondence-up" : "order-correspondence-down"); _ck(_v, 12, 0, currVal_0); var currVal_1 = _co.correspondence; _ck(_v, 20, 0, currVal_1); }, null); }
export function View_EventCorrespondenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventCorrespondenceComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.correspondence && (_co.correspondence.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EventCorrespondenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-correspondence", [], null, null, null, View_EventCorrespondenceComponent_0, RenderType_EventCorrespondenceComponent)), i1.ɵdid(1, 114688, null, 0, i3.EventCorrespondenceComponent, [i4.ToastrService, i5.EventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventCorrespondenceComponentNgFactory = i1.ɵccf("app-event-correspondence", i3.EventCorrespondenceComponent, View_EventCorrespondenceComponent_Host_0, { data: "data" }, {}, []);
export { EventCorrespondenceComponentNgFactory as EventCorrespondenceComponentNgFactory };
