import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent  {

    @Input() messages: String[] = null;

}
