<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb text="Mis Recibos"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class=" container">
    <div class="receipts-container">
        <app-receipt-filter [loading]="loading" [response]="response"></app-receipt-filter>

        <div *ngIf="undefined === response" class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div class="pagination wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length > 0" >
            <app-receipt-pagination [loading]="loading" [response]="response">
            </app-receipt-pagination>
        </div>

        <div class="list wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length > 0">
            <div *ngFor="let item of response?.content">
                <app-receipt-item [data]=item></app-receipt-item>
            </div>
        </div>

        <div class="pagination-bottom" *ngIf="response?.content?.length > 0">
            <app-receipt-pagination [loading]="loading" [response]="response">
            </app-receipt-pagination>
        </div>

        <div class="no-results wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length === 0">
            No hay recibos disponibles.
        </div>

        <!--<div class="wow fadeInUp" data-wow-duration="1s">
        <app-wp-footer-integration></app-wp-footer-integration>
    </div>-->

    </div>

</div>

<app-footer></app-footer>