import { Component, ViewContainerRef } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { ReceiptService } from './services/receipt-service';
import { LocationStrategy } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>
               <app-payment-loading></app-payment-loading>
			   <app-add-account-to-policy></app-add-account-to-policy>
               <change-policy-receipts></change-policy-receipts>
               <app-complete-profile-modal></app-complete-profile-modal>
               <app-pop-up-legaliboo></app-pop-up-legaliboo>
               `
})

export class AppComponent {

    isPopState = false;
    constructor(wowService: NgwWowService, viewContainerRef: ViewContainerRef, receiptService: ReceiptService,
        private router: Router, private locStrat: LocationStrategy, private clubPropietarioService: ClubPropietarioService) {
        this.clubPropietarioService.getCategoriaWordPress();
        wowService.init();
        receiptService.setRootViewContainerRef(viewContainerRef);

        console.clear();
        console.log('%c MiArea Cliente - Grupo Onlygal', 'color: red; font-family:tahoma; font-size: 40px;');

        this.locStrat.onPopState(() => {
            this.isPopState = true;
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && !this.isPopState) {
                window.scrollTo(0, 0);
                this.isPopState = false;
            }
            if (event instanceof NavigationEnd) {
                this.isPopState = false;
            }
        });

    }

}
