<div class="carousel-content">

    <div class="left">
        <app-icon-type color="blue" [icon]="data.branchType"></app-icon-type>
    </div>

    <div class="right no-text-select">
        <div class="row row-100">
            <app-label-text label="Nº de póliza / contrato:" text="{{data.number}}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-100">
            <app-label-text label="Vigencia:" text="{{data.startDate | date: 'dd/MM/yyyy' }} - {{data.endDate | date: 'dd/MM/yyyy' }}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-100">
            <app-label-text label="{{data.product}}" text="{{data.riskDescription}}" labelClass="blue-bold" [loading]=loading></app-label-text>
        </div>
        <div class="row row-7030 split-button">
            <app-split-button *ngIf=!loading label="Quiero..." direction=top [(close)]="close">
                <app-split-button-item label="Abrir un parte" *ngIf="showDirectAreas" route="/events/register/{{data?.number}}">
                </app-split-button-item>
                <app-split-button-item label="Consultar un parte" route="/events/history/{{data?.number}}">
                </app-split-button-item>
                <app-split-button-item *ngIf="data.isTaker === true" label="Descargar el PDF del contrato" (click)="onGetContractFile()">
                </app-split-button-item>
                <app-split-button-item *ngIf="data.isTaker === true && data.isEditable === true" label="Modificar o ampliar la cobertura" route="/hire/extend/{{data?.number}}"></app-split-button-item>
                <app-split-button-item label="Ver detalles" route="/products/{{data?.number}}"></app-split-button-item>
            </app-split-button>
        </div>
    </div>

</div>