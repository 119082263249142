<div class="event-links">
    <div class="steps">
        <div class="steps-title">
            <p>¿Qué quieres hacer?</p>
        </div>
        <div class="steps-part">
            <div class="steps-part-inner">
                <div class="sup" *ngIf="showDirectAreas">
                    <a [routerLink]="['/events/register']">
                        <p>Abrir un parte</p>
                        <p>&#x2192;</p>
                    </a>
                </div>
                <div class="inf">
                    <a [routerLink]="['/events/history']">
                        <p>Consultar historial de partes</p>
                        <p>&#x2192;</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>