import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-clause',
  templateUrl: './product-clause.component.html',
  styleUrls: ['./product-clause.component.scss']
})
export class ProductClauseComponent implements OnInit {


  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
