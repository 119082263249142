import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-contact-claims-page',
  templateUrl: './contact-claims-page.component.html',
  styleUrls: ['./contact-claims-page.component.scss'],
  animations: [
    trigger('fadeInUp', [
        state('in', style({ opacity: 1 })),
        transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
        transition(':leave', animate(600, style({ opacity: 0 })))
    ])
]
})
export class ContactClaimsPageComponent implements OnInit {
  
  running: boolean = false;
  messages: String[] = null;
  contactForm: FormGroup;
  
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      mobilePhone: ['', [Validators.required]],
      hour: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });

  }

  submitEnabled() {
    return !this.running;
}

getButtonLabel() {
    return (this.running) ? CommonsText.CARGANDO_MINUSCULA : CommonsText.ENVIAR_MINUSCULAS;
}


}
