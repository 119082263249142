<div class="subnav-container">
    <div class="title">
        <p>¿Qué quieres hacer?</p>
    </div>
    <div class="parts">
        <ul>
            <li *ngIf="!showDirectAreas"><a [routerLink]="['/events/history']" >Consultar el historial de partes</a></li>
            <li *ngIf="showDirectAreas"><a [routerLink]="['/events/register']" >Abrir un parte</a></li>
            <li *ngIf="showDirectAreas && showLegaliboo"><a [routerLink]="['/products']"   [queryParams]="{show:'documents'}">Crear un documento legal</a></li>
            <li *ngIf="showDirectAreas"><a [routerLink]="['/modify/payment']" >Modificar mis datos bancarios</a></li>
            <li *ngIf="showDirectAreas"><a [routerLink]="['/contact']">Contactar con mi mediador</a></li>
            <li *ngIf="showClubPropietario && isMobile"><a [routerLink]="['/club-del-propietario']" >Club del Propietario</a></li>
        </ul>
    </div>
</div>