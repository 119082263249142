import { Component, Input } from '@angular/core';
import { trigger, transition, useAnimation, state, style, animate } from '@angular/animations';

@Component({
    selector: 'app-picture-with-text',
    templateUrl: './picture-with-text.component.html',
    styleUrls: ['./picture-with-text.component.scss'],
    animations: [
        trigger('fadeIn', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class PictureWithTextComponent {
    @Input() isReinsured: boolean;

    ngOnInit() {

    }
}
