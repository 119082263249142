import { Component, AfterViewInit } from '@angular/core';
import { AuthService } from 'app/security/auth.service';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { MenuHorizontalDTO } from 'app/modules/club-propietario/model/MenuHorizontalDTO';
import { Location } from '@angular/common';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';
import { ActivatedRoute } from '@angular/router';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {

  senderParam:string;
  categoriaInicio: string = "00";
  literalInicio: string = "Inicio club del propietario";
  showModal: boolean = false;
  
  showInicio: Boolean;
  loadingProfile: Boolean = true;  
  menus: MenuHorizontalDTO[] = [];

  sender: string;        
  categoriaSubmit: CategoriaDataDTO = new CategoriaDataDTO();
  lstCategoriasAux: CategoriaDataDTO[];
  lstCategoriaDestacados: CategoriaDataDTO[];
  lstNoticiasDestacadas: NoticiaDataDTO[];
  
  crumb: string;
  crumbText: string;  
  showDirectAreas: Boolean = true;
  showInsuredAreas: boolean = true;

  
  constructor(  private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location,
    private clubPropietarioService: ClubPropietarioService
    ) {}

  ngOnInit() {

    var client: ClientDataDTO = JSON.parse(localStorage.getItem('clientData'));

    if(client.condicionesClub && client.politicaClub){
      this.showModal = true;
    }


    document.getElementsByTagName('body')[0].style.backgroundColor = '#ffffff';


    if(this.sender===undefined){
      this.route.paramMap.subscribe(params => { 
        this.senderParam = params.get('sender');
        if(this.senderParam !== null){
          this.sender=this.senderParam;
        }else{
          this.sender=this.categoriaInicio;
        }
      });
    }
    if(this.lstCategoriaDestacados===undefined || this.lstCategoriaDestacados===null){
            this.lstCategoriaDestacados = this.clubPropietarioService.getCategoriasCompletas();  
            this.establecerCategorias();
    }else{
      this.establecerCategorias();
    }   
    this.showInicio = this.validaInicio();
    this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
  }

  ngOnDestroy (){
    document.getElementsByTagName('body')[0].style.backgroundColor = '';
  }

  ngAfterViewInit() {}  

  submit(senderParam:string){
    this.sender = senderParam;
    this.location.replaceState('/club-del-propietario');
    this.establecerCategoriaSubmit(senderParam);
    this.establecerBreadCrumbs();
    this.ngOnInit();
  }

  establecerClassTab(param:string):string{
      if(this.sender == param){
        return 'enlaceMenuHorizontalChecked';
      }else{
        return 'enlaceMenuHorizontal';
      }
  }

  validaInicio():boolean{
    if(this.sender == this.categoriaInicio){
      return true;
    }else{
      return false;
    }
  }

  establecerCategoriaSubmit(categoriaParam:string){
    for (let i = 0; i < this.lstCategoriaDestacados.length; i++) { 
        if(this.lstCategoriaDestacados[i].categoria == categoriaParam){
          this.categoriaSubmit = this.lstCategoriaDestacados[i];
        }
    }
    if(this.categoriaSubmit == undefined) {
      this.categoriaSubmit = new CategoriaDataDTO();
      this.categoriaSubmit.categoria = this.categoriaInicio;
      this.categoriaSubmit.titulo = this.literalInicio;
   }
    
  }

  establecerBreadCrumbs(){
    if(this.sender == this.categoriaInicio){
      this.crumb = "Club del propietario";
      this.crumbText = this.literalInicio;
    }else{
      this.crumb = "Club del propietario > " + this.categoriaSubmit.titulo;
      this.crumbText = this.categoriaSubmit.titulo;
    } 
  }

  establecerMenusHorizontales(){
    this.menus = [];
    this.menus.push(new MenuHorizontalDTO(this.categoriaInicio, this.literalInicio, this.establecerClassTab(this.categoriaInicio)));
    this.lstCategoriaDestacados.forEach(cat =>{
      this.menus.push(new MenuHorizontalDTO(cat.categoria, cat.titulo, this.establecerClassTab(cat.categoria)));
    });
  }

  establecerCategorias(){
    this.establecerMenusHorizontales();  
    this.establecerCategoriaSubmit(this.sender==undefined?this.categoriaInicio:this.sender);
    this.establecerBreadCrumbs(); 
    this.loadingProfile = false;
  }
  
  refrescarHomeDos(idCat:string){
    this.sender = idCat;
    window.scroll(0,0);
    this.ngOnInit();
  }
}
