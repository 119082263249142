import { Component, HostListener, ElementRef, Input, Self, Optional, OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IsMobileService } from '../isMobile.service';

@Component({
    selector: 'app-date-picker',
    templateUrl: './datePicker.component.html',
    styleUrls: ['./datePicker.component.scss']
})

export class DatePickerComponent implements OnInit, ControlValueAccessor {

    @Input() label: string;
    @Input() description: String;
    @Input() errorDescription: String;
    @Input() beforeCurrentDateErrorDescription: String;
    @Input() afterCurrentDateErrorDescription: String;
    @Input() minAgeErrorDescription: String;
    @Input() correctDayAndMonthErrorDescription: String;

    pickerVisible: Boolean = false;
    isMobile: boolean;
    disabled: Boolean = false;

    value: any = null;

    @HostListener('document:click', ['$event'])
    offClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.pickerVisible = false;
        }
    }

    constructor(@Self() @Optional() public ngControl: NgControl, isMobileService: IsMobileService, private elementRef: ElementRef) {
        this.isMobile = isMobileService.isMobile;
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() { }

    writeValue(value: any): void {
        this.parseValue(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange(event) {
    }

    onTouched() { }

    parseValue(selected: Date) {
        if (!selected) {
            this.value = null;
        } else if (typeof selected === 'string') {
            this.value = selected;
        } else {
            this.value = `${this.addLeadingZero(selected.getDate())}-${this.addLeadingZero(selected.getMonth() + 1)}-${selected.getFullYear()}`;
        }
    }

    onChangeMobile(selected: String) {
        if ((selected !== undefined) && (selected !== null) && (selected !== '')) {
            const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
            this.onChange(selected.replace(pattern, '$3-$2-$1'));
        }
        if(selected === ""){
            this.onChange(null);
        }
    }

    onSelectCallback(selected: Date) {
        this.parseValue(selected);
        this.onChange(this.value);
        this.pickerVisible = true;
    }

    onFocusOut(value) {
    }


    private addLeadingZero(value: number): string {
        if (value < 10) {
            return `0${value.toString()}`;
        }
        return value.toString();
    }

}
