/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subcategoria.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./subcategoria.component";
import * as i5 from "../../../../services/client-service";
import * as i6 from "ngx-toastr";
import * as i7 from "../../../../commons/components/isMobile.service";
var styles_SubCategoriaComponent = [i0.styles];
var RenderType_SubCategoriaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubCategoriaComponent, data: {} });
export { RenderType_SubCategoriaComponent as RenderType_SubCategoriaComponent };
function View_SubCategoriaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "noticia"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "enlacePost"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 3), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 6, 0, "/club-del-propietario/detalle/", _v.context.$implicit.categoria, _v.context.$implicit.id); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.imagen.source_url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).target; var currVal_2 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.titulo; _ck(_v, 7, 0, currVal_4); }); }
function View_SubCategoriaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "showColumns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubCategoriaComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subcategoria.noticiaList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SubCategoriaComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "noticiaMobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "enlacePost"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 3), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 4, 0, "/club-del-propietario/detalle/", _v.context.$implicit.categoria, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.imagen.source_url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.titulo; _ck(_v, 5, 0, currVal_4); }); }
function View_SubCategoriaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "showColumns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SubCategoriaComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.subcategoria.noticiaList, 0, 4)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SubCategoriaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubCategoriaComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubCategoriaComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobilePhone; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isMobilePhone; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SubCategoriaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-subcategoria", [], null, null, null, View_SubCategoriaComponent_0, RenderType_SubCategoriaComponent)), i1.ɵdid(1, 4308992, null, 0, i4.SubCategoriaComponent, [i5.ClientService, i6.ToastrService, i7.IsMobileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubCategoriaComponentNgFactory = i1.ɵccf("app-subcategoria", i4.SubCategoriaComponent, View_SubCategoriaComponent_Host_0, { subcategoria: "subcategoria" }, {}, []);
export { SubCategoriaComponentNgFactory as SubCategoriaComponentNgFactory };
