var BlogComponent = /** @class */ (function () {
    function BlogComponent() {
        this.visible = false;
    }
    Object.defineProperty(BlogComponent.prototype, "stateName", {
        get: function () {
            return this.visible ? 'show' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    BlogComponent.prototype.toggle = function () {
        this.visible = !this.visible;
    };
    BlogComponent.prototype.getDelay = function () {
        return (this.delay * 0.2) + 's';
    };
    BlogComponent.prototype.getStyle = function () {
        if (this.content && this.content._embedded && this.content._embedded['wp:featuredmedia']) {
            return {
                'background-image': 'url(' + this.content._embedded['wp:featuredmedia']['0'].source_url + ')'
            };
        }
        return '';
    };
    return BlogComponent;
}());
export { BlogComponent };
