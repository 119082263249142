<div class="autentication-form no-text-select" [@fadeInUp]>
    <p class=title>
        Accede a tu Área Cliente
    </p>

    <form [formGroup]="authenticationForm" (ngSubmit)="onAuthenticate()"  autocomplete="false">

        <div class="row">
            <app-input-box focus="true" type="text" label="Número de DNI / NIF / NIE" errorDescription="(*) Campo obligatorio" formControlName="username" maxlength="25"></app-input-box>
        </div>

        <div class="row">
            <app-input-box type="password" label="Contraseña" errorDescription="(*) Campo obligatorio" formControlName="password" placeholder="Si no tienes, debes crear una cuenta" autocomplete="nope" maxlength="16"></app-input-box>
        </div>

        <div class="row">
            <app-error-messages [(messages)]=messages></app-error-messages>
        </div>

        <div class=forgot-pass>
            <p>¿Has olvidado tu contraseña? &nbsp;</p>
            <a [routerLink]="['/forgot-password']"> Haz click aquí</a>
        </div>

        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"></app-spinner-button>

    </form>

    <div class="register-pass">
        <p>¿No tienes cuenta o contraseña?&nbsp;</p>
        <a [routerLink]="[ '/register']">Regístrate</a>
    </div>
    <div class="contact-pass">
        <p>¿Tienes alguna duda?&nbsp;</p>
        <a href="https://www.onlygal.es/area-cliente/" target="_blank">Contáctanos</a>
    </div>

</div>