import {Component, Input} from '@angular/core';
import { AuthService } from 'app/security/auth.service';

@Component({
  selector: 'app-carousel-openevent',
  templateUrl: './carousel-openevent.component.html',
  styleUrls: ['./carousel-openevent.component.scss']
})
export class CarouselOpenEventComponent  {

  @Input() data;
  @Input() loading;
  show = true;
  showDirectAreas: Boolean = true;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    if ((this.authService.isLogged())) {
      this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
    }
  }
}
