<app-collapse-container-event caption="Indemnizaciones relacionadas con el parte" (click)="traerIndemnizaciones()">
    <div *ngIf="undefined === indemnizaciones" class="cargando">
        <div class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>
    </div>
    <div *ngIf="mobileSize">
        <div class="compensation" *ngIf="undefined !== indemnizaciones">
            <div *ngIf="indemnizaciones.length==0">
                <div class="compensation-main">
                    <div class="compensation-main-sup">
                        <div class="left">
                            <span>Nombre:</span>
                        </div>
                        <div class="right">
                            <span>Numero de cuenta:</span>
                        </div>
                        <div class="right">
                            <span>Importe:</span>
                        </div>
                        <div class="right">
                            <span>Fecha:</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="indemnizaciones.length>0">
                <div class="compensation-main"  *ngFor="let c of indemnizaciones ">
                    <div class="compensation-main-sup">
                        <div class="left">
                            <span>Nombre:</span>
                            <p>{{c.paymentName}}</p>
                        </div>
                        <div class="right">
                            <span>Numero de cuenta:</span>
                            <p>{{c.paymentcountNumber}}</p>
                        </div>
                        <div class="right">
                            <span>Importe:</span>
                            <p>{{c.price}} €</p>
                        </div>
                        <div class="right">
                            <span>Fecha:</span>
                            <p>{{c.date}}</p>
                        </div>
                    </div>
                    <hr size="1rem" style="margin-top: 1.5rem;" *ngIf="indemnizaciones.length>1" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="desktopSize">
        <div class="compensation" *ngIf="undefined !== indemnizaciones">
            <div class="compensation-main">
                <div class="compensation-main-sup">
                    <div class="left">
                        <span>Nombre:</span>
                    </div>
                    <div class="right">
                        <span>Numero de cuenta:</span>
                    </div>
                    <div class="right">
                        <span>Importe:</span>
                    </div>
                    <div class="right">
                        <span>Fecha:</span>
                    </div>
                </div>
                <div class="compensation-main-inf" *ngFor="let c of indemnizaciones ">
                    <div class="left">
                        <p>{{c.paymentName}}</p>
                    </div>
                    <div class="right">
                        <p>{{c.paymentcountNumber}}</p>
                    </div>
                    <div class="right">
                        <p>{{c.price}} €</p>
                    </div>
                    <div class="right">
                        <p>{{c.date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-collapse-container-event>