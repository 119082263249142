<div class="profile-title">
  <div class="profile-title-bread">
      <a [routerLink]="[ '/main']">Inicio&nbsp;&nbsp; </a>
      <!-- <a [routerLink]="[ '/main']">Inicio&nbsp;&nbsp; </a><a [routerLink]="[ '/products']">/&nbsp;&nbsp;{{crumb2}}</a> -->
      <!-- *ngIf="expression" -->
      <p>{{ undefined !== crumb ? '>' : '' }}&nbsp;&nbsp;{{crumb}}</p>
  </div>
  <div class="profile-title-name">
      <p>{{text}}</p>
  </div>
</div>