/**
 *
 * @author Iecisa
 */
export class ReceiptParamDTO {

    pageOffset: Number;
    pageSize: Number;
    startDate: string;
    endDate: string;
    policyNumber: string;
    policyCollective: string;
    statusType: any[];
    product: any[];
    policyItems: any[];
}
