import { Component, OnInit } from '@angular/core';
import { CommonsText } from 'app/commons/CommonsText';
import { AuthService } from 'app/security/auth.service';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-header-main-profile',
    templateUrl: './header-main-profile.component.html',
    styleUrls: ['./header-main-profile.component.scss']
})
export class HeaderMainProfileComponent implements OnInit {

    percent: Number = 100;
    userName: String = null;

    constructor(
        private authService: AuthService,
        private clientService: ClientService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.userName = this.authService.getUser().name;

        this.clientService.getClient()
            .then(value => {
                this.percent = ((this.clientService.getAddressPercent(value) + this.clientService.getContactPercent(value)) / 2);
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

}
