<div [ngSwitch]="isMobile">

    <div *ngSwitchCase="true" class="datePickerMobile">

        <label class="label-upperline "
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red'">{{label}}</label>

        <input type="date" [value]="value"
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            (input)="onChangeMobile($event.target.value)" (blur)="onTouched()" />

        <div class="description-mobile">
            <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
                <label>
                    {{description}}
                </label>
            </p>
            <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">
                
                <label *ngIf="ngControl.errors?.beforeCurrentDate">
                    {{beforeCurrentDateErrorDescription}}
                </label>

                <label *ngIf="ngControl.errors?.afterCurrentDate">
                    {{afterCurrentDateErrorDescription}}
                </label>

                <label *ngIf="ngControl.errors?.beforeCurrentDate === undefined && ngControl.errors?.afterCurrentDate === undefined">
                    {{errorDescription}}
                </label>
                <label *ngIf="ngControl.errors?.correctDayAndMonth">
                    {{correctDayAndMonthErrorDescription}}
                </label>

                <label *ngIf="ngControl.errors?.ErrorStartDay">
                    Desde debe ser inferior que Hasta
                </label>

                <label *ngIf="ngControl.errors?.ErrorEndDay">
                    Hasta debe ser superior que Desde
                </label>

            </p>
        </div>

    </div>

    <div *ngSwitchDefault class="datePickerDesktop">

        <label class="label-upperline "
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red'">{{label}}</label>
        <input type="text" [value]="value" aria-label="fecha de ocurrencia"
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            (focusout)="onFocusOut($event.target.value)" (input)="onChange($event.target.value)" (blur)="onTouched()"
            (click)="pickerVisible = !pickerVisible" mask="00-00-0000" placeholder="dd-mm-yyyy" />

        <div class="description">
            <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
                <label>
                    {{description}}
                </label>
            </p>
            <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">

                <label *ngIf="ngControl.errors?.beforeCurrentDate">
                    {{beforeCurrentDateErrorDescription}}
                </label>

                <label *ngIf="ngControl.errors?.afterCurrentDate">
                    {{afterCurrentDateErrorDescription}}
                </label>

                <label *ngIf="ngControl.errors?.beforeCurrentDate === undefined && ngControl.errors?.afterCurrentDate === undefined && ngControl.errors?.minAge === undefined">
                    {{errorDescription}}
                </label>
                <label *ngIf="ngControl.errors?.minAge">
                    {{minAgeErrorDescription}}
                </label>
                <label *ngIf="ngControl.errors?.correctDayAndMonth">
                    {{correctDayAndMonthErrorDescription}}
                </label>
                
                <label *ngIf="ngControl.errors?.ErrorStartDay">
                    Desde debe ser inferior que Hasta
                </label>

                <label *ngIf="ngControl.errors?.ErrorEndDay">
                    Hasta debe ser superior que Desde
                </label>
            
            </p>
        </div>

        <button type="button" aria-label="Ver Calendario" class="btn-icon" (click)="pickerVisible = !pickerVisible">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24"
                height="24" viewBox="0 0 24 24">
                <path fill="#4d87c7"
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
            </svg>
        </button>

        <div class="content" *ngIf="pickerVisible">
            <app-date [value]="value" [minYear]="value" (selected)="onSelectCallback($event)">
            </app-date>
        </div>

    </div>

</div>