import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, ControlContainer, Validators } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { CustomDateValidator } from 'app/commons/custom-date-validator';

import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { DatePipe } from '@angular/common';
import { DeydeService } from 'app/services/deyde-service'
import { CommonsText } from '../../../../../commons/CommonsText';

@Component({
    selector: 'app-event-register-second-step',
    templateUrl: './event-register-second-step.component.html',
    styleUrls: ['./event-register-second-step.component.scss'],
    providers: [DatePipe]
})
export class EventRegisterSecondStepComponent implements OnInit, AfterViewInit {
    //Subida de archivos.
    FormData = require('formdata-polyfill')

    dato: any;

    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    uploadFormData = new FormData();
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;
    running: any;
    orderDesc = false;

    documents = [];
    //Fin de subida de archivos.

    data: any;
    roadTypeSettings: any;
    actualPostalCode: any;
    loadingCities: Boolean = false;

    citySettings: any;
    cityList: any = [];
    province: string;

    @Input() prefixData: any[];
    prefixSettings: any;

    secondStepForm: FormGroup;
    patched: Boolean = false;

    @Input() set policy(data: any) {
        this.data = data;
    }

    @Input() insuredList: any[] = [];
    insuredListSettings: any;

    @Output() onInsuredSelected = new EventEmitter<any>();

    documentTypeSettings: any;
    documentTypeList: any[];
    showBirthDate: boolean = true;
    maxDate: Date;

    constructor(
        private controlContainer: ControlContainer,
        private clientService: ClientService,
        private toastr: ToastrService,
        private datepipe: DatePipe,
        private deydeService: DeydeService) {

        this.documentTypeList = CommonsUtils.documentType();

        this.insuredListSettings = {
            singleSelection: true,
            text: 'Seleccione un asegurado',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

        this.documentTypeSettings = {
            singleSelection: true,
            text: 'Documento',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

    }

    ngAfterViewInit(): void {
        if (!this.patched) {
            if (undefined !== this.data) {
                this.patched = true;
                this.patchForm(this.data.insured[0]);
            }
        }
    }

    ngOnInit() {
        this.setMaxDate();

        this.secondStepForm = <FormGroup>this.controlContainer.control;

        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.reloadFiles();

    }

    changeDocumentType(event) {
        if (event && event.itemName && event.itemName == 'CIF') {
            this.showBirthDate = false;
            this.secondStepForm.controls['bornDate'].clearValidators();
            this.secondStepForm.controls['bornDate'].setValidators(null);
            this.secondStepForm.controls['bornDate'].updateValueAndValidity();
        } else {
            this.showBirthDate = true;
            this.secondStepForm.controls['bornDate'].clearValidators();
            this.secondStepForm.controls['bornDate'].setValidators(
                [Validators.required, Validators.minLength(10), CustomDateValidator.minAge, CustomDateValidator.maxAge]);
            this.secondStepForm.controls['bornDate'].updateValueAndValidity();
        }
    }

    setMaxDate() {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        this.maxDate = date;
    }

    errorDescription(controlName: string) {
        const control = this.secondStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    }

    patchForm(person: any) {
        if (undefined !== person) {
            setTimeout(() => {
                this.showBirthDate = this.secondStepForm.controls['documentType'].value &&
                    this.secondStepForm.controls['documentType'].value.length > 0 &&
                    this.secondStepForm.controls['documentType'].value[0].itemName != 'CIF';
            }, 500);
            this.clientService.onSearchCity(person.postalCode)
                .then(success => {
                    this.cityList = [];
                    for (const city of success.response) {
                        if (city.id == person.city) {
                            this.cityList.push({
                                id: city.id, itemName: city.name, province: city.province
                            });
                            this.province = this.parseValue(city.province);
                            this.secondStepForm.patchValue({ province: city.province });
                        } else if (!person.city) {
                            this.searchCity();
                        }
                    }

                }).catch(error => {
                    this.loadingCities = false;
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                })
        }
    }

    searchProvince() {
        const postalCode = this.secondStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(success => {
                    this.actualPostalCode = postalCode;
                    this.loadingCities = false;

                    if (success.response[0].province) {
                        this.secondStepForm.patchValue({ province: success.response[0].province });
                        this.province = success.response[0].province;
                    }

                }).catch(error => {
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                })
        }
    }

    searchCity() {
        const postalCode = this.secondStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(success => {
                    this.actualPostalCode = postalCode;

                    this.loadingCities = false;
                    this.cityList = [];
                    this.secondStepForm.controls['city'].patchValue([]);

                    for (const city of success.response) {
                        this.cityList.push({
                            id: city.id, itemName: city.name, province: city.province
                        });
                    }

                    if (success.response.length === 1) {
                        const city = success.response[0];
                        this.secondStepForm.patchValue({ city: [{ id: city.id, itemName: city.name, province: city.province }] });
                        this.secondStepForm.patchValue({ province: city.province });
                        this.province = city.province;
                    }

                }).catch(error => {
                    this.loadingCities = false;
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                })
        }
    }

    onChangeEmail(data) {
        const v = data.target.value;
        const v2 = this.secondStepForm.get("email").status;
        if (v2 === "VALID") {
            if (((v.length >= 6) && v.includes("@")) && v.includes(".")) {
                this.deydeService.onCheckDeydeEmail(v)
                    .then(success => {
                        if (success[0].normalizado === 'S') {
                            this.secondStepForm.get("email").setErrors(null);
                        } else {
                            this.secondStepForm.get("email").setErrors({ 'incorrect': true });
                        }
                    }).catch(error => {
                        this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            }
        }
    }

    onChangeMobile(data) {
        const v = data.target.value;
        const v2 = this.secondStepForm.get("mobilePhone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydeMobile("+" + this.secondStepForm.get("mobilePhonePrefix").value[0].id+ " " + v)
                    .then(success => {
                        if (success[0].normalizado === 'S') {
                            this.secondStepForm.get("mobilePhone").setErrors(null);
                        } else {
                            this.secondStepForm.get("mobilePhone").setErrors({ 'incorrect': true });
                        }
                    }).catch(error => {
                        this.toastr.error(CommonsText.ERROR_DE_OPERACION, CommonsText.ERROR_SERVICIO_DEYDE, {
                            timeOut: 5000
                        }); 
                    })
            }
        }
    }

    onChangePhone(data) {
        const v = data.target.value;
        const v2 = this.secondStepForm.get("phone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydePhone("+" + this.secondStepForm.get("phonePrefix").value[0].id+ " " + v)
                    .then(success => {
                        if (success[0].normalizado === 'S') {
                            this.secondStepForm.get("phone").setErrors(null);
                        } else {
                            this.secondStepForm.get("phone").setErrors({ 'incorrect': true });
                        }
                    }).catch(error => {
                        this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        }); 
                    })
            }
        }
    }

    onChangePostalCode(data) {
        const v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    }

    onCitySelect(item: any) {
        this.secondStepForm.patchValue({ province: item.province });
        this.province = item.province;
    }

    isInvalid(controlName: string): boolean {
        const control = this.secondStepForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    }

    parseValue(value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        } else {
            return value;
        }
    }

    onChangeInsured(data) {
        this.searchProvince();
        this.onInsuredSelected.emit(data.id);
    }

    checkFile() {
        let returnB = false;
        for (let file of this.files) {
            if (file.size > 10485760 || !this.isValidFileType(file.type) || !this.isValidFileName(file.name) || !this.isValidMaxName(file.name)) {
                var i = this.files.indexOf(file);
                i !== -1 && this.files.splice(i, 1);

                if (file.size > 10485760 || !this.isValidFileType(file.type)) {
                    this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO,
                        CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }

                if (!this.isValidFileName(file.name)) {
                    this.toastr.error(CommonsText.ERROR_FORMATO_NOMBRE_ARCHIVO, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }

                if (!this.isValidMaxName(file.name)) {
                    this.toastr.error(CommonsText.ERROR_TAMAYO_NOMBRE_ARCHIVO_120, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }


            } else {
                let hash = {};
                this.files = this.files.filter(o => hash[o.name] ? false : hash[o.name] = true);
                this.secondStepForm.patchValue({ files: this.files })
                returnB = true;
            }

        };
        return returnB;
    }

    isValidFileName(name: string): boolean {
        let valid = false;
        let regex = /([^-\w.\s]*)/gm;
        let newName = name.replace(regex, '')
        if (newName === name) {
            valid = true;
        }

        return valid;
    }

    isValidFileType(type: string): boolean {
        let valid = false;
        if (type && (type.startsWith('image/') || type === CommonsText.IMG_TYPE)) {
            valid = true;
        }

        return valid;
    }

    isValidMaxName(name: string): boolean {
        let valid = true;
        if (name.length > 120) {
            valid = false;
        }

        return valid;
    }

    getDate() {
        return new Date()
    }

    reloadFiles() {
        if (this.secondStepForm.get('files').value != null && this.secondStepForm.get('files').value.length > 0) {
            this.files = this.secondStepForm.get('files').value;
        }
    }

}
