import { OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';
var ModifyPreferenceComponent = /** @class */ (function () {
    function ModifyPreferenceComponent(controlContainer, clientService, toastr, authService) {
        this.controlContainer = controlContainer;
        this.clientService = clientService;
        this.toastr = toastr;
        this.authService = authService;
        this.running = false;
        this.showClubPropietario = false;
    }
    Object.defineProperty(ModifyPreferenceComponent.prototype, "client", {
        set: function (value) {
            if (undefined !== value) {
                this.modifyPreferencesForm.patchValue({ insuranceAdvertising: !value.insuranceAdvertising });
                this.modifyPreferencesForm.patchValue({ legalServicesAdvertising: value.legalServicesAdvertising });
                this.modifyPreferencesForm.patchValue({ politicaClub: value.politicaClub });
                this.modifyPreferencesForm.patchValue({ condicionesClub: value.condicionesClub });
            }
        },
        enumerable: true,
        configurable: true
    });
    ModifyPreferenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showClubPropietario = this.authService.getProductos();
        this.modifyPreferencesForm = this.controlContainer.control;
        this.modifyPreferencesForm.valueChanges.subscribe(function (data) {
            _this.messages = null;
        });
    };
    ModifyPreferenceComponent.prototype.onModifyPreferences = function () {
        var _this = this;
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.modifyPreferencesForm.valid) {
                this.running = true;
                this.clientService.onModifyPreferences(this.modifyPreferencesForm.value)
                    .then(function (success) {
                    _this.clientService.getClient().then(function (response) {
                        _this.running = false;
                        _this.toastr.success(CommonsText.PREFERENCIAS_ACTUALIZADAS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });
                    }).catch(function (error) {
                        _this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                            timeOut: 5000
                        });
                    });
                }).catch(function (error) {
                    _this.running = false;
                    _this.messages = error.error.response;
                    if (undefined === _this.messages) {
                        _this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }
                    _this.toastr.error(_this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    ModifyPreferenceComponent.prototype.submitEnabled = function () {
        return this.modifyPreferencesForm.valid && !this.running;
    };
    ModifyPreferenceComponent.prototype.getButtonLabel = function () {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.GUARDAR_PREFERENCIAS;
    };
    return ModifyPreferenceComponent;
}());
export { ModifyPreferenceComponent };
