<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Contrata más"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class=" container">
    <div class="hire-container">
        <div *ngIf="undefined === response" class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div class="hire-container-collapse wow fadeInUp" data-wow-duration="1s" [hidden]="undefined === response">

            <div class="new-products">
                <app-collapse-container caption="Seguros">
                    <div class="list">
                        <div *ngFor="let item of newProducts">
                            <app-product-hire-service [data]=item></app-product-hire-service>
                        </div>
                    </div>
                </app-collapse-container>
            </div>

            <div class="new-products" *ngIf="responseDasLex && responseDasLex.content && responseDasLex.content.length > 0">

                <app-collapse-container caption="Servicios">

                    <div class="list">
                        <div *ngFor="let item of newServices">
                            <app-product-hire-service [data]=item></app-product-hire-service>
                        </div>
                    </div>


                   <!-- <div class="pagination">
                        <app-product-service-pagination caption="Otros servicios" checked="true"
                            [loading]="loadingDasLex" [response]="responseDasLex">
                        </app-product-service-pagination>
                    </div>

                    <div class="list">
                        <div *ngFor="let item of responseDasLex?.content">
                            <app-product-service [showDetailsButton]="true" [data]=item></app-product-service>
                        </div>
                    </div>

                    <div class="pagination-bottom-daslex">
                        <app-product-service-pagination [loading]="loadingDasLex" [response]="responseDasLex">
                        </app-product-service-pagination>
                    </div>
                    <div class="daslex-info">
                        <p>Gestionado por:</p>
                        <p>&nbsp;</p>
                        <p><span>Accede aquí a tu <a target="_blank" [href]="responseDasLex.content[0].urlDas">Área Privada</a></span></p> 
                        <p><span>para gestionar y contratar tus servicios</span></p>
                    </div>-->
                </app-collapse-container>
            </div>

        </div>

        <!--<div class="wow fadeInUp" data-wow-duration="1s">
            <app-wp-footer-integration></app-wp-footer-integration>
        </div>-->
    </div>
</div>

<app-footer></app-footer>