import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { AuthService } from 'app/security/auth.service';
import { Observable, Subject } from 'rxjs';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { CommonsText } from 'app/commons/CommonsText';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Polizas
 * @author Iecisa
 */
export class PolicyService {

    private pageSizeChange;
    private pageOffsetChange;
    private pageFilterChange;
    oldParams: PolicyParamDTO = new PolicyParamDTO();

    constructor(
        private http: HttpClient, private authService: AuthService,
        private toastr: ToastrService, private domainService: DomainService) {
    }

    public setPageSize(value: any) {
        this.pageSizeChange.next(value);
    }

    public setPageOffset(value: any) {
        this.pageOffsetChange.next(value);
    }

    public setPageFilter(value: any) {
        this.pageFilterChange.next(value);
    }

    observerPageSizeChange(): Observable<any> {
        this.pageSizeChange = new Subject<any>();
        return this.pageSizeChange.asObservable();
    }

    observerPageOffset(): Observable<any> {
        this.pageOffsetChange = new Subject<any>();
        return this.pageOffsetChange.asObservable();
    }

    observerPageFilter(): Observable<any> {
        this.pageFilterChange = new Subject<any>();
        return this.pageFilterChange.asObservable();
    }

    getCurrent(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/policies/current/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    }

    getRelation(){
        this.getCurrent()
        .then( success =>{
            let local = 'ASEG'
                for (const policy of success.response) {
                    if(policy.isTaker){
                        local = 'TOMA';
                        break;
                    }
                }
                localStorage.setItem('relationType', local);
            }
        ).catch(error => {
            this.toastr.error( CommonsText.ERROR_SERVICIO_POLIZAS , CommonsText.ERROR_DE_OPERACION, {
                timeOut: 5000
            }); 
        });      
    }

    getPolicyByNumber(policyNumber: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/policies/policy/' + policyNumber;
        return this.http.get(url).toPromise();
    }

    getMediators(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/policies/mediators/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    }

    getMediator(mediatorId: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/policies/mediator/' + mediatorId;
        return this.http.get(url).toPromise();
    }

    haveParameterChanges(params: PolicyParamDTO): Boolean {
        if (
            (undefined !== this.oldParams.statusType &&
                undefined !== params.statusType &&
                this.oldParams.statusType.length !== params.statusType.length) ||

            (this.oldParams.policyCollective !== params.policyCollective) ||
            (this.oldParams.policyNumber !== params.policyNumber) ||

            (undefined !== this.oldParams.product &&
                undefined !== params.product &&
                this.oldParams.product.length !== params.product.length)) {

            this.oldParams.statusType = Object.assign([], params.statusType);
            this.oldParams.policyItems = Object.assign([], params.policyItems);
            this.oldParams.policyCollective = params.policyCollective;
            this.oldParams.policyNumber = params.policyNumber;
            this.oldParams.product = Object.assign([], params.product);
            return true;
        }
        return false;
    }

    search(params: PolicyParamDTO): Promise<any> {
        let httpParams = new HttpParams();

        if (undefined !== params.pageOffset) {
            if (!this.haveParameterChanges(params)) {
                httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
            } else {
                httpParams = httpParams.append('pageOffset', '0');
            }
        }

        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }

        if ((undefined !== params.startDate) && (null !== params.startDate) && ('' !== params.startDate)) {
            httpParams = httpParams.append('startDate', params.startDate);
        }

        if ((undefined !== params.endDate) && (null !== params.endDate) && ('' !== params.endDate)) {
            httpParams = httpParams.append('endDate', params.endDate);
        }

        if ((undefined !== params.policyNumber) && (null !== params.policyNumber) && ('' !== params.policyNumber.trim())) {
            httpParams = httpParams.append('policyNumber', params.policyNumber);
        }

        if ((undefined !== params.policyCollective) && (null !== params.policyCollective) && ('' !== params.policyCollective.trim())) {
            httpParams = httpParams.append('policyCollective', params.policyCollective);
        }

        if (undefined !== params.statusType) {
            for (let i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }

        if (undefined !== params.product) {
            for (let i = 0; i < params.product.length; i++) {
                httpParams = httpParams.append('product', params.product[i].id);
            }
        }

        if (undefined !== params.policyItems) {
            for (let i = 0; i < params.policyItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.policyItems[i].id);
            }
        }

        const url = this.domainService.backendUrlWithContext + '/policies/search/' + this.authService.getSelectedEntity();
        return this.http.get(url, { params: httpParams }).toPromise();
    }

    getContractFile(policyNumber: any) {

        this.toastr.info(CommonsText.PREPARANDO_ARCHIVO_DESCARGA, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 12000
        });
        const url = this.domainService.backendUrlWithContext + '/policies/document/' + policyNumber;

        this.http.get(url, { observe: 'response', responseType: 'blob' as 'json' }).subscribe(
            (resp: HttpResponse<Blob>) => {
                this.saveToFileSystem(resp);
            },
            err => {
                this.toastr.error(CommonsText.FALLO_AL_DESCARGAR, CommonsText.SERVICIO_DE_DOCUMENTOS, {
                    timeOut: 5000
                });
            },
        );

    }

    private saveToFileSystem(response: any) {
        const contentType: string = response.headers.get('Content-Type');
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = parts[1].split('=')[1].split('\"').join('');
        const blob = new Blob([response.body], { type: contentType });
        saveAs(blob, filename);

        this.toastr.success(CommonsText.ARCHIVO_DESCARGADO_CORRECTAMENTE, CommonsText.SERVICIO_DE_DOCUMENTOS, {
            timeOut: 5000
        });
    }

    getHirePolicyDataByNumber(policyYear, policyNumber): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/policies/hireMoreInformation/' + policyYear + '/' + policyNumber;
        return this.http.get(url).toPromise();
    }

    onHireMoreFormSubmit(params: any): Promise<any> {
        const form = new FormData();

        if ((undefined !== params.policyNumber) && (null !== params.policyNumber)) {
            form.append('policyNumber', params.policyNumber);
        }

        if ((undefined !== params.policyYear) && (null !== params.policyYear)) {
            form.append('policyYear', params.policyYear);
        }

        if ((undefined !== params.modificationDate) && (null !== params.modificationDate)) {
            form.append('modificationDate', params.modificationDate);
        }

        if ((undefined !== params.observation) && (null !== params.observation)) {
            form.append('observation', params.observation);
        }

        const url = this.domainService.backendUrlWithContext + '/policies/hireMore';
        return this.http.post<any>(url, form).toPromise();
    }

    changeIdentifier(params: any): Promise<any> {
        const form = new FormData();

        form.append('polizsec', params.policyNumber);
        form.append('oldIdentifiyer', params.identifier);
        form.append('newIdentifiyer', params.newIdentifier);
        form.append('modificationDate', params.modificationDate);

        const url = this.domainService.backendUrlWithContext + '/policies/changeIdentifier';
        return this.http.post<any>(url, form).toPromise();
    }

}
