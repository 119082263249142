<div class="banner">
    <div class="content">
        <div class="text">
            <p>DAS evoluciona a onLygal</p>
            <p>PARA ACTIVAR TUS SOLUCIONES LEGALES</p>
        </div>
        <a href="https://onlygal.es/conocenos" target="_blank">
            <button class="button">> CONOCE CÓMO</button>
        </a>
    </div>
</div>

<div class="sbs-back">
    <app-check-browser-modal></app-check-browser-modal>

    

    <div class="container sbs">

        <section>
            <app-welcome-text></app-welcome-text>
        </section>

        <section *ngIf="!authenticated" class="section-center">
            <app-authentication-form (onAuthenticated)="onAuthenticated($event)"></app-authentication-form>
        </section>

        <div class="footer">
            <div class="sub container">
                <div class="left">
                    
                </div>
                <div class="right">
                </div>
            </div>
        </div>

        

    </div>
</div>