<div class="carousel-content">

    <div class="left">
        <app-icon-type color="blue" [icon]="'legal'"></app-icon-type>
    </div>

    <div class="right no-text-select">
        <div class="row row-100">
            <app-label-text label="Nº de contrato:" text="{{ data?.contrato }}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-100">
            <app-label-text label="Producto:" text="{{ data?.servicio }}" textClass="blue-bold" [loading]=loading>
            </app-label-text>
        </div>
        <div class="row row-100">
            <app-label-text label="Estado:" text="{{ data?.estado }}" [loading]=loading>
            </app-label-text>
        </div>
        <div class="row row-7030">
            <div class="inner-label">
                <app-label-text label="Abogado:" *ngIf="getLawyerName(data)" text="{{getLawyerName(data)}}"
                    [loading]=loading></app-label-text>
            </div>
            <div class="split-button">
                <app-split-button *ngIf=!loading label="Quiero..." direction=top [(close)]="close">
                    <app-split-button-item label="Ver detalles" route="/productService/{{data.expediente}}">
                    </app-split-button-item>
                </app-split-button>
            </div>
        </div>
    </div>

</div>