import { Component, OnInit, Input } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';

@Component({
  selector: 'app-product-last-receipt',
  templateUrl: './product-last-receipt.component.html',
  styleUrls: ['./product-last-receipt.component.scss']
})
export class ProductLastReceiptComponent implements OnInit {

  @Input() data;

  constructor(private receiptService: ReceiptService) { }

  ngOnInit() {
  }

  pay() {
    this.receiptService.payment(this.data);
  }

}
