<div class="input-group">
    <div class="input-edit" [ngClass]="extraStyle">

        <input [type]="type" [placeholder]="placeholder" [value]="value" [disabled]="disabled"
            (input)="onChange($event.target.value)" (blur)="onTouched()" [maxLength]="maxlength" #edit
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            [mask]="mask" (paste)="capturePaste($event)" autocomplete="nope">
        
        <label *ngIf="show" (click)="password($event)" class="eyeLabel"></label>
        
        <label class="label-upperline "
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red text-bold'">{{label}}</label>

    </div>

    <div class="description">
        <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
            <label>
                {{description}}
                <app-tooltip *ngIf="!(tooltip === null)" [text]="tooltip" [direction]="tooltipDirection"></app-tooltip>
            </label>
        </p>
        <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">
            <label *ngIf="ngControl.errors?.isValidPhone===false">                
                {{isValidPhoneErrorDescription}}
                <app-tooltip *ngIf="!(tooltip === null)" [text]="tooltip" [direction]="tooltipDirection"></app-tooltip>
            </label>
            <label
                *ngIf="ngControl.errors?.isValidPhone === undefined">
                {{errorDescription}}
                <app-tooltip *ngIf="!(tooltip === null)" [text]="tooltip" [direction]="tooltipDirection"></app-tooltip>
            </label>
        </p>
    </div>

</div>