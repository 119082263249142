import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-modify-preference',
    templateUrl: './modify-preference.component.html',
    styleUrls: ['./modify-preference.component.scss']
})
export class ModifyPreferenceComponent implements OnInit {

    @Input() set client(value: ClientDataDTO) {
        if (undefined !== value) {
            this.modifyPreferencesForm.patchValue({ insuranceAdvertising: !value.insuranceAdvertising });
            this.modifyPreferencesForm.patchValue({ legalServicesAdvertising: value.legalServicesAdvertising });
            this.modifyPreferencesForm.patchValue({ politicaClub: value.politicaClub });
            this.modifyPreferencesForm.patchValue({ condicionesClub: value.condicionesClub });
        }
    }

    modifyPreferencesForm: FormGroup;
    messages: any[];
    running: Boolean = false;
    showClubPropietario: Boolean = false;

    constructor(private controlContainer: ControlContainer,
        private clientService: ClientService,
        private toastr: ToastrService,
        private authService: AuthService) { }

    ngOnInit() {
        this.showClubPropietario = this.authService.getProductos();
        this.modifyPreferencesForm = <FormGroup>this.controlContainer.control;
        this.modifyPreferencesForm.valueChanges.subscribe(data => {
            this.messages = null;
        });
    }

    onModifyPreferences() {
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.modifyPreferencesForm.valid) {
                this.running = true;
                this.clientService.onModifyPreferences(this.modifyPreferencesForm.value)
                    .then(success => {
                        this.clientService.getClient().then(response=>{
                            this.running = false;
                            this.toastr.success(CommonsText.PREFERENCIAS_ACTUALIZADAS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                                timeOut: 5000
                            });
                        }).catch(error => {
                            this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                                timeOut: 5000
                            });
                        });
                    }).catch(error => {
                        this.running = false;
                        this.messages = error.error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            }
        }
    }

    submitEnabled() {
        return this.modifyPreferencesForm.valid && !this.running;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.GUARDAR_PREFERENCIAS;
    }

}
