/**
 *
 * @author Iecisa
 */
export class MenuHorizontalDTO {

    private codMenu: string;
    private literalMenu: string;
    private estilo: string;

    constructor(codigo: string, literal: string, estilo: string) { 
        this.setCodMenu(codigo);
        this.setLiteralMenu(literal);
        this.setEstilo(estilo);
    }
  
    public getCodMenu(): string {
        return this.codMenu;
    }
    public setCodMenu(value: string) {
        this.codMenu = value;
    }
    
    public getLiteralMenu(): string {
        return this.literalMenu;
    }
    public setLiteralMenu(value: string) {
        this.literalMenu = value;
    }

    public getEstilo(): string {
        return this.estilo;
    }
    public setEstilo(value: string) {
        this.estilo = value;
    }

    /** En este metodo se pasa por parametro la peticioon del servicio json, y se 
     *  completan los valores de las variables de este DTO.
     * 
     *  Actua como una interfaz.
     */
    public load(codigo: string, literal:string) {
        // Aqui se implementara la obtencion de datos de la peticion del servicio, JSON
        this.setCodMenu(codigo);
        this.setLiteralMenu(literal);
    }

}
