import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from 'app/services/wordpress-service';
import { ToastrService } from 'ngx-toastr';
import { version } from '../../../../../package.json';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-authentication-page',
    templateUrl: './authentication-page.component.html',
    styleUrls: ['./authentication-page.component.scss']
})

export class AuthenticationPageComponent implements OnInit {

    authenticated: Boolean = false;
    showEntities: Boolean = false;
    public version: string = version;
    token: any;

    constructor(private router: Router,
        private wordpressService: WordpressService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private authService: AuthService) { 
            this.loadTokenUserGrantor();
        }

        loadTokenUserGrantor(){
            if(this.route.snapshot.queryParams['authenticationcode']){
                this.token = this.route.snapshot.queryParams['authenticationcode'];
                localStorage.setItem('token', this.token);
                this.authService.loadGrantorUser();
                this.router.navigate(['/entities']);
            }
        }

    onAuthenticated(event) {
        if (event) {
            this.router.navigate(['/entities']);
        }
    }

    ngOnInit() {

        this.wordpressService.loadPage().then(any => {
        }).catch(error => {
            this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });

        this.wordpressService.loadMenu().then(any => {
        }).catch(error => {
            this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });

        this.wordpressService.loadWidget().then(any => {
        }).catch(error => {
            this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });

    }


    getYear() {
        const date = new Date();
        return date.getFullYear();
    }

}
