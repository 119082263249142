/**
 *
 * @author Iecisa
 */
export class AudityDTO {

    audityDocument: string;
    audityIp: string;

}
