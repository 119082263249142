import { ImagenDataDTO } from "./ImagenDataDTO";
import { NoticiaDataDTO } from "./NoticiaDataDTO";
import { SubCategoriaDataDTO } from "./SubCategoriaDataDTO";

/**
 *
 * @author jpardal
 */
export class CategoriaDataDTO {
    id: string;
    titulo: string;
    categoria: string;
    identificador: string;
    subCategoriaList: SubCategoriaDataDTO[] = [];
    noticasListDestacadas: NoticiaDataDTO[] = [];
    noticiasListAll: NoticiaDataDTO[] = [];
    destacado: boolean = false;
}
