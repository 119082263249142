<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb crumb="Mi perfil" text="Modificar perfil"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<form [formGroup]="modifyContactForm" class="no-text-select"></form>
<form [formGroup]="modifyAccessForm" class="no-text-select"></form>
<form [formGroup]="modifyPreferencesForm" class="no-text-select"></form>

<div class="modifyFormContainer">

    <div *ngIf="loading" class="container no-text-select pre-loading">
        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
    </div>

    <div [hidden]="loading">
        <div class="container no-text-select">
            <div class="tabs">
                <input type="radio" id="contact" name="tab-control" (click)="changeUrl(contactTab)" #contactTab>
                <input type="radio" id="payment" name="tab-control" (click)="changeUrl(paymentTab)" #paymentTab>
                <input type="radio" id="access" name="tab-control" (click)="changeUrl(accessTab)" #accessTab>
                <input type="radio" id="preferences" name="tab-control" (click)="changeUrl(preferenceTab)"
                    #preferenceTab>
                <ul>
                    <li title="contact">
                        <label for="contact" role="button">
                            <span>Modificar <br> datos de contacto</span>
                        </label>
                    </li>
                    <li title="bank" *ngIf="showDirectAreas">
                        <label for="payment" role="button">
                            <span>Modificar <br> datos bancarios</span>
                        </label>
                    </li>
                    <li title="access">
                        <label for="access" role="button">
                            <span>Modificar <br> datos de acceso</span>
                        </label>
                    </li>
                    <li title="preferences" *ngIf="showDirectAreas && showInsuredAreas">
                        <label for="preferences" role="button">
                            <span>Modificar <br> preferencias</span>
                        </label>
                    </li>
                </ul>

                <div [ngClass]="showDirectAreas ? 'slider':'slider-reinsured'">
                    <div class="indicator"></div>
                </div>
                              
                <div class="content">
                    <section>
                        <app-modify-contact [formGroup]="modifyContactForm" [roadTypeList]="roadTypeList" [client]="client">
                        </app-modify-contact>
                    </section>

                    <section>
                        <app-modify-bank [client]="client"></app-modify-bank>
                    </section>

                    <section>
                        <app-modify-access [formGroup]="modifyAccessForm"></app-modify-access>
                    </section>

                    <section>
                        <app-modify-preference [formGroup]="modifyPreferencesForm" [client]="client"></app-modify-preference>
                    </section>

                </div>
            </div>
        </div>
        
    </div>

</div>

<app-footer></app-footer>