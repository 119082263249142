import { DomSanitizer } from "@angular/platform-browser";
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'safeHtml',
  })
  export class SafeHtmlPipe implements PipeTransform {
  
    constructor(private sanitizer:DomSanitizer){}
  
    transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  
  }