<div class="event no-text-select">
    <div class="event-form">
        <div class="event-form-box background-{{data.eventStatusColor}}"></div>
        <div class="event-form-img">
            <app-icon-type [icon]="data.branchType" color="blue"></app-icon-type>
            <p>Situación: <span>{{ data.eventStatus }}</span></p>
        </div>
        <div class="event-form-number">
            <div class="left">
                <div *ngIf="!showDirectAreas && data.idRea != ''" class="left-sup">
                    <p>Nº de póliza / contrato:</p>
                    <span>{{ data.idRea }}</span>
                </div>

                <div *ngIf="showDirectAreas" class="left-sup">
                    <p>Nº de póliza / contrato:</p>
                    <span>{{ data.policyNumber }}</span>
                </div>
                <div class="left-inf">
                    <p>Referencia:</p>
                    <span>{{ data.eventNumber }}</span>
                </div>
            </div>
            <div class="center">
                <div *ngIf="showDirectAreas" class="center-sup">
                    <p>Producto:</p>
                    <span>{{ data.policyProduct }}</span>
                </div>
            </div>
            <div class="right">
                <div class="right-sup">
                    <p>Declaración:</p>
                    <span>{{ data.eventStartDate | date: 'dd/MM/yyyy' }} </span>
                </div>
                <div class="right-inf">
                    <p>Ocurrencia:</p>
                    <span>{{data.eventEndDate | date: 'dd/MM/yyyy' }} </span>
                </div>
            </div>
            <div class="righter">
                <div class="righter-button">
                    <app-split-button  *ngIf="data.isInsuredInEvent" label="Quiero..." direction=bottom-2>
                        <app-split-button-item label="Ver detalles" route="/event/{{ data.eventNumber }}"></app-split-button-item>
                        <app-split-button-item *ngIf="data.expeditionDate && data.expeditionAgent" label="Contactar con mi tramitador/a" route="/event/{{ data.eventNumber }}/{{show}}" ></app-split-button-item>
                    </app-split-button>
                </div>
            </div>
        </div>
    </div>
</div>