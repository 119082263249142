import {Injectable} from '@angular/core';
import {Http} from '@angular/http';

import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Blogs de Wordpress
 * @author Iecisa
 */
export class WpBlogService {

    blog: any = null;

    constructor(private http: Http) {
    }

    async load(): Promise<any> {
        const url = environment.blogUrl;
        if (this.blog === null) {
            const response = await this.http.get(url).toPromise();
            this.blog = response.json();
            return Promise.resolve(this.blog);
        } else {
            return Promise.resolve(this.blog);
        }
    }
}
