<div class="modify-container">

    <div>
        <div class="domicile">
            <div class="title">
                <p>Domiciliaciones</p>
            </div>
        </div>
        <div class="form">
            <div class="title">
                <p>Elige a qué cuentas bancarias quieres que domiciliemos tus contratos <span class="bold">(Este cambio implicará la domiciliación de los recibos asociados a dicho contrato que estén pendiente de pago a la nueva cuenta bancaria elegida)</span></p>
            </div>

            <div class="row row-404020" *ngFor="let policy of policyList">
                <div class="cont">
                    <div class="inner-policy">
                        <label for="">PÓLIZA</label>
                        <p>{{policy.number}} - <span>{{policy.product}}</span></p>
                    </div>
                </div>
                <div class="cont">
                    <div class="inner-select">
                        <i class="spinner" style="display: none"></i>
                        <p style="display:none" class="loading">Cargando<span>.</span><span>.</span><span>.</span></p>
                        <label for="">CUENTA BANCARIA</label>
                        <select *ngIf="policy.isEditable" [attr.disabled]="accountList.length == 0 ? '' : null" (change)="onChangeAccount($event, policy)">                           
                            <option value="{{policy.bankAccount}}" [attr.selected]="true">{{policy.bankAccount}}</option>
                            <option *ngFor="let c of accountList" value="{{c.itemName}}" [hidden]="c.itemName === policy.bankAccount" >{{c.itemName}}</option>
                        </select>
                    </div>
                </div>
                <div class="cont">
                    <div class="inner-pay">
                        <label for="">TIPO DE PAGO</label>
                        <p>{{policy.periodicity}}</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="account">
            <div class="title">
                <p>Mis cuentas</p>
            </div>
        </div>

        <div class="form form-7020">
            <div class="row row-7020">
                <div class="cont">
                    <div *ngFor="let account of accountList">
                        <div class="inner-account">
                            <label for="">CUENTA BANCARIA</label>
                            <p>{{account?.itemName}}</p>
                        </div>
                        <div class="inner-trash" (click)="onRemoveAccount($event, account)">
                            <button type="button" aria-label="boton_eliminar"></button>
                            <i class="spinner" style="display:none"></i>
                        </div>
                    </div>

                    <div class="emptyMessage" *ngIf="accountList.length === 0">
                        <p>No hay cuenta referenciada, por favor añade una cuenta para poder continuar.</p>
                    </div>

                </div>

                <div class="cont">
                    <div class="inner-button">
                        <button type="button" (click)="addNewAccount()">AÑADIR CUENTA</button>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="form form-new" *ngIf="showingAddNewAccountForm" [formGroup]="addNewAccountForm">
        <div class="data-count">
            <div class="data-count-cont">
                <app-input-box type="text" label="IBAN" formControlName="iban" mask="SS00" errorDescription="Formato (ES00)" (onPaste)="captureIban($event)"></app-input-box>
            </div>
            <div class="data-count-cont">
                <app-input-box type="text" label="Entidad" formControlName="entity" mask="0000" errorDescription="4 números" (onPaste)="captureIban($event)"></app-input-box>

            </div>
            <div class="data-count-cont">
                <app-input-box type="text" label="Sucursal" formControlName="office" mask="0000" errorDescription="4 números" (onPaste)="captureIban($event)"></app-input-box>

            </div>
            <div class="data-count-cont">
                <app-input-box type="text" label="DC" formControlName="digit" mask="00" errorDescription="2 números" (onPaste)="captureIban($event)">
                </app-input-box>

            </div>
            <div class="data-count-cont">
                <app-input-box type="text" label="Cuenta" formControlName="accountNumber" mask="0000000000" errorDescription="10 números" (onPaste)="captureIban($event)">
                </app-input-box>

            </div>
        </div>
        <div class="data-data">
            <div class="data-data-cont">
                <label>Titular de la cuenta:</label>
                <app-tooltip class="data-data-cont-tooltip" text="La titularidad de la cuenta bancaria debe coincidir con el tomador del seguro"></app-tooltip>
                <p>{{client.name}} {{client.surName}} {{client.lastName}}</p>
            </div>
            <div class="data-data-select">
                <label>Tipo de documento:</label>
                <p>{{client.documentType}}</p>
            </div>
            <div class="data-data-num">
                <label>Nº de documento:</label>
                <p>{{client.documentNumber}}</p>
            </div>
        </div>

        <div class="right">

            <ngfFormData [files]="files" postName="file" [(FormData)]="uploadFormData"></ngfFormData>

            <div [hidden]="files.length > 0">
                <div ngfDrop multiple="0" selectable="1" [(validDrag)]="validComboDrag" [(files)]="files" accept="image/*,application/pdf" [(dragFiles)]="dragFiles" class="well my-drop-zone" [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
                    (filesChange)="lastFileAt = getDate()">
                    <div class="img"></div>
                    <p>Haga clic o arrastre para adjuntar un documento que acredite la propiedad de la cuenta bancaria.
                        <span style="color:red"> (*) Obligatorio</span>
                    </p>
                </div>
            </div>

            <div *ngIf="files.length > 0" class="files">
                <div class="files-main" *ngFor="let item of files; let i = index">
                    <div class="name">
                        <div class="img">
                            <div class="img-inner"></div>
                        </div>
                        <p>{{ item.name }}</p>
                    </div>
                    <div class="size">
                        <p>{{ item.size / 1024 / 1024 | number: ".2" }} MB</p>
                    </div>
                    <div class="erase">
                        <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i, 1)"></button>
                    </div>
                </div>
            </div>

        </div>

        <div class="data-button">
            <div class="text">
                {{addNewAccountMessages}}
            </div>
            <app-spinner-button [label]="getNewAccountButtonLabel()" [enabled]="submitNewAccountEnabled()" [running]="runningNewAccount" (click)="onConfirmNewAccount()">
            </app-spinner-button>
        </div>

    </div>

</div>