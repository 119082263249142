import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthService } from 'app/security/auth.service';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { AccessDataDTO } from 'app/header-footer-template/model/AccessDataDTO';
import { Observable, Subject } from 'rxjs';
import { AccountDTO } from 'app/header-footer-template/model/AccountDTO';
import { DomainService } from './domain-service';

import 'rxjs/add/operator/toPromise';
import { ResetService } from './reset-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Clientes
 * @author Iecisa
 */
export class ClientService {

    clientData: ClientDataDTO = null;
    contactFactor = 25;
    addressFactor = 17;
    roadType: any;
    private loadingClientPercentage;
    private loadingModalAddAccound;
    private loadingLegalibooModal;
    private loadingModalChangeReceipts;

    constructor(private http: HttpClient, private authService: AuthService,
        private domainService: DomainService, private resetService: ResetService, private toastr: ToastrService) {
        this.resetService.observerLogout().subscribe((result: any) => {
            this.clientData = null;
            if (result !== null) {
                const url = this.domainService.backendUrlWithContext + '/clients/logout';
                this.http.get(url).toPromise().catch(error=>{
                    if(error.error.error === 'invalid_token'){
                        console.log('Token Invalido')
                    }
                });
            }
        });
    }

    async getClient(): Promise<any> {
        localStorage.removeItem('clientData');
        const url = this.domainService.backendUrlWithContext + '/clients';
        if (this.clientData === null) {
            const response = await this.http.get<any>(url).toPromise();
            this.clientData = response.response;

            this.loadingClientPercentage.next(((this.getAddressPercent(this.clientData) + this.getContactPercent(this.clientData)) / 2));
            localStorage.setItem('clientData', JSON.stringify(this.clientData));
            return Promise.resolve(this.clientData);
        } else {
            localStorage.setItem('clientData', JSON.stringify(this.clientData));
            return Promise.resolve(this.clientData);
        }
    }

    getRoadTypes(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/roadtypes';
        return this.http.get(url).toPromise();
    }

    getPaymentAccounts(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/paymentAccounts';
        return this.http.get(url).toPromise();
    }

    getBannersInfo(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/customBanner';
        return this.http.get(url).toPromise();
    }

    onModifyContact(param: ClientDataDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients';

        const form = new FormData();
        if (null !== param.name) {
            form.append('name', param.name);
        }
        if (null !== param.surname) {
            form.append('surname', param.surname);
        }
        if (null !== param.lastName && "" !== param.lastName) {
            form.append('lastName', param.lastName);
        }
        if (null !== param.birthDate) {
            form.append('birthDate', param.birthDate.replace(/-/g, "/"));
        }
        if (null !== param.email) {
            form.append('email', param.email);
        }
        if (null !== param.phone && "" !== param.phone) {
            form.append('phone', param.phone);
        }
        if ((null !== param.phonePrefix) && (undefined !== param.phonePrefix)) {
            if (null !== param.phone && "" !== param.phone) {
                form.append('phonePrefix', param.phonePrefix[0].id);
            }
        }
        if (null !== param.mobilePhone) {
            form.append('mobilePhone', param.mobilePhone);
        }
        if ((null !== param.mobilePhonePrefix) && (undefined !== param.mobilePhonePrefix)) {
            form.append('mobilePhonePrefix', param.mobilePhonePrefix[0].id);
        }
        if (null !== param.province) {
            form.append('province', param.province);
        }
        if (null !== param.postalCode) {
            form.append('postalCode', param.postalCode);
        }

        form.append('city', param.cityCb[0].id);
        form.append('roadTypeId', param.roadType[0].id);

        if (null !== param.address) {
            form.append('address', param.address);
        }
        if (null !== param.number) {
            form.append('number', param.number);
        }
        if (null !== param.portal && "" !== param.portal) {
            form.append('portal', param.portal);
        }
        if (null !== param.stair && "" !== param.stair) {
            form.append('stair', param.stair);
        }
        if (null !== param.floor && "" !== param.floor) {
            form.append('floor', param.floor);
        }
        if (null !== param.door && "" !== param.door) {
            form.append('door', param.door);
        }
        if (null !== param.notificationChannel) {
                form.append('notifications', param.notificationChannel);
        }

        return this.http.post<any>(url, form).toPromise().then(response => {
            if (response.response) {
                localStorage.setItem('token_replacement', param.name);
                this.authService.getUser().name = param.name;
                this.clientData.name = param.name;
                this.clientData.surname = param.surname;
                this.clientData.lastName = param.lastName;
                this.clientData.birthDate = param.birthDate;
                this.clientData.email = param.email;
                this.clientData.phone = param.phone;
                this.clientData.phonePrefix = param.phonePrefix[0].id;
                this.clientData.mobilePhone = param.mobilePhone;
                this.clientData.mobilePhonePrefix = param.mobilePhonePrefix[0].id;
                this.clientData.province = param.province;
                this.clientData.postalCode = param.postalCode;

                this.clientData.city = param.cityCb[0].id;
                this.clientData.cityDescription = param.cityCb[0].itemName;
                this.clientData.cityCb = param.cityCb;

                this.clientData.roadType = param.roadType;
                this.clientData.address = param.address;
                this.clientData.number = param.number;
                this.clientData.portal = param.portal;
                this.clientData.stair = param.stair;
                this.clientData.floor = param.floor;
                this.clientData.door = param.door;

                this.clientData.notificationChannel = param.notificationChannel;

                this.clientData.notifications = [];
                if (this.clientData.notificationChannel) {
                    this.clientData.notifications.push(param.notificationChannel);
                }
            }
            return response;
        });

    }

    onModifyAccess(param: AccessDataDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/modifyAccess';
        const form = new FormData();

        form.append('actualPassword', param.actual);
        form.append('newPassword', param.new);
        form.append('confirmation', param.confirmation);

        return this.http.post<any>(url, form).toPromise();
    }

    onModifyPreferences(param: ClientDataDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/modifyPreference';
        const form = new FormData();

        form.append('insuranceAdvertising', param.insuranceAdvertising ? 'false' : 'true');
        form.append('legalServicesAdvertising', param.legalServicesAdvertising ? 'true' : 'false');
        form.append('politicaClub', param.politicaClub ? 'true' : 'false');
        form.append('condicionesClub', param.condicionesClub ? 'true' : 'false');

        return this.http.post<any>(url, form).toPromise().then(response => {
            if (response.response) {
                this.clientData.insuranceAdvertising = !param.insuranceAdvertising;
                this.clientData.legalServicesAdvertising = param.legalServicesAdvertising;
                this.clientData.politicaClub = param.politicaClub;
                this.clientData.condicionesClub = param.condicionesClub;
            }
            return response;
        });
    }

    onAddNewAccount(account: AccountDTO, upload: FormData): Observable<HttpEvent<any>> {
        const url = this.domainService.backendUrlWithContext + '/clients/createNewAccount';
        const form = new FormData();

        form.append('iban', account.iban);
        form.append('office', account.office);
        form.append('titular', account.titular);
        form.append('entity', account.entity);
        form.append('documentType', account.documentType[0].id);
        form.append('documentNumber', account.documentNumber);
        form.append('digit', account.digit);
        form.append('accountNumber', account.accountNumber);

        if (undefined !== upload) {
            const file = upload.get('file');
            if (null !== file) {
                form.append('file', file);
            }
        }

        const req = new HttpRequest<FormData>('POST', url, form, { reportProgress: true })
        return this.http.request<any>(req);
    }

    onRemoveAccount(account: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/removePaymentAccount';
        const form = new FormData();

        form.append('accountId', account.id);
        return this.http.post(url, form).toPromise();
    }

    onChangeAccount(accountNumber: any, id: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/changePaymentAccount';
        const form = new FormData();

        form.append('accountNumber', accountNumber);
        form.append('id', id);

        return this.http.post(url, form).toPromise();
    }

    onContact(param: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/contact';

        const form = new FormData();
        form.append('name', param.name);
        form.append('mobilePhone', param.mobilePhonePrefix[0].id + ' ' + param.mobilePhone);
        form.append('hour', param.hour);
        form.append('message', param.message);

        return this.http.post<any>(url, form).toPromise();

    }

    getPayment(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/clients/payment/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    }

    getContactPercent(clientData: ClientDataDTO): any {
        let percent = 0;
        percent += (this.testValue(clientData.name, this.contactFactor));
        percent += (this.testValue(clientData.surname, this.contactFactor));
        percent += (this.testValue(clientData.email, this.contactFactor));
        percent += (this.testValue(clientData.mobilePhone, this.contactFactor));
        return (percent > 100) ? 100 : percent;
    }

    getContactFactor(): any {
        return this.contactFactor;
    }

    getAddressPercent(clientData: ClientDataDTO): any {
        let percent = 0;
        percent += (this.testValue(clientData.province, this.addressFactor));
        percent += (this.testValue(clientData.postalCode, this.addressFactor));
        percent += (this.testValue(clientData.city, this.addressFactor));
        percent += (this.testSelectValue(clientData.roadType, this.addressFactor));
        percent += (this.testValue(clientData.address, this.addressFactor));
        percent += (this.testValue(clientData.number, this.addressFactor));
        return (percent > 100) ? 100 : percent;
    }

    getAddressFactor(): any {
        return this.addressFactor;
    }

    private testValue(value: String, percent: any): any {
        let response: Number = percent;
        if (null === value || undefined === value || '' === value.trim()) {
            response = 0;
        }
        return response;
    }

    private testSelectValue(value: any[], percent: any): any {
        let response: Number = percent;
        if (null === value || undefined === value || value.length === 0) {
            response = 0;
        }
        return response;
    }

    onSearchCity(postalCode: string) {
        const url = this.domainService.backendUrlWithContext + '/clients/cities/' + postalCode;
        return this.http.get<any>(url).toPromise();
    }

    observerClientPercentageObserved(): Observable<any> {
        this.loadingClientPercentage = new Subject<any>();
        return this.loadingClientPercentage.asObservable();
    }
    observerAddNewAcount(): Observable<any> {
        this.loadingModalAddAccound = new Subject<any>();
        return this.loadingModalAddAccound.asObservable();
    }

    showModal(){
        this.loadingModalAddAccound.next(true);
    }

    observerChangeReceipts(): Observable<any> {
        this.loadingModalChangeReceipts = new Subject<any>();
        return this.loadingModalChangeReceipts.asObservable();
    }

    showChangeReceipts(){
        this.loadingModalChangeReceipts.next(true);
    }

    observerLegalibooPopUp(): Observable<any> {
        this.loadingLegalibooModal = new Subject<any>();
        return this.loadingLegalibooModal.asObservable();
    }

    showLegalibooModal(){
        this.loadingLegalibooModal.next(true);
    }
    
}

