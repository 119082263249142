import { ImagenDataDTO } from "./ImagenDataDTO";
import { NoticiaDataDTO } from "./NoticiaDataDTO";

/**
 *
 * @author jpardal
 */
export class SubCategoriaDataDTO {
    id: string;
    icono: string;
    titulo: string;    
    identificador: string;
    categoria: string;
    subcategoria: string;
    noticiaList: NoticiaDataDTO[] = [];
}
