<div class="carousel-content">

    <div class="left">
        <app-icon-type color="blue" [icon]="data.branchType"></app-icon-type>
    </div>

    <div class="right no-text-select">
        <div *ngIf="!showDirectAreas && data.idRea != ''" class="row row-5050">
            <app-label-text label="Nº de póliza / contrato" text="{{data.idRea}}" [loading]=loading></app-label-text>
            <app-label-text label="Nº del parte:" text="{{data.eventNumber}}" [loading]=loading></app-label-text>
        </div>

        <div *ngIf="showDirectAreas" class="row row-5050">
            <app-label-text label="Nº de póliza / contrato" text="{{data.policyNumber}}" [loading]=loading></app-label-text>
            <app-label-text label="Nº del parte:" text="{{data.eventNumber}}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-5050">
            <app-label-text label="Fecha de ocurrencia:" text="{{data.eventEndDate | date: 'dd/MM/yyyy'}}" [loading]=loading></app-label-text>
            <app-label-text label="Fecha de declaración" text="{{data.eventStartDate | date: 'dd/MM/yyyy'}}" [loading]=loading></app-label-text>
        </div>
        <div class="row row-100">
            <app-label-text label="Tramitador:" text="{{data.expeditionAgent}}" textClass="blue-normal" [loading]=loading></app-label-text>
        </div>
        <div class="row row-7030">
            <div class="inner-label">
                <app-label-text *ngIf="undefined !== data.expeditionDate" label="Situación parte:" text="{{data.eventStatus}}" [loading]=loading></app-label-text>
            </div>
            <!-- <div class="row-7030-right row-7030-right2"> -->
            <div class="split-button">
                <app-split-button *ngIf="!loading && (data.isInsuredInEvent)" label="Quiero..." direction=top>
                    <app-split-button-item label="Ver detalles" route="/event/{{data.eventNumber}}"></app-split-button-item>
                    <app-split-button-item *ngIf="data.expeditionDate && data.expeditionAgent" label="Contactar con mi tramitador/a" route="/event/{{ data.eventNumber }}/{{show}}" ></app-split-button-item>
                </app-split-button>
            </div>
        </div>
    </div>

</div>