<div class="page-container">

    <div class="group" [formGroup]="thirdStepForm">
        <div class="title">
            <p>Datos de contacto para gestionar este parte</p>
        </div>
        <div class="content">

            <div class="row-50">
                <app-input-box type="text" label="Nombre" id="name" errorDescription="(*) Campo obligatorio"
                    formControlName="name" maxlength="160">
                </app-input-box>

                <app-input-box type="text" label="Primer apellido" id="surname" errorDescription="(*) Campo obligatorio"
                    formControlName="surname" maxlength="60">
                </app-input-box>
            </div>

            <div class="row-50">
                <app-input-box type="text" label="Segundo apellido" errorDescription="(*) Campo obligatorio"
                    formControlName="lastName" maxlength="60">
                </app-input-box>

                <app-input-box type="text" label="E-MAIL" id="email" formControlName="email"
                    errorDescription="(*) Campo obligatorio" maxlength="128" (focusout)="onChangeEmail($event)">
                </app-input-box>
            </div>


            <div class="row-50">
                <div class="row-25-75 inner-row-phone">
                    <div class="prefix-container">
                        <angular2-multiselect [data]="prefixData" [settings]="prefixSettings" formControlName="phonePrefix">
                            <c-badge>
                                <ng-template let-item="item">
                                    <label class="phoneBadge">
                                        <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                        <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                        <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-badge>
                            <c-item>
                                <ng-template let-item="item">
                                    <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                        <span class="flags flag{{item.id}}"></span>
                                        <span>+{{item.id}}</span>
                                        <span>{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>
                    </div>
                    <app-input-box type="text" label="TELÉFONO FIJO" id="phone" formControlName="phone" maxlength="128"
                        mask="000000000000000000" isValidPhoneErrorDescription="Número no válido" (focusout)="onChangePhone($event)"></app-input-box>
                </div>
            
                <div class="row-25-75 inner-row-phone">
                    <div class="prefix-container">    
                        <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                            formControlName="mobilePhonePrefix">
                            <c-badge>
                                <ng-template let-item="item">
                                    <label class="phoneBadge">
                                        <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                        <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                        <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-badge>
                            <c-item>
                                <ng-template let-item="item">
                                    <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                        <span class="flags flag{{item.id}}"></span>
                                        <span>+{{item.id}}</span>
                                        <span>{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>
                    </div>
                    <app-input-box type="text" label="TELÉFONO MOVIL" id="mobilePhone" formControlName="mobilePhone" maxlength="128"
                        mask="000000000000000000" isValidPhoneErrorDescription="Número no válido" (focusout)="onChangeMobile($event)"></app-input-box>
                </div>            
            </div>

            <div class="row-50">
                <div class="row-25-75 inner-row-phone">
                    <div class="prefix-container">    
                        <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                            formControlName="faxPrefix">
                            <c-badge>
                                <ng-template let-item="item">
                                    <label class="phoneBadge">
                                        <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                        <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                        <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-badge>
                            <c-item>
                                <ng-template let-item="item">
                                    <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                        <span class="flags flag{{item.id}}"></span>
                                        <span>+{{item.id}}</span>
                                        <span>{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>
                    </div>
                    <app-input-box type="text" label="FAX" id="fax" formControlName="fax" maxlength="128"
                        mask="000000000000000000" isValidPhoneErrorDescription="Número no válido"></app-input-box>
                </div>

                <app-input-box type="text" label="Relación con asegurado" errorDescription="(*) Campo obligatorio"
                    formControlName="relationShip" maxlength="30">
                </app-input-box>

            </div>


        </div>

        <div class="content-divider"></div>

        <div class="content content-address">
            <div class="row row-search">
                <div class="row-50 row-search-inner">
                    <app-input-box type="text" label="C. POSTAL" id="postalCode" formControlName="postalCode" mask="00000"
                        errorDescription="(*) Campo obligatorio" maxlength="5" (keyup)="onChangePostalCode($event)">
                    </app-input-box>
                    <div class="row-50-search">
                        <a (click)="searchCity()">Buscar población</a>
                    </div>
                </div>
                <div class="row-city">

                    <div class="row-city-combo">
                        <angular2-multiselect [data]="cityList" [settings]="citySettings" id="city" formControlName="city"
                            (onSelect)="onCitySelect($event)"></angular2-multiselect>
                    </div>

                    <span *ngIf="loadingCities" class="spinner"></span>

                    <div class="description">
                        <p *ngIf="isInvalid('city')" class="text-red text-bold">
                            <label>
                                (*) Campo obligatorio
                            </label>
                        </p>
                    </div>
                </div>

            </div>

            <div class="row row-province">
                <div class="disabledField select">
                    <label for="">Provincia</label>
                    <p>{{province}}</p>
                </div>
            </div>

            <div class="row row-25-75">
                <div class="cont select-roadType">
                    <angular2-multiselect [data]="data.roadTypes" id="roadType" [settings]="roadTypeSettings"
                        formControlName="roadType">
                    </angular2-multiselect>
                </div>

                <app-input-box type="text" label="DOMICILIO" id="address" formControlName="address"
                    errorDescription="(*) Campo obligatorio" maxlength="80"></app-input-box>

            </div>

            <div class="row-20-20">

                <app-input-box type="text" label="NÚMERO" id="number" formControlName="number"
                    errorDescription="(*) Campo obligatorio" maxlength="5"></app-input-box>

                <app-input-box type="text" label="PORTAL" formControlName="portal" maxlength="32"></app-input-box>

                <app-input-box type="text" label="ESCALERA" formControlName="stair" maxlength="16"></app-input-box>

                <app-input-box type="text" label="PISO" formControlName="floor" maxlength="20"></app-input-box>

                <app-input-box type="text" label="PUERTA" formControlName="door" mask="AA" maxlength="16"></app-input-box>
            </div>


        </div>

        <div class="content-divider"></div>

        <div class="content content-time">
            <div class="row">
                <div class="row row-25-75 row-time">
                    <div class="title-time">
                        Horario de contacto
                    </div>
                    <div class="label-time">
                        <label>
                            <input type="checkbox" formControlName="callMorning">
                            Mañana
                        </label>
                        <label>
                            <input type="checkbox" formControlName="callEvening">
                            Tarde
                        </label>
                        <label>
                            <input type="checkbox" formControlName="callNight">
                            Noche
                        </label>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>