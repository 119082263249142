<div *ngIf="show" class="loading-container no-text-select">
  <div class="loading-container-cont fadeInUp">
      <div class="pre-loading">
          <p class="close">
              <b class="cross" (click)="closeModal()">&#10006;</b>
              </p>

          <p class="loading bottom-space">Para solicitar un documento legal<span>.</span><span>.</span><span>.</span></p>

          <p class="loading sub">Contacta con nuestro Centro de Asistencia Jurídica escribiendo a <a href="mailto:consultajuridica@onlygal.es">consultajuridica@onlygal.es</a> o llamando al 900 828 020.</p>

          <div class="button-row">
              <div class="button-place">                   
              </div>
              <div class="button-place">  
                  <app-spinner-button [label]="'Aceptar'" [enabled]="true" (click)="closeModal()">
                  </app-spinner-button>                 
              </div>
              <div class="button-place">                   
              </div>
          </div>
          
      </div>
  </div>
</div>