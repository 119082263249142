import { Component, OnInit, Input } from '@angular/core';
import { DasLexService } from 'app/services/daslex-service';

@Component({
    selector: 'app-product-service-pagination',
    templateUrl: './product-service-pagination.component.html',
    styleUrls: ['./product-service-pagination.component.scss']
})
export class ProductServicePaginationComponent implements OnInit {

    @Input() loading: Boolean;
    @Input() response: any;

    constructor(private dasLexService: DasLexService) { }

    ngOnInit() {
    }

    onChange(value) {
        this.dasLexService.setPageSize(value);
    }

    start(): String {
        return this.response.pageable.offset + 1;
    }

    end(): String {
        if (this.response && !this.response.last) {
            return this.response.pageable.offset + this.response.pageable.pageSize
        } else {
            return this.response ? this.response.totalElements : '';
        }
    }

    total(): String {
        return this.response ? this.response.totalElements : '';
    }

    prev() {
        if (!this.loading && this.response && !this.response.first) {
            this.dasLexService.setPageOffset(this.response.number - 1);
        }
    }

    next() {
        if (!this.loading && this.response && !this.response.last) {
            this.dasLexService.setPageOffset(this.response.number + 1);
        }
    }

    showPagination(): Boolean {
        return this.response && this.response.totalElements > 5;
    }

}

