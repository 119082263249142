<div *ngIf="!showModal">
  <app-check-modal-club></app-check-modal-club>
</div>

<app-header>
  <div class="inner-content-header container">
      <div class="profile">
        <app-header-breadcrumb crumb="Club del propietario" text="Club del propietario"></app-header-breadcrumb>
      </div>
      <div class="last-conect">
          <app-header-last-conection></app-header-last-conection>
      </div>
  </div>
</app-header>

<div class="container colorFondo" style="min-height: 400px;">

  <div *ngIf="loadingDatosNoticia" class="container no-text-select pre-loading">
      <p>Cargando<span>.</span><span>.</span><span>.</span></p>
  </div>

  <div *ngIf="!loadingDatosNoticia">
    <div class="container no-text-select">
      <br>  
      <div class="tabs menuHorizontal">
        <div *ngFor="let menu of menus">
            <span [routerLink]="['/club-del-propietario', menu.getCodMenu()]" class="{{ menu.getEstilo() }}">{{ menu?.getLiteralMenu() }}</span>
        </div>
      </div>
      <div class="noticiaDetalle">
        <br>
        <h1 class="title">{{ noticiaDetalle?.titulo }}</h1>
        <br>
        <br>
        <div class="divCategoriasNoticia">
          <p class="titulosCategoriasNoticia">Categorías:</p> <p class="categoriasNoticia">{{categoriaDetalle?.titulo}}{{', ' + subcategoriaDetalle?.titulo}}</p>
        </div>
        <br>
        <br>
        <img src="{{noticiaDetalle?.imagen.source_url}}" class="imagenNoticia">
        <br>
        <br>
        <div class="contenedorNoticia" [innerHtml]="noticiaDetalle?.detalle"></div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <div *ngIf="lstOtrasAyudas.length !== 0">
    <h1 class="tituloAyudas">Te puede interesar…</h1>
    <div *ngIf="hayOtrasAyudas" class="swipper">
      <br>
      <br>
      <div class="otrasAyudas">
        <div *ngFor="let noticia of lstOtrasAyudas | slice:0:3;">
          <img src="{{noticia.imagen.source_url}}" class="imagenPost">
          <br>
          <br>
          <a (click)="cargarNoticia(noticia.categoria, noticia.id)" class="enlacePost" >
            {{ noticia.titulo }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>