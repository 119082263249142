/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-links.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./event-links.component";
import * as i5 from "../../../../../security/auth.service";
var styles_EventLinksComponent = [i0.styles];
var RenderType_EventLinksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventLinksComponent, data: {} });
export { RenderType_EventLinksComponent as RenderType_EventLinksComponent };
function View_EventLinksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "sup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Abrir un parte"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2192"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 3, 0, "/events/register"); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_EventLinksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "event-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "steps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "steps-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFQu\u00E9 quieres hacer?"])), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "steps-part"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "steps-part-inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventLinksComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "inf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Consultar historial de partes"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2192"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDirectAreas; _ck(_v, 8, 0, currVal_0); var currVal_3 = _ck(_v, 12, 0, "/events/history"); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).target; var currVal_2 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_1, currVal_2); }); }
export function View_EventLinksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-links", [], null, null, null, View_EventLinksComponent_0, RenderType_EventLinksComponent)), i1.ɵdid(1, 114688, null, 0, i4.EventLinksComponent, [i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventLinksComponentNgFactory = i1.ɵccf("app-event-links", i4.EventLinksComponent, View_EventLinksComponent_Host_0, {}, {}, []);
export { EventLinksComponentNgFactory as EventLinksComponentNgFactory };
