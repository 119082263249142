import { Component, Input } from '@angular/core';
import { AuthService } from 'app/security/auth.service';

@Component({
    selector: 'app-event-item',
    templateUrl: './event-item.component.html',
    styleUrls: ['./event-item.component.scss']
})
export class EventItemComponent {

    @Input() data;
    show = true;
    showDirectAreas: Boolean = true;

    constructor(
      private authService: AuthService
    ) { }
  
    ngOnInit() {
      if ((this.authService.isLogged())) {
        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
      }
    }

}
