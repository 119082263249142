<div class="data">
    <div class="data-title">
        <p>Datos del parte</p>
    </div>
    <div class="data-main">
        <div class="data-main-sup">
            <div *ngIf="!showDirectAreas && data.idRea != ''" class="left">
                <span>Nº de póliza / contrato:</span>
                <p>{{data.idRea}}</p>
            </div>
            <div *ngIf="showDirectAreas" class="left">
                <span>Nº de póliza / contrato:</span>
                <p><a class="link" [routerLink]="['/products/' + data.policyNumber ]">{{data.policyNumber}}</a></p>
            </div>
            <!--alb1: CLI467: Ocultar el campo “Producto” solo para reaseguro-->
            <div *ngIf="showDirectAreas" class="center">
                <span>Producto:</span>
                <p><strong>{{data.policyProduct}}</strong></p>
            </div>
            <!-- fin alb1-->
            <!--alb2: CLI467: Subir el campo “Versión” solo para reaseguro -->
            <div *ngIf="!showDirectAreas" class="center">
                <span>Versión:</span>
                <p>{{ data.version }}</p>
            </div>
            <!--fin alb2-->
            <div class="right">
                <span>Fecha declaración:</span>
                <p>{{ data.eventStartDate | date: 'dd/MM/yyyy' }}</p>
            </div>
        </div>
        <div class="data-main-inf">
            <div class="left">
                <span>Referencia:</span>
                <p>{{ data.eventNumber }}</p>
            </div>
            <!--alb3 CLI467: Subir el campo “Versión” solo para reaseguro. Por lo tanto lo ocultamos aqui para reaseguro y solo mostramos en directo-->
            <div *ngIf="showDirectAreas" class="center">
                <span>Versión:</span>
                <p>{{ data.version }}</p>
            </div>
            <!--fin alb3-->
            <div class="right">
                <span>Fecha de los hechos:</span>
                <p>{{data.eventEndDate | date: 'dd/MM/yyyy' }}</p>
            </div>
        </div>
        <div class="data-main-inf" *ngIf="data.rejected">
            <div class="left">
                <span>Estado:</span>
                <p> Rechazado</p>
            </div>
            <div class="center">
                <span>Motivo:</span>
                <p>{{ getReason() }}</p>
            </div>            
            <div class="right">
                <span></span>
                <p></p>
            </div>
        </div>
    </div>
</div>