<div class="container-bar" *ngIf="percent < 100">
    <div class="progress-title">
        <a [routerLink]="['/modify']">Completa tu perfil</a>
    </div>
    <div class="progress-inner ">
        <div class="progress-inner-bar ">
            <div class="bar " [ngStyle]="{ 'width': percent + '%'} "></div>
        </div>
        <div class="progress-inner-percent ">
            <p>{{percent}}%</p>
        </div>
    </div>

</div>