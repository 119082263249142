import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';
import { PolicyService } from 'app/services/policy-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-product-detail-page',
    templateUrl: './product-detail-page.component.html',
    styleUrls: ['./product-detail-page.component.scss']
})
export class ProductDetailPageComponent implements OnInit {

    policy: any = undefined;
    policyNumber: any;
    policyYear: any;
    loadingPolicy: Boolean = true;

    constructor(private route: ActivatedRoute, private policyService: PolicyService, private toastr: ToastrService) { }

    ngOnInit() {
        this.policyYear = this.route.snapshot.params['policyYear'];
        this.policyNumber = this.route.snapshot.params['policyNumber'];

        this.policyService.getPolicyByNumber(this.policyNumber)
            .then(success => {
                this.loadingPolicy = false;
                this.policy = success.response;
            }).catch(error => {
                this.loadingPolicy = false;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }
}
