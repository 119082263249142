import { Component, OnInit, Input, Self, Optional, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import * as moment from 'moment';


@Component({
    selector: 'app-input-datepicker-box',
    templateUrl: './input-datepicker-box.component.html',
    styleUrls: ['./input-datepicker-box.component.scss'],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-Es' },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE] },

    ],
})

export class InputDatepickerBoxComponent implements OnInit, ControlValueAccessor {

    @Input() label: string;
    @Input() description: String;
    @Input() errorDescription: String;
    @Input() beforeCurrentDateErrorDescription: String;
    @Input() afterCurrentDateErrorDescription: String;
    @Input() minAgeErrorDescription: String;
    @Input() maxDate: Date;
    disabled: Boolean = false;

    @Output() onPaste = new EventEmitter<ClipboardEvent>();

    @ViewChild('edit') edit: ElementRef;
    value: Date;

    constructor(
        @Self() @Optional() public ngControl: NgControl,
        private elementRef: ElementRef,
        private _adapter: DateAdapter<any>) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    capturePaste(event: ClipboardEvent) {
        this.onPaste.emit(event);
    }

    ngOnInit() {
        moment.locale('es')
        this._adapter.setLocale('es');
    }

    _openCalendar(picker) {
        picker.open();
        setTimeout(() => this.edit.nativeElement.focus());
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any): void {
        this.parseValue(value);
    }


    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange(event) {
    }

    onTouched() { }

    parseValue(selected: any) {
        if (!selected) {
            this.value = null;
        } else if (typeof selected === 'string') {
            if(selected.length >= 9){
                const dateMomentObject = moment(selected, "DD/MM/YYYY");
                this.value = dateMomentObject.toDate();
            }
        } else {
            this.value = selected;
            //this.value = `${this.addLeadingZero(selected.getDate())}-${this.addLeadingZero(selected.getMonth() + 1)}-${selected.getFullYear()}`;
        }
    }


    onSelectCallback(selected: Date) {
        this.parseValue(selected);
        this.onChange(this.value);
    }

    onFocusOut(value) {
        if (value) {

            this.parseValue(value);
            this.onChange(this.value);
        }
    }


    private addLeadingZero(value: number): string {
        if (value < 10) {
            return `0${value.toString()}`;
        }
        return value.toString();
    }


}
