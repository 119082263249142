import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-product-data',
    templateUrl: './product-data.component.html',
    styleUrls: ['./product-data.component.scss']
})
export class ProductDataComponent implements OnInit {

    @Input() insured: any;
    @Input() first: any;
    
    constructor() { }

    ngOnInit() {
    }

}
