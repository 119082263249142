/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-password-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/change-password-form/change-password-form.component.ngfactory";
import * as i3 from "../../components/change-password-form/change-password-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/user-service";
import * as i7 from "../../../shared/services/wordpress/club-propietario.service";
import * as i8 from "../../../security/auth.service";
import * as i9 from "../../components/picture-with-text/picture-with-text.component.ngfactory";
import * as i10 from "../../components/picture-with-text/picture-with-text.component";
import * as i11 from "@angular/common";
import * as i12 from "./change-password-page.component";
var styles_ChangePasswordPageComponent = [i0.styles];
var RenderType_ChangePasswordPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangePasswordPageComponent, data: {} });
export { RenderType_ChangePasswordPageComponent as RenderType_ChangePasswordPageComponent };
function View_ChangePasswordPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-change-password-form", [], null, [[null, "onChangeSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChangeSubmit" === en)) {
        var pd_0 = (_co.onChangeSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ChangePasswordFormComponent_0, i2.RenderType_ChangePasswordFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.ChangePasswordFormComponent, [i4.FormBuilder, i5.ActivatedRoute, i6.UserService, i5.Router, i7.ClubPropietarioService, i8.AuthService], null, { onChangeSubmit: "onChangeSubmit" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ChangePasswordPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-picture-with-text", [], null, null, null, i9.View_PictureWithTextComponent_0, i9.RenderType_PictureWithTextComponent)), i1.ɵdid(2, 114688, null, 0, i10.PictureWithTextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ChangePasswordPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container sbs"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i11.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangePasswordPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangePasswordPageComponent_2)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container sbs"; var currVal_1 = (_co.changed ? "back-full" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.changed; _ck(_v, 3, 0, currVal_2); var currVal_3 = !_co.changed; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_ChangePasswordPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-password-page", [], null, null, null, View_ChangePasswordPageComponent_0, RenderType_ChangePasswordPageComponent)), i1.ɵdid(1, 49152, null, 0, i12.ChangePasswordPageComponent, [], null, null)], null, null); }
var ChangePasswordPageComponentNgFactory = i1.ɵccf("app-change-password-page", i12.ChangePasswordPageComponent, View_ChangePasswordPageComponent_Host_0, {}, {}, []);
export { ChangePasswordPageComponentNgFactory as ChangePasswordPageComponentNgFactory };
