import { Component, HostListener, OnInit } from '@angular/core';
import { ClientService } from 'app/services/client-service';

@Component({
    selector: 'change-policy-receipts',
    templateUrl: './change-policy-receipts.component.html',
    styleUrls: ['./change-policy-receipts.component.scss']
})
export class ChangePolicyReceiptsComponent implements OnInit {

    show: Boolean = false;

    constructor(
        private clientService: ClientService
    ) { }

    ngOnInit() {

        this.clientService.observerChangeReceipts().subscribe((result: any) => {
            if (result !== 100) {
                this.show = true;
            }
        });

    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (event.target.innerHTML.includes('loading-container-cont wow fadeInUp')) {
            // clicked outside => close modal
            this.show = false;
        }
    }

    closeModal() {
        this.show = false;
    }

}
