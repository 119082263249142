import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'app/services/client-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { UrlService } from 'app/shared/services/dominio/url.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-check-modal-club',
    templateUrl: './check-modal-club.component.html',
    styleUrls: ['./check-modal-club.component.scss']
})
export class CheckModalClubComponent implements OnInit {

    show: Boolean = true;
    clubFormPreferences: FormGroup;
    running: Boolean = false;
    messages: any[];

    constructor(
        private clientService: ClientService,
        private router: Router,
        private toastr: ToastrService,
        private urlService: UrlService,
        // private controlContainer: ControlContainer,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {

        var client: ClientDataDTO = JSON.parse(localStorage.getItem('clientData'));

        

        // this.clubFormPreferences = <FormGroup>this.controlContainer.control;
        this.clubFormPreferences = this.formBuilder.group({
            insuranceAdvertising: ['', []],
            legalServicesAdvertising: ['', []],
            politicaClub: [null, [Validators.required]],
            condicionesClub: [null, [Validators.required]]
        });

        this.clubFormPreferences.patchValue({ insuranceAdvertising: !client.insuranceAdvertising });
        this.clubFormPreferences.patchValue({ legalServicesAdvertising: client.legalServicesAdvertising });
        this.clubFormPreferences.patchValue({ politicaClub: client.politicaClub === false ? null : client.politicaClub});
        this.clubFormPreferences.patchValue({ condicionesClub: client.condicionesClub === false ? null : client.condicionesClub});

        // this.clientService.observerChangeReceipts().subscribe((result: any) => {
        //     if (result !== 100) {
        //         this.show = true;
        //     }
        // });

    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (event.target.innerHTML.includes('loading-container-cont wow fadeInUp')) {
            // clicked outside => close modal
            this.show = false;
        }
    }

    closeModal() {
        console.log(this.clubFormPreferences);
        this.show = false;
    }

    goMain() {
        this.router.navigate(['/main']);
    }

    submitEnabled() {
        return this.clubFormPreferences.valid && !this.running;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.GUARDAR_PREFERENCIAS;
    }

    goPolitica(){
        window.open(this.urlService.frontEndUrl + '/#/club-del-propietario/politica-club', '_blank');
    }

    goCondiciones(){
        window.open(this.urlService.frontEndUrl + '/#/club-del-propietario/condiciones-club', '_blank');
    }

    onModifyPreferences() {
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.clubFormPreferences.valid) {
                this.running = true;
                this.clientService.onModifyPreferences(this.clubFormPreferences.value)
                    .then(success => {
                        this.clientService.getClient().then(response=>{
                            this.show = false;
                            this.running = false;
                            this.toastr.success(CommonsText.PREFERENCIAS_ACTUALIZADAS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                                timeOut: 5000
                            });
                        }).catch(error => {
                            this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                                timeOut: 5000
                            });
                        });
                    }).catch(error => {
                        this.running = false;
                        this.messages = error.error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            }
        }
    }
    
}
