/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/forgot-password-form/forgot-password-form.component.ngfactory";
import * as i3 from "../../components/forgot-password-form/forgot-password-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../services/user-service";
import * as i6 from "../../components/picture-with-text/picture-with-text.component.ngfactory";
import * as i7 from "../../components/picture-with-text/picture-with-text.component";
import * as i8 from "../../components/email-notify/email-notify.component.ngfactory";
import * as i9 from "../../components/email-notify/email-notify.component";
import * as i10 from "@angular/common";
import * as i11 from "@angular/router";
import * as i12 from "./forgot-password-page.component";
var styles_ForgotPasswordPageComponent = [i0.styles];
var RenderType_ForgotPasswordPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordPageComponent, data: {} });
export { RenderType_ForgotPasswordPageComponent as RenderType_ForgotPasswordPageComponent };
function View_ForgotPasswordPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-forgot-password-form", [], null, [[null, "onForgotPasswordSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onForgotPasswordSubmit" === en)) {
        var pd_0 = (_co.onForgotPasswordSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ForgotPasswordFormComponent_0, i2.RenderType_ForgotPasswordFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.ForgotPasswordFormComponent, [i4.FormBuilder, i5.UserService], null, { onForgotPasswordSubmit: "onForgotPasswordSubmit" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ForgotPasswordPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-picture-with-text", [], null, null, null, i6.View_PictureWithTextComponent_0, i6.RenderType_PictureWithTextComponent)), i1.ɵdid(2, 114688, null, 0, i7.PictureWithTextComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ForgotPasswordPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-email-notify", [], null, null, null, i8.View_EmailNotifyComponent_0, i8.RenderType_EmailNotifyComponent)), i1.ɵdid(2, 49152, null, 0, i9.EmailNotifyComponent, [], null, null)], null, null); }
export function View_ForgotPasswordPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "container sbs inner-transparent"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i10.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "box-transparent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i11.RouterLink, [i11.Router, i11.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordPageComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordPageComponent_2)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordPageComponent_3)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container sbs inner-transparent"; var currVal_1 = (_co.emailSend ? "back-full" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 4, 0, "/"); _ck(_v, 3, 0, currVal_2); var currVal_3 = !_co.emailSend; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_co.emailSend; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.emailSend; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_ForgotPasswordPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-page", [], null, null, null, View_ForgotPasswordPageComponent_0, RenderType_ForgotPasswordPageComponent)), i1.ɵdid(1, 114688, null, 0, i12.ForgotPasswordPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordPageComponentNgFactory = i1.ɵccf("app-forgot-password-page", i12.ForgotPasswordPageComponent, View_ForgotPasswordPageComponent_Host_0, {}, {}, []);
export { ForgotPasswordPageComponentNgFactory as ForgotPasswordPageComponentNgFactory };
