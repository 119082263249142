<div class="selectable">
    <div class="selectable-inner">
        <a [routerLink]="['/contact/form']">
            <div class="selectable-form ">
                <div class="img "></div>
                <div class="text ">
                    <p>Formularios de contacto</p>
                </div>
            </div>
        </a>
        <a [routerLink]="['/contact/claims']">
            <div class="selectable-claims ">
                <div class="img "></div>
                <div class="text ">
                    <p>Reclamaciones</p>
                </div>
            </div>
        </a>
        <a href="https://ayuda.onlygal.es" target="help">
            <div class="selectable-questions ">
                <div class="img "></div>
                <div class="text ">
                    <p>Preguntas frecuentes</p>
                </div>
            </div>
        </a>
    </div>
</div>