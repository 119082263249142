import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountDTO } from 'app/header-footer-template/model/AccountDTO';
import { Subscription } from 'rxjs';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { PolicyService } from 'app/services/policy-service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-modify-bank',
    templateUrl: './modify-bank.component.html',
    styleUrls: ['./modify-bank.component.scss']
})
export class ModifyBankComponent implements OnInit {

    FormData = require('formdata-polyfill')

    @Input() client: ClientDataDTO;

    documentTypeSettings: any;

    documentTypeList: any[] = [
        { 'id': 'NIF', 'itemName': 'NIF' },
        { 'id': 'CIF', 'itemName': 'CIF' },
        { 'id': 'NIE', 'itemName': 'NIE' }
    ];

    accountList: any[] = [];

    policyList: any[] = [];

    addNewAccountForm: FormGroup;
    showingAddNewAccountForm: Boolean = false;
    addNewAccountMessages: any;
    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    uploadFormData = new FormData();
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;
    account: AccountDTO;
    runningNewAccount: any;

    constructor(private formBuilder: FormBuilder, private clientService: ClientService,
        private toastr: ToastrService, private policyService: PolicyService) { }

    ngOnInit() {

        this.documentTypeSettings = {
            singleSelection: true,
            text: 'Documento',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.clientService.getPaymentAccounts()
            .then(value => {
                this.accountList = value.response;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPOS_CUENTAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

        this.policyService.getCurrent()
            .then(value => {
                if (null !== value.response && undefined !== value.response) {
                    value.response.forEach(policy => {
                        if ((policy.collective === null || policy.collective === undefined) && policy.isTaker) {
                            this.policyList.push(policy);
                        } else {
                            if (policy.collective > 0 && policy.isTaker) {
                                this.policyList.push(policy);
                            }
                        }
                    })
                }

            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPOS_CUENTAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });


    }

    addNewAccount() {

        let name = '';

        if ((undefined !== this.client.name) && (null !== this.client.name)) {
            name = name + this.client.name;
        }

        if ((undefined !== this.client.surname) && (null !== this.client.surname)) {
            name = name + ' ' + this.client.surname;
        }

        if ((undefined !== this.client.lastName) && (null !== this.client.lastName)) {
            name = name + ' ' + this.client.lastName;
        }

        const documentType = [{ 'id': this.client.documentType, 'itemName': this.client.documentType }];

        if (!this.showingAddNewAccountForm) {
            this.addNewAccountForm = this.formBuilder.group({
                iban: [undefined, [Validators.required, Validators.minLength(4)]],
                entity: [undefined, [Validators.required, Validators.minLength(4)]],
                office: [undefined, [Validators.required, Validators.minLength(4)]],
                digit: [undefined, [Validators.required, Validators.minLength(2)]],
                accountNumber: [undefined, [Validators.required, Validators.minLength(10)]],
                titular: [name],
                documentType: [documentType],
                documentNumber: [this.client.documentNumber]
            });
            this.showingAddNewAccountForm = true;
            this.addNewAccountForm.valueChanges.subscribe(data => {
                if ((this.addNewAccountForm.touched || this.addNewAccountForm.dirty) && this.addNewAccountForm.invalid) {
                    this.addNewAccountMessages = CommonsText.TODOS_CAMPOS_OBLIGATORIOS;
                } else {
                    this.addNewAccountMessages = null;
                }
            });
        }
    }

    getDate() {
        return new Date()
    }

    getNewAccountButtonLabel(): String {
        return (this.runningNewAccount) ? CommonsText.CARGANDO_MINUSCULA : CommonsText.GUARDAR_CUENTA_MINUSCULA;
    }

    submitNewAccountEnabled(): boolean {
        return !this.runningNewAccount && this.addNewAccountForm.valid && (this.files.length > 0);
    }

    onConfirmNewAccount() {
        if (this.addNewAccountForm.valid) {
            this.runningNewAccount = true;
            return this.httpEmitter = this.clientService.onAddNewAccount(this.addNewAccountForm.value, this.uploadFormData)
                .subscribe(
                    event => {
                        this.httpEvent = event
                        if (event instanceof HttpResponse) {
                            delete this.httpEmitter;
                            this.files.length = 0;
                            this.accountList = event.body.response
                            this.runningNewAccount = false;
                            this.showingAddNewAccountForm = false;
                            this.toastr.success(CommonsText.CUENTA_AYADIDA_EXITO, CommonsText.SERVICIO_DE_CUENTAS);
                            this.clientService.showModal();
                        }
                    },
                    error => {
                        this.runningNewAccount = false;
                        this.addNewAccountMessages = error.error.response;
                        this.toastr.error(error.error.response, CommonsText.ERROR_SERVICIO_CUENTAS);
                    }
                )
        }
    }

    onRemoveAccount(event: any, account: any) {
        const button = (event.target.parentNode.getElementsByTagName('button')[0]);
        const spinner = (event.target.parentNode.getElementsByTagName('i')[0]);
        button.style.display = 'none';
        spinner.style.display = 'initial';
        this.clientService.onRemoveAccount(account)
            .then(value => {
                this.accountList = value.response;
                this.toastr.success(CommonsText.CUENTA_REMOVIDA_EXITO, CommonsText.SERVICIO_DE_CUENTAS);
            }).catch(error => {
                button.style.display = 'initial';
                spinner.style.display = 'none';
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPOS_CUENTAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    onChangeAccount(event: any, policy: any) {
        this.clientService.showChangeReceipts();
        const accountNumber = event.target.value;

        if (('' !== accountNumber) && ('' !== policy.id)) {

            const select = (event.target.parentNode.getElementsByTagName('select')[0]);
            const spinner = (event.target.parentNode.getElementsByTagName('i')[0]);
            const p = (event.target.parentNode.getElementsByTagName('p')[0]);

            select.style.display = 'none';
            spinner.style.display = 'initial';
            p.style.display = 'initial';

            this.clientService.onChangeAccount(accountNumber, policy.id)
                .then(value => {
                    select.style.display = 'initial';
                    spinner.style.display = 'none';
                    p.style.display = 'none';
                    policy.bankAccount = accountNumber;
                    this.toastr.success(CommonsText.CUENTA_CAMBIADA_EXITO, CommonsText.SERVICIO_DE_CUENTAS);
                }).catch(error => {
                    select.style.display = 'initial';
                    spinner.style.display = 'none';
                    p.style.display = 'none';
                    this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPOS_CUENTAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                        timeOut: 5000
                    });
                });
        }

    }

    captureIban(event: ClipboardEvent) {
        let pastedText = event.clipboardData.getData('text'); // ES39987665431201234567  89
        if ((undefined !== pastedText) && (null !== pastedText)) {
            pastedText = pastedText.replace(/\s/g, '');
            if (pastedText.length === 24) {
                this.addNewAccountForm.patchValue({ iban: pastedText.substring(0, 4) });
                this.addNewAccountForm.patchValue({ entity: pastedText.substring(4, 8) });
                this.addNewAccountForm.patchValue({ office: pastedText.substring(8, 12) });
                this.addNewAccountForm.patchValue({ digit: pastedText.substring(12, 14) });
                this.addNewAccountForm.patchValue({ accountNumber: pastedText.substring(14, 24) });
            }
        }
    }

}
