<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Modificar o ampliar la cobertura"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container">
    <div class="page-container" [formGroup]="hireMoreForm">

        <div *ngIf="loadingPage || undefined === data" class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div *ngIf="!loadingPage && undefined !== data" class="detail">

            <div class="change-confirmed" *ngIf="changeIdentifier">
                Cambio de matrícula realizado con éxito. ¡Gracias!
            </div>

            <div class="products">
                <div class="products-box {{ getBackgroundColor() }}"></div>
                <div class="products-icon">
                    <div class="products-icon-img">
                        <app-icon-type *ngIf="data.validChangeIdentifier && !isGeneralMod" [icon]="data.branchType"
                            color="{{ getIconColor() }}"></app-icon-type>
                        <app-icon-type *ngIf="!data.validChangeIdentifier || isGeneralMod" icon="edit-button"
                            color="blue"></app-icon-type>
                        <p>
                            Estado: <span [ngStyle]="getStatusColor()">{{ data.policyStatus }}</span>
                        </p>
                    </div>
                </div>
                <div class="products-policy products-policy-first">
                    <div class="sup">
                        <p>Producto:</p>
                        <span>{{ data.policyProduct }}</span><br>
                    </div>
                </div>
                <div class="products-policy">
                    <div class="sup">
                        <p>Nº de póliza / contrato:</p>
                        <span>{{ data.policyNumber }} </span> <span style="color:gray; font-size: 13px;"
                            *ngIf="undefined !== data.collective"> (Colectivo {{ data.collective }} ) </span>
                    </div>
                </div>
                <div class="products-policy">
                    <div class="inf">
                        <p>Vigencia:</p>
                        <span>{{ data.policyStartDate | date: "dd/MM/yyyy" }} -
                            {{ data.policyEndDate | date: "dd/MM/yyyy" }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="!changeIdentifier">
                <div class="car-message" *ngIf="data.validChangeIdentifier && !isGeneralMod">
                    <p style="line-height: 25px;">Para poder modificar la matrícula, el nuevo vehículo ha de ser del
                        mismo tipo que el actualmente
                        asegurado. Si el vehículo que quieres asegurar es diferente (turismo, monovolumen, ciclomotor,
                        etc.),
                        por favor, <label style="text-decoration: underline; cursor: pointer;"
                            (click)="setGeneralMod()">rellena este otro formulario</label></p>
                </div>

                <div *ngIf="false && data.validChangeIdentifier && !isGeneralMod" class="group wow fadeInUp"
                    data-wow-duration="1s">
                    <div class="title">
                        <p>Seleccione su vehículo asegurado</p>
                    </div>
                    <div class="content content-1">
                        <div class="risk-content">
                            <div class="risk" *ngFor="let risk of data.risksCompelted; index as i ">

                                <label class="btn">
                                    <input type="radio" formControlName="risk" (change)="identifierSelected()"
                                        value="{{risk.id}}">
                                    <span>
                                        <i class="check-icon"></i>
                                        {{risk.description}}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <span *ngIf="errorDescription('risk')" class="errorDescription">(*) Campo obligatorio</span>
                    </div>
                </div>


                <div class="group wow fadeInUp" data-wow-duration="1s">
                    <div class="title">
                        <p *ngIf="!data.validChangeIdentifier || isGeneralMod">Solicitud de modificación</p>
                        <p *ngIf="data.validChangeIdentifier && !isGeneralMod">Modificación de matrícula</p>
                    </div>

                    <div class="content">
                        <div class="declaration">
                            <div>
                                <app-date-picker label="Fecha en la que debe realizarse el cambio"
                                    formControlName="modificationDate" errorDescription="(*) Campo obligatorio"
                                    afterCurrentDateErrorDescription="La fecha debe ser mayor o igual que el día actual">
                                </app-date-picker>
                            </div>

                            <div class="observation" *ngIf="!data.validChangeIdentifier || isGeneralMod">
                                <label>Explícanos brevemente qué cambios quieres realizar en tu seguro</label>
                                <textarea aria-label="explicación" formControlName="observation" cols="30" rows="5"></textarea>
                                <span *ngIf="errorDescription('observation')" class="errorDescription">(*) Campo
                                    obligatorio</span>
                            </div>

                        </div>

                    </div>

                    <div class="content" *ngIf="data.validChangeIdentifier && !isGeneralMod">
                        <div class="left">
                            <app-input-box type="text" label="Matrícula actual" [disabled]="true"
                                formControlName="identifier"></app-input-box>
                        </div>
                        <div style="width: 260px;">
                            <app-input-box type="text" label="Tipo de vehículo" [disabled]="true"
                                formControlName="vehicleType"></app-input-box>
                        </div>
                        <div class="right">
                            <app-input-box type="text" label="Nueva Matrícula" formControlName="newIdentifier"
                                maxlength=20></app-input-box>
                        </div>
                    </div>
                    <div class="content" style="padding-bottom: 20px; padding-top: 0px;"
                        *ngIf="data.validChangeIdentifier && !isGeneralMod">
                        <div class="left" style="color:#e00e00;">
                            *El vehículo con matrícula anterior dejará de estar asegurado
                        </div>
                    </div>
                </div>

                <div class="buttons wow fadeInUp" data-wow-duration="1s">
                    <div class="message">{{messages}}</div>
                    <app-spinner-button [label]="getButtonLabel()" [enabled]="enableButton()" [running]="running"
                        (click)="onFormSubmit()">
                    </app-spinner-button>
                </div>

            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>