/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spinner-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./spinner-button.component";
var styles_SpinnerButtonComponent = [i0.styles];
var RenderType_SpinnerButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpinnerButtonComponent, data: {} });
export { RenderType_SpinnerButtonComponent as RenderType_SpinnerButtonComponent };
function View_SpinnerButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "spinner"]], null, null, null, null, null))], null, null); }
export function View_SpinnerButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpinnerButtonComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, ["", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (_co.enabled ? "button-check" : "button-check-disabled"), _co.customSpinnerButton); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.running; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.enabled; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.label; _ck(_v, 5, 0, currVal_3); }); }
export function View_SpinnerButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner-button", [], null, null, null, View_SpinnerButtonComponent_0, RenderType_SpinnerButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerButtonComponent, [], null, null)], null, null); }
var SpinnerButtonComponentNgFactory = i1.ɵccf("app-spinner-button", i3.SpinnerButtonComponent, View_SpinnerButtonComponent_Host_0, { enabled: "enabled", label: "label", running: "running", customSpinnerButton: "customSpinnerButton" }, {}, []);
export { SpinnerButtonComponentNgFactory as SpinnerButtonComponentNgFactory };
