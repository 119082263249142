import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { ToastrService } from 'ngx-toastr';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { DasLexService } from 'app/services/daslex-service';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';
import { CommonsText } from 'app/commons/CommonsText';
import { CommonsUtils } from 'app/commons/CommonsUtils';

@Component({
    selector: 'app-hire-more-page',
    templateUrl: './hire-more-page.component.html',
    styleUrls: ['./hire-more-page.component.scss']
})
export class HireMorePageComponent implements OnInit {
    loading: Boolean = false;
    response: any;
    loadingDasLex: Boolean = false;
    responseDasLex: any;
    newProducts: any = [];
    newServices: any = [];
    private params = new PolicyParamDTO();
    private paramsDasLex = new DasLexParamDTO();

    constructor(private dasLexService: DasLexService, private policyService: PolicyService, private toastr: ToastrService) { }

    ngOnInit(): void {
        this.params = new PolicyParamDTO();
        this.params.pageOffset = 0;
        this.params.pageSize = 5;
        this.search();

        this.paramsDasLex = new DasLexParamDTO();
        this.paramsDasLex.pageOffset = 0;
        this.paramsDasLex.pageSize = 5;

        this.policyService.observerPageSizeChange().subscribe((result: any) => {
            this.params.pageOffset = 0;
            this.params.pageSize = result;
            this.search();
        });

        this.policyService.observerPageOffset().subscribe((result: any) => {
            this.params.pageOffset = result;
            this.search();
        });

        this.searchDasLex();

        this.dasLexService.observerPageSizeChange().subscribe((result: any) => {
            this.paramsDasLex.pageOffset = 0;
            this.paramsDasLex.pageSize = result;
            this.searchDasLex();
        });

        this.dasLexService.observerPageOffset().subscribe((result: any) => {
            this.paramsDasLex.pageOffset = result;
            this.searchDasLex();
        });

        this.dasLexService.observerPageFilter().subscribe((result: any) => {
            this.searchDasLex();
        });

        this.newProducts = CommonsUtils.newProducs();




    }

    search() {
        this.loading = true;
        this.policyService.search(this.params)
            .then(success => {
                this.loading = false;
                this.response = success;
            }).catch(error => {
                this.loading = false;
                this.response = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    searchDasLex() {
        this.loadingDasLex = true;
        this.newServices = [];
        this.dasLexService.search(this.paramsDasLex)
            .then(success => {
                this.loadingDasLex = false;
                this.responseDasLex = success;
                if (this.responseDasLex.content.length > 0) {
                    this.newServices = [
                        {
                            icon: 'legal',
                            title: 'Ayuda legal siempre a tu alcance',
                            description: 'En casa, en la oficina, donde tú quieras. Tus servicios allí donde estés y cuándo lo desees. Accede a través del siguiente enlace para contratar más servicios desde cualquier dispositivo: ordenador, móvil o tablet.',
                            link: this.responseDasLex.content[0].urlDas
                        }
                    ];
                }

            }).catch(error => {
                this.loadingDasLex = false;
                this.responseDasLex = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_DASLEX, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

}
