import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { AuthService } from 'app/security/auth.service';
import { Observable, Subject } from 'rxjs';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de servicios de daslex
 * @author Iecisa
 */
export class LegalibooService {


    constructor(
        private http: HttpClient, private authService: AuthService,
        private toastr: ToastrService, private domainService: DomainService) {
    }
    
    getLegalibooConfig(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/legaliboo/auth/';
        return this.http.get(url).toPromise();
    }

    legalibooAccess() {
        const url = this.domainService.backendUrlWithContext + '/legaliboo/access/'
        const form = new FormData();

        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post<any>(url, form).toPromise();
    }
}
