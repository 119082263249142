import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonsUtils } from '../../../commons/CommonsUtils';
import { UserService } from '../../../services/user-service';
import { ConfirmRegisterDTO } from '../../model/ConfirmRegisterDTO';
import { AuthenticationDTO } from 'app/side-by-side-template/model/AuthenticationDTO';
import { AuthService } from 'app/security/auth.service';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { AudityService } from 'app/services/audity-service';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';
import { CommonsText } from '../../../commons/CommonsText';

@Component({
    selector: 'app-confirm-register-form',
    templateUrl: './confirm-register-form.component.html',
    styleUrls: ['./confirm-register-form.component.scss']
})
export class ConfirmRegisterFormComponent implements OnInit {

    @Output() onConfirmRegisterSubmit = new EventEmitter();

    confirmRegisterForm: FormGroup;
    confirmRegisterDTO: ConfirmRegisterDTO;
    messages: String[] = null;
    running: Boolean;
    audity: AudityDTO = new AudityDTO();
    ipAddress: string;

    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
        private authService: AuthService, private userService: UserService,
        private audityService: AudityService, private router: Router,
        private clubPropietarioService: ClubPropietarioService) { }

    ngOnInit() {
        this.authService.getIPAddress().subscribe((res:any)=>{  
            this.ipAddress=res._body;  
          }); 

        this.confirmRegisterDTO = new ConfirmRegisterDTO();

        this.route.queryParams.subscribe(params => {
            this.confirmRegisterDTO.uuid = params['uuid'];
            this.confirmRegisterDTO.email = params['address'];
        });

        if ((this.confirmRegisterDTO.uuid === undefined) || (this.confirmRegisterDTO.email === undefined)) {
            this.router.navigate(['/authentication']);
        }

        this.confirmRegisterForm = this.formBuilder.group({

            email: [this.confirmRegisterDTO.email,
            [Validators.required,
            Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]],

            uuid: [this.confirmRegisterDTO.uuid,
            [Validators.required]],

            password: [this.confirmRegisterDTO.password,
            [Validators.required,
            Validators.pattern(CommonsUtils.PASSWORD_REGEX)]],

            password2: [this.confirmRegisterDTO.password2,
            [Validators.required,
            Validators.pattern(CommonsUtils.PASSWORD_REGEX),
            CommonsUtils.matchValues('password')]],

            termsAndConditions: [this.confirmRegisterDTO.termsAndConditions, [Validators.required]],
            dataPolicy: [this.confirmRegisterDTO.dataPolicy, [Validators.required]]

        })

        this.confirmRegisterForm.valueChanges.subscribe(data => { this.messages = null; });

    }

    onConfirmRegister() {

        this.messages = [];
        if (this.submitEnabled()) {
            this.running = true;
            this.userService.onConfirmRegister(this.confirmRegisterForm.value)
                .then(success => {

                    if (success.response.value) {
                        this.audity.audityDocument = success.response.documentNumber;
                        this.audity.audityIp = this.ipAddress;
                        const authentication = new AuthenticationDTO();
                        authentication.username = success.response.documentNumber;
                        authentication.password = this.confirmRegisterForm.value.password;
                        this.audityService.saveRegister(this.audity);
                        this.authService.onAuthenticate(authentication)
                            .then(success => {
                                this.authService.innovaLogin(authentication)
                                .then(success =>{
                                    localStorage.setItem('categorias', JSON.stringify(this.clubPropietarioService.getCategoriaToSave()));
                                    this.audityService.saveLogin(this.audity);
                                    this.router.navigate(['/entities']);
                                })
                                .catch(error =>{
                                    this.audity.audityDocument = authentication.username;
                                    this.audity.audityIp = this.ipAddress;
                
                                    this.running = false;
                                    const error_description = error.error.error_description;
                
                                    switch (error_description) {
                                        case 'BLOCKED_BY_RGPD': {
                                            this.audityService.saveLoginBlockedByRgpd(this.audity);
                                            this.messages = [CommonsText.BLOCKED_BY_RGPD];
                                            break;
                                        }
                                        case 'WITHOUT_POLICIES': {
                                            this.audityService.saveLoginWithoutPolicies(this.audity);
                                            this.messages = [CommonsText.WITHOUT_POLICIES];
                                            break;
                                        }
                                        default: {
                                            this.audityService.saveLoginIncorrectParameter(this.audity);
                                            this.messages = [CommonsText.PARAMETROS_INCORRECTOS];
                                            break;
                                        }
                                    }
                                })
                                
                            }).catch(error => {

                                this.running = false;
                                const error_description = error.error.error_description;

                                switch (error_description) {
                                    case 'DISABLED': {
                                        this.audityService.saveLoginDisabled(this.audity);
                                        this.messages = [CommonsText.DISABLED];
                                        break;
                                    }
                                    case 'BLOCKED': {
                                        this.audityService.saveLoginBlocked(this.audity);
                                        this.messages = [CommonsText.BLOCKED];
                                        break;
                                    }
                                    case 'BLOCKED_BY_RENEWAL': {
                                        this.audityService.saveLoginBlockedByRenewal(this.audity);
                                        this.messages = [CommonsText.BLOCKED_BY_RENEWAL];
                                        break;
                                    }
                                    default: {
                                        this.audityService.saveLoginIncorrectParameter(this.audity);
                                        this.messages = [CommonsText.PARAMETROS_INCORRECTOS];
                                        break;
                                    }
                                }

                            })
                    }


                }).catch(error => {
                    this.audity.audityDocument = this.confirmRegisterForm.value.email;
                    this.audity.audityIp = this.ipAddress;
                    this.audityService.saveUserErrorRegister(this.audity);
                    this.running = false;
                    this.messages = error.error.response;
                    if (undefined === error.error.response) {
                        this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }
                })
        }

    }

    submitEnabled() {
        return this.confirmRegisterForm.valid && (!this.running);
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.CONFIRMAR;
    }

}
