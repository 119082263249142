<div class="preference no-text-select">
    <div class="preference-title">
        <p>Mis preferencias</p>
    </div>
    <div class="preference-form">
        <div class="preference-form-insurance">
            <p>Publicidad de seguros:</p>
            <span ngClass="{{data?.insuranceAdvertising ? 'unchecked' : 'checked' }}">No estoy de acuerdo en que, previa evaluación de mis cualidades, se me ofrezca publicidad de seguros.</span>
        </div>
        <div class="preference-form-service">
            <p>Publicidad de Servicios Legales:</p>
            <span ngClass="{{data?.legalServicesAdvertising ? 'checked' : 'unchecked' }}">Acepto que onLygal Seguros ceda mis datos a la empresa del Grupo, Onlygal Servicios Legales, S.L., para que esta me remita información publicitaria referida a servicios legales.</span>
        </div>
    </div>
    <div class="preference-modify">
        <a [routerLink]="[ '/modify/preferences']">Modificar datos</a>
    </div>
</div>