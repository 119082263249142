import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonsText } from 'app/commons/CommonsText';
import { DasLexService } from 'app/services/daslex-service';
var ProductServiceFilterComponent = /** @class */ (function () {
    function ProductServiceFilterComponent(dasLexService, formBuilder) {
        this.dasLexService = dasLexService;
        this.formBuilder = formBuilder;
        this.statusTypeList = [];
        this.serviceList = [];
        this.contractList = [];
        this.statusTypeSettings = {};
        this.serviceSettings = {};
        this.contractSettings = {};
        this.loading = false;
    }
    Object.defineProperty(ProductServiceFilterComponent.prototype, "response", {
        set: function (value) {
            if (value) {
                this.statusTypeList = value.statusType;
                this.serviceList = value.product;
                this.contractList = value.policyItems;
            }
        },
        enumerable: true,
        configurable: true
    });
    ProductServiceFilterComponent.prototype.ngOnInit = function () {
        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.serviceSettings = {
            singleSelection: false,
            text: 'Todos los servicios',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.contractSettings = {
            singleSelection: false,
            text: 'Todos los contratos',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.filterForm = this.formBuilder.group({
            startDate: ['', []],
            endDate: ['', []],
            statusType: [[], []],
            service: [[], []],
            contractItems: [[], []]
        });
    };
    ProductServiceFilterComponent.prototype.getButtonLabel = function () {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    };
    ProductServiceFilterComponent.prototype.onFilter = function () {
        this.dasLexService.setPageFilter(this.filterForm.value);
    };
    return ProductServiceFilterComponent;
}());
export { ProductServiceFilterComponent };
