import { Component, Input } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-product-hire-service',
    templateUrl: './product-hire-service.component.html',
    styleUrls: ['./product-hire-service.component.scss']
})
export class ProductHireServiceComponent {

    @Input() data;
    close: Boolean = false;

    constructor(private policyService: PolicyService, private router: Router, private route: ActivatedRoute) { }

    getIconColor(): any {
        if (undefined !== this.data) {
            return this.data.suspended ? 'red' : 'blue'
        }
    }

    getStatusColor(): any {
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? 'red' : 'black'
        }
        return {
            'color': color
        }
    }

    getBackgroundColor() {
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? 'red' : 'blue'
        }
        return 'background-' + color
    }

    openExternalLink() {
        window.open(this.data.link, '_blank');
    }
}
