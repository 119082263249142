import { Component, Input } from '@angular/core';
import { DomainService } from '../../../services/domain-service';

@Component({
    selector: 'app-icon-type',
    templateUrl: './icon-type.component.html',
    styleUrls: ['./icon-type.component.scss']
})
export class IconTypeComponent {

    @Input() icon: String;
    @Input() color: String;

    constructor(private domainService: DomainService) { }

    getStyle(): any {
        const svgUrl = this.domainService.wordpressUrl +
            '/wp-content/themes/frontend/resources/assets/images/branch/' + this.icon + '-' + this.color + '.svg'
        return {
            'background-image': 'url(' + svgUrl + ')',
            'background-size': 'contain',
            'background-repeat': 'no-repeat'
        }
    }

}
