<div class="container sbs inner-transparent" [ngClass]="(emailSend) ? 'back-full' : ''">
    <div class="box-transparent" [routerLink]="['/']">
    </div>
    <section class="side-left" *ngIf="!emailSend">
        <app-forgot-password-form (onForgotPasswordSubmit)="onForgotPasswordSubmit($event)">
        </app-forgot-password-form>
    </section>

    <section class="side-right" *ngIf="!emailSend">
        <app-picture-with-text></app-picture-with-text>
    </section>

    <div class="center" *ngIf="emailSend">
        <app-email-notify></app-email-notify>
    </div>

</div>