<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Partes"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="body">
    <div class="container">
        <div class="body-card">
            <div>
                <div class="card-position" *ngIf="openEvents.length > 0">
                    <app-carousel type="OPEN_EVENT" titleText="Partes abiertos" [items]="openEvents" [loading]=loadingOpenEvents>
                    </app-carousel>
                </div>
                <app-event-assistance-image *ngIf="openEvents.length === 0"></app-event-assistance-image>
            </div>
            <div>
                <app-event-links></app-event-links>
            </div>
        </div>     
        <!--<div class="wow fadeInUp" data-wow-duration="1s">
            <app-wp-footer-integration></app-wp-footer-integration>
        </div>-->
    </div>

</div>



<app-blog-container></app-blog-container>

<app-footer></app-footer>