<div class="date-picker">

    <div class="header">
        <button type="button" class="btnPrevious" (click)="onPreviousMonth()">
            <p>&#x221f;</p></button>
        <button type="button" class="btnMonth" (click)="onShowMonth()">{{ monthName() }}</button>
        <button type="button" class="btnYear" (click)="onShowYear()">{{ year }}</button>
        <button type="button" class="btnNext" (click)="onNextMonth()"><p>&#x221f;</p></button>
    </div>

    <div class="monthSelect" *ngIf="showingMonth">
        <div>
            <span class="month" (click)="onSetMonth(i)" *ngFor="let item of months; let i = index ;">
                <p>
                    {{ item }}
                </p>
            </span>
        </div>
    </div>

    <div class="yearsSelect" *ngIf="showingYear">
        <div>
            <span class="year" (click)="onSetYear(item)" *ngFor="let item of years">{{ item }}</span>
        </div>
    </div>

    <div class="calendar" *ngIf="!showingMonth && !showingYear">

        <div class="daysWeek">
            <span>L</span><span>M</span><span>X</span><span>J</span><span>V</span><span>S</span><span>D</span>
        </div>

        <div class="daysCalendar">
            <span class="day" (click)="onSetDate(item)" [ngClass]="{ 'circle': (item.day === currentDay) && (item.month === currentMonth) && (item.year === currentYear),
                         'mark' : (item.month !== month) }" *ngFor="let item of calendarDays"><p>
                             {{ item.day }}
                         </p>
            </span>
        </div>
    </div>

    <div class="footer">
        <button type="button" (click)="onSetNow()">Hoy</button>
    </div>

</div>