<div class="container sbs" [ngClass]="(confirmed) ? 'back-full' : ''">

    <section class="side-left" *ngIf="!confirmed">
        <app-confirm-register-form (onConfirmRegisterSubmit)="onConfirmRegisterSubmit($event)"></app-confirm-register-form>
    </section>

    <section class="side-right" *ngIf="!confirmed">
        <app-picture-with-text [isReinsured] = "reinsured"></app-picture-with-text>
    </section>

</div>