import { ComponentFactoryResolver } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ReceiptParamDTO } from 'app/header-footer-template/model/ReceiptParamDTO';
import { Subject } from 'rxjs/Subject';
import { AuthService } from 'app/security/auth.service';
import { PaymentFormComponent } from 'app/payment/payment-form/payment-form.component';
import { DomainService } from './domain-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "./domain-service";
import * as i4 from "ngx-toastr";
var ReceiptService = /** @class */ (function () {
    function ReceiptService(http, authService, domainService, factoryResolver, toastr) {
        this.http = http;
        this.authService = authService;
        this.domainService = domainService;
        this.factoryResolver = factoryResolver;
        this.toastr = toastr;
        this.oldParams = new ReceiptParamDTO();
    }
    ReceiptService.prototype.setPageSize = function (value) {
        this.pageSizeChange.next(value);
    };
    ReceiptService.prototype.setPageOffset = function (value) {
        this.pageOffsetChange.next(value);
    };
    ReceiptService.prototype.setPageFilter = function (value) {
        this.pageFilterChange.next(value);
    };
    ReceiptService.prototype.observerPageSizeChange = function () {
        this.pageSizeChange = new Subject();
        return this.pageSizeChange.asObservable();
    };
    ReceiptService.prototype.observerPageOffset = function () {
        this.pageOffsetChange = new Subject();
        return this.pageOffsetChange.asObservable();
    };
    ReceiptService.prototype.observerPageFilter = function () {
        this.pageFilterChange = new Subject();
        return this.pageFilterChange.asObservable();
    };
    ReceiptService.prototype.observerPaymentLoading = function () {
        this.loadingPaymentChange = new Subject();
        return this.loadingPaymentChange.asObservable();
    };
    ReceiptService.prototype.getPending = function () {
        var url = this.domainService.backendUrlWithContext + '/receipts/pending/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    ReceiptService.prototype.haveParameterChanges = function (params) {
        if ((undefined !== this.oldParams.statusType &&
            undefined !== params.statusType &&
            this.oldParams.statusType.length !== params.statusType.length) ||
            (this.oldParams.policyCollective !== params.policyCollective) ||
            (this.oldParams.policyNumber !== params.policyNumber) ||
            (this.oldParams.endDate !== params.endDate) ||
            (this.oldParams.startDate !== params.startDate) ||
            (undefined !== this.oldParams.product &&
                undefined !== params.product &&
                this.oldParams.product.length !== params.product.length)) {
            this.oldParams.statusType = Object.assign([], params.statusType);
            this.oldParams.policyItems = Object.assign([], params.policyItems);
            this.oldParams.policyCollective = params.policyCollective;
            this.oldParams.policyNumber = params.policyNumber;
            this.oldParams.product = Object.assign([], params.product);
            this.oldParams.endDate = params.endDate;
            this.oldParams.startDate = params.startDate;
            return true;
        }
        return false;
    };
    ReceiptService.prototype.search = function (params) {
        var httpParams = new HttpParams();
        if (undefined !== params.pageOffset) {
            if (!this.haveParameterChanges(params)) {
                httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
            }
            else {
                httpParams = httpParams.append('pageOffset', '0');
            }
        }
        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }
        if ((undefined !== params.startDate) && (null !== params.startDate) && ('' !== params.startDate)) {
            httpParams = httpParams.append('startDate', params.startDate);
        }
        if ((undefined !== params.endDate) && (null !== params.endDate) && ('' !== params.endDate)) {
            httpParams = httpParams.append('endDate', params.endDate);
        }
        if ((undefined !== params.policyNumber) && (null !== params.policyNumber) && ('' !== params.policyNumber.trim())) {
            httpParams = httpParams.append('policyNumber', params.policyNumber);
        }
        if ((undefined !== params.policyCollective) && (null !== params.policyCollective) && ('' !== params.policyCollective.trim())) {
            httpParams = httpParams.append('policyCollective', params.policyCollective);
        }
        if (undefined !== params.statusType) {
            for (var i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }
        if (undefined !== params.product) {
            for (var i = 0; i < params.product.length; i++) {
                httpParams = httpParams.append('product', params.product[i].id);
            }
        }
        if (undefined !== params.policyItems) {
            for (var i = 0; i < params.policyItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.policyItems[i].id);
            }
        }
        var url = this.domainService.backendUrlWithContext + '/receipts/search/' + this.authService.getSelectedEntity();
        return this.http.get(url, { params: httpParams }).toPromise();
    };
    ReceiptService.prototype.payment = function (data) {
        var _this = this;
        this.loadingPaymentChange.next(true);
        var url = this.domainService.backendUrlWithContext + '/receipts/payment/' + data.receiptNumber;
        this.http.get(url).toPromise().then(function (result) {
            var factory = _this.factoryResolver.resolveComponentFactory(PaymentFormComponent);
            var component = factory.create(_this.rootViewContainer.parentInjector);
            component.instance.setParameter(result.response);
            _this.rootViewContainer.insert(component.hostView);
        }).catch(function (error) {
            _this.toastr.error(CommonsText.ERROR_DE_COMUNICACION_SERVICIO_PAGOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
    };
    ReceiptService.prototype.getReceiptByNumber = function (receiptYear, receiptNumber) {
        var url = this.domainService.backendUrlWithContext + '/receipts/receipt/' + receiptYear + '/' + receiptNumber;
        return this.http.get(url).toPromise();
    };
    ReceiptService.prototype.setRootViewContainerRef = function (viewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    };
    ReceiptService.prototype.onPayWithAccountNumber = function (accountNumber, receiptYear, receiptNumber) {
        var form = new FormData();
        if ((undefined !== accountNumber) && (null !== accountNumber)) {
            form.append('accountNumber', accountNumber);
        }
        var url = this.domainService.backendUrlWithContext + '/receipts/payWithAccountNumber/' + receiptYear + '/' + receiptNumber;
        return this.http.post(url, form).toPromise();
    };
    ReceiptService.prototype.onPayWithNewAccount = function (receiptNumber, receiptYear, data, upload) {
        var url = this.domainService.backendUrlWithContext + '/receipts/payWithNewAccount/' + receiptYear + '/' + receiptNumber;
        var form = new FormData();
        form.append('iban', data.iban);
        form.append('office', data.office);
        form.append('titular', data.titular);
        form.append('entity', data.entity);
        form.append('documentType', data.documentType[0].id);
        form.append('documentNumber', data.documentNumber);
        form.append('digit', data.digit);
        form.append('accountNumber', data.accountNumber);
        if (undefined !== upload) {
            var file = upload.get('file');
            if (null !== file) {
                form.append('file', file);
            }
        }
        var req = new HttpRequest('POST', url, form, { reportProgress: true });
        return this.http.request(req);
    };
    ReceiptService.ngInjectableDef = i0.defineInjectable({ factory: function ReceiptService_Factory() { return new ReceiptService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.DomainService), i0.inject(i0.ComponentFactoryResolver), i0.inject(i4.ToastrService)); }, token: ReceiptService, providedIn: "root" });
    return ReceiptService;
}());
export { ReceiptService };
