import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-event-detail-page',
    templateUrl: './event-detail-page.component.html',
    styleUrls: ['./event-detail-page.component.scss']
})
export class EventDetailPageComponent implements OnInit {

    event: any = undefined;
    eventNumber: any;
    eventYear: any;
    loadingEvent: Boolean = true;

    constructor(private route: ActivatedRoute, 
        private eventService: EventService, 
        private toastr: ToastrService) { }

    ngOnInit() {
        this.eventYear = this.route.snapshot.params['eventYear'];
        this.eventNumber = this.route.snapshot.params['eventNumber'];

        this.eventService.getEventByNumber(this.eventNumber)
            .then(success => {
                this.loadingEvent = false;
                if(success.response.error === 'invalid.xml'){
                    this.toastr.error(CommonsText.PROBLEMA_DATOS_PARTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                        timeOut: 5000
                    });
                } else {
                    this.event = success.response;
                }
                
            }).catch(error => {
                this.loadingEvent = false;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

}
