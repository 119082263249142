import { Component, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';
import { CommonsText } from 'app/commons/CommonsText';


@Component({
  selector: 'app-inicio-club-propietario',
  templateUrl: './inicio-club-propietario.component.html',
  styleUrls: ['./inicio-club-propietario.component.scss']
})
export class InicioClubPropietarioComponent implements AfterViewInit {

  categoriasInicio: CategoriaDataDTO[] = undefined;
  @Output() refrescarHomeDos = new EventEmitter();
  
  loadingProfile: Boolean = false;
  lstNoticiasDestacadas: NoticiaDataDTO[] = [];
  lstCategoriaDestacados: CategoriaDataDTO[];
  showCategoria: boolean = false;
  
  constructor(  private clientService: ClientService, 
    private toastr: ToastrService,
    private clubPropietarioService: ClubPropietarioService) { }

  ngOnInit() {
    if(this.categoriasInicio === undefined){     
        this.categoriasInicio = this.clubPropietarioService.getCategoriasCompletas();  
        this.categoriasInicio.forEach((categoria, index) => {
        categoria.noticasListDestacadas.forEach(noticia => {
          this.lstNoticiasDestacadas.push(noticia);
        });
        if(index + 1 === this.categoriasInicio.length){
          this.showCategoria = true;
        }
      });
    }
  }

  ngAfterViewInit() {
       this.clientService.getBannersInfo()
        .then(success => {
          this.loadingProfile = false;
        }).catch(error => {
            this.loadingProfile = false;
            this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
  }

  refrescarHome(idCat:string){
    this.refrescarHomeDos.emit(idCat);
  }

}
