<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Mis Productos"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class=" container">
    <div class="no-text-select products-container">

        <div *ngIf="undefined === response" class="pre-loading" style="padding-bottom: 5rem;">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div [hidden]="undefined === response" class="collapsable-products">

            <app-collapse-container caption="Mis Seguros" [checked]="showProducts">
                <app-product-filter [loading]="loading" [response]="response"></app-product-filter>

                <div class="pagination wow fadeInUp" data-wow-duration="1s" *ngIf="response?.totalElements > 1">
                    <app-product-pagination [loading]="loading" [response]="response">
                    </app-product-pagination>
                </div>

                <div class="list wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length > 0">
                    <div *ngFor="let item of response?.content">
                        <app-product-item [data]=item></app-product-item>
                    </div>
                </div>

                <div class="pagination-bottom wow fadeInUp" data-wow-duration="1s" *ngIf="response?.totalElements > 1">
                    <app-product-pagination [loading]="loading" [response]="response">
                    </app-product-pagination>
                </div>

                <div class="no-results wow fadeInUp" data-wow-duration="1s" *ngIf="response?.content?.length === 0">
                    No hay seguros disponibles.
                </div>

            </app-collapse-container>

        </div>
    <!--<div *ngIf="showDirectAreas && !loadingLegaliboo && legalibooConfig">-->
        <div *ngIf="showDirectAreas && !loadingLegaliboo">

            <app-collapse-container caption="Documentos legales" [checked]="showDocuments">
                <app-product-legaliboo [legalibooConfig]="legalibooConfig"></app-product-legaliboo>
            </app-collapse-container>

        </div>

        <div *ngIf="responseDasLex && responseDasLex.content && responseDasLex.content.length > 0 
            || (paramsDasLex.statusType || paramsDasLex.service || paramsDasLex.contractItems)">

            <app-collapse-product-container caption="Mis Servicios" [checked]="showServices">

                <app-product-service-filter [loading]="loadingDasLex" [response]="responseDasLex">
                </app-product-service-filter>

                <div class="pagination">
                    <app-product-service-pagination [loading]="loadingDasLex" [response]="responseDasLex">
                    </app-product-service-pagination>
                </div>

                <div class="list">
                    <div *ngFor="let item of responseDasLex?.content">
                        <app-product-service [showDetailsButton]="true" [data]=item></app-product-service>
                    </div>
                </div>

                <div class="pagination-bottom-daslex">
                    <app-product-service-pagination [loading]="loadingDasLex" [response]="responseDasLex">
                    </app-product-service-pagination>
                </div>

                <div class="onlygal-info">
                    <p>Gestionado por:</p>
                    <p>&nbsp;</p>
                    <p><span>Accede aquí a tu <a target="_blank" [href]="urlOnlygal" (click)="serviceAccess()">Área Privada</a></span></p>
                    <p><span>para gestionar y contratar tus servicios</span></p>
                </div>
            </app-collapse-product-container>
        </div>

        <div *ngIf="loadFandid && user !== undefined && user.warranty">
            <app-collapse-container caption="Ayudas y subvenciones">
                <app-product-fandit></app-product-fandit>
            </app-collapse-container>
        </div>

    </div>

    <!--<div class="wow fadeInUp" data-wow-duration="1s">
        <app-wp-footer-integration></app-wp-footer-integration>
    </div>-->
</div>

<app-footer></app-footer>