<div class="last-receipt">
    <div class="last-receipt-main">

        <div class="last-receipt-main-img">
            <app-icon-type [icon]="data.branchType" color="{{ data.receiptStatusColor }}"></app-icon-type>
            <p>Estado: <span>{{ data.receiptStatus }}</span></p>
        </div>

        <div class="last-receipt-main-side">
            <div class="last-receipt-main-side-izq">
                <div class="inner-a">
                    <div class="policy row">
                        <p>Nº de recibo: &nbsp;</p>
                        <span>{{data.receiptNumber}}</span>
                        <span style="color:gray; font-size: 13px;" *ngIf="'E' === data.receiptType && !data.docCobroNumber"> (Extorno) </span>
                    </div>
                    <div class="effect row">
                        <p>Vigencia: &nbsp;</p>
                        <span>{{ data.receiptStartDate | date: 'dd/MM/yyyy' }} - {{data.receiptEndDate | date: 'dd/MM/yyyy' }}</span>
                    </div>
                    <div class="infDoc" *ngIf="data.docCobroNumber">
                        <span class="docCobro">Documento de Cobro</span>
                    </div>
                </div>
                <div class="inner-b">
                    <div class="import row">
                        <p>Importe: &nbsp;</p>
                        <span>{{data.receiptAmount | currency:'EUR' }}</span>
                    </div>
                    <div class="payment row">
                        <p>Tipo de cobro: &nbsp;</p>
                        <span>{{data.receiptPaymentMethod}}</span>
                    </div>
                </div>
            </div>
            <div class="last-receipt-main-side-der">
                <div class="last-receipt-main-side-der-button">
                    <app-split-button *ngIf="'red' === data.receiptStatusColor && 'E' !== data.receiptType || data.docCobroNumber && 'red' === data.receiptStatusColor" label="PAGAR">
                        <app-split-button-item label="Pagar con tarjeta de crédito" (click)="pay()"></app-split-button-item>
                        <app-split-button-item label="Pagar con otros medios de pago" route="/receipt/nw/{{data.receiptNumber}}"></app-split-button-item>
                    </app-split-button>
                </div>
            </div>

        </div>

    </div>
</div>