import { Component, AfterViewInit } from '@angular/core';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-profile-page',
    templateUrl: './profile-page.component.html',
    styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements AfterViewInit {

    loadingProfile: Boolean = false;
    loadingPayment: Boolean = false;
    profileResponse: any;
    paymentResponse: any;
    showDirectAreas: Boolean = true;
    showInsuredAreas: boolean = true;

    constructor(private clientService: ClientService, private toastr: ToastrService, private authService: AuthService) { }

    ngOnInit() {
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
            this.authService.getRelation() === 'TOMA' ? this.showInsuredAreas = true : this.showInsuredAreas = false;
        }
    }
    ngAfterViewInit() {
        this.loadingProfile = true;
        this.loadingPayment = true;

        this.clientService.getClient()
            .then(success => {
                this.loadingProfile = false;
                this.profileResponse = success;
            }).catch(error => {
                this.loadingProfile = false;
                this.profileResponse = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

        this.clientService.getPayment()
            .then(success => {
                this.loadingPayment = false;
                this.paymentResponse = success.response;
            }).catch(error => {
                this.loadingPayment = false;
                this.paymentResponse = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

}
