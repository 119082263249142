import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-coverage',
  templateUrl: './product-coverage.component.html',
  styleUrls: ['./product-coverage.component.scss']
})
export class ProductCoverageComponent implements OnInit {


  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
