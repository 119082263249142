/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-policy-receipts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../spinner-button/spinner-button.component.ngfactory";
import * as i3 from "../spinner-button/spinner-button.component";
import * as i4 from "@angular/common";
import * as i5 from "./change-policy-receipts.component";
import * as i6 from "../../../services/client-service";
var styles_ChangePolicyReceiptsComponent = [i0.styles];
var RenderType_ChangePolicyReceiptsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangePolicyReceiptsComponent, data: {} });
export { RenderType_ChangePolicyReceiptsComponent as RenderType_ChangePolicyReceiptsComponent };
function View_ChangePolicyReceiptsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "loading-container no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "loading-container-cont fadeInUp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "pre-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [["class", "cross"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2716"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "p", [["class", "loading bottom-space"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A1Recuerda!"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "p", [["class", "loading sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Al modificar la cuenta bancaria asociada a la p\u00F3liza, se ha modificado tambi\u00E9n la cuenta bancaria seleccionada asociada a cada uno de los recibos asociados a dicha p\u00F3liza que est\u00E9n pendientes de pago."])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "button-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "div", [["class", "button-place"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "button-place"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-spinner-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SpinnerButtonComponent_0, i2.RenderType_SpinnerButtonComponent)), i1.ɵdid(20, 49152, null, 0, i3.SpinnerButtonComponent, [], { enabled: [0, "enabled"], label: [1, "label"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "button-place"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = true; var currVal_1 = "Aceptar"; _ck(_v, 20, 0, currVal_0, currVal_1); }, null); }
export function View_ChangePolicyReceiptsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangePolicyReceiptsComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChangePolicyReceiptsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "change-policy-receipts", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChangePolicyReceiptsComponent_0, RenderType_ChangePolicyReceiptsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChangePolicyReceiptsComponent, [i6.ClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangePolicyReceiptsComponentNgFactory = i1.ɵccf("change-policy-receipts", i5.ChangePolicyReceiptsComponent, View_ChangePolicyReceiptsComponent_Host_0, {}, {}, []);
export { ChangePolicyReceiptsComponentNgFactory as ChangePolicyReceiptsComponentNgFactory };
