import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EventService } from 'app/services/event-service';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';
var EventFilterComponent = /** @class */ (function () {
    function EventFilterComponent(eventService, formBuilder, authService) {
        this.eventService = eventService;
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.showDirectAreas = true;
        this.statusTypeList = [];
        this.productList = [];
        this.statusTypeSettings = {};
        this.productSettings = {};
        this.policySettings = {};
        this.eventSettings = {};
        this.policyList = [];
        this.eventList = [];
        this.loading = false;
    }
    Object.defineProperty(EventFilterComponent.prototype, "response", {
        set: function (value) {
            if (undefined !== value) {
                this.statusTypeList = value.statusType;
                //Solo lo carga para directo, no para reaseguro
                if (this.showDirectAreas == true) {
                    this.productList = value.product;
                }
                this.policyList = value.policyItems;
                this.eventList = value.eventItems;
            }
        },
        enumerable: true,
        configurable: true
    });
    EventFilterComponent.prototype.ngOnInit = function () {
        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.productSettings = {
            singleSelection: false,
            text: 'Todos los seguros',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.policySettings = {
            singleSelection: false,
            text: 'Todas las pólizas',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.eventSettings = {
            singleSelection: false,
            text: 'Todos los partes',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.filterForm = this.formBuilder.group({
            startDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            endDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            statusType: [[], []],
            policyNumber: [this.policyNumber, []],
            product: [[], []],
            policyItems: [[], []],
            eventItems: [[], []]
        });
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }
    };
    EventFilterComponent.prototype.getButtonLabel = function () {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    };
    EventFilterComponent.prototype.onFilter = function () {
        this.eventService.setPageFilter(this.filterForm.value);
    };
    EventFilterComponent.prototype.comprobarDesde = function (event) {
        if (event !== "" && this.filterForm.value.endDate !== "") {
            var dateParts1 = event.split("-");
            var date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
            var dateParts2 = this.filterForm.value.endDate.split("-");
            var date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
            if (date1.getTime() > date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors({ ErrorStartDay: true });
            }
            else if (date1.getTime() < date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors(null);
            }
            else if (date1.getTime() == date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors(null);
            }
        }
    };
    EventFilterComponent.prototype.comprobarHasta = function (event) {
        if (event !== "" && this.filterForm.value.startDate !== "") {
            var dateParts1 = event.split("-");
            var date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
            var dateParts2 = this.filterForm.value.startDate.split("-");
            var date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
            if (date1.getTime() < date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors({ ErrorEndDay: true });
            }
            else if (date1.getTime() > date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors(null);
            }
            else if (date1.getTime() == date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors(null);
            }
        }
    };
    return EventFilterComponent;
}());
export { EventFilterComponent };
