import { Component, Input, ViewContainerRef, TemplateRef, Directive } from '@angular/core';

import { trigger, transition, useAnimation, state, style, animate } from '@angular/animations';

@Directive({
    selector: '[valueChangeDetector]'
})
export class ValueChangeDetectorDirective {

    private currentValue: any;
    private hasView = false;

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>
    ) { }

    @Input() set valueChangeDetector(val: any) {
        if (!this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (val !== this.currentValue) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.currentValue = val;
        }
    }
}

@Component({
    selector: 'app-welcome-text',
    templateUrl: './welcome-text.component.html',
    styleUrls: ['./welcome-text.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition('void => *', [style({ opacity: 0, transform: 'translateY(20%)' }), animate(450)]),
            transition('* => void', [animate('0.1s ease-in')])
        ])
    ]
})
export class WelcomeTextComponent {

    text = undefined;

    constructor() {
        this.ticToc();
        setInterval(() => {
            this.ticToc();
        }, 1000)
    }

    private ticToc() {
        const hour = new Date().getHours();
        if ((hour >= 0) && (hour < 14)) {
            this.text = '¡Buenos días!';
        } else if ((hour >= 14) && (hour < 20)) {
            this.text = '¡Buenas tardes!';
        } else if ((hour >= 20) && (hour <= 23)) {
            this.text = '¡Buenas noches!';
        }
    }

}
