<div class="container-taker-block no-text-select" [@fadeInUp]>
    <div class="title">
        <p>¡Hola!</p>
    </div>
    <div class="content">
        <p>Eres tomador de un seguro colectivo por eso, para poder acceder al Área Cliente, necesitamos que nos
            envíes a <a
            href="mailto:atencion.cliente@onlygal.es">atencion.cliente@onlygal.es</a> la siguiente documentación:</p>

        <p><a href="https://www.onlygal.es/app/uploads/2024/02/Designa-de-Interlocutor-Area-Cliente-onLygal-Seguros.pdf">El
                siguiente formulario cumplimentado.</a></p>

        <ul>
            <li>Una copia del DNI del representante legal de la compañía.</li>
            <li>Una copia de los poderes que acrediten que actúa como representante legal.</li>
            <li>Una copia del DNI de la persona que tendrá acceso al Área Cliente.</li>
        </ul>

        <p>Tras validar la documentación la persona a la que se le ha asignado el acceso al Área Cliente recibirá un
            correo
            para registrarse. Si tienes alguna duda, contáctanos a <a
                href="mailto:atencion.cliente@onlygal.es">atencion.cliente@onlygal.es</a>. Recuerda que estamos aquí para
            ayudarte.</p>
        <p>Muchas gracias.</p>
        <p>Equipo onLygal Seguros</p>
    </div>
    <app-contact-line></app-contact-line>
</div>