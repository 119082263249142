<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Detalle de parte {{eventYear}}/{{eventNumber}}"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container">

    <div class="no-text-select body">

        <div *ngIf="undefined === event" class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div *ngIf="undefined !== event" class="detail">
            <div class="detail-sup">
                <div class="detail-sup-title">
                    <p>Estado actual</p>
                </div>
                <div class="detail-sup-balls">
                    <app-event-check-balls [data]="event"></app-event-check-balls>
                </div>
                <div class="detail-situation">
                    <div class="situation">
                        <div>
                            <app-event-data [data]="event"></app-event-data>
                        </div>
                        <div>
                            <app-event-warranty [data]="event"></app-event-warranty>
                        </div>
                        <div>
                            <app-event-expedient [data]="event"></app-event-expedient>
                        </div>
                        <div>
                            <app-servicios-relacionados-parte [data]="event"></app-servicios-relacionados-parte>
						</div>
                        <div>
                            <app-pagos-parte [data]="event"></app-pagos-parte>
                        </div>
						 <div>
                            <app-indemnizaciones-parte [data]="event"></app-indemnizaciones-parte>
                        </div>
                        <div>
                            <app-event-correspondence [data]="event"></app-event-correspondence>
                        </div>
                        <div id="attach">
                            <app-event-documentation [data]="event"></app-event-documentation>
                        </div>                        
                    </div>
                    <div class="situation-blue">
                        <div>
                            <app-event-messenger [data]="event" *ngIf="event.expedientEvent && event.expedientEvent.length > 0"></app-event-messenger>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<app-blog-container></app-blog-container>


<app-footer></app-footer>