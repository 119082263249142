import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgwWowModule } from 'ngx-wow';
import {
    ToastNoAnimationModule
} from 'ngx-toastr';


import { SecurityModule } from './security/security.module';
import { AppComponent } from './app.component';

import { SideBySideTemplateModule } from './side-by-side-template/side-by-side-template.module';
import { HeaderFooterTemplateModule } from './header-footer-template/header-footer-template.module';

import { JwtInterceptor } from '@auth0/angular-jwt';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CommonsModule } from './commons/commons.module';
import { PaymentFormComponent } from './payment/payment-form/payment-form.component';
import { PaymentLoadingComponent } from './payment/payment-loading/payment-loading.component';
import { CompleteProfileModalComponent } from './modal/complete-profile-modal/complete-profile-modal.component';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { MatInputModule,MatDatepickerModule, MatNativeDateModule,  } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AppRoutingModule } from './app-routing.module'
import { ClubPropietarioModule } from './modules/club-propietario/club-propietario.module';

export function jwtOptionsFactory() {
    return {
        tokenGetter: () => {
            return localStorage.getItem('token');
        },
        whitelistedDomains: ['localhost:8090', 'intclientes.onlygal.es',
            'preclientes.onlygal.es', 'precliente.onlygal.es', 'clientes.onlygal.es']
    }
}

@NgModule({

    declarations: [AppComponent, PaymentFormComponent, PaymentLoadingComponent, CompleteProfileModalComponent],
    imports: [

        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,

        NgwWowModule,

        SideBySideTemplateModule,
        HeaderFooterTemplateModule,
        CommonsModule,

        SecurityModule,

        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory
            }
        }),

        BrowserAnimationsModule,
        ToastNoAnimationModule.forRoot(),

        DeviceDetectorModule.forRoot(),
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        ClubPropietarioModule,
        AppRoutingModule

    ],

    providers: [
        JwtInterceptor
    ],

    bootstrap: [AppComponent],
    entryComponents: [PaymentFormComponent]



})
export class AppModule { }
