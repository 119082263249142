import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { AuthService } from 'app/security/auth.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de notificaciones
 * @author Iecisa
 */
export class NotificationService {

    parentRoute = '/notifications';

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private toastr: ToastrService,
        private domainService: DomainService) {
    }


    // MARK AS READ NOTIFICATIONS BY ID
    markNotificationAsRead(notificationIds): Promise<any> {
        const form = new FormData();
        form.append('agenNotIds', notificationIds);
        const url = this.domainService.backendUrlWithContext + this.parentRoute + '/read';
        return this.http.post(url, form).toPromise();
    }

    // MARK AS DELETE NOTIFICATIONS BY ID
    markNotificationAsDelete(notificationIds): Promise<any> {
        const form = new FormData();
        form.append('agenNotIds', notificationIds);
        const url = this.domainService.backendUrlWithContext + this.parentRoute + '/delete';
        return this.http.post(url, form).toPromise();
    }

    // GET NOTIFICATIONS
    getNotifications(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + this.parentRoute + '/notifications/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    }
}
