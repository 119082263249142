<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Mis contactos"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container" [@fadeInUp]>
    <div class="body">
        <app-callme-title></app-callme-title>
        <div class="body-main">
            <div class="body-main-select">
                <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"></angular2-multiselect>
                <span *ngIf="loadingMediators" class="spinner"></span>
            </div>

            <div class="body-main-data">


                <div class="inner-cont wow fadeInUp" data-wow-duration="1s" *ngIf="undefined === mediator">
                    <div *ngIf="!running" class="aux">Seleccione un seguro para ver los detalles de contacto de su mediador</div>
                    <div *ngIf="running" class="aux pre-loading" style="font-size: 18px;">
                        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
                    </div>
                </div>

                <div class="inner-cont  wow fadeInUp" data-wow-duration="1s" *ngIf="undefined !== mediator">

                    <div *ngIf="undefined !== mediator.picture" class="cont1">
                        <div class="img">
                            <img [src]="prepareImage(mediator.picture)" />
                        </div>
                    </div>

                    <div class="cont2">
                        <div class="title">
                            <p>Mi mediador</p>
                            <p>{{mediator.name}}</p>

                        </div>
                        <div class="contact">

                            <div class="movil" *ngIf="undefined != mediator.phone1">
                                <div class="img"></div>
                                <div class="text">
                                    <p><a href="tel:{{mediator.phone1}}">{{mediator.phone1}}</a></p>
                                </div>
                            </div>

                            <div class="tel" *ngIf="undefined != mediator.phone2">
                                <div class="img"></div>
                                <div class="text">
                                    <p>{{mediator.phone2}}</p>
                                </div>
                            </div>

                            <div class="email" *ngIf="undefined != mediator.email">
                                <div class="img"></div>
                                <div class="text">
                                    <p><a href="mailto:{{mediator.email}}">{{mediator.email}}</a></p>
                                </div>
                            </div>

                            <p></p>
                        </div>
                    </div>
                </div>

                <div class="inner-cont  wow fadeInUp" data-wow-duration="1s">
                    <!--<div class="cont5">
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p><a href="tel:+34900 828 020">900 828 020</a></p>
                            </div>
                        </div>
                    </div>
                     <div class="cont3">
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="cont4">
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                        <div class="sect">
                            <div class="tel">
                                <div class="img"></div>
                            </div>
                            <div class="text">
                                <p>Asistencia técnica Innova</p>
                                <p>93 445 55 79</p>
                            </div>
                        </div>
                    </div> -->
                </div>

            </div>

        </div>
    </div>

    <!--<div class="wow fadeInUp" data-wow-duration="1s">
        <app-wp-footer-integration></app-wp-footer-integration>
    </div>-->
    
    <app-contact-boxes></app-contact-boxes>

</div>

<app-footer></app-footer>