import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { DeydeService } from 'app/services/deyde-service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-register-third-step',
    templateUrl: './event-register-third-step.component.html',
    styleUrls: ['./event-register-third-step.component.scss']
})
export class EventRegisterThirdStepComponent implements OnInit, AfterViewInit {

    data: any;
    roadTypeSettings: any;
    actualPostalCode: any;
    loadingCities: Boolean = false;

    @Input() prefixData: any[];
    prefixSettings: any;
    patched: Boolean = false;
    thirdStepForm: FormGroup;

    @Input() set policy(data: any) {
        this.data = data;
    }

    citySettings: any;
    cityList: any = [];
    province: string;
    idprovince: string;

    constructor(
        private controlContainer: ControlContainer,
        private clientService: ClientService,
        private toastr: ToastrService,
        private deydeService: DeydeService) {

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

    }

    ngOnInit() {
        this.thirdStepForm = <FormGroup>this.controlContainer.control;

        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.searchCity();
    }

    ngAfterViewInit(): void {
        if (!this.patched) {
            if (undefined !== this.data) {
                this.patched = true;
                setTimeout( ()=>{
                    this.patchForm(this.data.contact);
                },500);
                
            }
        }
    }

    errorDescription(controlName: string) {
        const control = this.thirdStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    }

    patchForm(person: any) {
        if (undefined !== person) {
            this.province = this.parseValue(person.province);
            this.cityList.push({
                id: person.city, itemName: person.cityDescription, province: person.province
            });
        }
    }

    searchCity() {
        const postalCode = this.thirdStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(success => {
                    this.actualPostalCode = postalCode;

                    this.loadingCities = false;
                    this.cityList = [];
                    this.thirdStepForm.controls['city'].patchValue([]);

                    for (const city of success.response) {
                        this.cityList.push({
                            id: city.id, itemName: city.name, province: city.province, idprovince: city.idprovince
                        });
                    }

                    if (success.response.length === 1) {
                        const city = success.response[0];
                        this.thirdStepForm.patchValue({ city: [{ id: city.id, itemName: city.name, province: city.province }] });
                        this.thirdStepForm.patchValue({ province: city.province });
                        this.thirdStepForm.patchValue({ idprovince: city.idprovince });
                        this.idprovince = city.idprovince
                        this.province = city.province;
                    }

                }).catch(error => {
                    this.loadingCities = false;
                    this.toastr.error(CommonsText.ERROR_DE_OPERACION, CommonsText.ERROR_SERVICIO_DEYDE, {
                        timeOut: 5000
                    });
                })
        }
    }

    onChangePostalCode(data) {
        const v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    }

    onCitySelect(item: any) {
        this.thirdStepForm.patchValue({ province: item.province });
        this.province = item.province;
    }

    isInvalid(controlName: string): boolean {
        const control = this.thirdStepForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    }

    parseSelectValue(value) {
        if (null === value || undefined === value) {
            return [];
        } else {
            return value;
        }
    }

    parseValue(value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        } else {
            return value;
        }
    }

    onChangeEmail(data) {
        const v = data.target.value;
        const v2 = this.thirdStepForm.get("email").status;
        if(v2 === "VALID"){
            if(((v.length >= 6) && v.includes("@")) && v.includes(".")){
                this.deydeService.onCheckDeydeEmail(v)
                .then(success => {
                    if(success[0].normalizado === 'S'){
                        this.thirdStepForm.get("email").setErrors(null);
                    } else {
                        this.thirdStepForm.get("email").setErrors({'incorrect': true});
                    }
                }).catch(error => {
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    }); 
                })
            }
        }
    }

    onChangeMobile(data) {
        const v = data.target.value;
        const v2 = this.thirdStepForm.get("mobilePhone").status;
        if(v2 === "VALID"){
            if((v.length >= 9) && v.length <= 9){
                this.deydeService.onCheckDeydeMobile("+" + this.thirdStepForm.get("mobilePhonePrefix").value[0].id+ " " + v)
                .then(success => {
                    if(success[0].normalizado === 'S'){
                        this.thirdStepForm.get("mobilePhone").setErrors(null);
                    } else {
                        this.thirdStepForm.get("mobilePhone").setErrors({'incorrect': true});
                    }
                }).catch(error => {
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    }); 
                })
            }
        }
    }

    onChangePhone(data) {
        const v = data.target.value;
        const v2 = this.thirdStepForm.get("phone").status;
        if(v2 === "VALID"){
            if((v.length >= 9) && v.length <= 9){
                this.deydeService.onCheckDeydePhone("+" + this.thirdStepForm.get("phonePrefix").value[0].id+ " " + v)
                .then(success => {
                    if(success[0].normalizado === 'S'){
                        this.thirdStepForm.get("phone").setErrors(null);
                    } else {
                        this.thirdStepForm.get("phone").setErrors({'incorrect': true});
                    }
                }).catch(error => {
                    this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    }); 
                })
            }
        }
    }

}
