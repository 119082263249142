<div *ngIf="!loadingMessages && messages">
    <div class="list" #messagelist>
        <div *ngFor="let item of messages">
            <app-event-messenger-message #messagelist [message]="item" [data]="data"></app-event-messenger-message>
        </div>
    </div>
</div>

<div *ngIf="!loadingMessages && !messages">
    <div class="list" >
        <div class="no-messages">
            No hay mensajes
        </div>
    </div>
</div>

<div *ngIf="loadingMessages" >
    <div class="list">
        <div class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>
    </div>

</div>
