import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner-button',
    templateUrl: './spinner-button.component.html',
    styleUrls: ['./spinner-button.component.scss']
})
export class SpinnerButtonComponent {

    @Input() enabled: Boolean;
    @Input() label: String;
    @Input() running: Boolean;
    @Input() customSpinnerButton: string = '';

}
