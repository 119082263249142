import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { HeaderComponent } from './header/header.component';
import { HeaderNavigationBarComponent } from './header/header-navigation-bar/header-navigation-bar.component';
import { HeaderWpIntegrationComponent } from './header/header-wp-integration/header-wp-integration.component';
import { HeaderBreadcrumbComponent } from './header/header-breadcrumb/header-breadcrumb.component';
import { HeaderLastConectionComponent } from './header/header-last-conection/header-last-conection.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [HeaderComponent, HeaderNavigationBarComponent, HeaderWpIntegrationComponent, HeaderBreadcrumbComponent, HeaderLastConectionComponent, FooterComponent],
  imports: [
    CommonModule,
    CoreRoutingModule   
  ],
  exports: [
    HeaderComponent, 
    HeaderNavigationBarComponent,
    HeaderWpIntegrationComponent,
    HeaderBreadcrumbComponent,
    HeaderLastConectionComponent,
    FooterComponent
  ]
})
export class CoreModule { }
