<div *ngIf="show" class="loading-container no-text-select">
  <div class="loading-container-cont wow fadeInUp" data-wow-duration="1s">
    <div class="pre-loading">
      <p class="close">
        <b class="cross" (click)="closeModal()">&#10006;</b>
      </p>

      <p class="loading sub">Si no ve correctamente el formulario de acceso al Área Cliente, por favor, use otro navegador.</p>
      <p class="loading sub">Desde onLygal Seguros recomendamos el uso de versiones actualizadas de <a href="https://www.google.com/intl/es_es/chrome/"
          target="_blank">Chrome</a>, <a href="https://www.mozilla.org/es-ES/firefox/new/" target="_blank">Firefox</a>
        o <a href="https://www.microsoft.com/es-es/edge" target="_blank">Edge.</a>
      </p>

    </div>
  </div>
</div>