import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { DomainService } from './domain-service';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de servicios de Deyde
 * @author Inetum
 */
export class DeydeService {


    constructor(
        private http: HttpClient, 
        private domainService: DomainService) {
    }

    onCheckDeydeEmail(email: any){
        const url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        const form = new FormData();
    
        form.append('email', email);
    
        return this.http.post<any>(url, form).toPromise();
    }

    onCheckDeydePhone(phone: any){
        const url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        const form = new FormData();
    
        form.append('phone', phone);
    
        return this.http.post<any>(url, form).toPromise();
    }

    onCheckDeydeMobile(mobile: any){
        const url = this.domainService.backendUrlWithContext + '/deyde/validateDeyde/';
        const form = new FormData();
    
        form.append('mobile', mobile);
    
        return this.http.post<any>(url, form).toPromise();
    }

}