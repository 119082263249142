/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./check-browser-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./check-browser-modal.component";
import * as i4 from "ngx-device-detector";
var styles_CheckBrowserModalComponent = [i0.styles];
var RenderType_CheckBrowserModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckBrowserModalComponent, data: {} });
export { RenderType_CheckBrowserModalComponent as RenderType_CheckBrowserModalComponent };
function View_CheckBrowserModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "loading-container no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "loading-container-cont wow fadeInUp"], ["data-wow-duration", "1s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "pre-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [["class", "cross"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2716"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "loading sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Si no ve correctamente el formulario de acceso al \u00C1rea Cliente, por favor, use otro navegador."])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "p", [["class", "loading sub"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desde onLygal Seguros recomendamos el uso de versiones actualizadas de "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://www.google.com/intl/es_es/chrome/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Chrome"])), (_l()(), i1.ɵted(-1, null, [", "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["href", "https://www.mozilla.org/es-ES/firefox/new/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Firefox"])), (_l()(), i1.ɵted(-1, null, [" o "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["href", "https://www.microsoft.com/es-es/edge"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edge."]))], null, null); }
export function View_CheckBrowserModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckBrowserModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CheckBrowserModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-check-browser-modal", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CheckBrowserModalComponent_0, RenderType_CheckBrowserModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.CheckBrowserModalComponent, [i4.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckBrowserModalComponentNgFactory = i1.ɵccf("app-check-browser-modal", i3.CheckBrowserModalComponent, View_CheckBrowserModalComponent_Host_0, {}, {}, []);
export { CheckBrowserModalComponentNgFactory as CheckBrowserModalComponentNgFactory };
