<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Notificaciones"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container">
    <div class="notifications-container">
        <div class="notifications-container-no-notifications" *ngIf="!notifications || notifications.length == 0">
            <p>No tienes notificaciones pendientes de leer</p>
        </div>
        <div *ngIf="undefined === notifications" class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>

        <div class="notifications-container-collapse wow fadeInUp" data-wow-duration="1s" [hidden]="undefined === notifications">
            <div class="inner-ul">
                <div class="scroll-notification">
                    <div *ngFor="let item of notifications">
                        <div section role="main" class="wraper">
                            <div [ngClass]="item.isRead == 'S' ? '':'new-ball'" (click)="markAsRead(item)"> </div>
                            <div class="notificacion-maxw" (click)="markAsRead(item)">
                                <p class="notification-title">
                                    <b>{{ item.title }}</b>
                                </p>
                                <p class="notification-annotation">
                                    <b [innerHTML]="item.annotation"></b>
                                </p>
                            </div>
                    
                            <div class="notification-date" (click)="markAsRead(item)">
                                <p>{{item.creationDate}}</p>
                            </div>

                            <div class="notification-delete" (click)="delete(item)">
                            </div>
                        </div>

                        <div class="hr"></div>
                    </div>

                </div>
                <div class="notification-step-bottom" *ngIf="notifications && notifications.length > 0">
                    <div class="notification-step-bottom-left">
                        <app-spinner-button [label]="'Leer Todo'" [customSpinnerButton]="'config-button'"
                            [enabled]="true" (click)="readAll()" [running]="readingNotifications">
                        </app-spinner-button>
                    </div>
                    <div class="notification-step-bottom-right">
                        <app-spinner-button [label]="'Eliminar Todo'" [customSpinnerButton]="'config-button'"
                            [enabled]="true" (click)="deleteAll()" [running]="readingNotifications">
                        </app-spinner-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--<div class="wow fadeInUp" data-wow-duration="1s">
        <app-wp-footer-integration></app-wp-footer-integration>
    </div>-->
</div>

<app-footer></app-footer>