import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonsText } from 'app/commons/CommonsText';
import { PolicyService } from 'app/services/policy-service';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
var ProductFilterComponent = /** @class */ (function () {
    function ProductFilterComponent(policyService, formBuilder) {
        this.policyService = policyService;
        this.formBuilder = formBuilder;
        this.statusTypeList = [];
        this.productList = [];
        this.policyList = [];
        this.statusTypeSettings = {};
        this.productSettings = {};
        this.policySettings = {};
        this.loading = false;
    }
    Object.defineProperty(ProductFilterComponent.prototype, "response", {
        set: function (value) {
            if (value) {
                this.statusTypeList = value.statusType;
                this.productList = value.product;
                this.policyList = value.policyItems;
            }
        },
        enumerable: true,
        configurable: true
    });
    ProductFilterComponent.prototype.ngOnInit = function () {
        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.productSettings = {
            singleSelection: false,
            text: 'Todos los seguros',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.policySettings = {
            singleSelection: false,
            text: 'Todas las pólizas',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.filterForm = this.formBuilder.group({
            startDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            endDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            statusType: [[], []],
            policyNumber: ['', []],
            policyCollective: ['', []],
            product: [[], []],
            policyItems: [[], []]
        });
    };
    ProductFilterComponent.prototype.getButtonLabel = function () {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    };
    ProductFilterComponent.prototype.onFilter = function () {
        this.policyService.setPageFilter(this.filterForm.value);
    };
    ProductFilterComponent.prototype.comprobarDesde = function (event) {
        if (event !== "" && this.filterForm.value.endDate !== "") {
            var dateParts1 = event.split("-");
            var date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
            var dateParts2 = this.filterForm.value.endDate.split("-");
            var date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
            if (date1.getTime() > date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors({ ErrorStartDay: true });
            }
            else if (date1.getTime() < date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors(null);
            }
            else if (date1.getTime() == date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors(null);
            }
        }
    };
    ProductFilterComponent.prototype.comprobarHasta = function (event) {
        if (event !== "" && this.filterForm.value.startDate !== "") {
            var dateParts1 = event.split("-");
            var date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
            var dateParts2 = this.filterForm.value.startDate.split("-");
            var date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
            if (date1.getTime() < date2.getTime()) {
                this.filterForm.controls['endDate'].setErrors({ ErrorEndDay: true });
            }
            else if (date1.getTime() > date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors(null);
            }
            else if (date1.getTime() == date2.getTime()) {
                this.filterForm.controls['startDate'].setErrors(null);
            }
        }
    };
    return ProductFilterComponent;
}());
export { ProductFilterComponent };
