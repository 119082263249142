import { HttpClient } from '@angular/common/http';
import { DomainService } from './domain-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./domain-service";
var AudityService = /** @class */ (function () {
    function AudityService(http, domainService) {
        this.http = http;
        this.domainService = domainService;
    }
    AudityService.prototype.saveLogin = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLogin';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveRegister = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveRegister';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginBlockedByRenewal = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlockedByRenewal';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginBlocked = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlocked';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginDisabled = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginDisabled';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginBlockedByRgpd = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlockedByRgpd';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginWithoutPolicies = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginWithoutPolicies';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveLoginIncorrectParameter = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveLoginIncorrectParameter';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveUserErrorRegister = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveUserErrorRegister';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.prototype.saveFanditAcces = function (param) {
        var url = this.domainService.backendUrlWithContext + '/audity/saveFanditAcces';
        var form = new FormData();
        var headers = CommonsUtils.headersBearer();
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);
        return this.http.post(url, form, { headers: headers }).toPromise();
    };
    AudityService.ngInjectableDef = i0.defineInjectable({ factory: function AudityService_Factory() { return new AudityService(i0.inject(i1.HttpClient), i0.inject(i2.DomainService)); }, token: AudityService, providedIn: "root" });
    return AudityService;
}());
export { AudityService };
