<div class="insured">
    <div class="insured-container">

        <div class="insured-container-data">
            <div class="insured-container-data-left">
                <div class="type">
                    <label for="">Tipo: &nbsp;</label>
                    <p>Vehiculo</p>
                </div>
                <div class="domicile">
                    <label for="">Matrícula: &nbsp; </label>
                    <p>&nbsp; {{data.registration}}</p>
                </div>
                <div class="state">
                    <label for="">Categoria: &nbsp;</label>
                    <p>{{data.category}}</p>
                </div>

            </div>

            <div class="insured-container-data-rigth">
                <div class="start-cont">
                    <label for="">Fecha Matriculación: &nbsp;</label>
                    <p>{{data.registrationDate | date: 'dd/MM/yyyy' }}</p>
                </div>

                <div class="date">
                    <label for="">Clase: &nbsp;</label>
                    <p>{{data.clas}}</p>
                </div>
                <div class="date">
                    <label for="">Tipo: &nbsp;</label>
                    <p>{{data.type}}</p>
                </div>
            </div>
        </div>
    </div>
</div>