import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirm-register-page',
  templateUrl: './confirm-register-page.component.html',
  styleUrls: ['./confirm-register-page.component.scss']
})
export class ConfirmRegisterPageComponent {

  confirmed: Boolean = false;
  innominado: any;
  reinsured: any;
    
    constructor(private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
        if(params['nameless']){
            this.innominado = params['nameless'];
        } else {
            this.reinsured = params['reinsured'];
        }
    });
   }

    onConfirmRegisterSubmit(event) {
        this.confirmed = event;
    }

}
