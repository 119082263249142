/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-wp-integration/header-wp-integration.component.ngfactory";
import * as i3 from "./header-wp-integration/header-wp-integration.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./header-navigation-bar/header-navigation-bar.component.ngfactory";
import * as i7 from "./header-navigation-bar/header-navigation-bar.component";
import * as i8 from "../../commons/components/isMobile.service";
import * as i9 from "../../security/auth.service";
import * as i10 from "../../services/notification-service";
import * as i11 from "ngx-toastr";
import * as i12 from "./header.component";
import * as i13 from "../../services/wordpress-service";
import * as i14 from "../../services/client-service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-wp-integration", [], null, null, null, i2.View_HeaderWpIntegrationComponent_0, i2.RenderType_HeaderWpIntegrationComponent)), i1.ɵdid(1, 49152, null, 0, i3.HeaderWpIntegrationComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.integration; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEntityClass(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "header no-text-select"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "box-transparent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "header-menu"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵdid(11, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-header-navigation-bar", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_HeaderNavigationBarComponent_0, i6.RenderType_HeaderNavigationBarComponent)), i1.ɵdid(14, 114688, null, 0, i7.HeaderNavigationBarComponent, [i8.IsMobileService, i9.AuthService, i10.NotificationService, i11.ToastrService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "header-content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(21, 0, [["chat_div", 1]], null, 0, "div", [["id", "chat_div"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getHeaderBackground(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, "/main"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.getIntegrationBackground(); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.getIntegrationLinearBackground(); _ck(_v, 8, 0, currVal_3); var currVal_4 = ((_co.showDirectAreas == true) ? "header-menu-logo" : "header-menu-logo2"); _ck(_v, 11, 0, currVal_4); _ck(_v, 14, 0); var currVal_5 = ((undefined !== _co.integration) && _co.showDirectAreas); _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.isMobile; _ck(_v, 18, 0, currVal_6); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i12.HeaderComponent, [i13.WordpressService, i11.ToastrService, i8.IsMobileService, i9.AuthService, i14.ClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i12.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, ["*"]);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
