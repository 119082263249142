import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/security/auth.service';
import { ClientService } from 'app/services/client-service';
import { UserService } from '../../../services/user-service';

@Component({
    selector: 'app-entities-page',
    templateUrl: './entities-page.component.html',
    styleUrls: ['./entities-page.component.scss']
})
export class EntitiesPageComponent implements OnInit {

    authenticated: Boolean = false;
    showEntities: Boolean = false;

    constructor(private router: Router,
        private authService: AuthService,
        private clientService: ClientService,
        private userService: UserService) { }

        ngOnInit(): void {

            if (!this.authService.isLogged() || !this.authService.getUser().entities || this.authService.getUser().entities.length == 0) {
                this.router.navigate(['/authentication']);
            }
    
            if (this.authService.getUser() !== undefined && this.authService.getUser() !== null) {
    
                if (this.authService.getUser().entities.length > 1) {
                    this.showEntities = true;
                } else if (this.authService.getUser().entities.length = 1) {
    
                    if (this.authService.getUser().entities[0] !== undefined && this.authService.getUser().entities[0] !== null) {
    
                        this.authService.setSelectedEntity(this.authService.getUser().entities[0].key);
                        this.userService.onSaveSelectEntity(this.authService.getUser().entities[0].key);
                        this.router.navigate(['/main']);
    
                    }
                    
                } else {
                    this.authService.setSelectedEntity('ONLYGAL');
                    this.userService.onSaveSelectEntity('ONLYGAL');
                    this.router.navigate(['/main']);
                }
    
            }
        }
    }
    
    