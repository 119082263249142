import { Component, Input, OnInit } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { AuthService } from 'app/security/auth.service';

@Component({
    selector: 'app-product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit{

    showDirectAreas: Boolean = true

    @Input() data;
    close: Boolean = true;

    constructor(private policyService: PolicyService,
                private authService: AuthService) { }

    ngOnInit(){
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }
    }

    getIconColor(): any {
        if (undefined !== this.data) {
            return this.data.suspended ? 'red' : 'blue'
        }
    }

    getStatusColor(): any {
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? '#d22630' : 'black'
        }
        return {
            'color': color
        }
    }

    getBackgroundColor(){
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? 'red' : 'blue'
        }
        return 'background-' + color
    }

    onGetContractFile() {
        this.close = true;
        setTimeout(() => {
            this.close = undefined;
        }, 200);
        this.policyService.getContractFile(this.data.id);
    }

    greatherThan(){
        if (undefined !== this.data) {
            return this.data.number.split("/")[1] > 10000000;
        }
    }


}
