/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-messenger.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../event-messenger-history/event-messenger-history.component.ngfactory";
import * as i3 from "../event-messenger-history/event-messenger-history.component";
import * as i4 from "ngx-toastr";
import * as i5 from "../../../../../services/event-service";
import * as i6 from "../event-messenger-send/event-messenger-send.component.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "../event-messenger-send/event-messenger-send.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../collapse-container/collapse-container.component.ngfactory";
import * as i11 from "../../../collapse-container/collapse-container.component";
import * as i12 from "./event-messenger.component";
import * as i13 from "@angular/router";
var styles_EventMessengerComponent = [i0.styles];
var RenderType_EventMessengerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventMessengerComponent, data: {} });
export { RenderType_EventMessengerComponent as RenderType_EventMessengerComponent };
function View_EventMessengerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "messenger-main-cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-event-messenger-history", [["class", "send-box"]], null, null, null, i2.View_EventMessengerHistoryComponent_0, i2.RenderType_EventMessengerHistoryComponent)), i1.ɵdid(2, 245760, null, 0, i3.EventMessengerHistoryComponent, [i4.ToastrService, i5.EventService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventMessengerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "messenger-main-cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-event-messenger-send", [["class", "send-box"]], null, null, null, i6.View_EventMessengerSendComponent_0, i6.RenderType_EventMessengerSendComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(3, 114688, null, 0, i8.EventMessengerSendComponent, [i4.ToastrService, i5.EventService, i9.FormBuilder, i7.DatePipe], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EventMessengerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-collapse-container", [["caption", "Comunicaciones con el Tramitador"], ["subcaption", "Los mensajes dentro de un parte ser\u00E1n vistos por los responsables de su gesti\u00F3n: onLygal, mediador y/o colaboradores. Para ver todos los mensajes o seguir comunic\u00E1ndote con Onlygal en relaci\u00F3n a este parte haz click o toca la flecha de la derecha"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMessages() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CollapseContainerComponent_0, i10.RenderType_CollapseContainerComponent)), i1.ɵdid(1, 49152, null, 0, i11.CollapseContainerComponent, [], { checked: [0, "checked"], slimStyle: [1, "slimStyle"], caption: [2, "caption"], subcaption: [3, "subcaption"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EventMessengerComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EventMessengerComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showDocuments; var currVal_2 = true; var currVal_3 = "Comunicaciones con el Tramitador"; var currVal_4 = "Los mensajes dentro de un parte ser\u00E1n vistos por los responsables de su gesti\u00F3n: onLygal, mediador y/o colaboradores. Para ver todos los mensajes o seguir comunic\u00E1ndote con Onlygal en relaci\u00F3n a este parte haz click o toca la flecha de la derecha"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.showContent; _ck(_v, 3, 0, currVal_5); var currVal_6 = _co.showContent; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = "messenger"; _ck(_v, 0, 0, currVal_0); }); }
export function View_EventMessengerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-messenger", [], null, null, null, View_EventMessengerComponent_0, RenderType_EventMessengerComponent)), i1.ɵdid(1, 114688, null, 0, i12.EventMessengerComponent, [i7.ViewportScroller, i13.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventMessengerComponentNgFactory = i1.ɵccf("app-event-messenger", i12.EventMessengerComponent, View_EventMessengerComponent_Host_0, { data: "data", showDocuments: "showDocuments" }, {}, []);
export { EventMessengerComponentNgFactory as EventMessengerComponentNgFactory };
