<div class="assistance-image">
    <div class="assistance  wow fadeInUp" data-wow-duration="1s">
        <div class="assistance-title">
            <p>¿Necesitas ayuda?</p>
        </div>
        <div class="assistance-box">
            <a href="">
                <div class="img"></div>
            </a>
        </div>
    </div>
</div>