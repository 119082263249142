/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel-card-wp-integration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./carousel-card-wp-integration.component";
import * as i5 from "../../../../../services/domain-service";
var styles_CarouselCardWpIntegrationComponent = [i0.styles];
var RenderType_CarouselCardWpIntegrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselCardWpIntegrationComponent, data: {} });
export { RenderType_CarouselCardWpIntegrationComponent as RenderType_CarouselCardWpIntegrationComponent };
function View_CarouselCardWpIntegrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["SABER M\u00C1S"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resolveRouterLink(_co.data.link); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CarouselCardWpIntegrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "card-content no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselCardWpIntegrationComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyle(); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.showButton(); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_CarouselCardWpIntegrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-card-wp-integration", [], null, null, null, View_CarouselCardWpIntegrationComponent_0, RenderType_CarouselCardWpIntegrationComponent)), i1.ɵdid(1, 49152, null, 0, i4.CarouselCardWpIntegrationComponent, [i5.DomainService], null, null)], null, null); }
var CarouselCardWpIntegrationComponentNgFactory = i1.ɵccf("app-carousel-card-wp-integration", i4.CarouselCardWpIntegrationComponent, View_CarouselCardWpIntegrationComponent_Host_0, { data: "data" }, {}, []);
export { CarouselCardWpIntegrationComponentNgFactory as CarouselCardWpIntegrationComponentNgFactory };
