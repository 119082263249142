import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http'
import { ReceiptParamDTO } from 'app/header-footer-template/model/ReceiptParamDTO';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { AuthService } from 'app/security/auth.service';
import { PaymentFormComponent } from 'app/payment/payment-form/payment-form.component';
import { DomainService } from './domain-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Recibos
 * @author Iecisa
 */
export class ReceiptService {

    private pageSizeChange;
    private pageOffsetChange;
    private pageFilterChange;
    private rootViewContainer;
    private loadingPaymentChange;

    oldParams: ReceiptParamDTO = new ReceiptParamDTO();

    constructor(
        private http: HttpClient, private authService: AuthService, private domainService: DomainService,
        private factoryResolver: ComponentFactoryResolver, private toastr: ToastrService) {
    }

    public setPageSize(value: any) {
        this.pageSizeChange.next(value);
    }

    public setPageOffset(value: any) {
        this.pageOffsetChange.next(value);
    }

    public setPageFilter(value: any) {
        this.pageFilterChange.next(value);
    }

    observerPageSizeChange(): Observable<any> {
        this.pageSizeChange = new Subject<any>();
        return this.pageSizeChange.asObservable();
    }

    observerPageOffset(): Observable<any> {
        this.pageOffsetChange = new Subject<any>();
        return this.pageOffsetChange.asObservable();
    }

    observerPageFilter(): Observable<any> {
        this.pageFilterChange = new Subject<any>();
        return this.pageFilterChange.asObservable();
    }

    observerPaymentLoading(): Observable<any> {
        this.loadingPaymentChange = new Subject<any>();
        return this.loadingPaymentChange.asObservable();
    }

    getPending(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/receipts/pending/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    }

    haveParameterChanges(params: ReceiptParamDTO): Boolean {
        if (
            (undefined !== this.oldParams.statusType &&
                undefined !== params.statusType &&
                this.oldParams.statusType.length !== params.statusType.length) ||

            (this.oldParams.policyCollective !== params.policyCollective) ||
            (this.oldParams.policyNumber !== params.policyNumber) ||
            (this.oldParams.endDate !== params.endDate) ||
            (this.oldParams.startDate !== params.startDate) ||

            (undefined !== this.oldParams.product &&
                undefined !== params.product &&
                this.oldParams.product.length !== params.product.length)) {

            this.oldParams.statusType = Object.assign([], params.statusType);
            this.oldParams.policyItems = Object.assign([], params.policyItems);
            this.oldParams.policyCollective = params.policyCollective;
            this.oldParams.policyNumber = params.policyNumber;
            this.oldParams.product = Object.assign([], params.product);
            this.oldParams.endDate = params.endDate;
            this.oldParams.startDate = params.startDate;
            return true;
        }
        return false;
    }

    search(params: ReceiptParamDTO): Promise<any> {
        let httpParams = new HttpParams();

        if (undefined !== params.pageOffset) {
            if (!this.haveParameterChanges(params)) {
                httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
            } else {
                httpParams = httpParams.append('pageOffset', '0');
            }
        }

        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }

        if ((undefined !== params.startDate) && (null !== params.startDate) && ('' !== params.startDate)) {
            httpParams = httpParams.append('startDate', params.startDate);
        }

        if ((undefined !== params.endDate) && (null !== params.endDate) && ('' !== params.endDate)) {
            httpParams = httpParams.append('endDate', params.endDate);
        }

        if ((undefined !== params.policyNumber) && (null !== params.policyNumber) && ('' !== params.policyNumber.trim())) {
            httpParams = httpParams.append('policyNumber', params.policyNumber);
        }

        if ((undefined !== params.policyCollective) && (null !== params.policyCollective) && ('' !== params.policyCollective.trim())) {
            httpParams = httpParams.append('policyCollective', params.policyCollective);
        }

        if (undefined !== params.statusType) {
            for (let i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }

        if (undefined !== params.product) {
            for (let i = 0; i < params.product.length; i++) {
                httpParams = httpParams.append('product', params.product[i].id);
            }
        }

        if (undefined !== params.policyItems) {
            for (let i = 0; i < params.policyItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.policyItems[i].id);
            }
        }

        const url = this.domainService.backendUrlWithContext + '/receipts/search/' + this.authService.getSelectedEntity();
        return this.http.get(url, { params: httpParams }).toPromise();
    }


    payment(data: any) {
        this.loadingPaymentChange.next(true);
        const url = this.domainService.backendUrlWithContext + '/receipts/payment/' + data.receiptNumber;
        this.http.get<any>(url).toPromise().then(
            result => {
                const factory = this.factoryResolver.resolveComponentFactory(PaymentFormComponent);
                const component = factory.create(this.rootViewContainer.parentInjector);
                component.instance.setParameter(result.response);
                this.rootViewContainer.insert(component.hostView);
            }
        ).catch(error => {
            this.toastr.error(CommonsText.ERROR_DE_COMUNICACION_SERVICIO_PAGOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
    }

    getReceiptByNumber(receiptYear: any, receiptNumber: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/receipts/receipt/' + receiptYear + '/' + receiptNumber;
        return this.http.get(url).toPromise();
    }

    setRootViewContainerRef(viewContainerRef) {
        this.rootViewContainer = viewContainerRef
    }

    onPayWithAccountNumber(accountNumber: any, receiptYear: any, receiptNumber: any): Promise<any> {
        const form = new FormData();
        if ((undefined !== accountNumber) && (null !== accountNumber)) {
            form.append('accountNumber', accountNumber);
        }
        const url = this.domainService.backendUrlWithContext + '/receipts/payWithAccountNumber/' + receiptYear + '/' + receiptNumber;
        return this.http.post(url, form).toPromise();
    }

    onPayWithNewAccount(receiptNumber: any, receiptYear: any, data: any, upload: FormData): Observable<HttpEvent<any>> {
        const url = this.domainService.backendUrlWithContext + '/receipts/payWithNewAccount/' + receiptYear + '/' + receiptNumber;
        const form = new FormData();

        form.append('iban', data.iban);
        form.append('office', data.office);
        form.append('titular', data.titular);
        form.append('entity', data.entity);
        form.append('documentType', data.documentType[0].id);
        form.append('documentNumber', data.documentNumber);
        form.append('digit', data.digit);
        form.append('accountNumber', data.accountNumber);

        if (undefined !== upload) {
            const file = upload.get('file');
            if (null !== file) {
                form.append('file', file);
            }
        }

        const req = new HttpRequest<FormData>('POST', url, form, { reportProgress: true })
        return this.http.request<any>(req);
    }
}
