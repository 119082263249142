import { Injectable, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  private _backendUrlWithContext: String;
  private _wordpressUrl: String;
  private _frontEndUrl: string;

  constructor(@Inject(DOCUMENT) private document: Document) {

      const window = this.document.defaultView;

      this._backendUrlWithContext = environment.backendUrlWithContext;
      this._wordpressUrl = environment.wordpressUrl;
      this._frontEndUrl = environment.frontEndUrl;

  }

  public get backendUrlWithContext(): String {
      return this._backendUrlWithContext;
  }

  public get frontEndUrl(): String{
    return this._frontEndUrl;
  }

  public get wordpressUrl(): String {
      return this._wordpressUrl;
  }
}
