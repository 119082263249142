import { OnInit } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';
var ModifyContactComponent = /** @class */ (function () {
    function ModifyContactComponent(controlContainer, clientService, toastr, authService) {
        this.controlContainer = controlContainer;
        this.clientService = clientService;
        this.toastr = toastr;
        this.authService = authService;
        this.roadTypeList = [];
        this.actualPostalCode = '00000';
        this.messages = null;
        this.loadingCities = false;
        this.patching = false;
        this.cityList = [];
        this.showDirectAreas = true;
        this.isCompany = false;
        this.CODE_ERROR_EMAIL_ALREADY_REGISTERED = 'email.already_registered';
        this.ERROREMAILACONT = 'ERROREMAILACONT';
        this.ERRORMOBILECONT = 'ERRORMOBILECONT';
        this.ERRORPHONECONT = 'ERRORPHONECONT';
        this.prefijoPhoneFlag = null;
        this.prefijoFijoPhoneFlag = null;
        this.prefixData = CommonsUtils.phonePrefix();
        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };
    }
    Object.defineProperty(ModifyContactComponent.prototype, "client", {
        set: function (value) {
            if (undefined !== value) {
                this.patching = true;
                this.modifyContactForm.patchValue({ name: this.parseValue(value.name) });
                this.modifyContactForm.patchValue({ surname: this.parseValue(value.surname) });
                this.modifyContactForm.patchValue({ lastName: this.parseValue(value.lastName) });
                this.modifyContactForm.patchValue({
                    birthDate: (value.birthDate ?
                        this.parseValue(value.birthDate.replace(/\//g, "-"))
                        : null)
                });
                this.modifyContactForm.patchValue({ email: this.parseValue(value.email) });
                this.modifyContactForm.patchValue({ phone: this.parseValue(value.phone) });
                this.modifyContactForm.patchValue({ phonePrefix: this.parsePrefixValue(value.phonePrefix) });
                this.modifyContactForm.patchValue({ mobilePhone: this.parseValue(value.mobilePhone) });
                this.modifyContactForm.patchValue({ mobilePhonePrefix: this.parsePrefixValue(value.mobilePhonePrefix) });
                this.modifyContactForm.patchValue({ address: this.parseValue(value.address) });
                this.modifyContactForm.patchValue({ province: this.parseValue(value.province) });
                this.province = this.parseValue(value.province);
                this.modifyContactForm.patchValue({ postalCode: this.parseValue(value.postalCode) });
                this.modifyContactForm.patchValue({ cityCb: this.parseCityValue(value) });
                this.modifyContactForm.patchValue({ roadType: this.parseSelectValue(value.roadType) });
                this.modifyContactForm.patchValue({ number: this.parseValue(value.number) });
                this.modifyContactForm.patchValue({ portal: this.parseValue(value.portal) });
                this.modifyContactForm.patchValue({ stair: this.parseValue(value.stair) });
                this.modifyContactForm.patchValue({ floor: this.parseValue(value.floor) });
                this.modifyContactForm.patchValue({ door: this.parseValue(value.door) });
                this.modifyContactForm.patchValue({ notificationChannel: this.parseValueRadioButton(value.notifications) });
                this.patching = false;
                this.contactProgress = this.clientService.getContactPercent(value);
                this.addressProgress = this.clientService.getAddressPercent(value);
                this.documentType = value.documentType;
                this.documentNumber = value.documentNumber;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModifyContactComponent.prototype, "name", {
        get: function () { return this.modifyContactForm.get('name'); },
        enumerable: true,
        configurable: true
    });
    ModifyContactComponent.prototype.checkValidatorErrors = function () {
        if (this.modifyContactForm.get('name').errors.checkUserAge) {
            return "(*) Tiene que ser mayor de edad";
        }
        if (this.modifyContactForm.get('name').errors.minLength) {
            return "(*) Campo obligatorio";
        }
    };
    ModifyContactComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setMaxDate();
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
            var user = this.authService.getUser();
            user.documentType && user.documentType == 'CIF' ? this.isCompany = true : this.isCompany = false;
        }
        this.modifyContactForm = this.controlContainer.control;
        this.modifyContactForm.valueChanges.subscribe(function (data) {
            if (!_this.patching) {
                _this.checkContactPercent();
                _this.checkAddressPercent();
            }
            _this.messages = null;
        });
        this.contactFactor = this.clientService.getContactFactor();
        this.addressFactor = this.clientService.getAddressFactor();
        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
    };
    ModifyContactComponent.prototype.onModifyContact = function () {
        var _this = this;
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.modifyContactForm.valid) {
                this.running = true;
                this.changeFormatBirthDate();
                this.clientService.onModifyContact(this.modifyContactForm.value)
                    .then(function (success) {
                    _this.running = false;
                    _this.toastr.success(CommonsText.PERFIL_ACTUALIZADO_EXITO, CommonsText.OPERACION_COMPLETADA, {
                        timeOut: 5000
                    });
                }).catch(function (error) {
                    _this.running = false;
                    if (error.error && error.error.developerResponse && error.error.developerResponse === _this.CODE_ERROR_EMAIL_ALREADY_REGISTERED) {
                        _this.toastr.error(error.response, CommonsText.ERROR_OPERACION_CORREO_EN_USO, {
                            timeOut: 5000
                        });
                        _this.messages = ['Este correo electrónico ya está en uso.'];
                        _this.modifyContactForm.get("email").setErrors({ 'incorrect': true });
                    }
                    else {
                        _this.messages = error.error.response;
                        if (undefined === error.error.response) {
                            _this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        else {
                            if (error.error && error.error.developerResponse && error.error.developerResponse === _this.ERROREMAILACONT) {
                                _this.modifyContactForm.get("email").setErrors({ 'incorrect': true });
                                _this.modifyContactForm.get("email").markAsTouched({ onlySelf: true });
                            }
                            if (error.error && error.error.developerResponse && error.error.developerResponse === _this.ERRORMOBILECONT) {
                                _this.modifyContactForm.get("mobilePhone").setErrors({ 'incorrect': true });
                                _this.modifyContactForm.get("mobilePhone").markAsTouched({ onlySelf: true });
                            }
                            if (error.error && error.error.developerResponse && error.error.developerResponse === _this.ERRORPHONECONT) {
                                _this.modifyContactForm.get("phone").setErrors({ 'incorrect': true });
                                _this.modifyContactForm.get("phone").markAsTouched({ onlySelf: true });
                            }
                            _this.toastr.error(error.error.response, CommonsText.ERROR_DE_OPERACION, {
                                timeOut: 5000
                            });
                        }
                    }
                });
            }
            else {
                Object.keys(this.modifyContactForm.controls).forEach(function (field) {
                    _this.modifyContactForm.get(field).markAsTouched({ onlySelf: true });
                });
                this.modifyContactForm.controls['cityCb'].markAsTouched({ onlySelf: true });
            }
        }
    };
    ModifyContactComponent.prototype.setMaxDate = function () {
        var date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        this.maxDate = date;
    };
    ModifyContactComponent.prototype.changeFormatBirthDate = function () {
        if (this.isCompany) {
            this.modifyContactForm.value.birthDate = null;
        }
        else if (this.modifyContactForm.value.birthDate) {
            if (this.modifyContactForm.value.birthDate instanceof Date) {
                this.modifyContactForm.value.birthDate = this.addLeadingZero(this.modifyContactForm.value.birthDate.getDate()) + "/" + this.addLeadingZero(this.modifyContactForm.value.birthDate.getMonth() + 1) + "/" + this.modifyContactForm.value.birthDate.getFullYear();
            }
            else {
                this.modifyContactForm.value.birthDate = this.modifyContactForm.value.birthDate.replace(/\//g, "-");
            }
        }
    };
    ModifyContactComponent.prototype.addLeadingZero = function (value) {
        if (value < 10) {
            return "0" + value.toString();
        }
        return value.toString();
    };
    ModifyContactComponent.prototype.submitEnabled = function () {
        return !this.running;
    };
    ModifyContactComponent.prototype.getButtonLabel = function () {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.GUARDAR_CAMBIOS_MINUSCULA;
    };
    ModifyContactComponent.prototype.getRadioButtonNotificationClass = function () {
        return this.isInvalid('notificationChannel') ? 'invalid' : '';
    };
    ModifyContactComponent.prototype.checkContactPercent = function () {
        this.contactProgress = 100;
        this.checkContactControl('name');
        this.checkContactControl('surname');
        this.checkContactControl('email');
        this.checkContactControl('mobilePhone');
        this.checkContactControl('birthDate');
    };
    ModifyContactComponent.prototype.checkAddressPercent = function () {
        this.addressProgress = 100;
        this.checkAddressControl('postalCode');
        this.checkAddressControl('cityCb');
        this.checkAddressControl('roadType');
        this.checkAddressControl('address');
        this.checkAddressControl('number');
    };
    ModifyContactComponent.prototype.checkContactControl = function (controlName) {
        var control = this.modifyContactForm.controls[controlName];
        if (control.invalid) {
            this.contactProgress = this.contactProgress - this.contactFactor;
        }
    };
    ModifyContactComponent.prototype.checkAddressControl = function (controlName) {
        var control = this.modifyContactForm.controls[controlName];
        if (control.invalid) {
            this.addressProgress = this.addressProgress - this.addressFactor;
        }
    };
    ModifyContactComponent.prototype.parseValue = function (value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        }
        else {
            return value;
        }
    };
    ModifyContactComponent.prototype.parseSelectValue = function (value) {
        if (null === value || undefined === value) {
            return [];
        }
        else {
            return value;
        }
    };
    ModifyContactComponent.prototype.parsePrefixValue = function (value) {
        var prefix = undefined;
        if (null !== value && undefined !== value) {
            this.prefixData.forEach(function (number) {
                if (number.id === value) {
                    prefix = number;
                }
            });
        }
        if (undefined !== prefix) {
            return [prefix];
        }
        else {
            return null;
        }
    };
    ModifyContactComponent.prototype.parseValueRadioButton = function (value) {
        if (value && value.length > 0) {
            return value[0];
        }
        else {
            return null;
        }
    };
    ModifyContactComponent.prototype.parseCityValue = function (value) {
        if (null === value.city || undefined === value.city || '' === value.city.trim()) {
            return null;
        }
        else {
            this.cityList.push({
                id: value.city, itemName: value.cityDescription, province: value.province
            });
            return [{ id: value.city, itemName: value.cityDescription }];
        }
    };
    ModifyContactComponent.prototype.parseCheckBoxValue = function (value, key) {
        if (null === value || undefined === value) {
            return false;
        }
        else {
            return value.some(function (e) { return e === key; });
        }
    };
    ModifyContactComponent.prototype.searchCity = function () {
        var _this = this;
        var postalCode = this.modifyContactForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(function (success) {
                _this.actualPostalCode = postalCode;
                _this.loadingCities = false;
                _this.cityList = [];
                _this.modifyContactForm.controls['cityCb'].patchValue(null);
                for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                    var city = _a[_i];
                    _this.cityList.push({
                        id: city.id, itemName: city.name, province: city.province
                    });
                }
                if (success.response.length === 1) {
                    var city = success.response[0];
                    _this.modifyContactForm.patchValue({ cityCb: [{ id: city.id, itemName: city.name, province: city.province }] });
                    _this.modifyContactForm.patchValue({ province: _this.parseValue(city.province) });
                    _this.province = _this.parseValue(city.province);
                }
            }).catch(function (error) {
                _this.loadingCities = false;
                _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                    timeOut: 5000
                });
            });
        }
    };
    ModifyContactComponent.prototype.onChangePostalCode = function (data) {
        var v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    };
    ModifyContactComponent.prototype.onCitySelect = function (item) {
        this.province = this.parseValue(item.province);
        this.modifyContactForm.patchValue({ province: this.parseValue(item.province) });
    };
    ModifyContactComponent.prototype.isInvalid = function (controlName) {
        var control = this.modifyContactForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    };
    ModifyContactComponent.prototype.cambioPrefijo = function (controlName, event) {
        if (controlName !== null && controlName === 'phone') {
            if (event.length > 0 && event[0].id !== this.prefijoFijoPhoneFlag) {
                var variable1 = this.modifyContactForm.get(controlName).value;
                this.modifyContactForm.get(controlName).setErrors(null);
                this.prefijoFijoPhoneFlag = this.modifyContactForm.get("phonePrefix").value[0].id;
                this.modifyContactForm.get(controlName).setValue(null);
                this.modifyContactForm.get(controlName).setValue(variable1);
            }
        }
        if (controlName !== null && controlName === 'mobilePhone') {
            if (event.length > 0 && event[0].id !== this.prefijoPhoneFlag) {
                var variable2 = this.modifyContactForm.get(controlName).value;
                this.modifyContactForm.get(controlName).setErrors(null);
                this.prefijoPhoneFlag = this.modifyContactForm.get("mobilePhonePrefix").value[0].id;
                this.modifyContactForm.get(controlName).setValue(null);
                this.modifyContactForm.get(controlName).setValue(variable2);
            }
        }
    };
    return ModifyContactComponent;
}());
export { ModifyContactComponent };
