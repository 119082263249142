/**
 *
 * @author Iecisa
 */
export class ChangePasswordDTO {
    email: string;
    uuid: string;
    password: string;
    password2: string;
}
