import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { DomainService } from './domain-service';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Usuarios
 * @author Iecisa
 */
export class WordpressService {

    private widgets: any = null;
    private menus: any = null;
    private pages: any = null;
    private categorias: any = null;
    private imagen: any = null;

    constructor(private http: Http, private domainService: DomainService) { }

    async loadWidget(): Promise<any> {
        const url = this.domainService.wordpressUrl + '/wp-json/widgets/get';

        if (this.widgets === null) {
            const response = await this.http.get(url).toPromise();
            this.widgets = response.json();
            return Promise.resolve(true);
        } else {
            return Promise.resolve(true);
        }

    }

    getWidget(slug: string): any {
        for (const widget in this.widgets) {
            if (widget === slug) {
                const item = this.widgets[widget];
                return null != item ? item : undefined;
            }
        }
    }

    async loadMenu(): Promise<any> {
        const url = this.domainService.wordpressUrl + '/wp-json/menus/get';

        if (this.menus === null) {
            const response = await this.http.get(url).toPromise();
            this.menus = response.json();
            return Promise.resolve(true);
        } else {
            return Promise.resolve(true);
        }

    }

    getMenu(slug: string): any {
        for (const menu in this.menus) {
            if (menu === slug) {
                return this.menus[menu];
            }
        }
    }


    async loadPage(): Promise<any> {
        const url = this.domainService.wordpressUrl + '/wp-json/wp/v2/pages';

        if (this.pages === null) {
            const response = await this.http.get(url).toPromise();
            this.pages = response.json();
            return Promise.resolve(true);
        } else {
            return Promise.resolve(true);
        }

    }

    getPage(slug: string): any {
        for (let i = 0; i < this.pages.length; i++) {
            if (this.pages[i].slug === slug) {
                return this.pages[i];
            }
        }
    }


    loadCategories(catNumber: string): any {
        const url = this.domainService.wordpressUrl + '/wp-json/wp/v2/posts?categories=' + catNumber;
        //const url = 'https://preclientes.onlygal.es' + '/wp-json/wp/v2/posts?categories=' + catNumber;
        return this.http.get(url).toPromise();
    }

    getCategorias(){
        if(this.categorias !== null){
            return this.categorias;
        }
    }

    loadImages(url: string): any {
        return this.http.get(url).toPromise();
    }

    getImage(){
        if(this.imagen !== null){
            return this.imagen;
        }
    }

}
