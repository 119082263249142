/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-sms-register-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../commons/components/error-messages/error-messages.component.ngfactory";
import * as i5 from "../../../commons/components/error-messages/error-messages.component";
import * as i6 from "../../../commons/components/spinner-button/spinner-button.component.ngfactory";
import * as i7 from "../../../commons/components/spinner-button/spinner-button.component";
import * as i8 from "../../../commons/components/contact-line/contact-line.component.ngfactory";
import * as i9 from "../../../commons/components/contact-line/contact-line.component";
import * as i10 from "./confirm-sms-register-form.component";
import * as i11 from "@angular/router";
import * as i12 from "../../../services/user-service";
import * as i13 from "../../../security/auth.service";
import * as i14 from "../../../services/audity-service";
var styles_ConfirmSmsRegisterFormComponent = [i0.styles];
var RenderType_ConfirmSmsRegisterFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmSmsRegisterFormComponent, data: {} });
export { RenderType_ConfirmSmsRegisterFormComponent as RenderType_ConfirmSmsRegisterFormComponent };
function View_ConfirmSmsRegisterFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row row-counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00BFNo has recibido ning\u00FAn c\u00F3digo por SMS? Por favor, espera "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "clock"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" para solicitar un nuevo c\u00F3digo. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clock; _ck(_v, 3, 0, currVal_0); }); }
export function View_ConfirmSmsRegisterFormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { number: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 26, "form", [["class", "no-text-select"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.onConfirmRegister() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(3, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "title row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registro del \u00C1rea Cliente"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Te hemos enviado un SMS a tu tel\u00E9fono m\u00F3vil con el c\u00F3digo de 6 cifras que debes introducir a continuaci\u00F3n."])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row steps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Paso 2 de 3 "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "number-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "row row-numbers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, [[1, 0], ["number", 1]], null, 0, "input", [["inputmode", "numeric"], ["maxlength", "6"], ["pattern", "[0-9]*"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmSmsRegisterFormComponent_1)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "app-error-messages", [], null, [[null, "messagesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("messagesChange" === en)) {
        var pd_0 = ((_co.messages = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ErrorMessagesComponent_0, i4.RenderType_ErrorMessagesComponent)), i1.ɵdid(21, 49152, null, 0, i5.ErrorMessagesComponent, [], { messages: [0, "messages"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-spinner-button", [], null, null, null, i6.View_SpinnerButtonComponent_0, i6.RenderType_SpinnerButtonComponent)), i1.ɵdid(24, 49152, null, 0, i7.SpinnerButtonComponent, [], { enabled: [0, "enabled"], label: [1, "label"], running: [2, "running"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-contact-line", [], null, null, null, i8.View_ContactLineComponent_0, i8.RenderType_ContactLineComponent)), i1.ɵdid(27, 49152, null, 0, i9.ContactLineComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = (_co.counterVisible === true); _ck(_v, 18, 0, currVal_7); var currVal_8 = _co.messages; _ck(_v, 21, 0, currVal_8); var currVal_9 = _co.submitEnabled(); var currVal_10 = _co.getButtonLabel(); var currVal_11 = _co.running; _ck(_v, 24, 0, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ConfirmSmsRegisterFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-sms-register-form", [], null, null, null, View_ConfirmSmsRegisterFormComponent_0, RenderType_ConfirmSmsRegisterFormComponent)), i1.ɵdid(1, 114688, null, 0, i10.ConfirmSmsRegisterFormComponent, [i11.ActivatedRoute, i12.UserService, i13.AuthService, i14.AudityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmSmsRegisterFormComponentNgFactory = i1.ɵccf("app-confirm-sms-register-form", i10.ConfirmSmsRegisterFormComponent, View_ConfirmSmsRegisterFormComponent_Host_0, {}, { onConfirmSmsRegisterSubmit: "onConfirmSmsRegisterSubmit", onResendSmsRegisterSubmit: "onResendSmsRegisterSubmit" }, []);
export { ConfirmSmsRegisterFormComponentNgFactory as ConfirmSmsRegisterFormComponentNgFactory };
