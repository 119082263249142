var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InjectionToken } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { AuthRedirectorGuard } from 'app/security/auth.redirector.guard';
import { LogoutGuard } from 'app/security/auth.logout.guard';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ModifyPageComponent } from './pages/modify-page/modify-page.component';
import { ReceiptPageComponent } from './pages/receipt-page/receipt-page.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
var redirectProvider = new InjectionToken('externalUrlRedirectResolver');
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ProductDetailPageComponent } from './pages/product-detail-page/product-detail-page.component';
import { EventsPageComponent } from './pages/event-page/event-page.component';
import { EventHistoryPageComponent } from './pages/event-history-page/event-history-page.component';
import { EventDetailPageComponent } from './pages/event-detail-page/event-detail-page.component';
import { EventRegisterPageComponent } from './pages/event-register-page/event-register-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ContactFormPageComponent } from './pages/contact-form-page/contact-form-page.component';
import { ContactClaimsPageComponent } from './pages/contact-claims-page/contact-claims-page.component';
import { ContactCallmePageComponent } from './pages/contact-callme-page/contact-callme-page.component';
import { ReceiptPayPageComponent } from './pages/receipt-pay-page/receipt-pay-page.component';
registerLocaleData(localeDe, 'de');
import { HammerGestureConfig, } from '@angular/platform-browser';
import { HireMorePageComponent } from './pages/hire-more-page/hire-more-page.component';
import { HireFormPageComponent } from './pages/hire-form-page/hire-form-page.component';
import { ProductServiceDetailPageComponent } from './pages/product-service-detail-page/product-service-detail-page.component';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { ClubPoliticaComponent } from './pages/club-politica/club-politica.component';
import { ClubCondicionesComponent } from './pages/club-condiciones/club-condiciones.component';
import { WordpressPageComponent } from './pages/wordpress-page/wordpress-page.component';
var MyHammerConfig = /** @class */ (function (_super) {
    __extends(MyHammerConfig, _super);
    function MyHammerConfig() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyHammerConfig.prototype.buildHammer = function (element) {
        var mc = new Hammer(element, {
            touchAction: "pan-y"
        });
        return mc;
    };
    return MyHammerConfig;
}(HammerGestureConfig));
export { MyHammerConfig };
var ɵ0 = { page: 'conditions-of-use' }, ɵ1 = { page: 'cookies-policy' };
var appRoutes = [
    {
        path: 'main',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'profile',
        canActivate: [AuthRedirectorGuard],
        component: ProfilePageComponent,
    },
    {
        path: 'modify',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'receipts',
        canActivate: [AuthRedirectorGuard],
        component: ReceiptPageComponent,
    },
    {
        path: 'hire',
        canActivate: [AuthRedirectorGuard],
        component: HireMorePageComponent,
    },
    {
        path: 'hire/extend/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: HireFormPageComponent,
    },
    {
        path: 'receipt/:paymentResponse/:receiptYear/:receiptNumber',
        canActivate: [AuthRedirectorGuard],
        component: ReceiptPayPageComponent,
    },
    {
        path: 'products',
        canActivate: [AuthRedirectorGuard],
        component: ProductPageComponent,
    },
    {
        path: 'productService/:expediente',
        canActivate: [AuthRedirectorGuard],
        component: ProductServiceDetailPageComponent,
    },
    {
        path: 'products/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: ProductDetailPageComponent,
    },
    {
        path: 'events',
        canActivate: [AuthRedirectorGuard],
        component: EventsPageComponent,
    },
    {
        path: 'contact',
        canActivate: [AuthRedirectorGuard],
        component: ContactPageComponent,
    },
    {
        path: 'contact/claims',
        canActivate: [AuthRedirectorGuard],
        component: ContactClaimsPageComponent,
    },
    {
        path: 'contact/form',
        canActivate: [AuthRedirectorGuard],
        component: ContactFormPageComponent,
    },
    {
        path: 'contact/callme',
        canActivate: [AuthRedirectorGuard],
        component: ContactCallmePageComponent,
    },
    {
        path: 'events/history',
        canActivate: [AuthRedirectorGuard],
        component: EventHistoryPageComponent,
    },
    {
        path: 'events/history/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventHistoryPageComponent,
    },
    {
        path: 'event/:eventYear/:eventNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventDetailPageComponent,
    },
    {
        path: 'event/:eventYear/:eventNumber/:show',
        canActivate: [AuthRedirectorGuard],
        component: EventDetailPageComponent,
    },
    {
        path: 'events/register',
        canActivate: [AuthRedirectorGuard],
        component: EventRegisterPageComponent,
    },
    {
        path: 'events/register/:policyYear/:policyNumber',
        canActivate: [AuthRedirectorGuard],
        component: EventRegisterPageComponent,
    },
    {
        path: 'modify/contact',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/payment',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/access',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'modify/preferences',
        canActivate: [AuthRedirectorGuard],
        component: ModifyPageComponent,
    },
    {
        path: 'conditions-of-use',
        canActivate: [AuthRedirectorGuard],
        component: WordpressPageComponent,
        data: ɵ0
    },
    {
        path: 'cookies-policy',
        canActivate: [AuthRedirectorGuard],
        component: WordpressPageComponent,
        data: ɵ1
    },
    {
        path: 'services',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'logout',
        canActivate: [LogoutGuard],
        component: MainPageComponent,
    },
    {
        path: 'redirect',
        resolve: {
            url: redirectProvider,
        },
        component: MainPageComponent,
    },
    {
        path: '',
        canActivate: [AuthRedirectorGuard],
        component: MainPageComponent,
    },
    {
        path: 'notifications',
        canActivate: [AuthRedirectorGuard],
        component: NotificationsPageComponent,
    },
    {
        path: 'club-del-propietario/politica-club',
        canActivate: [AuthRedirectorGuard],
        component: ClubPoliticaComponent,
    },
    {
        path: 'club-del-propietario/condiciones-club',
        canActivate: [AuthRedirectorGuard],
        component: ClubCondicionesComponent,
    },
];
var ɵ2 = function (route) {
    var externalUrl = route.paramMap.get('url');
    window.open(externalUrl, '_blank');
};
var HeaderFooterTemplateModule = /** @class */ (function () {
    function HeaderFooterTemplateModule() {
    }
    return HeaderFooterTemplateModule;
}());
export { HeaderFooterTemplateModule };
export { ɵ0, ɵ1, ɵ2 };
