import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss']
})
export class ChangePasswordPageComponent {

    changed: Boolean = false;

    onChangeSubmit(event) {
        this.changed = event;
    }

}
