import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { ToastrService } from 'ngx-toastr';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { DasLexService } from 'app/services/daslex-service';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';
import { NotificationService } from 'app/services/notification-service';
import { CommonsText } from 'app/commons/CommonsText';


@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent implements OnInit {
  loading: Boolean = false;
  response: any;
  private params = new PolicyParamDTO();
  notifications: any;
  readingNotifications: boolean;
  pendingNotifications: boolean;

  constructor(private notificationService: NotificationService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.search();
  }

  checkPendingNotifications() {
    this.pendingNotifications = false;

    if (this.notifications) {
        this.notifications.forEach(not => {
            if (not.isRead != 'S') {
                this.pendingNotifications = true;
            }
        });
    }

    return this.pendingNotifications;
}

  markAsRead(notification) {
    if (notification && notification.isRead != 'S') {
      const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);
      if (found) {
        const index = this.notifications.indexOf(found);
        found.isRead = 'S';
        this.notifications[index] = found;
        this.checkPendingNotifications();
      }

      let arrayIds: number[] = [notification.agenNotId];
      this.notificationService.markNotificationAsRead(arrayIds)
        .then(success => {

        }).catch(error => {
          const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);
          if (found) {
            const index = this.notifications.indexOf(found);
            found.isRead = '';
            this.notifications[index] = found;
            this.checkPendingNotifications();
          }
          this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
            timeOut: 5000
          });
        });
    }
  }

  delete(notification) {
    let arrayIds: number[] = [notification.agenNotId];
      this.notificationService.markNotificationAsDelete(arrayIds)
        .then(success => {
          let index = null;
          const found = this.notifications.find(n => n.agenNotId === notification.agenNotId);
  
          if (found) {
            index = this.notifications.indexOf(found);
            if (index > -1) {
              this.notifications.splice(index, 1);
            }
  
            if (this.notifications.length == 0) {
              this.pendingNotifications = false;
            }
          }
        }).catch(error => {
          this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
            timeOut: 5000
          });
        });

  }

  deleteAll() {
    this.readingNotifications = true;
    let arrayIds: number[] = [];
    this.notifications.forEach(n => {
      arrayIds.push(n.agenNotId)
    });
      this.notificationService.markNotificationAsDelete(arrayIds)
      .then(success => {
        this.notifications = [];
        this.pendingNotifications = false;
        this.readingNotifications = false;
      }).catch(error => {
        this.readingNotifications = false;
        this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
          timeOut: 5000
        });
      });
  }

  readAll() {
    this.readingNotifications = true;
    let arrayIds: number[] = [];
    this.notifications.forEach(n => {
      arrayIds.push(n.agenNotId)
    });
    this.notificationService.markNotificationAsRead(arrayIds)
      .then(success => {
        this.readingNotifications = false;
        this.notifications.forEach(n => {
          n.isRead = 'S'
        });
        this.pendingNotifications = false;
      }).catch(error => {
        this.readingNotifications = false;
        this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
          timeOut: 5000
        });
      });
  }

  search() {
    this.loading = true;
    this.notificationService.getNotifications()
      .then(success => {
        this.loading = false;
        this.notifications = success.response;
      }).catch(error => {
        this.loading = false;
        this.response = null;
        this.toastr.error(CommonsText.PROBLEMA_SERVICIO_NOTIFICACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
          timeOut: 5000
        });
      });
  }

}
