<app-header>
  <div class="inner-content-header container">
    <div class="profile">
      <app-header-breadcrumb crumb="Club del propietario" text="Política de privacidad"></app-header-breadcrumb>
    </div>
    <div class="last-conect">
      <app-header-last-conection></app-header-last-conection>
    </div>
  </div>
</app-header>

<div class="container" style="min-height: 400px;">

  <h1>Responsable del tratamiento</h1>

  <p>onLygal Seguros y Reaseguros, S.A., en adelante, “onLygal Seguros”.</p>

  <p>Domicilio de contacto: Pza. Europa, 41-43 6ª planta, de Hospitalet de Llobregat, Barcelona (08908). Email de
    contacto: <a href="mailto:lopd@onlygal.es">lopd@onlygal.es</a></p>

  <h1>Finalidad del tratamiento</h1>

  <p>Los datos personales que nos facilites los utilizaremos para informarte sobre ofertas y promociones de proveedores
    asociados incluidos en la aplicación.</p>

  <h1>Legitimidad del tratamiento</h1>

  <p>El tratamiento se legitima en el consentimiento explícito del Usuario, al aceptar con un clic la política de
    privacidad.</p>

  <h1>Plazo de conservación de tus datos</h1>

  <p>Trataremos tus datos personales mientras tu cuenta de usuario está activa. Una vez cancelada, por las causas
    indicadas en las condiciones de uso, los datos serán bloqueados con el fin de impedir su tratamiento excepto para su
    puesta a disposición de Administraciones Públicas y Tribunales, con el fin de cumplir nuestras obligaciones legales.
  </p>

  <h1>Destinatarios de los datos</h1>

  <p>Tus datos no serán enviados a ninguna otra entidad, salvo obligación legal.</p>

  <h1>Derechos de protección de datos</h1>

  <p>Tienes derecho a obtener información sobre si onLygal Seguros está tratando tus datos personales.</p>

  <p>En particular, tienes derecho a:</p>
  <ul>
    <li>Acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso,
      solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron
      recogidos.</li>
    <li>Puedes solicitar en cualquier momento la cancelación de la cuenta (baja del servicio) enviando un email a la
      dirección de correo electrónico que figura en las condiciones de uso.</li>
    <li>En determinadas circunstancias, podrás solicitar la limitación del tratamiento de sus datos, en cuyo caso
      únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</li>
  </ul>


  <p>El usuario puede ejercer los referidos derechos dirigiendo un email a la dirección electrónica <a
      href="mailto:lopd@onlygal.es">lopd@onlygal.es</a>. Puede obtener información adicional acerca de sus derechos ante la
    Agencia Española de Protección de Datos en <a href="https://www.agpd.es" target="_blank">www.agpd.es</a></p>

  <p>Cuando el usuario no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación
    ante la Agencia Española de Protección de Datos, <a href="https://www.agpd.es" target="_blank">www.agpd.es</a>, clicando en la pestaña de “Canal del ciudadano”.</p>

</div>
<app-footer></app-footer>