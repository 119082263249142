<div *ngIf="show" class="loading-container no-text-select">
    <div class="loading-container-cont wow fadeInUp" data-wow-duration="1s">
        <div class="pre-loading">
            <p class="close">
                <b class="cross" (click)="closeModal()">&#10006;</b>
                </p>

            <p class="loading">¡Tu perfil no está completo!<span>.</span><span>.</span><span>.</span></p>

            <p class="loading sub">Completa tu información personal en menos de un minuto</p>

            <div class="button-row">
                <div class="button-place">                   
                </div>
                <div class="button-place">  
                    <app-spinner-button [label]="'Ir a Modificar Perfil'" [enabled]="true" (click)="goToModifyProfile()">
                    </app-spinner-button>                 
                </div>
                <div class="button-place">                   
                </div>
            </div>
            
        </div>
    </div>
</div>