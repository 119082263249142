import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';

declare function desloadChat(): any;

@Injectable()
export class AuthRedirectorGuard implements CanActivate {

    private restrictedRoutes: string[] = [
        '/products',
        '/productService',
        '/receipts',
        '/modify/payment',
        '/modify/preferences',
        '/events/register',
        '/events/register/',
        '/contact',
        '/club-del-propietario',
        '/hire'
    ]

    private restrictedRoutesInsured: string[] = [
        '/modify/preferences'
    ]

    private restrictedRoutesClub: string[] = [
        '/club-del-propietario',
        '/club-del-propietario/detalle/',
        '/club-del-propietario/categoria/'
    ]

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!this.authService.isLogged()) {
            desloadChat();
            this.router.navigate(['/authentication']);
        } else if (this.authService.isLogged() && state.url === '/') {
            this.router.navigate(['/main']);
        } else if (this.authService.isLogged() && this.authService.getSelectedEntity() !== 'ONLYGAL') {
            this.validateRouteForReinsured(state.url);
        } else if(this.authService.isLogged() && this.authService.getRelation() !== 'TOMA'){
            this.validateRouteForInsured(state.url);
        } else if(this.authService.isLogged() && !this.authService.getProductos()){
            this.validateRouteForClub(state.url);
        }

        return true;
    }

    validateRouteForReinsured(navigatedUrl) {
        if (navigatedUrl) {
            this.restrictedRoutes.forEach(r => {
                if (navigatedUrl.lastIndexOf(r, 0) == 0) {
                    this.router.navigate(['/main']);
                }
            })
        }
    }

    validateRouteForInsured(navigatedUrl) {
        if (navigatedUrl) {
            this.restrictedRoutesInsured.forEach(r => {
                if (navigatedUrl.lastIndexOf(r, 0) == 0) {
                    this.router.navigate(['/main']);
                }
            })
        }
    }
    validateRouteForClub(navigatedUrl) {
        if (navigatedUrl) {
            this.restrictedRoutesClub.forEach(r => {
                if (navigatedUrl.lastIndexOf(r, 0) == 0) {
                    this.router.navigate(['/main']);
                }
            })
        }
    }
}
