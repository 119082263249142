import { Component, OnInit } from '@angular/core';
import { EventParamDTO } from 'app/header-footer-template/model/EventParamDTO';
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-history-page',
    templateUrl: './event-history-page.component.html',
    styleUrls: ['./event-history-page.component.scss']
})
export class EventHistoryPageComponent implements OnInit {
    loading: Boolean = false;
    response: any;

    params = new EventParamDTO();

    constructor(private route: ActivatedRoute, private eventService: EventService, private toastr: ToastrService) { }

    ngOnInit(): void {

        this.params = new EventParamDTO();
        this.params.policyNumber = this.route.snapshot.params['policyNumber'];
        this.params.pageOffset = 0;
        this.params.pageSize = 10;
        this.search();

        this.eventService.observerPageSizeChange().subscribe((result: any) => {
            this.params.pageOffset = 0;
            this.params.pageSize = result;
            this.search();
        });

        this.eventService.observerPageOffset().subscribe((result: any) => {
            this.params.pageOffset = result;
            this.search();
        });

        this.eventService.observerPageFilter().subscribe((result: any) => {
            this.params.startDate = result.startDate;
            this.params.endDate = result.endDate;
            this.params.statusType = result.statusType;
            this.params.product = result.product;
            this.params.policyNumber = result.policyNumber;
            this.params.policyItems = result.policyItems;
            this.params.eventItems = result.eventItems;
            this.search();
        });
    }

    search() {
        this.loading = true;
        this.eventService.search(this.params)
            .then(success => {
                this.loading = false;
                this.response = success;
            }).catch(error => {
                this.loading = false;
                this.response = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

}
