<div class="accordion">
    <div [ngClass]="slimStyle ? 'accordion-container-slim' : 'accordion-container'">
        <label [ngClass]="slimStyle ? 'accordion-title-slim' : 'accordion-title'" (click)="onClick()">

            <p [ngClass]="slimStyle ? 'caption' : ''">{{caption}} </p>


            <p class="subcaption" *ngIf="subcaption">{{subcaption}}</p>
            <i
                [ngClass]="[checked ? (slimStyle ? 'checked-icon-slim':'checked-icon') : '', slimStyle ? 'accordion-icon-slim':'accordion-icon']"></i>
            <i class="spinner " *ngIf="loading"></i>
        </label>
        <div class="accordion-content" [ngClass]="checked ? 'content-visible' : 'content-not-visible'">
            <div [ngClass]="slimStyle ? 'inner-contracted-slim':'inner-contracted'">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>