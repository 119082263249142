/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./payment-form.component";
var styles_PaymentFormComponent = [];
var RenderType_PaymentFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaymentFormComponent, data: {} });
export { RenderType_PaymentFormComponent as RenderType_PaymentFormComponent };
export function View_PaymentFormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { form: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["form", 1]], null, 3, "form", [["method", "post"], ["ngNoForm", ""]], [[8, "action", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["name", "Ds_SignatureVersion"], ["type", "hidden"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "input", [["name", "Ds_MerchantParameters"], ["type", "hidden"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "input", [["name", "Ds_Signature"], ["type", "hidden"]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.url, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.signatureVersion, ""); _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.merchantParameters, ""); _ck(_v, 3, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", _co.signature, ""); _ck(_v, 4, 0, currVal_3); }); }
export function View_PaymentFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-payment-form", [], null, null, null, View_PaymentFormComponent_0, RenderType_PaymentFormComponent)), i0.ɵdid(1, 49152, null, 0, i1.PaymentFormComponent, [], null, null)], null, null); }
var PaymentFormComponentNgFactory = i0.ɵccf("app-payment-form", i1.PaymentFormComponent, View_PaymentFormComponent_Host_0, {}, {}, []);
export { PaymentFormComponentNgFactory as PaymentFormComponentNgFactory };
