import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { RouterModule } from '@angular/router';
import { AuthRedirectorGuard } from './auth.redirector.guard';
import { AuthService } from './auth.service';
import { LogoutGuard } from './auth.logout.guard';

@NgModule({
    declarations: [],

    imports: [
        CommonModule,
        RouterModule
    ],

    providers: [
        AuthGuard,
        AuthRedirectorGuard,
        LogoutGuard,
        AuthService
    ]

})
export class SecurityModule { }
