/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome-text.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./welcome-text.component";
var styles_WelcomeTextComponent = [i0.styles];
var RenderType_WelcomeTextComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeTextComponent, data: { "animation": [{ type: 7, name: "fadeInOut", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(20%)" }, offset: null }, { type: 4, styles: null, timings: 450 }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: null, timings: "0.1s ease-in" }], options: null }], options: {} }] } });
export { RenderType_WelcomeTextComponent as RenderType_WelcomeTextComponent };
function View_WelcomeTextComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-wrapper no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[24, "@fadeInOut", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 2, 0, currVal_1); }); }
export function View_WelcomeTextComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeTextComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.ValueChangeDetectorDirective, [i1.ViewContainerRef, i1.TemplateRef], { valueChangeDetector: [0, "valueChangeDetector"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WelcomeTextComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-welcome-text", [], null, null, null, View_WelcomeTextComponent_0, RenderType_WelcomeTextComponent)), i1.ɵdid(1, 49152, null, 0, i2.WelcomeTextComponent, [], null, null)], null, null); }
var WelcomeTextComponentNgFactory = i1.ɵccf("app-welcome-text", i2.WelcomeTextComponent, View_WelcomeTextComponent_Host_0, {}, {}, []);
export { WelcomeTextComponentNgFactory as WelcomeTextComponentNgFactory };
