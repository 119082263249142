import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { UserService } from '../../../services/user-service';
import { ConfirmSmsRegisterDTO } from '../../model/ConfirmSmsRegisterDTO';
import { AudityService } from 'app/services/audity-service';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from '../../../commons/CommonsText';

@Component({
    selector: 'app-confirm-sms-register-form',
    templateUrl: './confirm-sms-register-form.component.html',
    styleUrls: ['./confirm-sms-register-form.component.scss']
})
export class ConfirmSmsRegisterFormComponent implements OnInit {

    @Output() onConfirmSmsRegisterSubmit = new EventEmitter();
    @Output() onResendSmsRegisterSubmit = new EventEmitter();

    confirmSmsRegisterDTO: ConfirmSmsRegisterDTO;
    messages: String[] = null;
    running: Boolean = false;
    counterVisible: Boolean;
    clock: String;
    start: Date;

    prefixData: any[];
    prefixSettings: any;
    resendForm: FormGroup;

    audity: AudityDTO = new AudityDTO();
    ipAddress: any;

    @ViewChild('number') number: ElementRef;
    

    constructor(private route: ActivatedRoute, 
                private userService: UserService, 
                private authService: AuthService,
                private audityService: AudityService) {

        this.counterVisible = this.userService.getCounterVisibility();
        this.start = new Date(new Date().getTime() + (2 * 60000));

        this.ticToc();
        setInterval(() => {
            this.ticToc();
        }, 1000)

    }

    private ticToc() {
        const distance = this.start.getTime() - new Date().getTime();
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.clock = (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);

        if ((minutes <= 0) && (seconds <= 0) && this.counterVisible) {
            this.onResendSmsRegisterSubmit.emit(true);
        }
    }

    ngOnInit() {
        this.authService.getIPAddress().subscribe((res:any)=>{  
            this.ipAddress=res._body;  
          }); 
        this.confirmSmsRegisterDTO = new ConfirmSmsRegisterDTO();
        setTimeout(() => {
            this.number.nativeElement.focus();
        }, 1000);
    }

    onConfirmRegister() {

        this.messages = [];
        if (this.submitEnabled()) {
            this.running = true;

            this.confirmSmsRegisterDTO.code = this.userService.getCode();

            if (undefined === this.confirmSmsRegisterDTO.code) {
                this.confirmSmsRegisterDTO.code = this.route.snapshot.params['code'];
            }

            this.confirmSmsRegisterDTO.number = this.number.nativeElement.value;

            this.userService.onConfirmSmsRegister(this.confirmSmsRegisterDTO)
                .then(success => {
                    if(success.response.error !== null){
                        this.running = false;
                        this.audity.audityDocument = 'undefined';
                        this.audity.audityIp = this.ipAddress;
                        this.audityService.saveUserErrorRegister(this.audity);
                        this.messages = [success.response.error];
                    } else {
                        this.onConfirmSmsRegisterSubmit.emit(success);
                    }
                }).catch(error => {
                    this.running = false;
                    this.messages = error.error.response;
                    this.audity.audityDocument = 'undefined';
                    this.audity.audityIp = this.ipAddress;
                    this.audityService.saveUserErrorRegister(this.audity);
                    if (undefined === error.error.response) {
                        this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }
                })
        }

    }

    submitEnabled() {
        return this.number.nativeElement.value !== '' && !this.running;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.CONFIRMAR;
    }

}
