import { Component, OnInit } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';

@Component({
    selector: 'app-payment-loading',
    templateUrl: './payment-loading.component.html',
    styleUrls: ['./payment-loading.component.scss']
})
export class PaymentLoadingComponent implements OnInit {

    show: Boolean = false;

    constructor(private receiptService: ReceiptService) { }

    ngOnInit() {
        this.receiptService.observerPaymentLoading().subscribe((result: any) => {
            this.show = result;
        });
    }

}
