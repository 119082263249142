<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Servicio de reclamaciones y quejas de la compañía onLygal Seguros"></app-header-breadcrumb>
        </div>
        <!-- <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div> -->
    </div>
</app-header>

<div class="container" [@fadeInUp]>
    <div class="body no-text-select">
        <div class="body-main">
            <div class="body-main-text">
                <p>El departamento de onLygal Seguros que se encarga de resolver las quejas y reclamaciones se denomina Servicio de Atención al Cliente (SAC). Tiene independencia y es competente para resolver las quejas y reclamaciones que pueda plantear cualquier
                    persona física o jurídica, que tenga la condición de tomador, asegurado, beneficiario o mediador de seguros de los anteriores, o sus causahabientes o representantes.</p>
                <p>Este Servicio se enmarca dentro de la política de protección de los derechos e intereses de los clientes y usuarios y tiene su fundamento legal en la Orden ECO/734/2004 de 11 de marzo sobre los Departamentos y Servicios de Atención al
                    Cliente y el Defensor del Cliente de las entidades financieras.</p>
                <p>El Servicio se rige por un <a href="https://www.das.es/app/uploads/2016/12/Reglamento-SAC-DAS.pdf" target="_blank">Reglamento aprobado en fecha 19 de Julio de 2004</a>. Para contactar con el Servicio de Atención al Cliente puede rellenar
                    el <a href="https://www.onlygal.es/atencion-al-cliente/realizar-una-queja-o-reclamacion/formulario-realizar-una-queja-reclamacion/" target="help">formulario de contacto</a>, enviar un correo a <a href="mailto:sac@onlygal.es">sac@onlygal.es</a>                    o enviar un escrito a la siguiente dirección:</p>
                <p><strong>Servicio de Atención al Cliente</strong></p>
                <p>Onlygal Defensa del Automovilista y de Siniestros Internacional S.A. de Seguros y Reaseguros<br>Pl. Europa 41-43 6ª planta<br>08908 – L’Hospitalet de Llobregat (Barcelona)</p>
            </div>
            <div class="body-main-accordion">
                <!-- <h1>Pure CSS Accordion <sup>2.0</sup></h1> -->
                <div class="row">
                    <div class="col">
                        <!-- <h2>Open <b>multiple</b></h2> -->
                        <div class="tabs">
                            <div class="tab">
                                <input type="checkbox" id="chck1">
                                <label class="tab-label" for="chck1">
                                    <p>¿Qué información debe contener el escrito de queja o reclamación?</p> </label>
                                <div class="tab-content tab-content-sup">
                                    <div class="title">
                                        <p>En el escrito de reclamación deberás indicarnos los siguientes datos:</p>
                                    </div>
                                    <div class="body">
                                        <p>Identificación del reclamante, con indicación de su DNI, NIF o NIE, y domicilio postal y, en su caso, electrónico. Si quien formula la reclamación o queja lo hace por representación, deberá identificarse y aportar
                                            documentación acreditativa al respecto.</p>
                                        <p>Identificación o referencia de la póliza y/o siniestro al que se refiera la reclamación o queja.</p>
                                        <p>Causa de la queja o reclamación, lo más detallada posible, aportando la documentación y elementos probatorios relacionados con la misma, que el reclamante estime de interés.</p>
                                        <p>Petición que se formula.</p>
                                        <p>Indicación de que el reclamante no tiene conocimiento de que la queja o reclamación está siendo sustanciada a través de procedimiento administrativo, judicial o arbitral.</p>
                                        <p>Lugar, fecha y firma del reclamante.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tab">
                                <input type="checkbox" id="chck2">
                                <label class="tab-label" for="chck2">
                                   <p>¿En qué consiste el procedimiento?</p></label>
                                <div class="tab-content tab-content-inf">
                                    <div class="body">
                                        <p>Primero: El Servicio de Atención al Cliente acusará recibo de la reclamación.</p>
                                        <p>Segundo: Podrá solicitar al reclamante todos los antecedentes e información que precise.</p>
                                        <p>Tercero: Analizará la reclamación, la documentación y obtendrá antecedentes de las áreas afectadas a las que se refiera la reclamación.</p>
                                        <p>Cuarto: Tomará una decisión en un plazo máximo de un mes a partir de la fecha en que se presenta la reclamación ante el Servicio de Atención al Cliente. Si no resuelve dentro del plazo previsto, la queja o reclamación
                                            se entenderá desestimada.</p>
                                        <p>Quinto: Comunicará su decisión al reclamante a su dirección postal o a la dirección de correo electrónico que hubiera facilitado.</p>
                                        <p>Si la decisión le da la razón al reclamante, la decisión del Servicio de Atención al Cliente vincula a onLygal Seguros. Si la decisión no es favorable al reclamante, este no está obligado a cumplirla.</p>
                                        <p>Sexto: Si el reclamante no está de acuerdo con la decisión del Servicio de Atención al Cliente (porque la desestimó total o parcialmente), no la admitió a trámite o no la resolvió en un mes a contar desde su presentación,
                                            podrá dirigir su petición al <a href="http://www.dgsfp.mineco.es/es/Consumidor/Reclamaciones/Paginas/InformacionProcedimiento.aspx" target="_blank">Servicio de Reclamaciones de la Dirección General de Seguros y Fondos de Pensiones</a>,
                                            acreditando dichos extremos, a la siguiente dirección:</p>
                                        <p>Servicio de Reclamaciones <br> Dirección General de Seguros y Fondos de Pensiones <br> Paseo de la Castellana, 44 28046 Madrid <br>
                                            <a href="http://www.dgsfp.mineco.es/es/Paginas/Iniciocarrousel.aspx" target="_blank">www.dgsfp.mineco.es</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <h2>Open <b>one</b></h2>
                        <div class="tabs">
                            <div class="tab">
                                <input type="radio" id="rd1" name="rd">
                                <label class="tab-label" for="rd1">Item 1</label>
                                <div class="tab-content">
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, facilis.
                                </div>
                            </div>
                            <div class="tab">
                                <input type="radio" id="rd2" name="rd">
                                <label class="tab-label" for="rd2">Item 2</label>
                                <div class="tab-content">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil, aut.
                                </div>
                            </div>
                            <div class="tab">
                                <input type="radio" id="rd3" name="rd">
                                <label for="rd3" class="tab-close">Close others &times;</label>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <app-contact-boxes></app-contact-boxes>

</div>

<app-footer></app-footer>