<div class="insured">
    <div class="insured-container">

        <div class="insured-container-data">
            <div class="insured-container-data-left">
                <div class="type">
                    <label for="">Tipo: &nbsp;</label>
                    <p>INMUEBLE</p>
                </div>
                <div class="domicile">
                    <label for="">Domicilio: &nbsp;</label>
                    <p>{{data.address}}</p>
                </div>
                <div class="state">
                    <label for="">Población: &nbsp;</label>
                    <p>{{data.province}}</p>
                </div>
                <div class="state" *ngIf="(null !== data.rentAmouth) && (undefined !== data.rentAmouth)">
                    <label for="">Alquiler: &nbsp;</label>
                    <p>{{data.rentAmouth}}</p>
                </div>
            </div>
        </div>
    </div>
</div>