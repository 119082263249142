<div class=" container " style="min-height: 400px;">
  <div *ngIf="loadCatEnCurso" class="container no-text-select pre-loading">
    <p>Cargando<span>.</span><span>.</span><span>.</span></p>
  </div>

  <div *ngIf="!loadCatEnCurso" style="width: 100%;">
    <br>
    <div style="left: 50%;">
      <h2 class="title">{{categoria.titulo}}</h2>
    </div>
    <br>
    <br>
    <div class="contenedorBotones" *ngIf="!isMobilePhone">
      <button *ngFor="let subcategoria of categoria.subCategoriaList" type="button" class="botonCategoria"
        (click)="ancla(subcategoria.id)"><img src="{{subcategoria.icono}}"
          class="imagenBoton"><span>{{subcategoria.titulo}}</span>
      </button>
    </div>
    <br>
    <br>
    <div *ngIf="categoria.subCategoriaList.length > 0" >
      <div *ngFor="let subcategoria of categoria.subCategoriaList" id="{{subcategoria.id}}">
        <div class="contenedorSubCategoria">
          <img src="{{subcategoria.icono}}" class="imagenSubcategoria"><span
            class="tituloSubcategoria">{{subcategoria.titulo}}</span>
        </div>
        <br>
        <div style="width:100%;">

          <div *ngIf="subcategoria.noticiaList.length > 0">
            <app-subcategoria [subcategoria]="subcategoria"></app-subcategoria>
          </div>

          <div *ngIf="subcategoria.noticiaList.length == 0">
            <div class="grid">
              <div></div>
              <div class="parrafo">
                <p>¡Lo sentimos! No hay noticias relacionadas.</p>
              </div>
              <div></div>
            </div>
          </div>

        </div>
        <br>
        <br>
      </div>
      <br>
      <br>
    </div>
    <div *ngIf="categoria.subCategoriaList.length == 0"  class="parrafo">
      <p>¡Lo sentimos! No hay noticias relacionadas.</p>
    </div>
  </div>
</div>