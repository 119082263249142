<div *ngIf="show" class="loading-container no-text-select">
    <div class="loading-container-cont fadeInUp">
        <div class="pre-loading">
            <!-- <p class="close">
              <b class="cross" (click)="closeModal()">&#10006;</b>
              </p> -->

            <p class="loading bottom-space">Bienvenido al Club de propietarios de
                onLygal Seguros<span>.</span><span>.</span><span>.</span></p>

            <p class="loading sub">Un espacio donde ponemos a tu disposición multitud de recursos e informaciones útiles
                para los propietarios de viviendas de alquiler.</p>
            <div [formGroup]="clubFormPreferences">
                <div class="form">
                    <div class="row">
                        <app-check-box formControlName="politicaClub">
                            <div class="inner-check">
                                <p>Acepto la <a (click)="goPolitica()">política de privacidad</a></p>
                            </div>
                        </app-check-box>
                    </div>
                    <div class="row">
                        <app-check-box formControlName="condicionesClub">
                            <div class="inner-check">
                                <p>Acepto las <a (click)="goCondiciones()">condiciones de uso del Club</a></p>
                            </div>
                        </app-check-box>
                    </div>
                </div>
            </div>
            <div class="button-row">

                <div class="button-place">
                </div>

                <div class="button-place">
                    <app-spinner-button [label]="'Cancelar'" [enabled]="true" (click)="goMain()">
                    </app-spinner-button>
                </div>
 
                <div class="button-spacer">
                </div>

                <div class="button-place">
                    <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running" (click)="onModifyPreferences()">
                    </app-spinner-button>
                </div>

                <div class="button-place">
                </div>

            </div>
        </div>
    </div>
</div>