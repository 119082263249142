/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-clause.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./product-clause.component";
var styles_ProductClauseComponent = [i0.styles];
var RenderType_ProductClauseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductClauseComponent, data: {} });
export { RenderType_ProductClauseComponent as RenderType_ProductClauseComponent };
export function View_ProductClauseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "contracted"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "contracted-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.data.description; _ck(_v, 7, 0, currVal_1); }); }
export function View_ProductClauseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-clause", [], null, null, null, View_ProductClauseComponent_0, RenderType_ProductClauseComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProductClauseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductClauseComponentNgFactory = i1.ɵccf("app-product-clause", i2.ProductClauseComponent, View_ProductClauseComponent_Host_0, { data: "data" }, {}, []);
export { ProductClauseComponentNgFactory as ProductClauseComponentNgFactory };
