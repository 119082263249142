import { Component, Input } from '@angular/core';
import { CommonsText } from 'app/commons/CommonsText';
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-servicios-relacionados-parte',
    templateUrl: './servicios-relacionados-parte.component.html',
    styleUrls: ['./servicios-relacionados-parte.component.scss']
})
export class ServiciosRelacionadosParteComponent {

    @Input() data: any;
    servicios: any[] = undefined;
    flag: boolean = false;

    constructor(
        private eventService: EventService,
        private toastr: ToastrService
    ){}

    traerServicios(){
        if(!this.flag){
            this.flag = true;
            this.eventService.getServices(this.data.eventNumber)
            .then(value=>{
                this.servicios = value.response;
            })
            .catch(error=>{
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            })
        }
    }

}
