import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent {

    @Input() content;
    @Input() delay;
    visible = false;

    get stateName() {
        return this.visible ? 'show' : 'hide'
    }

    toggle() {
        this.visible = !this.visible;
    }

    getDelay(): String {
        return (this.delay * 0.2) + 's';
    }

    getStyle() {
        if (this.content && this.content._embedded && this.content._embedded['wp:featuredmedia']) {
            return {
                'background-image': 'url(' + this.content._embedded['wp:featuredmedia']['0'].source_url + ')'
            }
        }
        return '';
    }
}
