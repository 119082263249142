<div class="legaliboo">
  <div class="legaliboo-box"></div>
  <div class="legaliboo-icon">

      <div class="legaliboo-icon-img">
      </div>
  </div>
  <div class="legaliboo-policy">
      <div class="sup">
          &nbsp;
      </div>
      <div class="sup-1">
          <p>Tus documentos legales a un click de distancia</p>
      </div>

      <div class="inf">
          <p>Crea tu documento legal personalizado y listo para firmar de forma rápida, ágil y sencilla. Más de 100 documentos en temáticas como Vivienda, Laboral, Contratos con Proveedores/Clientes, Identidad Digital, Compras, Servicios, etc.</p>
      </div>

      <div class="inf-1">
          &nbsp;
      </div>

  </div>
  <div class="legaliboo-button">
    <form>
        <app-spinner-button [label]="'Crear'" [enabled]="true" [customSpinnerButton]="'hire-more'"(click)="submitLegaliboo()">
        </app-spinner-button>
    </form>
    <!-- 
        <form #hiddenForm [action]="legalibooConfig ? legalibooConfig.endpoint : ''" [target]="'_blank'" [method]="'POST'">
            <input type="hidden" name="oauth" [value]="legalibooConfig.oauth">
            <input type="hidden" name="client" [value]="legalibooConfig.client">
        <form>
    -->
  </div>
</div>