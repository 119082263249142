<div class="input-group">
    <div class="input-edit">
        <input matInput [matDatepicker]="picker" [type]="'text'" #edit (focus)="_openCalendar(picker)"
            (click)="_openCalendar(picker)"  [max]="maxDate"
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            [value]="value" (change)="onChange($event.target.value)" (focusout)="onFocusOut($event.target.value)"
            (input)="onSelectCallback($event.target.value)" (blur)="onTouched()" mask="00-00-0000" placeholder="DD-MM-YYYY">

        <label class="label-upperline "
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red text-bold'">{{label}}</label>
        <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
        
        <mat-datepicker #picker></mat-datepicker>

        <button type="button" class="btn-icon" (click)="_openCalendar(picker)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24"
                height="24" viewBox="0 0 24 24">
                <path fill="#4d87c7"
                    d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
            </svg>
        </button>
    </div>

    <div class="description">
        <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
            <label>
                {{description}}
            </label>
        </p>
        <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">
            
            <label *ngIf="ngControl.errors?.beforeCurrentDate">
                {{beforeCurrentDateErrorDescription}}
            </label>

            <label *ngIf="ngControl.errors?.afterCurrentDate">
                {{afterCurrentDateErrorDescription}}
            </label>

            <label *ngIf="ngControl.errors?.beforeCurrentDate === undefined && ngControl.errors?.afterCurrentDate === undefined && ngControl.errors?.minAge === undefined">
                {{errorDescription}}
            </label>
            <label *ngIf="ngControl.errors?.minAge">
                {{minAgeErrorDescription}}
            </label>

        </p>
    </div>
</div>