var SplitButtonComponent = /** @class */ (function () {
    function SplitButtonComponent() {
        this._toggle = false;
    }
    Object.defineProperty(SplitButtonComponent.prototype, "close", {
        set: function (value) {
            if (value === true) {
                this._toggle = false;
            }
        },
        enumerable: true,
        configurable: true
    });
    SplitButtonComponent.prototype.toggleMenu = function () {
        this._toggle = !this._toggle;
    };
    return SplitButtonComponent;
}());
export { SplitButtonComponent };
