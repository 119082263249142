<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Formularios de contacto"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container" [@fadeInUp]>
    <div class="body no-text-select">
        <div class="body-title">
            <p>Déjanos tu consulta y te contactaremos</p>
        </div>
        <div class="body-sec">
            <!-- <div class="title">
                <p>Particulares / Empresas</p>
            </div> -->
            <div class="links">
                <div class="link">
                    <a href="https://www.onlygal.es/atencion-al-cliente/informarme-o-contratar-un-producto/" target="_blank">
                        <p>
                            Informarme o contratar un producto
                        </p>
                    </a>
                </div>
                <div class="link">
                    <a [routerLink]="['/events/register']">
                        <p>
                            Realizar una consulta jurídica
                        </p>
                    </a>
                </div>
                <div class="link">
                    <a [routerLink]="['/events']">
                        <p>
                            Declarar o consultar un siniestro
                        </p>
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.onlygal.es/atencion-al-cliente/consultar-modificar-o-dar-de-baja-mi-seguro/" target="_blank">
                        <p>
                            Consultar, modificar o dar de baja mi seguro
                        </p>
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.onlygal.es/atencion-al-cliente/realizar-una-gestion-postventa-sobre-un-servicio-contratado/" target="_blank">
                        <p>
                            Realizar una gestión postventa sobre un servicio contratado
                        </p>
                    </a>
                </div>
            </div>
        </div>
        <!-- <div class="body-sec">
            <div class="title title-med">
                <p>Mediadores</p>
            </div>
            <div class="links">
                <div class="link">
                    <a href="https://www.onlygal.es/mediadores-1/informarme-sobre-productos-y-polizas/" target="_blank">
                        <p>
                            Informarme sobre productos y pólizas
                        </p>
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.onlygal.es/mediadores-1/colaborar-con-onlygal-como-mediador/" target="_blank">
                        <p>Colaborar con Onlygal como mediador</p>
                    </a>
                </div>
            </div>
        </div> -->
        <!-- <div class="body-sec">
            <div class="title title-med">
                <p>Otros</p>
            </div>
            <div class="links">
                <div class="link">
                    <a href="https://www.onlygal.es/conocenos/unete-a-onlygal/" target="_blank">
                        <p> Únete al Grupo Onlygal</p>
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.onlygal.es/conocenos/forma-parte-de-nuestra-red/" target="_blank">
                        <p>Forma parte de nuestra red</p>
                    </a>
                </div>
                <div class="link">
                    <a href="https://www.onlygal.es/conocenos/innovacion/" target="blank">
                        <p>Innovación</p>
                    </a>
                </div>
            </div>
        </div> -->
    </div>

    <app-contact-boxes></app-contact-boxes>

</div>

<app-footer></app-footer>