import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FanditService } from 'app/services/fandit-service';
import { ToastrService } from 'ngx-toastr';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { AudityService } from 'app/services/audity-service';
import { AuthService } from '../../../../security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-product-fandit',
  templateUrl: './product-fandit.component.html',
  styleUrls: ['./product-fandit.component.scss']
})
export class ProductFanditComponent implements OnInit {

  @ViewChild('hiddenForm') private hiddenForm: ElementRef;
  urlFandit = '';
  audity: AudityDTO = new AudityDTO();
  ipAddress: string;

  constructor(private fanditService: FanditService,
              private toastr: ToastrService,
              private authService: AuthService,
              private audityService: AudityService) { }

  ngOnInit() {
    this.authService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res._body;  
    }); 
  }

  openFandit(){
    this.fanditService.fanditAccess()            
    .then(success => {
      this.audity.audityDocument = this.authService.getUser().documentNumber + "";
      this.audity.audityIp = this.ipAddress;
      this.audityService.saveFanditAcces(this.audity);
      window.open(success.response.urlFandit);
    }).catch(error => {
        this.toastr.error(CommonsText.PROBLEMA_SERVICIO_FANDIT, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
            timeOut: 5000
        });
    });
  }

}
