import { environment } from 'environments/environment';
import 'rxjs/add/operator/toPromise';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var DomainService = /** @class */ (function () {
    function DomainService(document) {
        this.document = document;
        var window = this.document.defaultView;
        this._backendUrlWithContext = environment.backendUrlWithContext;
        this._wordpressUrl = environment.wordpressUrl;
        this._frontEndUrl = environment.frontEndUrl;
    }
    Object.defineProperty(DomainService.prototype, "backendUrlWithContext", {
        get: function () {
            return this._backendUrlWithContext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DomainService.prototype, "frontEndUrl", {
        get: function () {
            return this._frontEndUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DomainService.prototype, "wordpressUrl", {
        get: function () {
            return this._wordpressUrl;
        },
        enumerable: true,
        configurable: true
    });
    DomainService.ngInjectableDef = i0.defineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.inject(i1.DOCUMENT)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
