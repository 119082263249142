import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.scss'],

    encapsulation: ViewEncapsulation.None,
})

export class TimeComponent {

    @Input() hour: string;
    @Input() minute: string;
    @Output() selected = new EventEmitter();
    @Input() disabledHours = [];

    hours = ['00', '01', '02', '03', '04', '05', '06',
        '07', '08', '09', '10', '11', '12', '13',
        '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23'];

    minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

    minutesOpen: boolean;
    hoursOpen: boolean;

    formatNumber(number: any): string {
        if (undefined === number) {
            return '00';
        } else {
            if (!(typeof (number) === 'string' || number instanceof String)) {
                const n: number = number;
                return <string>(n <= 9 ? '0' + n : n)
            }
            return number.toString();
        }
    }

    selectHourChange(hour: string): void {
        if ((undefined !== this.disabledHours) && this.disabledHours.some(e => e === hour)) {
            return;
        }
        this.hour = hour;
        if (this.minute == null) {
            this.minute = '00';
        }
        this.change();
    }

    selectMinuteChange(minute: string): void {
        this.minute = minute;
        this.change();
    }

    change() {
        const e = this.formatNumber(this.hour) + ':' + this.formatNumber(this.minute);
        this.selected.emit(e);
        this.minutesOpen = false;
        this.hoursOpen = false;
    }

    public toggleHourMenu(): void {
        this.minutesOpen = false;
        this.hoursOpen = !this.hoursOpen;
    }

    public toggleMinuteMenu(): void {
        this.hoursOpen = false;
        this.minutesOpen = !this.minutesOpen;
    }

    getClass(value: any): any {
        if ((undefined !== this.disabledHours) && this.disabledHours.some(e => e === value)) {
            return 'time-value-disabled';
        } else {
            return 'time-value';
        }
    }

}
