import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { DomainService } from '../../../services/domain-service';

@Component({
    selector: 'app-split-button-item',
    templateUrl: './split-button-item.component.html',
    styleUrls: ['./split-button-item.component.scss']
})
export class SplitButtonItemComponent {

    @Input() label: String;
    @Input() route: String;

    constructor(private location: Location, private domainService: DomainService) { }

    resolveRouterLink(param) {
        if (undefined === param) {
            return [this.location.path()];
        } else {
            const newParam = param.replace(this.domainService.wordpressUrl, '');
            return (newParam.includes('http')) ? ['/redirect', { url: newParam }] : newParam;
        }
    }
}
