<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb text="Mi Perfil"></app-header-breadcrumb>
        </div>
        <div class="sub-nav">
            <app-header-profile-bar></app-header-profile-bar>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container alturaMinima">

    <div *ngIf="loadingProfile" class="container no-text-select pre-loading">
        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
    </div>

    <div *ngIf="!loadingProfile" class="container-contact">
        <div class="wow fadeInUp" data-wow-duration="1s">
            <app-profile-contact [data]="profileResponse"></app-profile-contact>
        </div>
        <div class="wow fadeInUp" data-wow-duration="1s" *ngIf="showDirectAreas && paymentResponse?.length > 0">
            <app-profile-bank [data]="paymentResponse"></app-profile-bank>
        </div>
        <div class="wow fadeInUp" data-wow-duration="1s" *ngIf="showDirectAreas && showInsuredAreas">
            <app-profile-preference [data]="profileResponse"></app-profile-preference>
        </div>
    </div>
    <!--<app-wp-footer-integration></app-wp-footer-integration>-->
        
</div>

<app-footer></app-footer>