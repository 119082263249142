import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-payment-form',
    templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent {

    url: any;
    signatureVersion: any;
    merchantParameters: any;
    signature: any;

    @ViewChild('form') private form: ElementRef;

    public setParameter(data: any) {
        this.url = data.url;
        this.signatureVersion = data.signatureVersion;
        this.merchantParameters = data.merchantParameters;
        this.signature = data.signature;

        setInterval(() => {
            this.form.nativeElement.submit();
        }, 2000)

    }


}
