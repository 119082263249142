<section class="card-content no-text-select">

    <div [ngStyle]=getStyle()>
        <div>
            <p>{{data.title}}</p>
            <p>{{data.message}}</p>
        </div>        
        <button *ngIf=showButton() [routerLink]=resolveRouterLink(data.link)>SABER MÁS</button>
    </div>

</section>