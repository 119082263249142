<app-collapse-container-event caption="Pagos relacionados con el parte" (click)="traerPagos()">
    <div *ngIf="undefined === pagos" class="cargando">
        <div class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>
    </div>
    <div *ngIf="mobileSize">
        <div class="payment" *ngIf="undefined !== pagos">
            <div *ngIf="pagos.length==0">
                <div class="payment-main">
                    <div class="payment-main-sup">
                        <div class="left">
                            <span>Nombre:</span>
                        </div>
                        <div class="right">
                            <span>Numero de cuenta:</span>
                        </div>
                        <div class="right">
                            <span>Importe:</span>
                        </div>
                        <div class="right">
                            <span>Fecha:</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="pagos.length>0">
                <div class="payment-main" *ngFor="let c of pagos ">
                    <div class="payment-main-sup">
                        <div class="left">
                            <span>Nombre:</span>
                            <p>{{c.paymentName}}</p>
                        </div>
                        <div class="right">
                            <span>Numero de cuenta:</span>
                            <p>{{c.paymentcountNumber}}</p>
                        </div>
                        <div class="right">
                            <span>Importe:</span>
                            <p>{{c.price}} €</p>
                        </div>
                        <div class="right">
                            <span>Fecha:</span>
                            <p>{{c.date | date: 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                    <hr size="1rem" style="margin-top: 1.5rem;" *ngIf="pagos.length>1" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="desktopSize">
        <div class="payment" *ngIf="undefined !== pagos">
            <div class="payment-main">
                <div class="payment-main-sup">
                    <div class="left">
                        <span>Nombre:</span>
                    </div>
                    <div class="right">
                        <span>Numero de cuenta:</span>
                    </div>
                    <div class="right">
                        <span>Importe:</span>
                    </div>
                    <div class="right">
                        <span>Fecha:</span>
                    </div>
                </div>
                <div class="payment-main-inf" *ngFor="let c of pagos ">
                    <div class="left">
                        <p>{{c.paymentName}}</p>
                    </div>
                    <div class="right">
                        <p>{{c.paymentcountNumber}}</p>
                    </div>
                    <div class="right">
                        <p>{{c.price}} €</p>
                    </div>
                    <div class="right">
                        <p>{{c.date | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-collapse-container-event>