<app-collapse-container  [id]="'messenger'" [checked]="showDocuments" caption="Comunicaciones con el Tramitador"
    subcaption="Los mensajes dentro de un parte serán vistos por los responsables de su gestión: onLygal, mediador y/o colaboradores. Para ver todos los mensajes o seguir comunicándote con Onlygal en relación a este parte haz click o toca la flecha de la derecha"
    [slimStyle]="true" (click)="showMessages()">


    <div class="messenger-main-cont" *ngIf="showContent">
        <app-event-messenger-history class="send-box" [data]="data"></app-event-messenger-history>
    </div>

    <div class="messenger-main-cont" *ngIf="showContent">
        <app-event-messenger-send [data]="data" class="send-box"></app-event-messenger-send>
    </div>

</app-collapse-container>