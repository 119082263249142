import { Injectable } from '@angular/core';
import { SubCategoriaDataDTO } from 'app/shared/models/wordpress/SubCategoriaDataDTO';
import { ImagenDataDTO } from 'app/shared/models/wordpress/ImagenDataDTO';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { UrlService } from '../dominio/url.service';
import { environment } from 'environments/environment';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';


@Injectable({
  providedIn: 'root'
})
export class ClubPropietarioService {

  private listaCategoriaDTO : CategoriaDataDTO[] =[];

  constructor(private httpclient: Http, private urlService: UrlService, private toastr: ToastrService) {}

  getCategoriasCompletas(): CategoriaDataDTO[]{
    return JSON.parse(localStorage.getItem('categorias'));
  }

  getCategoriaToSave(): CategoriaDataDTO[]{
    return this.listaCategoriaDTO;
  }

  async getCategoriaWordPress(): Promise<any> {
    const urlCategoria = this.urlService.wordpressUrl + '/wp-json/wp/v2/categories?per_page=100';
    const response = await this.httpclient.get(urlCategoria).toPromise();
    this.getListaNoticiasWordPress(this.mapCategoria(response));    
    return of(this.listaCategoriaDTO);
  }

  private mapCategoria(data: any) { 
    let datos = data.json();
    let listaCategoriaDTO: CategoriaDataDTO[] = [];
    let listaCategorias = datos.filter(x => x.parent == 0 && x.slug != "sin-categoria");
    
    /*Tenemos las categorias*/
    for(let i = 0; i < listaCategorias.length; i++) {
      let categoria = new CategoriaDataDTO();
      categoria.id = listaCategorias[i].id;
      categoria.categoria = listaCategorias[i].id;
      categoria.titulo = listaCategorias[i].name;
      categoria.identificador = listaCategorias[i].slug;
      categoria.destacado = false;

      /*Tenemos las subCategorias*/
      let listaSubCategorias = datos.filter(x => x.parent == categoria.id && x.slug != "sin-categoria");
      for(let j = 0; j < listaSubCategorias.length; j++) {
        let subCategoria = new SubCategoriaDataDTO();
        subCategoria.id = listaSubCategorias[j].id;
        subCategoria.subcategoria = listaSubCategorias[j].id;
        subCategoria.categoria = categoria.id;
        subCategoria.titulo = listaSubCategorias[j].name;
        subCategoria.identificador = listaSubCategorias[j].slug;
        subCategoria.icono = this.setIcono(subCategoria.identificador);

        categoria.subCategoriaList.push(subCategoria);
      }
      listaCategoriaDTO.push(categoria);
    }
      this.listaCategoriaDTO = listaCategoriaDTO;

    return listaCategoriaDTO;
  }

    getListaNoticiasWordPress(listaCategoriaDTO: CategoriaDataDTO[]): CategoriaDataDTO[] {
      listaCategoriaDTO.forEach(categoria => {
        this.getNoticiaWordPress(categoria);
    });
    return listaCategoriaDTO;
  }

  getNoticiaWordPress (categoria: CategoriaDataDTO) {
    const urlCategoria = this.urlService.wordpressUrl + '/wp-json/wp/v2/posts?categories=' + categoria.id;
    const promise = this.httpclient.get(urlCategoria).toPromise().then(data => {
                      this.mapNoticia(data, categoria);
                    }).catch(error => {
                      this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                          timeOut: 5000
                      });
                  });;
  };  

  private mapNoticia(data: any, categoria: CategoriaDataDTO) { 
    let datos = data.json();
    for(let i = 0; i < datos.length; i++) {      
      this.setCategoria(datos[i], categoria);
    } 
    return categoria;   
  }

  getUrlImagen(idImagen: string, imagen:ImagenDataDTO){
    const urlImagen = this.urlService.wordpressUrl + '/wp-json/wp/v2/media/' + idImagen;
    const promise = this.httpclient.get(urlImagen).toPromise().then(data => {
        let datos = data.json();
        imagen.id = datos.id;
        imagen.source_url = datos.source_url;  
    }).catch(error => {
      this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
          timeOut: 5000
      });
  });;
  }

  getNoticia(idNoticia: string, idCategoria: string): Observable<NoticiaDataDTO> {
    const urlNoticia = this.urlService.wordpressUrl + '/wp-json/wp/v2/posts/' + idNoticia;
    return this.httpclient.get(urlNoticia).pipe(map(res => this.mapNoticiaList(res, idCategoria)));
  }

  private mapNoticiaList(data: any, idCategoria: string) {
    let noticiaDTO = new NoticiaDataDTO();
    let datos = data.json();
    noticiaDTO.id = datos.id;
    noticiaDTO.categoria = idCategoria;
    const found =  datos.categories.find(element => element != idCategoria);
    noticiaDTO.subcategoria = found;
    noticiaDTO.titulo = datos.title.rendered;
    noticiaDTO.detalle = datos.content.rendered;
    noticiaDTO.idImagen = datos.featured_media;
    noticiaDTO.imagen = new ImagenDataDTO();
    this.getUrlImagen(noticiaDTO.idImagen, noticiaDTO.imagen);
    return noticiaDTO;
  }

  private setCategoria(data:any, categoriaDTO: CategoriaDataDTO) {      
      if(data.categories.length == 1) {
          // Estamos en la categoria
          categoriaDTO.categoria = categoriaDTO.id;

          if(data.tags.length > 0 && data.tags.find(element => element > 0)) {
            categoriaDTO.destacado = true;
          }

          /*Noticia Categoria por ser Destacado*/
          let noticiaCate: NoticiaDataDTO = new NoticiaDataDTO();
          noticiaCate.id = data.id
          noticiaCate.titulo = data.title.rendered;              
          noticiaCate.detalle = data.content.rendered;
          noticiaCate.categoria = categoriaDTO.id;
          noticiaCate.subcategoria = "";
          noticiaCate.idImagen = data.featured_media;
          noticiaCate.destacado = categoriaDTO.destacado;
          noticiaCate.imagen = new ImagenDataDTO();
          this.getUrlImagen(noticiaCate.idImagen, noticiaCate.imagen);          
          categoriaDTO.noticasListDestacadas.push(noticiaCate);
          categoriaDTO.noticiasListAll.push(noticiaCate);
      } else {
          // Estamos en una subcategoria          
          const found =  data.categories.find(element => element != categoriaDTO.id);
          let subCategoria = categoriaDTO.subCategoriaList.find(x => x.subcategoria == found);
          /*Noticia Categoria por ser Destacado*/
          let noticiaCate: NoticiaDataDTO = new NoticiaDataDTO();
          noticiaCate.id = data.id
          noticiaCate.titulo = data.title.rendered;              
          noticiaCate.detalle = data.content.rendered;
          noticiaCate.categoria = categoriaDTO.id;
          noticiaCate.subcategoria = found;
          noticiaCate.idImagen = data.featured_media;
          noticiaCate.imagen = new ImagenDataDTO();
          this.getUrlImagen(noticiaCate.idImagen, noticiaCate.imagen);  
          if(data.tags.length > 0 && data.tags.find(element => element > 0)) {
            categoriaDTO.destacado = true;
            categoriaDTO.noticasListDestacadas.push(noticiaCate);
          } 
          categoriaDTO.noticiasListAll.push(noticiaCate);                
          subCategoria.noticiaList.push(noticiaCate);
      }
    return categoriaDTO;
  } 

  getCategoria(idCategoria : String) : CategoriaDataDTO {
    return this.listaCategoriaDTO.find(element => element.categoria == idCategoria);
  }
  getCategoriaStorage(idCategoria : String) : CategoriaDataDTO{
    let cat = JSON.parse(localStorage.getItem('categorias'));
    return cat.find(element => element.categoria == idCategoria);
  }

  private setIcono(identificador: String): string {
    let icono: string;
    let url: string

    if(identificador.includes("preguntas")) {
      icono = "/assets/images/clubPropietario/ic_consejos.svg";;
    } else if (identificador.includes("documentos")) {
      icono = "/assets/images/clubPropietario/ic_documentos.svg";;
    } else if (identificador.includes("formacion")) {
      icono = "/assets/images/clubPropietario/ic_formacion.svg";;
    } else if (identificador.includes("proveedor")) {
      icono = "/assets/images/clubPropietario/ic_proveedores.svg";;
    } else {
      icono = "/assets/images/clubPropietario/ic_formacion.svg";
    }

    //Comenta para dejar de cargar iconos de INT.
    url = environment.wordpressUrl + '/wp-content/themes/frontend/resources/' + icono;

    //Descomenta para cargar las iconos en local.
    //url = environment.production ? '/wp-content/themes/frontend/resources/' + icono: icono;

    return url;
  }
}
