import { ViewContainerRef, TemplateRef } from '@angular/core';
var ValueChangeDetectorDirective = /** @class */ (function () {
    function ValueChangeDetectorDirective(viewContainer, templateRef) {
        this.viewContainer = viewContainer;
        this.templateRef = templateRef;
        this.hasView = false;
    }
    Object.defineProperty(ValueChangeDetectorDirective.prototype, "valueChangeDetector", {
        set: function (val) {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            }
            else if (val !== this.currentValue) {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.currentValue = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    return ValueChangeDetectorDirective;
}());
export { ValueChangeDetectorDirective };
var WelcomeTextComponent = /** @class */ (function () {
    function WelcomeTextComponent() {
        var _this = this;
        this.text = undefined;
        this.ticToc();
        setInterval(function () {
            _this.ticToc();
        }, 1000);
    }
    WelcomeTextComponent.prototype.ticToc = function () {
        var hour = new Date().getHours();
        if ((hour >= 0) && (hour < 14)) {
            this.text = '¡Buenos días!';
        }
        else if ((hour >= 14) && (hour < 20)) {
            this.text = '¡Buenas tardes!';
        }
        else if ((hour >= 20) && (hour <= 23)) {
            this.text = '¡Buenas noches!';
        }
    };
    return WelcomeTextComponent;
}());
export { WelcomeTextComponent };
