<div class="contact no-text-select">
    <div class="contact-title">
        <p>Mis datos de contacto</p>
    </div>
    <div class="contact-form">
        <div class="contact-form-row">
            <div class="left">
                <span>nombre completo</span>
                <p>{{data?.name}} {{data?.surname}} {{data?.lastName}}</p>
            </div>
            <div class="right">
                <span>nº de documento</span>
                <p>{{data?.documentNumber}}</p>
            </div>
        </div>
        <div class="contact-form-row" *ngIf="!isCompany">
            <div class="left">
                <span>fecha de nacimiento</span>
                <p>{{data?.birthDate}}</p>
            </div>
        </div>
        <div class="contact-form-row">
            <div class="left">
                <span>email</span>
                <p>{{data?.email}}</p>
            </div>
            <div class="right no-border" *ngIf="showDirectAreas">
                <span>canal de notificaciones</span>
                <div class="box">
                    <div ngClass="{{ getCheckBoxStyle('SMS') }}">
                        <p>SMS</p>
                    </div>
                    <div ngClass="{{ getCheckBoxStyle('EMAIL') }}">
                        <p>Email</p>
                    </div>
                    <div ngClass="{{  getCheckBoxStyle('NINGUNO') }}">
                        <p>Ninguno</p>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="contact-form-row">
            <div class="left">
                <span>teléfono fijo</span>
                <p>{{getPhone()}}</p>
            </div>
            <div class="right">
                <span>teléfono móvil</span>
                <p>{{getMobilePhone()}}</p>
            </div>
        </div>
        <div class="contact-form-row row-70">
            <div class="left">
                <span>dirección</span>
                <p>{{data?.address}} {{data?.number}} {{data?.portal}} {{data?.stair}} {{data?.floor}} {{data?.door}}
                </p>
            </div>
            <div class="right">
                <span>c. postal</span>
                <p>{{data?.postalCode}}</p>
            </div>
        </div>
        <div class="contact-form-row">
            <div class="left">
                <span>población</span>
                <p>{{data?.cityDescription}}</p>
            </div>
            <div class="right">
                <span>Provincia</span>
                <p>{{data?.province}}</p>
            </div>
        </div>
    </div>
    <div class="contact-modify">
        <a [routerLink]="[ '/modify/contact']">Modificar datos de contacto</a>
    </div>
</div>