import { Component, OnInit, Input } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';

@Component({
    selector: 'app-receipt-pagination',
    templateUrl: './receipt-pagination.component.html',
    styleUrls: ['./receipt-pagination.component.scss']
})
export class ReceiptPaginationComponent implements OnInit {

    @Input() loading: Boolean;
    @Input() response: any;

    constructor(private receiptService: ReceiptService) { }

    ngOnInit() {
    }

    onChange(value) {
        this.receiptService.setPageSize(value);
    }

    start(): String {
        return this.response.pageable.offset + 1;
    }

    end(): String {
        if (!this.response.last) {
            return this.response.pageable.offset + this.response.pageable.pageSize
        } else {
            return this.response.totalElements;
        }
    }

    total(): String {
        return this.response.totalElements;
    }

    prev() {
        if (!this.loading && !this.response.first) {
            this.receiptService.setPageOffset(this.response.number - 1);
        }
    }

    next() {
        if (!this.loading && !this.response.last) {
            this.receiptService.setPageOffset(this.response.number + 1);
        }
    }

    showPagination(): Boolean {
        return this.response.totalElements > 5;
    }

}

