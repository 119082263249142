import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/security/auth.service';
import { PolicyService } from 'app/services/policy-service';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { ClubPropietarioService } from 'app/shared/services/wordpress/club-propietario.service';


@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit{

    showClubAreas: boolean;
    showCategoria: boolean = false;
    categorias: CategoriaDataDTO[];
    lstCategoriaDestacados: CategoriaDataDTO[];
    showDirectAreas: boolean = true;
    
    constructor(private policyService: PolicyService,
                private authService: AuthService,
                private clubPropietarioService: ClubPropietarioService) {
    }

    ngOnInit() {    
        if(this.categorias === undefined){
            this.categorias = this.clubPropietarioService.getCategoriasCompletas();  
            this.showCategoria = true;
        }

        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        this.policyService.getRelation();
        if ((this.authService.isLogged()) && this.showDirectAreas) {
            this.authService.getProductos() ? this.showClubAreas = true : this.showClubAreas = false;
        }
    }
}
