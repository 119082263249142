import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de Reset de caches
 * @author Iecisa
 */
export class ResetService {

    private logoutChange;

    observerLogout(): Observable<any> {
        this.logoutChange = new Subject<any>();
        return this.logoutChange.asObservable();
    }

    public logout(token: any) {
        if (undefined !== this.logoutChange) {
            this.logoutChange.next(token);
        }
    }

}

