/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./callme-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./callme-title.component";
var styles_CallmeTitleComponent = [i0.styles];
var RenderType_CallmeTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallmeTitleComponent, data: {} });
export { RenderType_CallmeTitleComponent as RenderType_CallmeTitleComponent };
export function View_CallmeTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "body-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "body-title-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tel\u00E9fonos de contacto por seguros"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "body-title-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "body-title-right-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFTe llamamos?"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "img"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _ck(_v, 6, 0, "/contact/callme"); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CallmeTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-callme-title", [], null, null, null, View_CallmeTitleComponent_0, RenderType_CallmeTitleComponent)), i1.ɵdid(1, 114688, null, 0, i3.CallmeTitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallmeTitleComponentNgFactory = i1.ɵccf("app-callme-title", i3.CallmeTitleComponent, View_CallmeTitleComponent_Host_0, {}, {}, []);
export { CallmeTitleComponentNgFactory as CallmeTitleComponentNgFactory };
