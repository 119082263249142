import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { CommonsText } from 'app/commons/CommonsText';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-modify-access',
    templateUrl: './modify-access.component.html',
    styleUrls: ['./modify-access.component.scss']
})

export class ModifyAccessComponent implements OnInit {

    @Input() modifyAccessForm: FormGroup;
    messages: any[];
    running: Boolean = false;

    constructor(private controlContainer: ControlContainer,
        private clientService: ClientService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.modifyAccessForm = <FormGroup>this.controlContainer.control;

        this.modifyAccessForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

    }

    onModifyAccess() {
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.modifyAccessForm.valid) {
                this.running = true;
                this.clientService.onModifyAccess(this.modifyAccessForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.DATOS_ACCESO_ACTUALIZADOS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });
                    }).catch(error => {
                        this.running = false;
                        this.messages = error.error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })

            }
        }

    }

    submitEnabled() {
        return this.modifyAccessForm.valid && !this.running;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.CAMBIAR_CONTRASEÑA;
    }

}
