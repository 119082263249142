import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-profile-progress-bar',
  templateUrl: './header-profile-progress-bar.component.html',
  styleUrls: ['./header-profile-progress-bar.component.scss']
})
export class HeaderProfileProgressBarComponent  {

    @Input() percent: Number = 100;


}
