<div class="header no-text-select" [ngStyle]=getHeaderBackground()>
  <div class="box-transparent" [routerLink]="['/main']"></div>
  <div class="header-menu" [ngStyle]=getIntegrationBackground()>
      <div [ngStyle]=getIntegrationLinearBackground()>
          <div>
              <div [ngClass]="showDirectAreas == true ? 'header-menu-logo' : 'header-menu-logo2'"></div>
          
              <div class="container">
                  
                  <app-header-navigation-bar></app-header-navigation-bar>
                  <app-header-wp-integration *ngIf="undefined !== integration && showDirectAreas" [data]="integration"></app-header-wp-integration>
              </div>
              <div *ngIf="isMobile" [ngClass]="getEntityClass()"></div>
          </div>
          
      </div>
  </div>

  <div class="header-content">
      <ng-content></ng-content>
  </div>

  <div id="chat_div" #chat_div></div>

</div>