import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/security/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "ngx-toastr";
import * as i4 from "./domain-service";
var FanditService = /** @class */ (function () {
    function FanditService(http, authService, toastr, domainService) {
        this.http = http;
        this.authService = authService;
        this.toastr = toastr;
        this.domainService = domainService;
    }
    FanditService.prototype.fanditAccess = function () {
        var url = this.domainService.backendUrlWithContext + '/fandit/access/';
        var form = new FormData();
        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post(url, form).toPromise();
    };
    FanditService.ngInjectableDef = i0.defineInjectable({ factory: function FanditService_Factory() { return new FanditService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.ToastrService), i0.inject(i4.DomainService)); }, token: FanditService, providedIn: "root" });
    return FanditService;
}());
export { FanditService };
