import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'app/security/auth.service';

@Component({
    selector: 'app-event-data',
    templateUrl: './event-data.component.html',
    styleUrls: ['./event-data.component.scss']
})
export class EventDataComponent {

    @Input() data: any;

    
    showDirectAreas: Boolean = true;

    constructor(
        private authService: AuthService
      ) { }
    
      ngOnInit() {
        if ((this.authService.isLogged())) {
          this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }
      }

    getReason(): string {
        if (this.data && this.data.rejected) {
            switch (this.data.statusReason) {
                case 'EFPO':
                    return 'Rechazo por efecto de la póliza posterior al siniestro';
                case 'HENO':
                    return 'Rechazo por hecho no cubierto';
                case 'OTRO':
                    return 'Rechazo por Otros Motivos';
                case 'PECA':
                    return 'Rechazo por período de carencia';
                case 'POBA':
                    return 'Rechazo por póliza de baja';
                case 'POSU':
                    return 'Rechazo por póliza en suspenso';
                case 'REPE':
                    return 'Rechazo por recibo de póliza pendiente';
                case 'RINO':
                    return 'Rechazo por riesgo no asegurado';
                default:
                    return '';
            }
        }
        else {
            return '';
        }

    }

}
