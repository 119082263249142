<ng-container *ngIf="isReinsuredParam; then isReinsured; else isNotReinsured">
</ng-container>

<ng-template #isReinsured>
    <div class="contact">
        <p>Si necesitas ayuda para este proceso, te ayudamos <a href="https://onlygal.es/area-cliente-de-onlygal/" target="_blank">aquí</a></p>
    </div>
</ng-template>

<ng-template #isNotReinsured>
    <div class="contact">
        <p>Si necesitas ayuda para este proceso, te ayudamos <a href="https://www.onlygal.es/area-cliente/" target="_blank">aquí</a></p>
    </div>
</ng-template>