import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';

@Component({
    selector: 'app-noticias-club',
    templateUrl: './noticias-club.component.html',
    styleUrls: ['./noticias-club.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NoticiasClubComponent implements OnInit{

    loading: Boolean = true;
    showArrow: boolean = false;
    @Input() delay;
    @Input() categoria: CategoriaDataDTO[];
    expan: boolean = false;
    isMobilePhone: boolean;
  
    constructor(private router: Router) {}
  
    ngOnInit() {}
  
    ngAfterViewInit (){
          this.loading = true;       
    }
  
    getDelay(): String {
        return (this.delay * 0.2) + 's';
    }
  
    getStyle(imagen: string) {
        if (imagen !== null) {
            return {
                'background-image': 'url(' + imagen + ')'
            }
        }
        return '';
    }
  
    detallePost(categoria:string ,id: string){
        this.router.navigate(['/club-del-propietario/detalle/' + categoria + '/' + id]);
    }
}
