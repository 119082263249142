import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthenticationPageComponent } from './pages/authentication-page/authentication-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { ConfirmRegisterPageComponent } from './pages/confirm-register-page/confirm-register-page.component';

import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { ConfirmRegisterFormComponent } from './components/confirm-register-form/confirm-register-form.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { PictureWithTextComponent } from './components/picture-with-text/picture-with-text.component';
import { WelcomeTextComponent, ValueChangeDetectorDirective } from './components/welcome-text/welcome-text.component';
import { AuthenticationFormComponent } from './components/authentication-form/authentication-form.component';
import { EmailNotifyComponent } from './components/email-notify/email-notify.component';

import { CommonsModule } from '../commons/commons.module';
import { SecurityModule } from '../security/security.module';
import { WpEmptyPageComponent } from './pages/wp-empty-page/wp-empty-page.component';
import { SelectedEntityComponent } from './components/selected-entity/selected-entity.component';
import { EntitiesPageComponent } from './pages/entities-page/entities-page.component';
import { ConfirmSmsRegisterPageComponent } from './pages/confirm-sms-register-page/confirm-sms-register-page.component';
import { ConfirmSmsRegisterFormComponent } from './components/confirm-sms-register-form/confirm-sms-register-form.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ConfirmSmsRegisterResendFormComponent } from './components/confirm-sms-resend-register-form/confirm-sms-register-resend-form.component';
import { TakerBlockNotifyComponent } from './components/taker-block-notify/taker-block-notify.component';

const appRoutes: Routes = [

    {
        path: 'authentication',
        component: AuthenticationPageComponent,
    },
    {
        path: 'authentication/:authenticationcode',
        component: AuthenticationPageComponent,
    },

    {
        path: 'entities',
        component: EntitiesPageComponent,
    },

    {
        path: 'change-password',
        component: ChangePasswordPageComponent,
    },

    {
        path: 'confirm-register',
        component: ConfirmRegisterPageComponent,
    },

    {
        path: 'confirm/:code',
        component: ConfirmSmsRegisterPageComponent,
    },

    {
        path: 'confirm/:code/reinsured/:reinsured',
        component: ConfirmSmsRegisterPageComponent,
    },

    {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
    },

    {
        path: 'register',
        redirectTo: 'register/step1',
    },
    {
        path: 'register/step1',
        component: RegisterPageComponent,
    },

    {
        path: 'register/conditions-of-use',
        component: WpEmptyPageComponent,
        data: { page: 'conditions-of-use' }
    },

    {
        path: 'register/cookies-policy',
        component: WpEmptyPageComponent,
        data: { page: 'cookies-policy' }
    },

    {
        path: 'register/privacy-politics',
        component: WpEmptyPageComponent,
        data: { page: 'privacy-politics' }
    },


];

@NgModule({
    declarations: [RegisterPageComponent, ForgotPasswordPageComponent, ChangePasswordPageComponent, ConfirmSmsRegisterFormComponent,
        ConfirmRegisterPageComponent, RegisterFormComponent, ForgotPasswordFormComponent, ConfirmSmsRegisterPageComponent,
        ChangePasswordFormComponent, ConfirmRegisterFormComponent, PictureWithTextComponent, WpEmptyPageComponent,
        WelcomeTextComponent, AuthenticationFormComponent, AuthenticationPageComponent, EmailNotifyComponent, TakerBlockNotifyComponent,
        ConfirmSmsRegisterResendFormComponent, ValueChangeDetectorDirective, SelectedEntityComponent, EntitiesPageComponent],

    imports: [

        RouterModule.forRoot(appRoutes),

        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,

        AngularMultiSelectModule,

        SecurityModule,
        CommonsModule

    ],
    exports: [
        RouterModule
    ]

})
export class SideBySideTemplateModule { }
