import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-profile-preference',
    templateUrl: './profile-preference.component.html',
    styleUrls: ['./profile-preference.component.scss']
})
export class ProfilePreferenceComponent {

    @Input() data: any = undefined;

}
