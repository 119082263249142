<div class="products">
    <div class="products-box {{ getBackgroundColor() }}"></div>
    <div class="products-icon">

        <div class="products-icon-img">
            <app-icon-type [icon]="'legal'" color="{{ getIconColor() }}"></app-icon-type>
            <p>{{ data?.producto }}</p>
            <p>{{ data?.servicio }}</p>
        </div>
    </div>
    <div class="products-policy">
        <div class="sup">
            <p>Nº de contrato:</p>
            <span>{{ data?.contrato }}</span>
        </div>
        <div class="sup-1">
            <p>Fecha de contratación:</p>
            <span>{{ data?.fechaContratacion }}</span>
        </div>
        <div class="inf">
            <p>Estado:</p>
            <span>{{ data?.estado }}</span>
        </div>
        <div class="inf-1">
            <p>Sub-estado:</p>
            <span>{{ data?.subestado }}</span>
        </div>
    </div>
    <div class="products-product"> 
        <p *ngIf="data && data.letradoApiResponse && data.letradoApiResponse.codigo">Datos del letrado</p>
        <div class="products-policy" style="position: initial;" *ngIf="data && data.letradoApiResponse && data.letradoApiResponse.codigo">
            <div class="sup">
                <p>Nombre:</p>
                <span>{{ data?.letradoApiResponse.nombre }}</span>
            </div>
            <div class="sup-1">
                <p>Apellidos:</p>
                <span>{{ data?.letradoApiResponse.apellidos }}</span>
            </div>
            <div class="inf">
                <p>Correo:</p>
                <span>{{ data?.letradoApiResponse.correo }}</span>
            </div>
            <div class="inf-1">               
                <p>Proveedor:</p>
                <span>{{ data?.letradoApiResponse.proveedor }}</span>
            </div>
        </div>
    </div>
    <div class="products-button" *ngIf="showDetailsButton">
        <app-split-button *ngIf=!loading label="Quiero..." direction=top [(close)]="close">
            <app-split-button-item label="Ver detalles" route="/productService/{{data.expediente}}">
            </app-split-button-item>
        </app-split-button>
    </div>
</div>