import { Component, OnInit } from '@angular/core';
import { CommonsText } from 'app/commons/CommonsText';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-header-profile-progress-bar-simple',
    templateUrl: './header-profile-progress-bar-simple.component.html',
    styleUrls: ['./header-profile-progress-bar-simple.component.scss']
})
export class HeaderProfileProgressBarSimpleComponent implements OnInit {

    percent: Number = 0;

    constructor(private clientService: ClientService, private toastr: ToastrService) { }

    ngOnInit() {
        this.clientService.getClient()
            .then(value => {
                this.percent = ((this.clientService.getAddressPercent(value) + this.clientService.getContactPercent(value)) / 2);
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

}
