import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'app/services/client-service';
import { SubCategoriaDataDTO } from 'app/shared/models/wordpress/SubCategoriaDataDTO';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
  selector: 'app-subcategoria',
  templateUrl: './subcategoria.component.html',
  styleUrls: ['./subcategoria.component.scss']
})
export class SubCategoriaComponent implements OnInit {
  
  @Input() subcategoria: SubCategoriaDataDTO; 

  loadingProfile: Boolean = false;
  isMobilePhone: boolean;

  constructor(private clientService: ClientService, 
              private toastr: ToastrService,
              private isMobile: IsMobileService) { 
                this.isMobilePhone = isMobile.isMobile;
              }

  ngOnInit() {
    this.loadingProfile = true;
  }

  ngAfterViewInit() {
      this.clientService.getBannersInfo()
          .then(success => {
              this.loadingProfile = false;
          }).catch(error => {
              this.loadingProfile = false;
              this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                  timeOut: 5000
              });
          });
    }
}