<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Detalle de expediente {{expediente}}"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>


<div class="container">
    <div *ngIf="undefined === packDetalleApiresponse && undefined === gastosDetalleApiResponse && undefined === precioDetalleApiResponse " class="products-detail-container pre-loading">
        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
    </div>
    
    <div class="no-text-select products-detail-container ">

        <div class="products-detail-container-product">
            <app-product-service [data]=service></app-product-service>
        </div>

        <div *ngIf="packDetalleApiresponse">
            <app-collapse-container *ngIf="packDetalleApiresponse" caption="{{packDetalleApiresponse.titulo}}">
                <div *ngFor="let item of packDetalleApiresponse.listaSubPack">
                    <div  class="contracted-cont" *ngIf="item.titulo">
                        <div class="contracted-cont">
                            <div class="contracted-cont-title">
                                <div>
                                    <p>{{item.titulo}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="contracted-inner-app">
                            <div class="contracted">
                                <div class="contracted-main">
                                    <div>
                                        <p>{{item.texto1}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contracted-inner-app" *ngIf="item.texto2">
                            <div class="contracted-main">
                                <div>
                                    <p>{{item.texto2}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="contracted-inner-app" *ngIf="item.texto3">
                            <div class="contracted-main">
                                <div>
                                    <p>{{item.texto3}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-collapse-container>
        </div>
    
        <div *ngIf="undefined !== precioDetalleApiResponse">
            <app-collapse-container *ngIf="precioDetalleApiResponse" caption="Precio">
                <div class="contracted-cont">
                    <div class="contracted-cont">
                        <div class="contracted-cont-title">
                            <div class="left">
                                <p></p>
                            </div>
                            <div class="center">
                                <p>IMPORTE</p>
                            </div>
                            <div class="right">
                                <p>IVA</p>
                            </div>
                        </div>
                    </div>
                    <div class="contracted-inner-app">
                        <div class="contracted">
                            <div class="contracted-main">
                                <div class="left">
                                    <p>{{precioDetalleApiResponse.titulo}}</p>
                                </div>
                                <div class="center">
                                    <p>{{precioDetalleApiResponse.importe}}</p>
                                </div>
                                <div class="right">
                                    <p>{{precioDetalleApiResponse.iva}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contracted-inner-app" *ngIf="precioDetalleApiResponse.letrapeque && precioDetalleApiResponse.letrapeque.replace(' ', '') != ''">
                        <div class="contracted">
                            <div class="contracted-main">
                                <div>
                                    <p>{{precioDetalleApiResponse.letrapeque}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contracted-inner-app" *ngIf="precioDetalleApiResponse.extra && precioDetalleApiResponse.extra.replace(' ', '') != ''">
                        <div class="contracted">
                            <div class="contracted-main">
                                <div>
                                    <p>{{precioDetalleApiResponse.extra}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-collapse-container>
        </div>
    
        <div *ngIf="gastosDetalleApiResponse">
            <app-collapse-container *ngIf="gastosDetalleApiResponse" caption="{{gastosDetalleApiResponse.titulo}}">
                <div class="contracted-cont">
                    <div *ngIf="gastosDetalleApiResponse.texto1"  class="contracted-inner-app">
                        <div class="contracted">
                            <div class="contracted-main">
                                <div>
                                    <p>{{gastosDetalleApiResponse.texto1}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="gastosDetalleApiResponse.texto2" class="contracted-inner-app">
                        <div class="contracted">
                            <div class="contracted-main">
                                <div>
                                    <p>{{gastosDetalleApiResponse.texto2}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-collapse-container>
        </div>
    </div>
    <div>
        <div class="onlygal-info">
            <p>Gestionado por:</p>
            <p>&nbsp;</p>
            <p><span>Accede aquí a tu <a target="_blank" [href]="urlOnlygal" (click)="serviceAccess()">Área Privada</a></span></p>
            <p><span>para gestionar y contratar tus servicios</span></p>
        </div>
    </div>
</div>

<app-footer></app-footer>