<div class="warranty" *ngIf="undefined !== data.coverage">
    <div class="warranty-title">
        <p>Coberturas y capitales asegurados</p>
    </div>

    <div *ngIf="mobileSize">
        <div class="warranty-main" *ngFor="let v of data.coverage">
            <div class="warranty-main-cont">
                <div class="left">
                    <span>Cobertura:</span>
                    <p>{{v.description}}</p>
                </div>
                <div class="right">
                    <span>Capital:</span>
                    <p>{{v.capital}}</p>
                </div>
                <div class="right">
                    <span>Capital libre elección:</span>
                    <p>{{v.capitalFree}}</p>
                </div>
                <div class="right">
                    <span>Carencia:</span>
                    <p>{{v.carencia}}</p>
                </div>
                <div class="right">
                    <span>Franquicia:</span>
                    <p>{{v.franquicia}}</p>
                </div>
                <div class="right">
                    <span>Mínimo litigioso:</span>
                    <p>{{v.minLitigioso}}</p>
                </div>
            </div>
            <hr size="1rem" style="margin-top: 1.5rem;" *ngIf="(data.coverage).length>1" />
        </div>
    </div>

    <div *ngIf="desktopSize">
        <div class="warranty-main">
            <div class="warranty-main-cont">
                <div class="left">
                    <span>Cobertura:</span>
                </div>
                <div class="right">
                    <span>Capital:</span>
                </div>
                <div class="right">
                    <span>Capital libre elección:</span>
                </div>
                <div class="right">
                    <span>Carencia:</span>
                </div>
                <div class="right">
                    <span>Franquicia:</span>
                </div>
                <div class="right">
                    <span>Mínimo litigioso:</span>
                </div>
            </div>
            <div class="warranty-main-cont" *ngFor="let v of data.coverage">
                <div class="left">
                    <p>{{v.description}}</p>
                </div>
                <div class="right">
                    <p>{{v.capital}}</p>
                </div>
                <div class="right">
                    <p>{{v.capitalFree}}</p>
                </div>
                <div class="right">
                    <p>{{v.carencia}}</p>
                </div>
                <div class="right">
                    <p>{{v.franquicia}}</p>
                </div>
                <div class="right">
                    <p>{{v.minLitigioso}}</p>
                </div>
            </div>
        </div>
    </div>

</div>