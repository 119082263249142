<div [ngSwitch]="isMobile">

    <div *ngSwitchCase="true" class="timerPickerMobile">
        <label class="label-upperline " [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red'">{{label}}</label>
        <input type="time" [value]="value"
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            (input)="onChange($event.target.value)" (blur)="onTouched()" />

        <div class="description">
            <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
                <label>
                    {{description}}
                </label>
            </p>
            <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">
                <label>
                    {{errorDescription}}
                </label>
            </p>
        </div>

    </div>

    <div *ngSwitchDefault class="timerPickerDesktop">
        <label class="label-upperline " [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red'">{{label}}</label>
        <input type="text" [value]="value"
            [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'border-gray text-black' : 'border-red text-red'"
            (focusout)="onFocusOut($event.target.value)"
            (input)="onChange($event.target.value)" (blur)="onTouched()"
            (click)="pickerVisible = !pickerVisible" mask="00:00" />

        <div class="description">
            <p *ngIf="!(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-black">
                <label>
                    {{description}}
                </label>
            </p>
            <p *ngIf="(ngControl.invalid && (ngControl.touched || ngControl.dirty))" class="text-red text-bold">
                <label>
                    {{errorDescription}}
                </label>
            </p>
        </div>

        <button type="button" class="btn-icon" (click)="pickerVisible = !pickerVisible">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24"
                height="24" viewBox="0 0 24 24">
                <path fill="#4d87c7"
                    d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
            </svg>
        </button>
        <div class="content" *ngIf="pickerVisible">
            <app-time [hour]="hour" [minute]="minute" (selected)="onSelectCallback($event)"
                [disabledHours]="disabledHours">
            </app-time>
            <div class="content-footer">
                <button type="button" (click)="onSetTimeToNow()">Ahora</button>
            </div>
        </div>
    </div>

</div>