<div class="blog no-text-select wow fadeInUp" data-wow-duration="1s" [attr.data-wow-delay]="getDelay()">
    <div class="clubPropietarioCategoria">
        <div class="container no-text-select">
            <div class="positionVer">
                <div class="tituloClub">
                    <h1>{{categoria.titulo}}</h1>
                </div>
                <div *ngIf="!isMobilePhone" class="verDiv">
                    <span class="buttonVer" (click)="irDetalleCategoria()">Ver Más</span>
                </div>
            </div>
            <div class="pestania">           
                <mat-tab-group  id="categoria">
                    <mat-tab *ngFor="let item of categoria.subCategoriaList; let i = index" [attr.data-index]="i"  title="subcategoria{{item.id}}">
                        <ng-template mat-tab-label>
                            <img src="{{item.icono}}" class="mat-tab-img">
                            <label for="subcategoria{{item.id}}" role="button" class="mat-tab-labe">
                                <span>{{item.titulo}}</span>
                            </label>
                        </ng-template>
                        <div class="content" id="parent" [ngClass]="{'minTamanio': !expan}">
                            <div *ngIf="item.noticiaList.length == 0">
                                <div class="grid">
                                    <div></div>
                                    <div class="parrafo">
                                        <p>¡Lo sentimos! Ahora mismo no hay ningún consejo sobre este tema.</p>
                                        <p>Prueba con otra pestaña</p>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div *ngIf="item.noticiaList.length > 0" class="gridNoticia">
                                <div *ngFor="let noticias of item.noticiaList" class="noticia" (click)="detallePost(noticias.categoria, noticias.id)">
                                    <div class="imagenPostDiv">
                                        <img class="imagenPost" [ngStyle]="getStyle(noticias.imagen ? noticias.imagen.source_url : null)">
                                    </div>
                                    <div class="enlacePost">
                                        <span  [innerHtml]="noticias.titulo"></span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="item.noticiaList.length >= 5" [ngClass]="{'arrowRotate': expan}" data-wow-duration="5s" [attr.data-wow-delay]="getDelay()" class="arrow" (click)="expandir()"></div>
                        </div>
                    </mat-tab>
                </mat-tab-group>            
            </div>
            <div *ngIf="isMobilePhone" class="verDivMobile">
                <span class="buttonVer" (click)="irDetalleCategoria()">Ver Más</span>
            </div>
        </div>
    </div>
</div>