<div class="products">
    <div class="products-box {{ getBackgroundColor() }}"></div>
    <div class="products-icon">

        <div class="products-icon-img">
            <app-icon-type [icon]="data.icon" color="{{ getIconColor() }}"></app-icon-type>
        </div>
    </div>
    <div class="products-policy">
        <div class="sup">
            &nbsp;
        </div>
        <div class="sup-1">
            <p>{{data.title}}</p>
        </div>

        <div class="inf">
            <p>{{data.description}}</p>
        </div>

        <div class="inf-1">
            &nbsp;
        </div>

    </div>
    <div class="products-button">
        <app-spinner-button [label]="'Contratar'" [enabled]="true" [customSpinnerButton]="'hire-more'" (click)=openExternalLink()>
        </app-spinner-button>
    </div>
</div>