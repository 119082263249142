<div class="page-container" [formGroup]="secondStepForm">

    <div class="group" [hidden]="!(this.insuredList.length > 1)">
        <div class="title">
            <p>Seleccione un asegurado</p>
        </div>
        <div class="content">
            <div class="row row-padding">
                <angular2-multiselect [data]="insuredList" [settings]="insuredListSettings"
                    (onSelect)="onChangeInsured($event)" formControlName="insured">
                </angular2-multiselect>
            </div>
        </div>
    </div>

    <div class="group">
        <div class="title">
            <p>Datos del asegurado</p>
        </div>
        <div class="content">

            <div class="row-50">
                <app-input-box type="text" id="name" label="Nombre" errorDescription="(*) Campo obligatorio"
                    formControlName="name" maxlength="160">
                </app-input-box>

                <app-input-box type="text" id="surname" label="Primer apellido" errorDescription="(*) Campo obligatorio"
                    formControlName="surname" maxlength="60">
                </app-input-box>
            </div>

            <div class="row-50">
                <app-input-box type="text" label="Segundo apellido" errorDescription="(*) Campo obligatorio"
                    formControlName="lastName" maxlength="60">
                </app-input-box>

                <div class="row-25-75">
                    <div class="data-data-select">
                        <angular2-multiselect [data]="documentTypeList" id="documentType" [settings]="documentTypeSettings"
                            formControlName="documentType" id="documentType" (onSelect)="changeDocumentType($event)">
                        </angular2-multiselect>
                    </div>
                    <div class="data-data-num">
                        <app-input-box type="text" label="Nº de documento" formControlName="documentNumber" id="documentNumber"
                            errorDescription="Nº de Documento">
                        </app-input-box>
                    </div>
                </div>

            </div>

            <div class="row-50">
                <app-input-box type="text" label="E-MAIL" id="email" formControlName="email"
                    errorDescription="(*) Campo obligatorio" maxlength="128" (focusout)="onChangeEmail($event)">
                </app-input-box>

                <app-input-datepicker-box *ngIf="showBirthDate" id="bornDate" label="FECHA DE NACIMIENTO" formControlName="bornDate"
                    [maxDate]="maxDate" minAgeErrorDescription="(*) Debe ser mayor de edad"
                    errorDescription="(*) Campo obligatorio">
                </app-input-datepicker-box>
            </div>

            <div class="row-50">
                <div class="row-25-75 inner-row-phone">
                    <div class="prefix-container">
                        <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                            formControlName="phonePrefix">
                            <c-badge>
                                <ng-template let-item="item">
                                    <label class="phoneBadge">
                                        <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                        <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                        <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-badge>
                            <c-item>
                                <ng-template let-item="item">
                                    <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                        <span class="flags flag{{item.id}}"></span>
                                        <span>+{{item.id}}</span>
                                        <span>{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>
                    </div>

                    <app-input-box type="text" label="TELÉFONO FIJO" id="phone" formControlName="phone" maxlength="128"
                        mask="000000000000000000" isValidPhoneErrorDescription="Número no válido" (focusout)="onChangePhone($event)"></app-input-box>
                </div>

                <div class="row-25-75 inner-row-phone">
                    <div class="prefix-container">
                        <angular2-multiselect [data]="prefixData" [settings]="prefixSettings"
                            formControlName="mobilePhonePrefix">
                            <c-badge>
                                <ng-template let-item="item">
                                    <label class="phoneBadge">
                                        <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                                        <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                        <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-badge>
                            <c-item>
                                <ng-template let-item="item">
                                    <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                        <span class="flags flag{{item.id}}"></span>
                                        <span>+{{item.id}}</span>
                                        <span>{{item.itemName}}</span>
                                    </label>
                                </ng-template>
                            </c-item>
                        </angular2-multiselect>
                    </div>
                    <app-input-box type="text" label="TELÉFONO MOVIL" id="mobilePhone" formControlName="mobilePhone" maxlength="128"
                        mask="000000000000000000" isValidPhoneErrorDescription="Número no válido" (focusout)="onChangeMobile($event)"></app-input-box>

                </div>

            </div>

        </div>

        <div class="content-divider"></div>

        <div class="content content-address">
            <div class="row row-search">
                <div class="row-50 row-search-inner">
                    <app-input-box type="text" label="C. POSTAL" id="postalCode" formControlName="postalCode" mask="00000"
                        errorDescription="(*) Campo obligatorio" maxlength="5" (keyup)="onChangePostalCode($event)"
                        (change)="onChangePostalCode($event)">
                    </app-input-box>
                    <div class="row-50-search">
                        <a (click)="searchCity()">Buscar población</a>
                    </div>
                </div>
                <div class="row-city">

                    <div class="row-city-combo">
                        <angular2-multiselect [data]="cityList" [settings]="citySettings" id="city" formControlName="city"
                            (onSelect)="onCitySelect($event)"></angular2-multiselect>
                    </div>

                    <span *ngIf="loadingCities" class="spinner"></span>

                    <div class="description">
                        <p *ngIf="isInvalid('city')" class="text-red text-bold">
                            <label>
                                (*) Campo obligatorio
                            </label>
                        </p>
                    </div>
                </div>

            </div>

            <div class="row row-province">
                <div class="disabledField select">
                    <label for="">Provincia</label>
                    <p>{{province}}</p>
                </div>
            </div>

            <div class="row row-25-75">
                <div class="cont select-roadType">
                    <angular2-multiselect [data]="data.roadTypes" id="roadType" [settings]="roadTypeSettings"
                        formControlName="roadType">
                    </angular2-multiselect>
                </div>

                <app-input-box type="text" label="DOMICILIO" id="address" formControlName="address"
                    errorDescription="(*) Campo obligatorio" maxlength="80"></app-input-box>

            </div>

            <div class="row-20-20">

                <app-input-box type="text" label="NÚMERO" formControlName="number" id="number"
                    errorDescription="(*) Campo obligatorio" maxlength="5"></app-input-box>

                <app-input-box type="text" label="PORTAL" formControlName="portal" maxlength="32"></app-input-box>

                <app-input-box type="text" label="ESCALERA" formControlName="stair" maxlength="16"></app-input-box>

                <app-input-box type="text" label="PISO" formControlName="floor" maxlength="20"></app-input-box>

                <app-input-box type="text" label="PUERTA" formControlName="door" mask="AA" maxlength="16"></app-input-box>
            </div>


        </div>

        <div class="content-divider"></div>

        <div class="content content-address documentation-main-cont">
            <div class="left"></div>
            <div class="right">

                <ngfFormData [files]="files" postName="file" [(FormData)]="uploadFormData"></ngfFormData>

                <div *ngIf="files.length > 0 && checkFile()" >
                    <div *ngFor="let item of files; let i = index" class="files">
                        <div class="files-main" >
                            <div class="name">
                                <div class="img">
                                    <div class="img-inner"></div>
                                </div>
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="size">
                                <p>{{ item.size / 1024 / 1024 | number: ".2" }} MB</p>
                            </div>
                            <div class="erase">
                                <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i, 1)"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div ngfDrop multiple="1" selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
                        [(dragFiles)]="dragFiles" class="well my-drop-zone"
                        [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
                        (filesChange)="lastFileAt = getDate()">
                        <div class="img"></div>
                        <p>Haga clic o arrastre para adjuntar un documento.</p>
                    </div>
                    <p class="errorDescription">El archivo a adjuntar debe tener como máximo 10MB y los formatos permitidos son .pdf e imágenes.</p>
                </div>

            </div>


        </div>

    </div>

</div>