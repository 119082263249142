export const environment = {

    production: false,

    authorization: 'RlJPTlRFTkRfTUlEQVM6a0lqb2lRMnhwWlc1MFNXUWlMQ0pzWVhOMFEyOXVibVZqZEdsdmJpSTZiblZzYkN3aWMyTnZjR1VpT2xzaWM=',
    clientId: 'FRONTEND_MIDAS',

    urlWordpressClubPropietario:'/club-del-propietario',

    backendUrlWithContext: 'https://intclientes.onlygal.es/midas_api',
    wordpressUrl: 'https://intclienteswp.onlygal.es',
    frontEndUrl: 'https://intclientes.onlygal.es',

    chatUrl: 'https://omnitest.invoxcontact.com',
    chatToken: 'D4dURf9pN1k1iER3XzHqq99c',
    chatPack: '/packs/js/sdk.js',

    blogUrl: 'https://www.onlygal.es/juridico/wp-json/wp/v2/posts?_embed',

    reCaptchaKey: '6LfMptsZAAAAAISuiGvorXTYTVVXfgsYHlPlYWr2',
    siteKey: '6LfMptsZAAAAAFS3zb1dz9xVHT04CkGsNR79KV6H',

};
