<div class="products">
    <!-- nuevo -->
    <div class="products-box {{ getBackgroundColor() }}"></div>
    <!-- nuevo -->
    <div class="products-icon">

        <div class="products-icon-img">
            <app-icon-type [icon]="data.branchType" color="{{ getIconColor() }}"></app-icon-type>
            <p>
                Estado: <span [ngStyle]="getStatusColor()">{{ data.status }}</span>
            </p>
        </div>
        <!-- nuevo -->
    </div>
    <div class="products-policy">
        <div class="sup">
            <p>Nº de póliza / contrato:</p>
            <span>{{ data?.number }} </span> <span style="color:gray; font-size: 13px;" *ngIf="undefined !== data?.collective"> (Colectivo {{ data?.collective }} ) </span>
        </div>
        <div class="inf">
            <p>Vigencia:</p>
            <span>{{ data?.startDate | date: "dd/MM/yyyy" }} -
                {{ data?.endDate | date: "dd/MM/yyyy" }}</span>
        </div>
    </div>
    <div class="products-product">
        <p>Producto:</p>
        <span>{{ data?.product }}</span><br>
        <span>{{data?.riskDescription}}</span>
        <div class="taker" *ngIf="undefined !== data?.taker && data.isTaker === true">
            <p>Tomador:</p>
            <span>{{data?.taker.name}} {{data?.taker.surname}} {{data?.taker.lastname}}</span>
        </div>
    </div>
    <div class="products-button">
        <app-split-button label="QUIERO..." [anulado]="data.suspended" direction="bottom-1" [(close)]="close">
            <app-split-button-item label="Abrir un parte" *ngIf="!data.suspended && showDirectAreas" route="/events/register/{{ data?.number }}">
            </app-split-button-item>
            <app-split-button-item label="Consultar un parte" route="/events/history/{{ data?.number }}">
            </app-split-button-item>
            <app-split-button-item *ngIf="data.isTaker === true && greatherThan()" label="Descargar el PDF del contrato" (click)="onGetContractFile()">
            </app-split-button-item>
            <app-split-button-item *ngIf="data.isTaker === true && data.isEditable === true" label="Modificar o ampliar la cobertura" route="/hire/extend/{{data?.number}}"></app-split-button-item>
            <app-split-button-item label="Ver detalles" route="/products/{{ data?.number }}"></app-split-button-item>
        </app-split-button>
    </div>
</div>