/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-assistance-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./event-assistance-image.component";
var styles_EventAssistanceImageComponent = [i0.styles];
var RenderType_EventAssistanceImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventAssistanceImageComponent, data: {} });
export { RenderType_EventAssistanceImageComponent as RenderType_EventAssistanceImageComponent };
export function View_EventAssistanceImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "assistance-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "assistance  wow fadeInUp"], ["data-wow-duration", "1s"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "assistance-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFNecesitas ayuda?"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "assistance-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "img"]], null, null, null, null, null))], null, null); }
export function View_EventAssistanceImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-event-assistance-image", [], null, null, null, View_EventAssistanceImageComponent_0, RenderType_EventAssistanceImageComponent)), i1.ɵdid(1, 114688, null, 0, i2.EventAssistanceImageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventAssistanceImageComponentNgFactory = i1.ɵccf("app-event-assistance-image", i2.EventAssistanceImageComponent, View_EventAssistanceImageComponent_Host_0, {}, {}, []);
export { EventAssistanceImageComponentNgFactory as EventAssistanceImageComponentNgFactory };
