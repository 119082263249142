import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-profile-bank',
    templateUrl: './profile-bank.component.html',
    styleUrls: ['./profile-bank.component.scss']
})
export class ProfileBankComponent {

    @Input() data: any;


}
