/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entities-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/selected-entity/selected-entity.component.ngfactory";
import * as i3 from "../../components/selected-entity/selected-entity.component";
import * as i4 from "../../../security/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/domain-service";
import * as i7 from "../../../services/user-service";
import * as i8 from "../../components/welcome-text/welcome-text.component.ngfactory";
import * as i9 from "../../components/welcome-text/welcome-text.component";
import * as i10 from "@angular/common";
import * as i11 from "./entities-page.component";
import * as i12 from "../../../services/client-service";
var styles_EntitiesPageComponent = [i0.styles];
var RenderType_EntitiesPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntitiesPageComponent, data: {} });
export { RenderType_EntitiesPageComponent as RenderType_EntitiesPageComponent };
function View_EntitiesPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-selected-entity", [], null, null, null, i2.View_SelectedEntityComponent_0, i2.RenderType_SelectedEntityComponent)), i1.ɵdid(2, 114688, null, 0, i3.SelectedEntityComponent, [i4.AuthService, i5.Router, i6.DomainService, i7.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_EntitiesPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "sbs-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "container sbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-welcome-text", [], null, null, null, i8.View_WelcomeTextComponent_0, i8.RenderType_WelcomeTextComponent)), i1.ɵdid(4, 49152, null, 0, i9.WelcomeTextComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntitiesPageComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showEntities; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_EntitiesPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-entities-page", [], null, null, null, View_EntitiesPageComponent_0, RenderType_EntitiesPageComponent)), i1.ɵdid(1, 114688, null, 0, i11.EntitiesPageComponent, [i5.Router, i4.AuthService, i12.ClientService, i7.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntitiesPageComponentNgFactory = i1.ɵccf("app-entities-page", i11.EntitiesPageComponent, View_EntitiesPageComponent_Host_0, {}, {}, []);
export { EntitiesPageComponentNgFactory as EntitiesPageComponentNgFactory };
