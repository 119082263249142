import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/security/auth.service';

@Component({
  selector: 'app-header-last-conection',
  templateUrl: './header-last-conection.component.html',
  styleUrls: ['./header-last-conection.component.scss']
})
export class HeaderLastConectionComponent implements OnInit {

  lastConnection: String = undefined;

  constructor(private authService: AuthService) { }

  ngOnInit() {
      this.lastConnection = this.authService.getUser().lastConnection;
  }

}
