/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-line.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contact-line.component";
var styles_ContactLineComponent = [i0.styles];
var RenderType_ContactLineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactLineComponent, data: {} });
export { RenderType_ContactLineComponent as RenderType_ContactLineComponent };
function View_ContactLineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ContactLineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Si necesitas ayuda para este proceso, te ayudamos "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://onlygal.es/area-cliente-de-onlygal/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["aqu\u00ED"]))], null, null); }
function View_ContactLineComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Si necesitas ayuda para este proceso, te ayudamos "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://www.onlygal.es/area-cliente/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["aqu\u00ED"]))], null, null); }
export function View_ContactLineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactLineComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["isReinsured", 2]], null, 0, null, View_ContactLineComponent_2)), (_l()(), i1.ɵand(0, [["isNotReinsured", 2]], null, 0, null, View_ContactLineComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isReinsuredParam; var currVal_1 = i1.ɵnov(_v, 2); var currVal_2 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ContactLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-line", [], null, null, null, View_ContactLineComponent_0, RenderType_ContactLineComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContactLineComponent, [], null, null)], null, null); }
var ContactLineComponentNgFactory = i1.ɵccf("app-contact-line", i3.ContactLineComponent, View_ContactLineComponent_Host_0, { isReinsuredParam: "isReinsuredParam" }, {}, []);
export { ContactLineComponentNgFactory as ContactLineComponentNgFactory };
