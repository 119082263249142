import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { CommonsText } from 'app/commons/CommonsText';


@Component({
    selector: 'app-hire-form-page',
    templateUrl: './hire-form-page.component.html',
    styleUrls: ['./hire-form-page.component.scss']
})
export class HireFormPageComponent implements OnInit {

    data: any = undefined;
    hireMoreForm: FormGroup;
    running: Boolean = false;
    isGeneralMod: Boolean = false;
    messages: any[] = [];
    selectedRisk: any;
    loadingPage: Boolean = true;
    policyYear: any;
    policyNumber: any;
    changeIdentifier: Boolean = false;


    constructor(private route: ActivatedRoute,
        private policyService: PolicyService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder) { }

    ngOnInit() {

        this.policyYear = this.route.snapshot.params['policyYear'];
        this.policyNumber = this.route.snapshot.params['policyNumber'];

        this.hireMoreForm = this.formBuilder.group({
            policyNumber: [this.policyNumber, [Validators.required]],
            policyYear: [this.policyYear, [Validators.required]],
            risk: [null],
            modificationDate: [null, [Validators.required, CustomDateValidator.afterEqualCurrentDate]],
            observation: [null],
            vehicleType: [null],
            newIdentifier: [null],
            identifier: [null],
        });

        this.hireMoreForm.controls['vehicleType'].disable();

        this.hireMoreForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

        this.loadPolicyItem();

        this.policyService.getHirePolicyDataByNumber(this.policyYear, this.policyNumber)
            .then(success => {
                this.data = success.response;
                this.setValidators();
                this.identifierSelected();
                this.loadingPage = false;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });


    }

    setValidators() {
        if (this.data.validChangeIdentifier && !this.isGeneralMod) {
            //this.hireMoreForm.controls["risk"].setValidators([Validators.required]);
            this.hireMoreForm.controls["newIdentifier"].setValidators([Validators.required]);
            this.hireMoreForm.controls["observation"].setValidators(null);
        } else {
            //this.hireMoreForm.controls["risk"].setValidators(null);
            this.hireMoreForm.controls["newIdentifier"].setValidators(null);
            this.hireMoreForm.controls["observation"].setValidators([Validators.required]);
        }
        this.hireMoreForm.controls["risk"].updateValueAndValidity();
        this.hireMoreForm.controls["newIdentifier"].updateValueAndValidity();
        this.hireMoreForm.controls["observation"].updateValueAndValidity();
    }

    errorDescription(controlName: string) {
        const control = this.hireMoreForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    }

    enableButton(){
        if (this.data.validChangeIdentifier && !this.isGeneralMod) {
            return !this.running && this.hireMoreForm.valid && this.hireMoreForm.controls["newIdentifier"].value;
        }
        else{
            return !this.running && this.hireMoreForm.valid;
        }
        
    }
    getIconColor(): any {
        if (undefined !== this.data) {
            return this.data.suspended ? 'red' : 'blue'
        }
    }

    getStatusColor(): any {
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? '#d22630' : 'black'
        }
        return {
            'color': color
        }
    }

    getBackgroundColor() {
        let color = 'black';
        if (undefined !== this.data) {
            color = this.data.suspended ? 'red' : 'blue'
        }
        return 'background-' + color
    }

    getButtonLabel() {
        return this.running ? CommonsText.CARGANDO_MINUSCULA : CommonsText.ENVIAR_MINUSCULAS;
    }

    setGeneralMod() {
        this.isGeneralMod = true;
        this.setValidators();
    }


    identifierSelected() {
        //const filtered = this.data.risksCompelted.filter(r => r.id == this.hireMoreForm.controls['risk'].value);
        if (this.data.validChangeIdentifier && !this.isGeneralMod) {
            this.selectedRisk = this.data.risksCompelted[0];
            const vehicleType = this.data.vehiculeTypes.filter(r => r.id == this.selectedRisk.modalveh);
            this.hireMoreForm.controls['vehicleType'].setValue(vehicleType[0].itemName);
            this.hireMoreForm.controls['identifier'].setValue(this.selectedRisk.description);
        }

    }
    loadPolicyItem() {
        this.policyService.getHirePolicyDataByNumber(this.policyYear, this.policyNumber)
            .then(success => {
                this.data = success.response;
                this.setValidators();
                this.identifierSelected();
                this.loadingPage = false;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }
    onFormSubmit() {
        this.loadingPage = true;

        this.messages = [];

        if (this.hireMoreForm.valid) {
            this.running = true;
            if (this.data.validChangeIdentifier && !this.isGeneralMod) {
                this.policyService.changeIdentifier(this.hireMoreForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.DATOS_ENVIADOS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });


                        this.hireMoreForm.controls['modificationDate'].setValue(null);
                        this.hireMoreForm.controls['observation'].setValue(null);
                        this.hireMoreForm.controls['newIdentifier'].setValue(null);

                        Object.keys(this.hireMoreForm.controls).forEach(key => {
                            this.hireMoreForm.get(key).markAsPristine();
                            this.hireMoreForm.get(key).markAsUntouched();
                            this.hireMoreForm.get(key).updateValueAndValidity();
                        });

                        this.loadPolicyItem();

                        this.changeIdentifier = true;

                    }).catch(error => {
                        this.running = false;
                        this.messages = error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            } else {
                this.policyService.onHireMoreFormSubmit(this.hireMoreForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.DATOS_ENVIADOS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });

                        this.hireMoreForm.controls['modificationDate'].setValue(null);
                        this.hireMoreForm.controls['observation'].setValue(null);
                        this.hireMoreForm.controls['newIdentifier'].setValue(null);

                        Object.keys(this.hireMoreForm.controls).forEach(key => {
                            this.hireMoreForm.get(key).markAsPristine();
                            this.hireMoreForm.get(key).markAsUntouched();
                            this.hireMoreForm.get(key).updateValueAndValidity();
                        });


                        this.loadPolicyItem();

                    }).catch(error => {
                        this.running = false;
                        this.messages = error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            }

        } else {

            Object.keys(this.hireMoreForm.controls).forEach(field => {
                this.hireMoreForm.get(field).markAsTouched({ onlySelf: true });
            });

        }
    }

}
