/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-profile-progress-bar-simple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header-profile-progress-bar-simple.component";
import * as i4 from "../../../../services/client-service";
import * as i5 from "ngx-toastr";
var styles_HeaderProfileProgressBarSimpleComponent = [i0.styles];
var RenderType_HeaderProfileProgressBarSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderProfileProgressBarSimpleComponent, data: {} });
export { RenderType_HeaderProfileProgressBarSimpleComponent as RenderType_HeaderProfileProgressBarSimpleComponent };
export function View_HeaderProfileProgressBarSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "progress-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Completa tu perfil"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "progress-inner "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "progress-inner-bar "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "bar "]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "width": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "progress-inner-percent "]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", "%"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, (_co.percent + "%")); _ck(_v, 7, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.percent; _ck(_v, 11, 0, currVal_1); }); }
export function View_HeaderProfileProgressBarSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-profile-progress-bar-simple", [], null, null, null, View_HeaderProfileProgressBarSimpleComponent_0, RenderType_HeaderProfileProgressBarSimpleComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderProfileProgressBarSimpleComponent, [i4.ClientService, i5.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderProfileProgressBarSimpleComponentNgFactory = i1.ɵccf("app-header-profile-progress-bar-simple", i3.HeaderProfileProgressBarSimpleComponent, View_HeaderProfileProgressBarSimpleComponent_Host_0, {}, {}, []);
export { HeaderProfileProgressBarSimpleComponentNgFactory as HeaderProfileProgressBarSimpleComponentNgFactory };
