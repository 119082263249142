/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/register-form/register-form.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../../components/register-form/register-form.component";
import * as i5 from "../../../services/user-service";
import * as i6 from "../../../security/auth.service";
import * as i7 from "../../../services/audity-service";
import * as i8 from "../../components/picture-with-text/picture-with-text.component.ngfactory";
import * as i9 from "../../components/picture-with-text/picture-with-text.component";
import * as i10 from "../../components/taker-block-notify/taker-block-notify.component.ngfactory";
import * as i11 from "../../components/taker-block-notify/taker-block-notify.component";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common";
import * as i14 from "./register-page.component";
var styles_RegisterPageComponent = [i0.styles];
var RenderType_RegisterPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterPageComponent, data: {} });
export { RenderType_RegisterPageComponent as RenderType_RegisterPageComponent };
function View_RegisterPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "side-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-register-form", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onRegisterSubmit"], [null, "onTakerBlock"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("onRegisterSubmit" === en)) {
        var pd_2 = (_co.onRegisterSubmit($event) !== false);
        ad = (pd_2 && ad);
    } if (("onTakerBlock" === en)) {
        var pd_3 = (_co.onTakerBlock($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_RegisterFormComponent_0, i2.RenderType_RegisterFormComponent)), i1.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), i1.ɵdid(5, 114688, null, 0, i4.RegisterFormComponent, [i5.UserService, i6.AuthService, i7.AudityService, i3.ControlContainer], { isReinsured: [0, "isReinsured"], isInnominado: [1, "isInnominado"] }, { onRegisterSubmit: "onRegisterSubmit", onTakerBlock: "onTakerBlock" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.registerForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.reinsured; var currVal_9 = _co.innominado; _ck(_v, 5, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_RegisterPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "side-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-picture-with-text", [], null, null, null, i8.View_PictureWithTextComponent_0, i8.RenderType_PictureWithTextComponent)), i1.ɵdid(2, 114688, null, 0, i9.PictureWithTextComponent, [], { isReinsured: [0, "isReinsured"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reinsured; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RegisterPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-taker-block-notify", [], null, null, null, i10.View_TakerBlockNotifyComponent_0, i10.RenderType_TakerBlockNotifyComponent)), i1.ɵdid(2, 49152, null, 0, i11.TakerBlockNotifyComponent, [], null, null)], null, null); }
export function View_RegisterPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "box-transparent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i12.RouterLink, [i12.Router, i12.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [], [[4, "--entity", null]], null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i13.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(5, 4), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPageComponent_1)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPageComponent_2)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterPageComponent_3)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "/"); _ck(_v, 1, 0, currVal_0); var currVal_2 = _ck(_v, 5, 0, "container", _co.getEntityClass(), "sbs", (_co.takerBlocked ? "back-full" : "")); _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.takerBlocked; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.takerBlocked; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.takerBlocked; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.reinsured; _ck(_v, 3, 0, currVal_1); }); }
export function View_RegisterPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-page", [], null, null, null, View_RegisterPageComponent_0, RenderType_RegisterPageComponent)), i1.ɵdid(1, 114688, null, 0, i14.RegisterPageComponent, [i12.Router, i12.ActivatedRoute, i3.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterPageComponentNgFactory = i1.ɵccf("app-register-page", i14.RegisterPageComponent, View_RegisterPageComponent_Host_0, {}, {}, []);
export { RegisterPageComponentNgFactory as RegisterPageComponentNgFactory };
