<div class="modify-container" [formGroup]="modifyPreferencesForm">
    <div class="preferences">
        <div class="title">
            <p>Tus preferencias</p>
        </div>
        <div class="bar">
        </div>
    </div>
    <div class="form">
        <div class="row">
            <app-check-box formControlName="insuranceAdvertising">
                <div class="inner-check">
                    <p>Publicidad de Seguros: </p>
                    <p>No estoy de acuerdo que, previa evaluación de mis cualidades, se me ofrezca publicidad de
                        seguros.</p>
                </div>
            </app-check-box>
        </div>
        <div class="row">
            <app-check-box formControlName="legalServicesAdvertising">
                <div class="inner-check">
                    <p>Publicidad de Servicios Legales: </p>
                    <p>Acepto que onLygal Seguros ceda mis datos a la empresa del Grupo, Onlygal Servicios Legales, S.L., para que
                        esta me remita información publicitaria referida a servicios legales.</p>
                </div>
            </app-check-box>
        </div>
        <div *ngIf="showClubPropietario" class="row">
            <app-check-box formControlName="politicaClub">
                <div class="inner-check">
                    <p>Politica del Club del propietario:</p>
                    <p>Acepto la política de privacidad</p>
                </div>
            </app-check-box>
        </div>
        <div *ngIf="showClubPropietario" class="row">
            <app-check-box formControlName="condicionesClub">
                <div class="inner-check">
                    <p>Condiciones del Club del propietario:</p>
                    <p>Acepto las condiciones de uso del Club</p>
                </div>
            </app-check-box>
        </div>
    </div>
</div>

<div class="container">
    <div class="buttons">
        <div class="message">{{messages}}</div>
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running"
            (click)="onModifyPreferences()">
        </app-spinner-button>
    </div>
</div>