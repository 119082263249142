import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventService } from 'app/services/event-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { ToastrService } from 'ngx-toastr';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { ViewportScroller } from '@angular/common';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-register-page',
    templateUrl: './event-register-page.component.html',
    styleUrls: ['./event-register-page.component.scss']
})
export class EventRegisterPageComponent implements OnInit {

    page = 0;
    data: any;

    firstStepForm: FormGroup;
    secondStepForm: FormGroup;
    thirdStepForm: FormGroup;

    patchForm: number = 0;
    messages: any;

    policyEventData: any;
    running: Boolean = false;
    prefixData: any[];
    insuredList: any[];

    @ViewChild('contCheck') private contCheck: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private eventService: EventService,
        private router: Router,
        private viewportScroller: ViewportScroller,
        private toastr: ToastrService) { }

    ngOnInit() {

        this.prefixData = CommonsUtils.phonePrefix();

        this.firstStepForm = this.formBuilder.group({
            policyNumber: [null, [Validators.required]],
            eventDate: [null, [Validators.required, CustomDateValidator.beforeEqualsCurrentDate, CustomDateValidator.correctDayAndMonth]],
            risk: ['', [Validators.required]],
            eventTime:[null, []],
            declarationObservation: ['', [Validators.required]]
        });

        this.secondStepForm = this.formBuilder.group({
            insured: [null],
            name: [null, [Validators.required]],
            surname: [null, [Validators.required]],
            lastName: [null, []],
            bornDate: [null, [Validators.required, Validators.minLength(10), CustomDateValidator.minAge, CustomDateValidator.maxAge]],
            email: [null, [Validators.required, Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]],
            phonePrefix: [null, []],
            phone: [null, [CommonsUtils.validPhone('phonePrefix')]],
            mobilePhonePrefix: [null, []],
            mobilePhone: [null, [CommonsUtils.validMobilePhone('mobilePhonePrefix')]],
            province: [null, [Validators.required]],
            postalCode: [null, [Validators.required]],
            city: [null, [Validators.required]],
            address: [null, [Validators.required]],
            roadType: [null, [Validators.required]],
            number: [null, [Validators.required]],
            documentType: [null, [Validators.required]],
            documentNumber: [null, [Validators.required]],
            files: [null, []],
            portal: [null, []],
            door: [null, []],
            stair: [null, []],
            floor: [null, []]
        });

        this.thirdStepForm = this.formBuilder.group({
            name: [null, [Validators.required]],
            surname: [null, [Validators.required]],
            lastName: [null, []],
            email: [null, [Validators.required, Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]],
            phonePrefix: [null, []],
            phone: [null, [CommonsUtils.validPhone('phonePrefix')]],
            mobilePhonePrefix: [null, []],
            mobilePhone: [null, [CommonsUtils.validMobilePhone('mobilePhonePrefix')]],
            faxPrefix: [null, []],
            fax: [null, [CommonsUtils.validPhone('faxPrefix')]],
            province: [null, [Validators.required]],
            postalCode: [null, [Validators.required]],
            city: [null, [Validators.required]],
            address: [null, [Validators.required]],
            roadType: [null, [Validators.required]],
            number: [null, [Validators.required]],
            portal: [null, []],
            door: [null, []],
            stair: [null, []],
            floor: [null, []],
            callMorning: [null, []],
            callEvening: [null, []],
            callNight: [null, []],
            relationShip: [null, []],
            idprovince: [null, []]
        });

        this.firstStepForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

        this.secondStepForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

        this.thirdStepForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

    }

    onPreviousPage() {
        if (this.page > 0) {
            this.page -= 1;
            window.scrollTo(0, this.contCheck.nativeElement.offsetTop - 60);
        }
    }

    onNextPage() {

        if (this.page === 0) {
            if (!this.firstStepForm.valid) {

                Object.keys(this.firstStepForm.controls).forEach(field => {
                    this.firstStepForm.get(field).markAsTouched({ onlySelf: true });
                });

                this.checkFieldToScroll(this.firstStepForm);

                return;

            } 
        }

        if (this.page === 1) {
            if (!this.secondStepForm.valid) {

                Object.keys(this.secondStepForm.controls).forEach(field => {
                    this.secondStepForm.get(field).markAsTouched({ onlySelf: true });
                });
                this.checkFieldToScroll(this.secondStepForm);
 
                return;

            }
        }

        if (this.page === 2) {
            if (!this.thirdStepForm.valid) {

                Object.keys(this.thirdStepForm.controls).forEach(field => {
                    this.thirdStepForm.get(field).markAsTouched({ onlySelf: true });
                });

                this.checkFieldToScroll(this.thirdStepForm);

                return;

            }
        }

        if (this.page < 2) {
            this.page += 1;
            window.scrollTo(0, this.contCheck.nativeElement.offsetTop - 60);
        } else {

            if (this.firstStepForm.valid && this.secondStepForm.valid && this.thirdStepForm.valid) {
                this.running = true;
                this.eventService.onRegisterEvent(this.firstStepForm.value, this.secondStepForm.value, this.thirdStepForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.SOLICITUD_ENVIADA_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });
                        this.router.navigate(['/event/' + success.response]);
                    }).catch(error => {
                        this.running = false;
                        this.messages = error.error.response;
                        if (undefined === error.error.response) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages, CommonsText.SERVICIO_DE_PARTES, {
                            timeOut: 5000
                        });
                    })

            }
        }
    }

    checkFieldToScroll(stepForm: FormGroup){
        let stop = false;
        Object.keys(stepForm.controls).forEach(field => {
            if(!stop){
                if(stepForm.get(field).validator !== null && stepForm.get(field).status === "INVALID"){
                    this.viewportScroller.scrollToAnchor(field);
                    window.scrollTo(0, window.scrollY - 80);
                    stop = true;
                }
            }
        });
    }

    onSelectCallback(data: any) {

        this.data = data;

        if (this.data.risks.length === 1) {
            this.firstStepForm.patchValue({ risk: this.data.risks[0].id });
        } else if (this.data.risks.length === 0) {
            this.firstStepForm.patchValue({ risk: 0 });
        } else {
            this.firstStepForm.patchValue({ risk: null });
        }

        this.insuredList = [];
        for (let i = 0; i < this.data.insured.length; i++) {
            this.insuredList.push({
                id: i, itemName: this.data.insured[i].name + ' ' +
                    this.data.insured[i].surname + ' ' + (this.data.insured[i].lastName ? this.data.insured[i].lastName : '')
            });
        }

        this.patchSecondForm(this.data.insured[0]);

        this.secondStepForm.patchValue({
            insured: [this.insuredList[0]]
        });

        this.patchThirdForm(this.data.contact);

    }

    disableNextPage() {

        let disable = false;

        if (this.running) {
            disable = true;
        } else {
            if (this.page === 0) {
                if (undefined === this.data) {
                    disable = true;
                }
            }
        }

        return disable;

    }

    getButtonLabel() {
        if (!this.running) {
            if (this.page === 0) {
                return 'Siguiente paso';
            } else if (this.page === 1) {
                return 'Ultimo paso';
            } else if (this.page === 2) {
                return CommonsText.CONFIRMAR_MINUSCULA;
            }
        } else {
            return CommonsText.CARGANDO_MINUSCULA;
        }
    }

    patchSecondForm(person: any) {
        if (undefined !== person) {
            const isCompany = person.documentType == 'CIF' ? true : false;
            if (isCompany) {
                this.secondStepForm.controls['bornDate'].setValidators(null);
            } else if (!this.secondStepForm.controls['bornDate'].validator) {
                this.secondStepForm.controls['bornDate'].setValidators(
                    [Validators.required, Validators.minLength(10), CustomDateValidator.minAge, CustomDateValidator.maxAge]
                    );

            }
            this.secondStepForm.patchValue({
                name: this.parseValue(person.name),
                surname: this.parseValue(person.surname),
                documentType: this.parseDocumentTypeValue(person.documentType),
                documentNumber: this.parseValue(person.documentNumber),
                lastName: this.parseValue(person.lastName),
                bornDate: this.parseValue(person.bornDate ? person.bornDate : null),
                postalCode: this.parseValue(person.postalCode),
                city: this.parseCityValue(person),
                roadType: this.parseSelectValue(person.roadType),
                province: this.parseValue(person.province),
                address: this.parseValue(person.address),
                number: this.parseValue(person.number),
                portal: this.parseValue(person.portal),
                door: this.parseValue(person.door),
                email: this.parseValue(person.email),
                phonePrefix: this.parsePrefixValue(person.phonePrefix),
                mobilePhonePrefix: this.parsePrefixValue(person.mobilePhonePrefix),
                phone: this.parseValue(person.phone),
                mobilePhone: this.parseValue(person.mobilePhone),
                stair: this.parseValue(person.stair),
                floor: this.parseValue(person.floor),
                files: this.parseValue(person.files)
            });
        }
    }

    patchThirdForm(person: any) {
        if (undefined !== person) {
            this.thirdStepForm.patchValue({
                name: this.parseValue(person.name),
                surname: this.parseValue(person.surname),
                lastName: this.parseValue(person.lastName),
                postalCode: this.parseValue(person.postalCode),
                city: this.parseCityValue(person),
                roadType: this.parseSelectValue(person.roadType),
                province: this.parseValue(person.province),
                address: this.parseValue(person.address),
                number: this.parseValue(person.number),
                portal: this.parseValue(person.portal),
                door: this.parseValue(person.door),
                email: this.parseValue(person.email),
                phone: this.parseValue(person.phone),
                mobilePhone: this.parseValue(person.mobilePhone),
                phonePrefix: this.parsePrefixValue(person.phonePrefix),
                mobilePhonePrefix: this.parsePrefixValue(person.mobilePhonePrefix),
                stair: this.parseValue(person.stair),
                floor: this.parseValue(person.floor)
            });
        }
    }

    parseDocumentTypeValue(value) {
        let docType = undefined;
        if (null !== value && undefined !== value) {
            CommonsUtils.documentType().forEach(number => {
                if (number.id === value) {
                    docType = number;
                }
            })
        }

        if (undefined !== docType) {
            return [docType];
        } else {
            return null;
        }
    }

    parseSelectValue(value) {
        if (null === value || undefined === value) {
            return [];
        } else {
            return value;
        }
    }

    parseCityValue(value) {
        if (null === value.city || undefined === value.city || '' === value.city.trim()) {
            return null;
        } else {
            return [{ id: value.city, itemName: value.cityDescription, province: value.province }];
        }
    }

    parseValue(value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        } else {
            return value;
        }
    }

    parsePrefixValue(value) {
        let prefix = undefined;
        if (null !== value && undefined !== value) {
            this.prefixData.forEach(number => {
                if (number.id === value) {
                    prefix = number;
                }
            })
        }

        if (undefined !== prefix) {
            return [prefix];
        } else {
            return null;
        }

    }

    onInsuredSelected($event) {
        this.patchSecondForm(this.data.insured[$event]);
    }

}
