import { OnInit, AfterViewInit, EventEmitter } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { HttpEvent } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DeydeService } from 'app/services/deyde-service';
import { CommonsText } from '../../../../../commons/CommonsText';
var EventRegisterSecondStepComponent = /** @class */ (function () {
    function EventRegisterSecondStepComponent(controlContainer, clientService, toastr, datepipe, deydeService) {
        this.controlContainer = controlContainer;
        this.clientService = clientService;
        this.toastr = toastr;
        this.datepipe = datepipe;
        this.deydeService = deydeService;
        //Subida de archivos.
        this.FormData = require('formdata-polyfill');
        this.files = [];
        this.uploadFormData = new FormData();
        this.orderDesc = false;
        this.documents = [];
        this.loadingCities = false;
        this.cityList = [];
        this.patched = false;
        this.insuredList = [];
        this.onInsuredSelected = new EventEmitter();
        this.showBirthDate = true;
        this.documentTypeList = CommonsUtils.documentType();
        this.insuredListSettings = {
            singleSelection: true,
            text: 'Seleccione un asegurado',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };
        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };
        this.documentTypeSettings = {
            singleSelection: true,
            text: 'Documento',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
    }
    Object.defineProperty(EventRegisterSecondStepComponent.prototype, "policy", {
        set: function (data) {
            this.data = data;
        },
        enumerable: true,
        configurable: true
    });
    EventRegisterSecondStepComponent.prototype.ngAfterViewInit = function () {
        if (!this.patched) {
            if (undefined !== this.data) {
                this.patched = true;
                this.patchForm(this.data.insured[0]);
            }
        }
    };
    EventRegisterSecondStepComponent.prototype.ngOnInit = function () {
        this.setMaxDate();
        this.secondStepForm = this.controlContainer.control;
        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.reloadFiles();
    };
    EventRegisterSecondStepComponent.prototype.changeDocumentType = function (event) {
        if (event && event.itemName && event.itemName == 'CIF') {
            this.showBirthDate = false;
            this.secondStepForm.controls['bornDate'].clearValidators();
            this.secondStepForm.controls['bornDate'].setValidators(null);
            this.secondStepForm.controls['bornDate'].updateValueAndValidity();
        }
        else {
            this.showBirthDate = true;
            this.secondStepForm.controls['bornDate'].clearValidators();
            this.secondStepForm.controls['bornDate'].setValidators([Validators.required, Validators.minLength(10), CustomDateValidator.minAge, CustomDateValidator.maxAge]);
            this.secondStepForm.controls['bornDate'].updateValueAndValidity();
        }
    };
    EventRegisterSecondStepComponent.prototype.setMaxDate = function () {
        var date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        this.maxDate = date;
    };
    EventRegisterSecondStepComponent.prototype.errorDescription = function (controlName) {
        var control = this.secondStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    };
    EventRegisterSecondStepComponent.prototype.patchForm = function (person) {
        var _this = this;
        if (undefined !== person) {
            setTimeout(function () {
                _this.showBirthDate = _this.secondStepForm.controls['documentType'].value &&
                    _this.secondStepForm.controls['documentType'].value.length > 0 &&
                    _this.secondStepForm.controls['documentType'].value[0].itemName != 'CIF';
            }, 500);
            this.clientService.onSearchCity(person.postalCode)
                .then(function (success) {
                _this.cityList = [];
                for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                    var city = _a[_i];
                    if (city.id == person.city) {
                        _this.cityList.push({
                            id: city.id, itemName: city.name, province: city.province
                        });
                        _this.province = _this.parseValue(city.province);
                        _this.secondStepForm.patchValue({ province: city.province });
                    }
                    else if (!person.city) {
                        _this.searchCity();
                    }
                }
            }).catch(function (error) {
                _this.loadingCities = false;
                _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                    timeOut: 5000
                });
            });
        }
    };
    EventRegisterSecondStepComponent.prototype.searchProvince = function () {
        var _this = this;
        var postalCode = this.secondStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(function (success) {
                _this.actualPostalCode = postalCode;
                _this.loadingCities = false;
                if (success.response[0].province) {
                    _this.secondStepForm.patchValue({ province: success.response[0].province });
                    _this.province = success.response[0].province;
                }
            }).catch(function (error) {
                _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                    timeOut: 5000
                });
            });
        }
    };
    EventRegisterSecondStepComponent.prototype.searchCity = function () {
        var _this = this;
        var postalCode = this.secondStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(function (success) {
                _this.actualPostalCode = postalCode;
                _this.loadingCities = false;
                _this.cityList = [];
                _this.secondStepForm.controls['city'].patchValue([]);
                for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                    var city = _a[_i];
                    _this.cityList.push({
                        id: city.id, itemName: city.name, province: city.province
                    });
                }
                if (success.response.length === 1) {
                    var city = success.response[0];
                    _this.secondStepForm.patchValue({ city: [{ id: city.id, itemName: city.name, province: city.province }] });
                    _this.secondStepForm.patchValue({ province: city.province });
                    _this.province = city.province;
                }
            }).catch(function (error) {
                _this.loadingCities = false;
                _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                    timeOut: 5000
                });
            });
        }
    };
    EventRegisterSecondStepComponent.prototype.onChangeEmail = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.secondStepForm.get("email").status;
        if (v2 === "VALID") {
            if (((v.length >= 6) && v.includes("@")) && v.includes(".")) {
                this.deydeService.onCheckDeydeEmail(v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.secondStepForm.get("email").setErrors(null);
                    }
                    else {
                        _this.secondStepForm.get("email").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    EventRegisterSecondStepComponent.prototype.onChangeMobile = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.secondStepForm.get("mobilePhone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydeMobile("+" + this.secondStepForm.get("mobilePhonePrefix").value[0].id + " " + v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.secondStepForm.get("mobilePhone").setErrors(null);
                    }
                    else {
                        _this.secondStepForm.get("mobilePhone").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(CommonsText.ERROR_DE_OPERACION, CommonsText.ERROR_SERVICIO_DEYDE, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    EventRegisterSecondStepComponent.prototype.onChangePhone = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.secondStepForm.get("phone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydePhone("+" + this.secondStepForm.get("phonePrefix").value[0].id + " " + v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.secondStepForm.get("phone").setErrors(null);
                    }
                    else {
                        _this.secondStepForm.get("phone").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    EventRegisterSecondStepComponent.prototype.onChangePostalCode = function (data) {
        var v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    };
    EventRegisterSecondStepComponent.prototype.onCitySelect = function (item) {
        this.secondStepForm.patchValue({ province: item.province });
        this.province = item.province;
    };
    EventRegisterSecondStepComponent.prototype.isInvalid = function (controlName) {
        var control = this.secondStepForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    };
    EventRegisterSecondStepComponent.prototype.parseValue = function (value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        }
        else {
            return value;
        }
    };
    EventRegisterSecondStepComponent.prototype.onChangeInsured = function (data) {
        this.searchProvince();
        this.onInsuredSelected.emit(data.id);
    };
    EventRegisterSecondStepComponent.prototype.checkFile = function () {
        var returnB = false;
        var _loop_1 = function (file) {
            if (file.size > 10485760 || !this_1.isValidFileType(file.type) || !this_1.isValidFileName(file.name) || !this_1.isValidMaxName(file.name)) {
                i = this_1.files.indexOf(file);
                i !== -1 && this_1.files.splice(i, 1);
                if (file.size > 10485760 || !this_1.isValidFileType(file.type)) {
                    this_1.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }
                if (!this_1.isValidFileName(file.name)) {
                    this_1.toastr.error(CommonsText.ERROR_FORMATO_NOMBRE_ARCHIVO, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }
                if (!this_1.isValidMaxName(file.name)) {
                    this_1.toastr.error(CommonsText.ERROR_TAMAYO_NOMBRE_ARCHIVO_120, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }
            }
            else {
                var hash_1 = {};
                this_1.files = this_1.files.filter(function (o) { return hash_1[o.name] ? false : hash_1[o.name] = true; });
                this_1.secondStepForm.patchValue({ files: this_1.files });
                returnB = true;
            }
        };
        var this_1 = this, i;
        for (var _i = 0, _a = this.files; _i < _a.length; _i++) {
            var file = _a[_i];
            _loop_1(file);
        }
        ;
        return returnB;
    };
    EventRegisterSecondStepComponent.prototype.isValidFileName = function (name) {
        var valid = false;
        var regex = /([^-\w.\s]*)/gm;
        var newName = name.replace(regex, '');
        if (newName === name) {
            valid = true;
        }
        return valid;
    };
    EventRegisterSecondStepComponent.prototype.isValidFileType = function (type) {
        var valid = false;
        if (type && (type.startsWith('image/') || type === CommonsText.IMG_TYPE)) {
            valid = true;
        }
        return valid;
    };
    EventRegisterSecondStepComponent.prototype.isValidMaxName = function (name) {
        var valid = true;
        if (name.length > 120) {
            valid = false;
        }
        return valid;
    };
    EventRegisterSecondStepComponent.prototype.getDate = function () {
        return new Date();
    };
    EventRegisterSecondStepComponent.prototype.reloadFiles = function () {
        if (this.secondStepForm.get('files').value != null && this.secondStepForm.get('files').value.length > 0) {
            this.files = this.secondStepForm.get('files').value;
        }
    };
    return EventRegisterSecondStepComponent;
}());
export { EventRegisterSecondStepComponent };
