/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./split-button-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./split-button-item.component";
import * as i5 from "../../../services/domain-service";
var styles_SplitButtonItemComponent = [i0.styles];
var RenderType_SplitButtonItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SplitButtonItemComponent, data: {} });
export { RenderType_SplitButtonItemComponent as RenderType_SplitButtonItemComponent };
export function View_SplitButtonItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.resolveRouterLink(_co.route); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.label; _ck(_v, 2, 0, currVal_3); }); }
export function View_SplitButtonItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-split-button-item", [], null, null, null, View_SplitButtonItemComponent_0, RenderType_SplitButtonItemComponent)), i1.ɵdid(1, 49152, null, 0, i4.SplitButtonItemComponent, [i3.Location, i5.DomainService], null, null)], null, null); }
var SplitButtonItemComponentNgFactory = i1.ɵccf("app-split-button-item", i4.SplitButtonItemComponent, View_SplitButtonItemComponent_Host_0, { label: "label", route: "route" }, {}, []);
export { SplitButtonItemComponentNgFactory as SplitButtonItemComponentNgFactory };
