/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-profile-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header-profile-progress-bar-simple/header-profile-progress-bar-simple.component.ngfactory";
import * as i3 from "../header-profile-progress-bar-simple/header-profile-progress-bar-simple.component";
import * as i4 from "../../../../services/client-service";
import * as i5 from "ngx-toastr";
import * as i6 from "@angular/router";
import * as i7 from "./header-profile-bar.component";
var styles_HeaderProfileBarComponent = [i0.styles];
var RenderType_HeaderProfileBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderProfileBarComponent, data: {} });
export { RenderType_HeaderProfileBarComponent as RenderType_HeaderProfileBarComponent };
export function View_HeaderProfileBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "profile-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "profile-bar-comp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header-profile-progress-bar-simple", [], null, null, null, i2.View_HeaderProfileProgressBarSimpleComponent_0, i2.RenderType_HeaderProfileProgressBarSimpleComponent)), i1.ɵdid(3, 114688, null, 0, i3.HeaderProfileProgressBarSimpleComponent, [i4.ClientService, i5.ToastrService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "profile-bar-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵted(-1, null, ["Completa tu perfil"]))], function (_ck, _v) { _ck(_v, 3, 0); var currVal_0 = _ck(_v, 7, 0, "/modify"); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_HeaderProfileBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-profile-bar", [], null, null, null, View_HeaderProfileBarComponent_0, RenderType_HeaderProfileBarComponent)), i1.ɵdid(1, 49152, null, 0, i7.HeaderProfileBarComponent, [], null, null)], null, null); }
var HeaderProfileBarComponentNgFactory = i1.ɵccf("app-header-profile-bar", i7.HeaderProfileBarComponent, View_HeaderProfileBarComponent_Host_0, {}, {}, []);
export { HeaderProfileBarComponentNgFactory as HeaderProfileBarComponentNgFactory };
