import { Component, Input } from '@angular/core';
import { AuthService } from 'app/security/auth.service';
@Component({
    selector: 'app-profile-contact',
    templateUrl: './profile-contact.component.html',
    styleUrls: ['./profile-contact.component.scss']
})
export class ProfileContactComponent {

    @Input() data: any = undefined;

    showDirectAreas: Boolean = true;
    isCompany: boolean;
    
    constructor(private authService: AuthService) { }
    ngOnInit() {
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
            const user = this.authService.getUser();
            user.documentType && user.documentType == 'CIF' ? this.isCompany = true : this.isCompany = false;
        }
    }

    getCheckBoxStyle(key) {
        if ((this.data) && (this.data.notifications)) {
            return this.data.notifications.some(e => e === key) ? 'box-checked' : 'box-unchecked'
        } else {
            return 'box-unchecked';
        }
    }


    getPhone(): String {
        let phonePrefix = '';
        let phone = ''
        if (this.data) {
            if (this.data.phonePrefix) {
                phonePrefix = '+' + this.data.phonePrefix + ' ';
            }
            if (this.data.phone) {
                phone = this.data.phone;
            }
        }
        if (phone !== '' && phone !== null && phone !== 'null') {
            return phonePrefix + phone;
        } else {
            return '';
        }
    }

    getMobilePhone(): String {
        let phonePrefix = '';
        let phone = ''
        if (this.data) {
            if (this.data.mobilePhonePrefix) {
                phonePrefix = '+' + this.data.mobilePhonePrefix + ' ';
            }
            if (this.data.mobilePhone) {
                phone = this.data.mobilePhone;
            }
        }
        if (phone !== '' && phone !== null && phone !== 'null') {
            return phonePrefix + phone;
        } else {
            return '';
        }
    }

}
