<div class="modify-container" [formGroup]="modifyAccessForm" (ngSubmit)="onModifyAccess()">
    <div class="domicile">
        <div class="title">
            <p>Cambio de Contraseña</p>
        </div>
        <div class="bar">
        </div>
    </div>
    <div class="form">
        <div class="row">
            <div class="cont">
                <app-input-box type="password" label="CONTRASEÑA ACTUAL" errorDescription="Informe su contraseña actual" formControlName="actual" maxlength="16"></app-input-box>
            </div>
            <div class="cont">
                <app-input-box type="password" label="NUEVA CONTRASEÑA" errorDescription="Informe una contraseña" description="Informe una nueva contraseña" formControlName="new" maxlength="16" tooltip="Utiliza ocho caracteres como mínimo combinando letras mayúsculas y minúsculas, números y símbolos (€-.,´`+¡'}{][¬~€#@|\;:_^*¿?=)(/&%$·&quot;!)"
                    tooltipDirection="left"></app-input-box>
            </div>
            <div class="cont">
                <app-input-box type="password" label="CONFIRMACIÓN DE CONTRASEÑA" errorDescription="La contraseña debe ser la misma que la confirmación" description="Confirme su nueva contraseña" formControlName="confirmation" maxlength="16" tooltip="Utiliza ocho caracteres como mínimo combinando letras mayúsculas y minúsculas, números y símbolos (€-.,´`+¡'}{][¬~€#@|\;:_^*¿?=)(/&%$·&quot;!)"
                    tooltipDirection="left"></app-input-box>
            </div>
        </div>
    </div>

</div>

<div class="container">
    <div class="buttons">
        <div class="message">{{messages}}</div>
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running" (click)="onModifyAccess()"></app-spinner-button>
    </div>
</div>