<div class="expedient" *ngIf="undefined !== data.expedientEvent">
    <div class="expedient-title">
        <p>Expedientes relacionados con el parte</p>
    </div>

    <div *ngIf="mobileSize">
        <div class="expedient-main" *ngFor="let c of data.expedientEvent">
            <div class="expedient-main-sup">
                <div class="left">
                    <span>Nº de expediente</span>
                    <p>{{c.expedientNumber}}</p>
                </div>
                <div class="right">
                    <span>Tipo de expediente:</span>
                    <p>{{c.expedientType}}</p>
                </div>
                <div class="right">
                    <span>Tramitador:</span>
                    <p>{{c.expedientAgent}}</p>
                </div>
                <div class="right">
                    <span>Situación expediente:</span>
                    <p>{{c.expedientSituation}} <app-tooltip *ngIf="c.expedientSituationLarga !== ''" direction="top"
                            textAling="directionLeft" text="{{c.expedientSituationLarga}}"></app-tooltip></p>
                </div>
            </div>
            <hr size="1rem" style="margin-top: 1.5rem;" *ngIf="(data.expedientEvent).length>1" />
        </div>
    </div>

    <div *ngIf="desktopSize">
        <div class="expedient-main">
            <div class="expedient-main-sup">
                <div class="left">
                    <span>Nº de expediente</span>
                </div>
                <div class="right">
                    <span>Tipo de expediente:</span>
                </div>
                <div class="right">
                    <span>Tramitador:</span>
                </div>
                <div class="right">
                    <span>Situación expediente:</span>
                </div>
            </div>
            <div class="expedient-main-inf" *ngFor="let c of data.expedientEvent">
                <div class="left">
                    <p>{{c.expedientNumber}}</p>
                </div>
                <div class="right">
                    <p>{{c.expedientType}}</p>
                </div>
                <div class="right">
                    <p>{{c.expedientAgent}}</p>
                </div>
                <div class="right">
                    <p>{{c.expedientSituation}} <app-tooltip *ngIf="c.expedientSituationLarga !== ''" direction="top"
                            textAling="directionLeft" text="{{c.expedientSituationLarga}}"></app-tooltip></p>
                </div>
            </div>
        </div>
    </div>
</div>