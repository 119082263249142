import { Routes } from '@angular/router';
import { AuthenticationPageComponent } from './pages/authentication-page/authentication-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { ConfirmRegisterPageComponent } from './pages/confirm-register-page/confirm-register-page.component';
import { WpEmptyPageComponent } from './pages/wp-empty-page/wp-empty-page.component';
import { EntitiesPageComponent } from './pages/entities-page/entities-page.component';
import { ConfirmSmsRegisterPageComponent } from './pages/confirm-sms-register-page/confirm-sms-register-page.component';
var ɵ0 = { page: 'conditions-of-use' }, ɵ1 = { page: 'cookies-policy' }, ɵ2 = { page: 'privacy-politics' };
var appRoutes = [
    {
        path: 'authentication',
        component: AuthenticationPageComponent,
    },
    {
        path: 'authentication/:authenticationcode',
        component: AuthenticationPageComponent,
    },
    {
        path: 'entities',
        component: EntitiesPageComponent,
    },
    {
        path: 'change-password',
        component: ChangePasswordPageComponent,
    },
    {
        path: 'confirm-register',
        component: ConfirmRegisterPageComponent,
    },
    {
        path: 'confirm/:code',
        component: ConfirmSmsRegisterPageComponent,
    },
    {
        path: 'confirm/:code/reinsured/:reinsured',
        component: ConfirmSmsRegisterPageComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordPageComponent,
    },
    {
        path: 'register',
        redirectTo: 'register/step1',
    },
    {
        path: 'register/step1',
        component: RegisterPageComponent,
    },
    {
        path: 'register/conditions-of-use',
        component: WpEmptyPageComponent,
        data: ɵ0
    },
    {
        path: 'register/cookies-policy',
        component: WpEmptyPageComponent,
        data: ɵ1
    },
    {
        path: 'register/privacy-politics',
        component: WpEmptyPageComponent,
        data: ɵ2
    },
];
var SideBySideTemplateModule = /** @class */ (function () {
    function SideBySideTemplateModule() {
    }
    return SideBySideTemplateModule;
}());
export { SideBySideTemplateModule };
export { ɵ0, ɵ1, ɵ2 };
