<div  class="blog no-text-select wow fadeInUp" data-wow-duration="1s" [attr.data-wow-delay]="getDelay()">
  <br>
  <h1 class="title mt-2">Bienvenido/a al Club del Propietario</h1>
  <div class="recomendacion">
    <div class="pestania">           
      <mat-tab-group  id="recomendar" backgroundColor="recomendar" color="label-recomendar" class="custom-tabs" mat-align-tabs="center">
          <mat-tab>
              <ng-template mat-tab-label id="recomendar">             
                  <label role="button" class="mat-tab-labe">
                      <span class="title-tab">Te recomendamos</span>
                  </label>
              </ng-template>
              <div class="contenedor" id="parent">
                  <div *ngIf="lstNoticiasDestacadas.length == 0">
                      <div class="grid">
                          <div></div>
                          <div class="parrafo">
                              <p>¡Lo sentimos! Ahora mismo no hay ningún consejo sobre este tema.</p>
                          </div>
                          <div></div>
                      </div>
                  </div>
                  <div *ngIf="lstNoticiasDestacadas.length > 0">
                      <div *ngFor="let noticia of lstNoticiasDestacadas | slice:0:4;" class="noticia">
                        <div class="row">
                          <div class="imagenPostDiv">
                            <a class="enlace-noticia" [routerLink]="[ '/club-del-propietario/detalle/', noticia.categoria, noticia.id]">
                              <img class="imagenPost" [ngStyle]="getStyle(noticia.imagen ? noticia.imagen.source_url : null)">
                            </a>                           
                          </div>
                          <div class="enlacePost">
                              <span (click)="detallePost(noticia.categoria, noticia.id)" [innerHtml]="noticia.titulo"></span>
                          </div>
                        </div>
                          
                      </div>
                  </div>
              </div>
          </mat-tab>
      </mat-tab-group>
    </div>  
  </div>
  
</div>
