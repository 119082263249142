/**
 *
 * @author Iecisa
 */
export class ConfirmRegisterDTO {
    email: string;
    uuid: string;
    password: string;
    password2: string;
    termsAndConditions: Boolean;
    dataPolicy: Boolean;
}
