<div class="checkballs">
    <div class="checkballs-cont">
        <div class="checkballs-cont-step" [ngClass]="getProgressStep()">
            <div class="title">
                <p>{{step_1.date | date: 'dd/MM/yyyy' }}</p>
                <p>{{step_1.status}}</p>
                <!-- <p>{{step_1.description}}</p> -->
            </div>
            <div class="check">
                <div [ngClass]="getStatus1()"></div>
            </div>
        </div>
        <!-- <div class="checkballs-cont-step">
            <div class="title">
                <p>{{step_2.date | date: 'dd/MM/yyyy' }}</p>
                <p>{{step_2.status}}</p>
                <p>{{step_2.description}}</p>
            </div>
            <div class="check">
                <div [ngClass]="getStatus2()">
                </div>
            </div>
        </div> -->
        <div class="checkballs-cont-step">
            <div class="title">
                <p>{{step_3.date | date: 'dd/MM/yyyy' }}</p>
                <p>{{step_3.status}}</p>
                <!-- <p>{{step_3.description}}</p> -->
            </div>
            <div class="check">
                <div [ngClass]="getStatus3()">
                </div>
            </div>
        </div>
        <div class="checkballs-cont-step">
            <div class="title">
                <p>{{step_4.date | date: 'dd/MM/yyyy' }}</p>
                <p>{{step_4.status}}</p>
                <!-- <p>{{step_4.description}}</p> -->
            </div>
            <div class="check">
                <div [ngClass]="getStatus4()">
                </div>
            </div>
        </div>
    </div>
</div>