<div class="split">
    <button class="button-check" type="button" (click)="toggleMenu()" [ngClass]="{'anulado': anulado}">
    <p>
      {{ label }}
    </p>
    <i class="icon" [ngClass]="[_toggle ? 'up' : 'down', anulado ? 'icono_anulado' : '']"></i>


  </button>

    <div *ngIf="_toggle" class="content {{ direction }}">
        <ng-content></ng-content>
    </div>
</div>