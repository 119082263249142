<div class="container-bar">
    <div class="progress-title">
        <p>Completa tu perfil</p>
    </div>
    <div class="progress-inner ">
        <div class="progress-inner-bar ">
            <div class="bar " [ngStyle]="{ 'width': percent + '%'} "></div>
        </div>
        <div class="progress-inner-percent ">
            <p>{{percent}}%</p>
        </div>
    </div>

</div>