<div *ngIf="show" class="loading-container no-text-select">
  <div class="loading-container-cont fadeInUp">
      <div class="pre-loading">
          <p class="close">
              <b class="cross" (click)="closeModal()">&#10006;</b>
              </p>

          <p class="loading bottom-space">¡Recuerda!<span>.</span><span>.</span><span>.</span></p>

          <p class="loading sub">Al modificar la cuenta bancaria asociada a la póliza, se ha modificado también la cuenta bancaria seleccionada asociada a cada uno de los recibos asociados a dicha póliza que estén pendientes de pago.</p> 
          <div class="button-row">
              <div class="button-place">                   
              </div>
              <div class="button-place">  
                  <app-spinner-button [label]="'Aceptar'" [enabled]="true" (click)="closeModal()">
                  </app-spinner-button>                 
              </div>
              <div class="button-place">                   
              </div>
          </div>
          
      </div>
  </div>
</div>