<div class="page-container" [formGroup]="firstStepForm">

    <div class="group">
        <div class="title">
            <p>Seleccione su seguro</p>
        </div>
        <div class="content">
            <div class="policySelect">
                <angular2-multiselect [data]="dropdownList" [settings]="dropdownSettings" (onSelect)="onPolicySelect($event)" formControlName="policyNumber"></angular2-multiselect>
                <span *ngIf="loadingPolicies" class="spinner"></span>
            </div>

        </div>
    </div>

    <div *ngIf="undefined !== data">   
        <div class="group">
            <div class="title">
                <p>Fecha de los hechos</p>
            </div>
            <div class="content dateTime">
                <div class="inner-dateTime">
                    <app-date-picker id="eventDate" label="Fecha de los hechos" formControlName="eventDate" correctDayAndMonthErrorDescription="El día, mes o año es erróneo" errorDescription="(*) Campo obligatorio" beforeCurrentDateErrorDescription="La fecha debe ser menor o igual que el día actual">
                    </app-date-picker>
                    <app-time-picker label="Horario" formControlName="eventTime">
                    </app-time-picker>
                </div>
            </div>
        </div>

        <div class="group" *ngIf="data.risks.length > 1">
            <div class="title">
                <p>Objecto asegurado</p>
            </div>
            <div class="content content-1">
                <div class="risk-content">
                    <div class="risk" *ngFor="let risk of data.risks; index as i ">

                        <label class="btn">
                            <input type="radio" formControlName="risk" value="{{risk.id}}">
                            <span>
                                <i class="check-icon"></i>
                                {{risk.itemName}}
                            </span>
                        </label>
                    </div>
                </div>
                <span *ngIf="errorDescription('risk')" class="errorDescription">(*) Campo
                    obligatorio</span>
            </div>
        </div>

        <div class="group"  >
            <div class="title">
                <p>Detalles de lo ocurrido</p>
            </div>
            <div class="content">
                <div class="declaration">
                    <div class="observation" id="declarationObservation">
                        <label>Detalles de lo ocurrido</label>
                        <textarea maxlength="3000" title="Detalles de lo ocurrido" formControlName="declarationObservation" cols="30" rows="5"></textarea>
                        <span *ngIf="errorDescription('declarationObservation')" class="errorDescription">(*) Campo
                            obligatorio</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
</div>