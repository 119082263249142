import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';
@Component({
    selector: 'app-event-messenger',
    templateUrl: './event-messenger.component.html',
    styleUrls: ['./event-messenger.component.scss']
})
export class EventMessengerComponent implements OnInit{

    FormData = require('formdata-polyfill')

    @Input() data: any;
    @Input() public showDocuments: boolean;
    showContent = false;

    constructor( private viewportScroller: ViewportScroller,
                 private route: ActivatedRoute ) { }

    ngOnInit(){
        if (this.route.snapshot.params['show']) {
            this.showDocuments = true;
            this.showContent = true;

            
            setTimeout(() => {
                this.viewportScroller.scrollToAnchor('messenger');
            }, 4000);
            
        }
    }

    showMessages() {
        this.showContent = true;
    }

}
