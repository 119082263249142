import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-confirm-sms-register-page',
    templateUrl: './confirm-sms-register-page.component.html',
    styleUrls: ['./confirm-sms-register-page.component.scss']
})
export class ConfirmSmsRegisterPageComponent {

    resend: Boolean = false;
    reinsured;
    innominado;
    parametros;

    constructor(private router: Router,
        private route: ActivatedRoute) {
            // this.parametros = this.route.snapshot.params
            // if(this.parametros['nameless']){
            //     this.innominado = this.parametros['nameless'];
            // } else {
            //     this.reinsured = this.parametros['reinsured'];
            // }

            let urlActual = new URL(window.location.href);
            let partes = urlActual.hash.split('/');
            this.reinsured = partes[partes.length - 1];
     }

    onConfirmSmsRegisterSubmit(event) {
        if (event) {
            if ((undefined !== event.response) && (null !== event.response) &&
                (undefined !== event.response.address) && (null !== event.response.address)) {
                    if(this.reinsured){
                        this.router.navigateByUrl(
                            '/confirm-register?address=' + event.response.address + '&uuid=' + event.response.uuid + '&reinsured=' + this.reinsured);
                    } else {
                        this.router.navigateByUrl(
                            '/confirm-register?address=' + event.response.address + '&uuid=' + event.response.uuid);
                    }

            }
        }
    }

    onResendSmsRegisterSubmit(event) {
        this.resend = true;
    }

    onReceiveNewSmsCode(event) {
        this.resend = false;
    }

    getEntityClass() {
        switch (this.reinsured) {
            case 'L0639':
                return 'euroins';
            case 'L1454':
                return 'prima';
            case 'C0616':
                return 'seguro-rga';
            case 'C0012':
                return 'eterna-aseguradora';
            case 'C0037':
                return 'santander';
            case 'C0072':
                return 'generali';
            case 'C0121':
                return 'metropolis';      
            case 'C0124':
                return 'segurcaixa';
            case 'C0133':
                return 'ocaso';
            case 'C0139':
                return 'patria-hispana';
            case 'C0157':
                return 'helvetia';
            case 'C0161':
                return 'dkv';
            case 'C0572':
                return 'lagun-aro';
            case 'C0786':
                return 'mutua';
            case 'DLA03':
                return 'aegon';
            case 'DLA04':
                return 'ergo';
            case 'DLA06':
                return 'amic';
            case 'DLA07':
                return 'allianz';
            case 'DLA09':
                return 'vidacaixa';
            case 'E0217':
                return 'erv';
            case 'L0614':
                return 'nationale-suisse';
            case 'L0639':
                return 'euroins';
            case 'M0084':
                return 'mmt';
            case 'M0199':
                return 'mutua-propietarios'; 
            default:
                return '';
        }
}

}
