import { Component, OnInit, Input, Output } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PolicyService } from 'app/services/policy-service';
import { EventService } from 'app/services/event-service';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-register-first-step',
    templateUrl: './event-register-first-step.component.html',
    styleUrls: ['./event-register-first-step.component.scss']
})
export class EventRegisterFirstStepComponent implements OnInit {

    @Output() selected = new EventEmitter();
    @Output() declarationType = new EventEmitter();

    data: any;

    @Input() set policy(data: any) {
        this.data = data;
    }

    firstStepForm: FormGroup;

    loadingPolicies: Boolean = false;

    dropdownList: any[] = [];
    selectedItems: any[] = [];
    dropdownSettings = {};

    constructor(
        private route: ActivatedRoute,
        private controlContainer: ControlContainer,
        private toastr: ToastrService,
        private eventService: EventService,
        private policyService: PolicyService) { }

    ngOnInit() {

        const policyNumber = this.route.snapshot.params['policyYear'] + '/' + this.route.snapshot.params['policyNumber'];

        this.firstStepForm = <FormGroup>this.controlContainer.control;

        this.dropdownSettings = {
            singleSelection: true,
            text: 'Selecciona un Seguro',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.loadingPolicies = true;

        this.policyService.getCurrent()
            .then(success => {
                this.loadingPolicies = false;

                for (const policy of success.response) {
                    const policyItem = { id: policy.number, itemName: policy.number + ' - ' + policy.product }
                    this.dropdownList.push(policyItem);
                    if ((undefined !== policyNumber) && (null !== policyNumber)) {
                        if (policyItem.id === policyNumber) {
                            this.firstStepForm.patchValue({ policyNumber: [policyItem] });
                            this.onPolicySelect(policyItem);
                        }
                        if(success.response.length === 1){
                            this.firstStepForm.patchValue({ policyNumber: [policyItem] });
                            this.onPolicySelect(policyItem);
                        }
                    }
                }

            }).catch(error => {
                this.loadingPolicies = false;
                this.dropdownList = [];
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    onPolicySelect(item: any) {

        this.loadingPolicies = true;
        this.firstStepForm.controls['risk'].reset();
        this.eventService.getPolicyEventByNumber(item.id)
            .then(success => {
                this.loadingPolicies = false;
                this.selected.emit(success.response);
            }).catch(error => {
                this.loadingPolicies = false;
                this.selected.emit(undefined);
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    errorDescription(controlName: string) {
        const control = this.firstStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    }

    onChangeDeclaration(event) {
        this.declarationType.emit(event.target.value);
    }



}
