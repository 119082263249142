/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timePicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../time.component/time.component.ngfactory";
import * as i4 from "../time.component/time.component";
import * as i5 from "@angular/forms";
import * as i6 from "ngx-mask";
import * as i7 from "./timePicker.component";
import * as i8 from "../isMobile.service";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
function View_TimePickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "text-black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 2, 0, currVal_0); }); }
function View_TimePickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "text-red text-bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescription; _ck(_v, 2, 0, currVal_0); }); }
function View_TimePickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "timerPickerMobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "label-upperline "]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "input", [["type", "time"]], [[8, "value", 0]], [[null, "input"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label-upperline "; var currVal_1 = (!(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)) ? "text-black" : "text-red"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = (!(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)) ? "border-gray text-black" : "border-red text-red"); _ck(_v, 5, 0, currVal_4); var currVal_5 = !(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.value; _ck(_v, 4, 0, currVal_3); }); }
function View_TimePickerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "text-black"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 2, 0, currVal_0); }); }
function View_TimePickerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "text-red text-bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDescription; _ck(_v, 2, 0, currVal_0); }); }
function View_TimePickerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-time", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelectCallback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TimeComponent_0, i3.RenderType_TimeComponent)), i1.ɵdid(2, 49152, null, 0, i4.TimeComponent, [], { hour: [0, "hour"], minute: [1, "minute"], disabledHours: [2, "disabledHours"] }, { selected: "selected" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "content-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSetTimeToNow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ahora"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hour; var currVal_1 = _co.minute; var currVal_2 = _co.disabledHours; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_TimePickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "timerPickerDesktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "label-upperline "]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["mask", "00:00"], ["type", "text"]], [[8, "value", 0]], [[null, "focusout"], [null, "input"], [null, "blur"], [null, "click"], [null, "keydown"], [null, "paste"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onFocus($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9).a($event) !== false);
        ad = (pd_3 && ad);
    } if (("paste" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9).onPaste() !== false);
        ad = (pd_4 && ad);
    } if (("focusout" === en)) {
        var pd_5 = (_co.onFocusOut($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.onChange($event.target.value) !== false);
        ad = (pd_6 && ad);
    } if (("blur" === en)) {
        var pd_7 = (_co.onTouched() !== false);
        ad = (pd_7 && ad);
    } if (("click" === en)) {
        var pd_8 = ((_co.pickerVisible = !_co.pickerVisible) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.MaskDirective]), i1.ɵprd(5120, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.MaskDirective]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵprd(512, null, i6.MaskService, i6.MaskService, [i2.DOCUMENT, i6.config, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 540672, null, 0, i6.MaskDirective, [i2.DOCUMENT, i6.MaskService], { maskExpression: [0, "maskExpression"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_6)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn-icon"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.pickerVisible = !_co.pickerVisible) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "24"], ["version", "1.1"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:path", [["d", "M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"], ["fill", "#4d87c7"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_7)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "label-upperline "; var currVal_1 = (!(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)) ? "text-black" : "text-red"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = (!(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)) ? "border-gray text-black" : "border-red text-red"); _ck(_v, 7, 0, currVal_4); var currVal_5 = "00:00"; _ck(_v, 9, 0, currVal_5); var currVal_6 = !(_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)); _ck(_v, 12, 0, currVal_6); var currVal_7 = (_co.ngControl.invalid && (_co.ngControl.touched || _co.ngControl.dirty)); _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.pickerVisible; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.value; _ck(_v, 4, 0, currVal_3); }); }
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimePickerComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMobile; _ck(_v, 1, 0, currVal_0); var currVal_1 = true; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-time-picker", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).offClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 114688, null, 0, i7.TimePickerComponent, [[8, null], i8.IsMobileService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("app-time-picker", i7.TimePickerComponent, View_TimePickerComponent_Host_0, { label: "label", disabledHours: "disabledHours", description: "description", errorDescription: "errorDescription" }, {}, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
