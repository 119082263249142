import { Component, Input, OnInit } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { AuthService } from 'app/security/auth.service';

@Component({
    selector: 'app-carousel-product',
    templateUrl: './carousel-product.component.html',
    styleUrls: ['./carousel-product.component.scss']
})
export class CarouselProductComponent implements OnInit{

    showDirectAreas: Boolean = true;
    @Input() data: any;
    @Input() loading;
    close: Boolean = false;

    constructor(private policyService: PolicyService,
                private authService: AuthService) { }

    ngOnInit(){
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }
    }

    onGetContractFile() {
        this.close = true;
        setTimeout(() => {
            this.close = undefined;
        }, 200);
        this.policyService.getContractFile(this.data.id);
    }

}
