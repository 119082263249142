/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-boxes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./contact-boxes.component";
var styles_ContactBoxesComponent = [i0.styles];
var RenderType_ContactBoxesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactBoxesComponent, data: {} });
export { RenderType_ContactBoxesComponent as RenderType_ContactBoxesComponent };
export function View_ContactBoxesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "selectable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "selectable-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "selectable-form "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "img "]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "text "]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Formularios de contacto"])), (_l()(), i1.ɵeld(10, 0, null, null, 7, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "selectable-claims "]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "img "]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "text "]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reclamaciones"])), (_l()(), i1.ɵeld(18, 0, null, null, 5, "a", [["href", "https://ayuda.onlygal.es"], ["target", "help"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "selectable-questions "]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "img "]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "text "]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Preguntas frecuentes"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/contact/form"); _ck(_v, 3, 0, currVal_2); var currVal_5 = _ck(_v, 12, 0, "/contact/claims"); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); }); }
export function View_ContactBoxesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-boxes", [], null, null, null, View_ContactBoxesComponent_0, RenderType_ContactBoxesComponent)), i1.ɵdid(1, 114688, null, 0, i4.ContactBoxesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactBoxesComponentNgFactory = i1.ɵccf("app-contact-boxes", i4.ContactBoxesComponent, View_ContactBoxesComponent_Host_0, {}, {}, []);
export { ContactBoxesComponentNgFactory as ContactBoxesComponentNgFactory };
