import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-wp-integration',
  templateUrl: './header-wp-integration.component.html',
  styleUrls: ['./header-wp-integration.component.scss']
})
export class HeaderWpIntegrationComponent {

  @Input() data: any;

  constructor() { }

 }
