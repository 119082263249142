import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CustomDateValidator } from 'app/commons/custom-date-validator';

export interface Day {
    day: number;
    month: number;
    year: number;
    date: Date;
}

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss']
})

export class DateComponent {

    date: Date;
    month: number;
    year: number;

    min: number;
    max: number;

    currentMonth: number;
    currentDay: number;
    currentYear: number;

    showingMonth: Boolean;
    showingYear: Boolean;

    calendarDays: Day[];

    months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril',
        'Mayo', 'Junio', 'Julio', 'Agosto',
        'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    years: number[] = [];

    @Input() minYear;

    @Input() set value(value: String) {
        let converted: Date = null;
        if ((undefined === value) || (null === value)) {
            converted = new Date();
        } else {
            const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
            converted = new Date(value.replace(pattern, '$3-$2-$1'));
            this.chargedYears();
        }
        this.date = converted;
        this.month = converted.getMonth();
        this.year = converted.getFullYear();
        this.showCalendar();
    }

    @Output() selected = new EventEmitter<Date>();

    constructor() {
        this.chargedYears();
    }

    chargedYears() {
        let converted: Date = null;
        const temp: Date = new Date();
        this.currentMonth = temp.getMonth();
        this.currentDay = temp.getDate();
        if (!this.minYear) {
            this.currentYear = temp.getFullYear();
            this.years = [];
        } else {
            const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
            converted = new Date(this.minYear.replace(pattern, '$3-$2-$1'));
            this.currentYear = converted.getFullYear();
            this.years = [];
        }

        let startYear = this.currentYear - 10;
        this.min = startYear;
        while (startYear <= (this.currentYear + 10)) {
            this.years.push(startYear);
            startYear = startYear + 1;
        }
        this.max = startYear - 1;
    }

    onShowMonth() {
        this.showingYear = false;
        this.showingMonth = !this.showingMonth;
    }

    onShowYear() {
        this.showingMonth = false;
        this.showingYear = !this.showingYear;
    }

    onPreviousMonth() {
        this.date.setMonth(this.date.getMonth() - 1);

        if (this.date.getFullYear() < this.min) {
            this.date.setMonth(this.date.getMonth() + 1);
            return;
        }

        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showCalendar();
    }

    onNextMonth() {
        this.date.setMonth(this.date.getMonth() + 1);

        if (this.date.getFullYear() > this.max) {
            this.date.setMonth(this.date.getMonth() - 1);
            return;
        }

        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showCalendar();
    }

    monthName(): String {
        return this.months[this.month];
    }

    onSetMonth(value: number) {
        this.date.setMonth(value);
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showCalendar();
    }

    onSetYear(value: number) {
        this.date.setFullYear(value);
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showingYear = false;
        this.showCalendar();
    }

    onSetNow() {
        this.date = new Date();
        this.year = this.date.getFullYear();
        this.month = this.date.getMonth();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showingYear = false;
        this.showCalendar();
    }

    onSetDate(day: Day) {
        this.date = day.date;
        this.year = this.date.getFullYear();
        this.month = this.date.getMonth();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showingYear = false;
        this.showCalendar();
    }

    showCalendar() {

        const temp: Date = new Date();
        const newCalendar: Day[] = [];
        let i;

        temp.setDate(1);
        temp.setMonth(this.month);
        temp.setFullYear(this.year);

        const pn = temp.getDay() === 0 ? 7 : temp.getDay();
        for (i = 1; i < pn; i++) {
            const prevDay = new Date(temp);
            prevDay.setDate(prevDay.getDate() - i);
            const dayToAdd = {
                day: prevDay.getDate(),
                month: prevDay.getMonth(),
                year: prevDay.getFullYear(),
                date: prevDay
            };
            newCalendar.push(dayToAdd);
        }

        newCalendar.reverse();

        const monthDay = new Date(temp);
        while (monthDay.getMonth() === temp.getMonth()) {
            const dayToAdd = {
                day: monthDay.getDate(),
                month: monthDay.getMonth(),
                year: monthDay.getFullYear(),
                date: new Date(monthDay)
            };
            newCalendar.push(dayToAdd);
            monthDay.setDate(monthDay.getDate() + 1);
        }

        temp.setMonth(temp.getMonth() + 1);
        temp.setDate(temp.getDate() - 1);
        const nn = 7 - temp.getDay();

        for (i = 0; i < nn; i++) {
            const nextDay = new Date(temp);
            nextDay.setDate(nextDay.getDate() + i + 1);
            const dayToAdd = {
                day: nextDay.getDate(),
                month: nextDay.getMonth(),
                year: nextDay.getFullYear(),
                date: nextDay
            };
            newCalendar.push(dayToAdd);
        }

        this.calendarDays = newCalendar;

    }
}