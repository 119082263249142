<section class="carousel no-text-select">

    <div class="header ">
        <h3 class="title ">{{titleText}}</h3>
        <div class="nav-bar " *ngIf="items.length> 1"><span class="numeracion">{{currentSlide + 1}} de {{items.length}}</span>
            <div class="inner-i espacio">
                <i (click)="prev()" [ngClass]="currentSlide === 0 ? 'previous-disabled' : 'previous-enabled' "></i>
                <i (click)="next()" [ngClass]="currentSlide === (items.length-1) ? 'prior-disabled' : 'prior-enabled' "></i>
            </div>
            </div>
    </div>

    <section class="body">
        
        <ul [ngStyle]="getStyle()" #carouselWheels (swipeleft)="onSwipeLeft($event)" (swiperight)="onSwipeRight($event)">
            <li *ngFor="let item of visibleItems;">
                <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{link:item}"></ng-container>
            </li>
        </ul>
    </section>
    <div class="footer" *ngIf="undefined !== url">
        <a [routerLink]="resolveRouterLink(url)" [queryParams]="{show:paramValue}">Ver más</a>
    </div>
</section>


<ng-template #content let-link='link'>

    <app-carousel-receipt [data]="link" *ngIf="type==='RECEPT'" [loading]="loading"></app-carousel-receipt>

    <app-carousel-product [data]="link" *ngIf="type==='PRODUCT'" [loading]="loading"></app-carousel-product>

    <app-carousel-service [data]="link" *ngIf="type==='SERVICE'" [loading]="loading"></app-carousel-service>

    <app-carousel-openevent [data]="link" *ngIf="type==='OPEN_EVENT'" [loading]="loading"></app-carousel-openevent>

</ng-template>