<div class="container sbs" [ngClass]="(changed) ? 'back-full' : ''">

    <section class="side-left" *ngIf="!changed">
        <app-change-password-form (onChangeSubmit)="onChangeSubmit($event)"></app-change-password-form>
    </section>

    <section class="side-right" *ngIf="!changed">
        <app-picture-with-text></app-picture-with-text>
    </section>

</div>