<app-collapse-container-event caption="Servicios relacionados con el parte" (click)="traerServicios()">
    <div *ngIf="undefined === servicios" class="cargando">
        <div class="pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>
    </div>

    <div class="service" *ngIf="undefined !== servicios">

        <div *ngIf="servicios.length==0">
            <div class="service-main">
                <div class="service-main-sup">
                    <div class="left">
                        <span>Nº de servicio:</span>
                    </div>
                    <div class="center">
                        <span>Tipo de servicio:</span>
                    </div>
                    <div class="right">
                        <span>Especialidad:</span>
                    </div>
                    <div class="right">
                        <span>Estado:</span>
                    </div>
                    <div class="right">
                        <span>Fecha:</span>
                    </div>
                </div>
                <div class="service-main-inf">
                    <div class="left-inf">
                        <span>Nombre:</span>
                    </div>
                    <div class="center-inf">
                        <span>Teléfono:</span>
                    </div>
                    <div class="right-inf">
                        <span>Email:</span>
                    </div>
                    <div class="right"></div>
                </div>
            </div>
        </div>

        <div class="service-main" *ngFor="let c of servicios ">
            <div *ngIf="servicios.length>0">
                <div class="service-main-sup">
                    <div class="left">
                        <span>Nº de servicio:</span>
                        <p>{{c.serviceNumber}}</p>
                    </div>
                    <div class="center">
                        <span>Tipo de servicio:</span>
                        <p>{{c.serviceType}}</p>
                    </div>
                    <div class="right">
                        <span>Especialidad:</span>
                        <p>{{c.serviceSpecialty}}</p>
                    </div>
                    <div class="right">
                        <span>Estado:</span>
                        <p>{{c.serviceStatus}}</p>
                    </div>
                    <div class="right">
                        <span>Fecha:</span>
                        <p>{{c.serviceDate}}</p>
                    </div>
                </div>
                <div class="service-main-inf">
                    <div class="left-inf">
                        <span>Nombre:</span>
                        <p>{{c.serviceName}}</p>
                    </div>
                    <!--<div class="center"></div>-->
                    <div class="center-inf">
                        <span>Teléfono:</span>
                        <p>{{c.servicePhone}}</p>
                    </div>
                    <div class="right-inf">
                        <span>Email:</span>
                        <p class="breakword">{{c.serviceEmail}}</p>
                    </div>
                    <div class="right"></div>
                </div>
            </div>
            <hr size="1rem" style="margin-top: 1.5rem;" *ngIf="servicios.length>1" />
        </div>
    </div>


</app-collapse-container-event>
