import { Component, OnInit, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-check-box',
    templateUrl: './check-box.component.html',
    styleUrls: ['./check-box.component.scss']
})

export class CheckBoxComponent implements OnInit, ControlValueAccessor {

    @Input() disabled: boolean;
    @Input() label: String;

    @Input() description: String;
    @Input() errorDescription: String;

    value: any = undefined;

    constructor(@Self() @Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit() { }

    writeValue(value: any): void {
        this.value = value;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange(event) {
        this.writeValue(event.target.checked ? true : undefined);
    }

    onTouched() { }

    onClick(event) {
        this.writeValue(event.target.checked ? true : undefined);
        this.onChange(event.target.checked ? true : undefined);
    }

}
