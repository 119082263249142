import { Component, Input } from '@angular/core';
import { DomainService } from '../../../../../services/domain-service';


@Component({
    selector: 'app-carousel-card-wp-integration',
    templateUrl: './carousel-card-wp-integration.component.html',
    styleUrls: ['./carousel-card-wp-integration.component.scss']
})
export class CarouselCardWpIntegrationComponent {

    @Input() data: any;

    constructor(private domainService: DomainService) { }

    resolveRouterLink(param) {
        if (undefined !== param) {
            const newParam = param.replace(this.domainService.wordpressUrl, '');
            return (newParam.includes('http')) ? ['/redirect', { url: newParam }] : newParam;
        }
    }

    getStyle(): any {
        if (undefined !== this.data.image) {
            return {
                'background-image': 'url(' + this.data.image + ')',
                'background-size': 'cover',
                'padding': '0',
                'height': '280px'
            }
        }
        return;
    }

    showButton(): Boolean {
        return undefined !== this.data.link;
    }
}
