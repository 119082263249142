import { Component, OnInit } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';
import { ReceiptParamDTO } from 'app/header-footer-template/model/ReceiptParamDTO';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-receipt-page',
    templateUrl: './receipt-page.component.html',
    styleUrls: ['./receipt-page.component.scss']
})
export class ReceiptPageComponent implements OnInit {

    loading: Boolean = false;
    response: any;
    //footer: any = undefined;
    //footer2: any = undefined;

    private params = new ReceiptParamDTO();

    constructor(private receiptService: ReceiptService,
        private toastr: ToastrService) { }

    ngOnInit(): void {
        this.params = new ReceiptParamDTO();
        this.params.pageOffset = 0;
        this.params.pageSize = 10;
        this.search();

        this.receiptService.observerPageSizeChange().subscribe((result: any) => {
            this.params.pageOffset = 0;
            this.params.pageSize = result;
            window.scrollTo(0, 0);
            this.search();
        });

        this.receiptService.observerPageOffset().subscribe((result: any) => {
            this.params.pageOffset = result;
            window.scrollTo(0, 0);
            this.search();
        });

        this.receiptService.observerPageFilter().subscribe((result: any) => {
            this.params.startDate = result.startDate;
            this.params.endDate = result.endDate;
            this.params.statusType = result.statusType;
            this.params.policyNumber = result.policyNumber;
            this.params.product = result.product;
            this.params.policyCollective = result.policyCollective;
            this.params.policyItems = result.policyItems;
            this.search();
        });
    }

    search() {
        this.loading = true;
        this.receiptService.search(this.params)
            .then(success => {
                this.loading = false;
                this.response = success;
            }).catch(error => {
                this.loading = false;
                this.response = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

}
