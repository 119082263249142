/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../carousel-items/carousel-receipt/carousel-receipt.component.ngfactory";
import * as i5 from "../carousel-items/carousel-receipt/carousel-receipt.component";
import * as i6 from "../../../../services/receipt-service";
import * as i7 from "../carousel-items/carousel-product/carousel-product.component.ngfactory";
import * as i8 from "../carousel-items/carousel-product/carousel-product.component";
import * as i9 from "../../../../services/policy-service";
import * as i10 from "../../../../security/auth.service";
import * as i11 from "../carousel-items/carousel-service/carousel-service.component.ngfactory";
import * as i12 from "../carousel-items/carousel-service/carousel-service.component";
import * as i13 from "../carousel-items/carousel-openevent/carousel-openevent.component.ngfactory";
import * as i14 from "../carousel-items/carousel-openevent/carousel-openevent.component";
import * as i15 from "./carousel.component";
import * as i16 from "@angular/animations";
import * as i17 from "../../../../commons/components/isMobile.service";
var styles_CarouselComponent = [i0.styles];
var RenderType_CarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "nav-bar "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "numeracion"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " de ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "inner-i espacio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prev() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.currentSlide === 0) ? "previous-disabled" : "previous-enabled"); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.currentSlide === (_co.items.length - 1)) ? "prior-disabled" : "prior-enabled"); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentSlide + 1); var currVal_1 = _co.items.length; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_CarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { link: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent, 14); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CarouselComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(3, { show: 0 }), (_l()(), i1.ɵted(-1, null, ["Ver m\u00E1s"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.paramValue); var currVal_3 = _co.resolveRouterLink(_co.url); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CarouselComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-receipt", [], null, null, null, i4.View_CarouselReceiptComponent_0, i4.RenderType_CarouselReceiptComponent)), i1.ɵdid(1, 49152, null, 0, i5.CarouselReceiptComponent, [i6.ReceiptService], { data: [0, "data"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.link; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CarouselComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-product", [], null, null, null, i7.View_CarouselProductComponent_0, i7.RenderType_CarouselProductComponent)), i1.ɵdid(1, 114688, null, 0, i8.CarouselProductComponent, [i9.PolicyService, i10.AuthService], { data: [0, "data"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.link; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CarouselComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-service", [], null, null, null, i11.View_CarouselServiceComponent_0, i11.RenderType_CarouselServiceComponent)), i1.ɵdid(1, 49152, null, 0, i12.CarouselServiceComponent, [i3.Router, i3.ActivatedRoute], { data: [0, "data"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.link; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CarouselComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-openevent", [], null, null, null, i13.View_CarouselOpenEventComponent_0, i13.RenderType_CarouselOpenEventComponent)), i1.ɵdid(1, 114688, null, 0, i14.CarouselOpenEventComponent, [i10.AuthService], { data: [0, "data"], loading: [1, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.link; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CarouselComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_8)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "RECEPT"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "PRODUCT"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.type === "SERVICE"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.type === "OPEN_EVENT"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_CarouselComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { carousel: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "section", [["class", "carousel no-text-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "header "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "title "]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "section", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["carouselWheels", 1]], null, 3, "ul", [], null, [[null, "swipeleft"], [null, "swiperight"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("swipeleft" === en)) {
        var pd_0 = (_co.onSwipeLeft($event) !== false);
        ad = (pd_0 && ad);
    } if (("swiperight" === en)) {
        var pd_1 = (_co.onSwipeRight($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_CarouselComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.items.length > 1); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.getStyle(); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.visibleItems; _ck(_v, 11, 0, currVal_3); var currVal_4 = (undefined !== _co.url); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleText; _ck(_v, 4, 0, currVal_0); }); }
export function View_CarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [], null, null, null, View_CarouselComponent_0, RenderType_CarouselComponent)), i1.ɵdid(1, 4243456, null, 0, i15.CarouselComponent, [i16.AnimationBuilder, i1.ElementRef, i17.IsMobileService], null, null)], null, null); }
var CarouselComponentNgFactory = i1.ɵccf("app-carousel", i15.CarouselComponent, View_CarouselComponent_Host_0, { titleText: "titleText", visibleItems: "visibleItems", type: "type", url: "url", paramValue: "paramValue", loading: "loading", timing: "timing", showControls: "showControls", items: "items" }, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
