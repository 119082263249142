<div class="pagination no-text-select">

    <div class="col" *ngIf="showPagination()">
        <p>Resultado por página</p>
    </div>

    <div class="col" *ngIf="showPagination()">
        <select aria-label="Resultados por pagina" [disabled]="loading" [(value)]="response.size" (change)="onChange($event.target.value)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>

    <div class="col">
        <p>{{ start() }} - {{ end() }} de {{ total() }}</p>
    </div>

    <div class="col col-pag" *ngIf="showPagination()">
        <i (click)="prev()" [ngClass]="response?.first === true ? 'previous-disabled' : 'previous-enabled' ">&nbsp;</i>
        <i (click)="next()" [ngClass]="response?.last === true ? 'prior-disabled' : 'prior-enabled' ">&nbsp;</i>
    </div>

</div>