<div class="nav" [ngClass]="scrolled == true ? 'nav-white' : 'nav-blue'">
  <div class="box-transparent" [routerLink]="['/main']" [ngClass]="scrolled == true ? 'nav-white' : 'nav-none'"></div>
  <div *ngIf="!showDirectAreas && !isMobile" [ngClass]="scrolled == false ? getEntityClass():null"></div>
  <div [ngClass]="scrolled == true ? getEntityClassNav() : null" ></div>
  <div class="full-width">
      <ul>
          <li *ngIf="showDirectAreas"><a [routerLink]="['/products']" routerLinkActive="router-link-active">Mis Productos</a></li>
          <li *ngIf="showDirectAreas"><a [routerLink]="['/receipts']" routerLinkActive="router-link-active">Mis Recibos</a></li>
          <li *ngIf="showDirectAreas"><a [routerLink]="['/contact']" routerLinkActive="router-link-active"> Mis Contactos</a></li>
          <li><a [routerLink]="['/events']" routerLinkActive="router-link-active">Partes</a></li>
          <li *ngIf="showDirectAreas"><a [routerLink]="['/hire']" routerLinkActive="router-link-active">Contrata Más</a></li>
          <li *ngIf="showClubPropietario"><a [routerLink]="['/club-del-propietario']" routerLinkActive="router-link-active">Club del Propietario</a></li>
 
          <li>
              <a class="mail" aria-label="notificaciones" [routerLink]="['/notifications']" [ngClass]=" checkPendingNotifications() ?'mail-sms' : ''"></a>
          </li>
          <li>
              <a class="user">
                  <ul>
                      <li><a [routerLink]="['/profile']">Mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/contact']">Modificar mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/access']">Cambiar contraseña</a></li>
                      <li><a *ngIf="showChangeEntity" [routerLink]="[ '/entities']" >Cambiar aseguradora</a></li>
                      <li><a [routerLink]="[ '/logout']">Cerrar sesión</a></li>
                  </ul>
              </a>
          </li>
          
      </ul>
      
  </div>
  
  <div *ngIf="showDirectAreas"class="responsive no-text-select">
      <ul>
          <li>
              <div class="burger" (click)="toggleBurger()" [ngClass]="{ open: burgerState }">
                  <div class="burger-menu" [ngClass]="getBurgerClass()">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                  </div>
              </div>
          </li>
          <li class="inner-menu">
              <diV class="menu" [ngClass]="{ 'open': burgerState }">
                  <ul>
                      <li><a [routerLink]="['/products']">Mis Productos</a></li>
                      <li><a [routerLink]="['/receipts']">Mis Recibos</a></li>
                      <li><a [routerLink]="['/contact']"> Mis Contactos</a></li>
                      <li><a [routerLink]="['/events']">Partes</a></li>
                      <li><a [routerLink]="['/hire']">Contrata Más</a></li>
                      <li *ngIf="showClubPropietario"><a [routerLink]="['/club-del-propietario']">Club del propietario</a></li>
                      <li>
                          <a class="mail" aria-label="notificaciones" [routerLink]="['/notifications']" [ngClass]=" checkPendingNotifications() ?'mail-sms' : ''">Notificaciones</a>
                      </li>
                  </ul>

                  <ul>
                      <li><a [routerLink]="[ '/profile']">Mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/contact']">Modificar mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/access']">Cambiar contraseña</a></li>
                      <li *ngIf="showChangeEntity"> <a [routerLink]="[ '/entities']" >Cambiar aseguradora</a></li>
                      <li><a [routerLink]="[ '/logout']">Cerrar sesión</a></li>
                  </ul>
                  <ul>
                      <li></li>
                  </ul>
              </diV>
          </li>
      </ul>
  </div>

  <div *ngIf="!showDirectAreas" class="responsive no-text-select">
      <ul>
          <li>
              <div class="burger" (click)="toggleBurger()" [ngClass]="{ open: burgerState }">
                  <div class="burger-menu" [ngClass]="getBurgerClass()">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                  </div>
              </div>
          </li>
          <li class="inner-menu">
              <diV class="menu" [ngClass]="{ 'open': burgerState }">
                  <ul>
                      <li><a [routerLink]="['/events']">Partes</a></li>
                      <li> <a class="mail" aria-label="notificaciones" [routerLink]="['/notifications']" [ngClass]=" checkPendingNotifications() ?'mail-sms' : ''">Notificaciones</a></li>
                      <li><a [routerLink]="[ '/profile']">Mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/contact']">Modificar mi perfil</a></li>
                      <li><a [routerLink]="[ '/modify/access']">Cambiar contraseña</a></li>
                      <li *ngIf="showChangeEntity"><a [routerLink]="[ '/entities']" >Cambiar aseguradora</a></li>
                      <li><a [routerLink]="[ '/logout']">Cerrar sesión</a></li>
                  </ul>
              </diV>
          </li>
      </ul>
  </div>

</div>
<div class="aux-box" [ngClass]="{ 'visible': scrolled }"></div>