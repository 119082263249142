import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-breadcrumb',
  templateUrl: './header-breadcrumb.component.html',
  styleUrls: ['./header-breadcrumb.component.scss']
})
export class HeaderBreadcrumbComponent {

  @Input() crumb: String;
  @Input() crumb2: String;
  @Input() text: String;
  @Input() policy: String;

}
