import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { Location } from '@angular/common';
import { CommonsText } from '../../../commons/CommonsText';

@Component({
    selector: 'app-confirm-sms-register-resend-form',
    templateUrl: './confirm-sms-register-resend-form.component.html',
    styleUrls: ['./confirm-sms-register-resend-form.component.scss']
})
export class ConfirmSmsRegisterResendFormComponent {

    @Output() onConfirmSmsRegisterSubmit = new EventEmitter();
    @Output() onReceiveNewSmsCode = new EventEmitter();

    messages: String[] = null;
    running: Boolean = false;
    prefixData: any[];
    prefixSettings: any;
    resendRegisterForm: FormGroup;
    reinsured;
    innominado;
    parametros;

    constructor(private route: ActivatedRoute, private userService: UserService,
        private formBuilder: FormBuilder, private location: Location) {

            this.parametros = this.route.snapshot.params
            if(this.parametros['nameless']){
                this.innominado = this.parametros['nameless'];
            } else {
                this.reinsured = this.parametros['reinsured'];
            }

        this.resendRegisterForm = this.formBuilder.group({
            mobilePhonePrefix: [null, [Validators.required]],
            mobilePhone: [null, [CommonsUtils.validMobilePhone('mobilePhonePrefix'), Validators.required]],
        })

        if (undefined !== this.userService.getLatestRegister()) {
            const mobilePhonePrefix = this.userService.getLatestRegister().mobilePhonePrefix;
            const mobilePhone = this.userService.getLatestRegister().mobilePhone;
            this.resendRegisterForm.patchValue({
                mobilePhonePrefix: mobilePhonePrefix,
                mobilePhone: mobilePhone
            });
        }

        this.prefixData = CommonsUtils.phonePrefix();

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

    }

    submitEnabled() {
        return (this.resendRegisterForm.valid && (!this.running));
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.SOLICITAR_NUEVO_CODIGO_CONFIRMACION;
    }

    onResendRegister() {

        if (this.submitEnabled()) {

            const latestRegister = this.userService.getLatestRegister();
            latestRegister.mobilePhone = this.resendRegisterForm.value.mobilePhone;
            latestRegister.mobilePhonePrefix = this.resendRegisterForm.value.mobilePhonePrefix;

            this.userService.onRegister(latestRegister, this.reinsured)
                .then(success => {
                    this.running = false;
                    this.userService.setCounterVisibility(true);
                    this.userService.setLatestRegister(latestRegister);

                    if ((undefined !== success.response.code) && (null !== success.response.code)) {
                        this.userService.setCode(success.response.code);
                        if(this.reinsured){
                            this.location.replaceState('/confirm/' + success.response.code + '/reinsured/' + this.reinsured);
                        }else{
                            this.location.replaceState('/confirm/' + success.response.code);
                        }
                        
                        this.onReceiveNewSmsCode.emit(true);
                    }

                }).catch(error => {
                    this.running = false;
                    this.messages = error.error.response;
                    if (undefined === error.error.response) {
                        this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }
                })
        }

    }

}
