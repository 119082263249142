import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { DomainService } from './domain-service';
import { Observable, Subject } from 'rxjs';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { CommonsUtils } from 'app/commons/CommonsUtils';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de servicios de Auditoria
 * @author Iecisa
 */
export class AudityService {

    constructor(
        private http: HttpClient, private domainService: DomainService) {
    }
    
    saveLogin(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLogin';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveRegister(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveRegister';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveLoginBlockedByRenewal(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlockedByRenewal';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveLoginBlocked(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlocked';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveLoginDisabled(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginDisabled';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveLoginBlockedByRgpd(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginBlockedByRgpd';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveLoginWithoutPolicies(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginWithoutPolicies';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }
    
    saveLoginIncorrectParameter(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveLoginIncorrectParameter';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveUserErrorRegister(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveUserErrorRegister';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();

        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

    saveFanditAcces(param: AudityDTO): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/audity/saveFanditAcces';
        const form = new FormData();

        const headers = CommonsUtils.headersBearer();
        
        form.append('audityDocument', param.audityDocument);
        form.append('audityIp', param.audityIp);

        return this.http.post<any>(url, form, {headers:headers}).toPromise();
    }

}
