<div class="time-picker">
    <button type="button" class="btn_hour" (click)="toggleHourMenu()">{{ formatNumber(hour) }}</button>

    <div class="dots">
        <p>:</p>
    </div>

    <button type="button" class="btn_minute" (click)="toggleMinuteMenu()">{{ formatNumber(minute) }}</button>

    <div class="clock">

        <div class="selection_hours" *ngIf="hoursOpen">
            <div class="time-values">
                <div *ngFor="let hour of hours">
                    <div [ngClass]="getClass(hour)" (click)="selectHourChange(hour)">
                        <p>
                            {{ hour }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="selection_minutes" *ngIf="minutesOpen">
            <div class="time-values time_minute">
                <div class="time-value" (click)="selectMinuteChange(minute)" *ngFor="let minute of minutes">
                    <p>
                        {{ minute }}
                    </p>
                </div>
            </div>
        </div>

    </div>

</div>