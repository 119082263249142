import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EventService } from 'app/services/event-service';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from 'app/commons/CommonsText';


@Component({
    selector: 'app-event-filter',
    templateUrl: './event-filter.component.html',
    styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit {

    showDirectAreas: Boolean = true;

    filterForm: FormGroup;

    statusTypeList = [];
    productList = [];
    statusTypeSettings = {};
    productSettings = {};
    policySettings = {};
    eventSettings = {};
    policyList = [];
    eventList = [];

    @Input() set response(value: any) {

        if (undefined !== value) {
            this.statusTypeList = value.statusType;
            //Solo lo carga para directo, no para reaseguro
            if(this.showDirectAreas == true){
                this.productList = value.product;
            }
            this.policyList = value.policyItems;
            this.eventList = value.eventItems;
        }
    }

    @Input() loading: Boolean = false;
    @Input() policyNumber: any;

    constructor(private eventService: EventService,
        private formBuilder: FormBuilder,
        private authService: AuthService) { }

    ngOnInit(): void {

        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.productSettings = {
            singleSelection: false,
            text: 'Todos los seguros',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.policySettings = {
            singleSelection: false,
            text: 'Todas las pólizas',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.eventSettings = {
            singleSelection: false,
            text: 'Todos los partes',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        }

        this.filterForm = this.formBuilder.group({
            startDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            endDate: ['', [CustomDateValidator.correctDayAndMonthFilterVersion]],
            statusType: [[], []],
            policyNumber: [this.policyNumber, []],
            product: [[], []],
            policyItems: [[], []],
            eventItems: [[], []]
        });

        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }

    }

    getButtonLabel() {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    }

    onFilter() {
        this.eventService.setPageFilter(this.filterForm.value);
    }

    comprobarDesde(event){
        if(event !== "" && this.filterForm.value.endDate !== ""){

            let dateParts1 = event.split("-");
            let date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]); 

            let dateParts2 = this.filterForm.value.endDate.split("-")
            let date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]); 

            if(date1.getTime() > date2.getTime()){
                this.filterForm.controls['startDate'].setErrors({ErrorStartDay: true});
            }
            else if(date1.getTime() < date2.getTime()){
                this.filterForm.controls['endDate'].setErrors(null);
            } 
            else if(date1.getTime() == date2.getTime()){
                this.filterForm.controls['endDate'].setErrors(null);
            }
        }

    }

    comprobarHasta(event){
        if(event !== "" && this.filterForm.value.startDate !== ""){

            let dateParts1 = event.split("-");
            let date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]); 

            let dateParts2 = this.filterForm.value.startDate.split("-")
            let date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]); 

            if(date1.getTime() < date2.getTime()){
                this.filterForm.controls['endDate'].setErrors({ErrorEndDay: true});
            }
            else if(date1.getTime() > date2.getTime()){
                this.filterForm.controls['startDate'].setErrors(null);
            } 
            else if(date1.getTime() == date2.getTime()){
                this.filterForm.controls['startDate'].setErrors(null);
            }
        }

    }

}
