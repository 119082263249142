import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from 'app/header-footer-template/pages/main-page/main-page.component';
import { AuthRedirectorGuard } from 'app/security/auth.redirector.guard';

const routes: Routes = [ 
  { path: 'main', canActivate: [AuthRedirectorGuard], component: MainPageComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
