import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WordpressService } from 'app/services/wordpress-service';
import { DomainService } from '../../services/domain-service';
import { version } from '../../../../package.json';
import { AuthService } from 'app/security/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  products: any;
    receipts: any;
    contacts: any;
    services: any;
    social: any;
    onlygal: any;
    customer: any;
    sub: any;
    public version: string = version;

    showDirectAreas: Boolean = true;


    constructor(
        private wordpressService: WordpressService,
        private router: Router,
        private domainService: DomainService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {

        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;

        this.wordpressService.loadMenu()
            .then(any => {
                this.products = this.wordpressService.getMenu('footer-product-menu');
                this.receipts = this.wordpressService.getMenu('footer-receipt-menu');
                this.contacts = this.wordpressService.getMenu('footer-contact-menu');
                this.showDirectAreas ?
                    this.services = this.wordpressService.getMenu('footer-event-menu')
                    : this.services = this.wordpressService.getMenu('footer-reinsured-event-menu')
                    ;
                this.showDirectAreas ?
                    this.contacts = this.wordpressService.getMenu('footer-contact-menu')
                    : this.contacts = this.wordpressService.getMenu('footer-reinsured-contact-menu')
                    ;
                this.social = this.wordpressService.getMenu('footer-social-menu');
                this.onlygal = this.wordpressService.getMenu('footer-das-menu');
                this.customer = this.wordpressService.getMenu('footer-customersupport-menu');
                this.sub = this.wordpressService.getMenu('footer-sub-menu');
            }).catch(error => {
            })



    }

    resolveRouterLink(param) {
        const newParam = param.replace(this.domainService.wordpressUrl, '');
        return (newParam.includes('http')) ? ['/redirect', { url: newParam }] : newParam;
    }

    getYear() {
        const date = new Date();
        return date.getFullYear();
    }

}
