import { Component, OnInit, Input } from '@angular/core';
import { PolicyService } from 'app/services/policy-service';
import { DasLexService } from 'app/services/daslex-service';
import { AuthService } from 'app/security/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';
import { ActivatedRoute } from '@angular/router';
import { LegalibooService } from 'app/services/legaliboo-service';
import { ClientService } from 'app/services/client-service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
    loading: Boolean = false;
    loadingDasLex: Boolean = false;
    response: any;
    user: any;
    loadFandid: boolean = false;
    responseDasLex: any;
    urlOnlygal = '';
    legalibooConfig: any;
    loadingLegaliboo: boolean = true;
    showDirectAreas: boolean = true;
    clicked: boolean = true;
    @Input() public showProducts: boolean;
    @Input() public showServices: boolean;
    @Input() public showDocuments: boolean;

    private params = new PolicyParamDTO();
    paramsDasLex = new DasLexParamDTO();

    constructor(
        private clientService: ClientService,
        private dasLexService: DasLexService,
        private policyService: PolicyService,
        private legalibooService: LegalibooService,
        private authService: AuthService,
        private toastr: ToastrService,
        private route: ActivatedRoute) {

        this.route.queryParams.subscribe(params => {

            let show = params['show'];

            if (show == 'services') {
                this.showServices = true;
            }
            if (show == 'products') {
                this.showProducts = true;
            }
            if (show == 'documents') {
                this.showDocuments = true;
            }
        }
        );
    }

    ngOnInit(): void {

        this.clientService.getBannersInfo()
        .then(response => {
                this.user = response.response;
                this.loadFandid = true;
        }).catch(error => {
            this.toastr.error(CommonsText.ERROR_RECUPERAR_CLIENTE, CommonsText.ERROR_DE_OPERACION, {
                timeOut: 5000
            }); 
        });

        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;

        this.params = new PolicyParamDTO();
        this.params.pageOffset = 0;
        this.params.pageSize = 5;

        this.paramsDasLex = new DasLexParamDTO();
        this.paramsDasLex.pageOffset = 0;
        this.paramsDasLex.pageSize = 5;

        this.search();

        if (this.showDirectAreas) {
            this.isLegalibooAvailable();
        }

        this.policyService.observerPageSizeChange().subscribe((result: any) => {
            this.params.pageOffset = 0;
            this.params.pageSize = result;
            window.scrollTo(0, 0);
            this.search();
        });

        this.policyService.observerPageOffset().subscribe((result: any) => {
            this.params.pageOffset = result;
            window.scrollTo(0, 0);
            this.search();
        });

        this.policyService.observerPageFilter().subscribe((result: any) => {
            this.params.startDate = result.startDate;
            this.params.endDate = result.endDate;
            this.params.statusType = result.statusType;
            this.params.policyNumber = result.policyNumber;
            this.params.product = result.product;
            this.params.policyCollective = result.policyCollective;
            this.params.policyItems = result.policyItems;
            this.search();
        });

        this.searchDasLex();

        this.dasLexService.observerPageSizeChange().subscribe((result: any) => {
            this.paramsDasLex.pageOffset = 0;
            this.paramsDasLex.pageSize = result;
            this.searchDasLex();
        });

        this.dasLexService.observerPageOffset().subscribe((result: any) => {
            this.paramsDasLex.pageOffset = result;
            this.searchDasLex();
        });

        this.dasLexService.observerPageFilter().subscribe((result: any) => {
            this.paramsDasLex.statusType = result.statusType;
            this.paramsDasLex.service = result.service;
            this.paramsDasLex.contractItems = result.contractItems;
            this.searchDasLex();
        });
    }

    search() {
        this.loading = true;

        this.policyService.search(this.params)
            .then(success => {
                this.loading = false;
                this.response = success;
            }).catch(error => {
                this.loading = false;
                this.response = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_POLIZAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    searchDasLex() {
        this.loadingDasLex = true;

        this.dasLexService.search(this.paramsDasLex)
            .then(success => {
                this.loadingDasLex = false;
                this.responseDasLex = success;
                if (this.responseDasLex.content.length > 0) {
                    this.urlOnlygal = this.responseDasLex.content[0].urlDas;
                }
            }).catch(error => {
                this.loadingDasLex = false;
                this.responseDasLex = null;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_DASLEX, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    searchLegaliboo() {
        // this.loadingLegaliboo = false;
        // this.legalibooService.getLegalibooConfig()
        //     .then(response => {
        //         this.loadingLegaliboo = false;
        //         this.legalibooConfig = response.response;
        //     }).catch(error => {
        //         this.loadingLegaliboo = false;
        //         this.toastr.error(CommonsText.PROBLEMA_SERVICIO_LEGALIBOO, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
        //             timeOut: 5000
        //         });
        //     });
    }


    /* 
        HARDCODED METHOD TO CHECK IF LEGALIBOO LINK IS VISIBLE
        Only available por users with PP09 and PP11 policies
    */
    isLegalibooAvailable() {

        const params = new PolicyParamDTO();
        params.pageOffset = 0;
        params.pageSize = 9999;

        this.policyService.search(params)
            .then(success => {
                for(let prod of success.product){
                    if (prod && prod.id && (prod.id == 'PP09' || prod.id == 'PP11')) {
                        // this.searchLegaliboo();
                        this.loadingLegaliboo = false;
                        break;
                    }
                }
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_LEGALIBOO, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    serviceAccess(){
        this.dasLexService.serviceAccess();
    }

}
