<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb text="Pagar recibo pendiente"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container">

    <div *ngIf="undefined === receipt" class="pre-loading">
        <p>Cargando<span>.</span><span>.</span><span>.</span></p>
    </div>

    <div class="receipt-confirmed" *ngIf="undefined !== receipt && this.paymentResponse === 'ok'">
        Pago confirmado con éxito. ¡Gracias!
    </div>

    <div class="receipt-confirmed-ko" *ngIf="undefined !== receipt && this.paymentResponse === 'ko'">
        Pago no confirmado. Seleccione un nuevo medio de pago y confirme.
    </div>

    <div class="receipt-confirmed" *ngIf="undefined !== receipt && this.paymentResponse === 'np'">
        Método de pago configurado con éxito. ¡Gracias!
    </div>

    <div *ngIf="undefined !== receipt" class="receipt-container">
        <div class="receipt no-text-select">
            <div class="receipt-form">
                <div class="receipt-form-box background-{{ getColorByOperation() }}"></div>
                <div class="receipt-form-img">
                    <app-icon-type [icon]="receipt.branchType" color="{{ getColorByOperation() }}"></app-icon-type>
                </div>
                <div class="receipt-form-number">
                    <div class="sup">
                        <div class="sup-izq" *ngIf="!receipt.docCobroNumber">
                            <p>Nº de póliza / contrato:</p>
                            <span>{{ receipt.policyNumber }}</span> <span style="color:gray; font-size: 13px;" *ngIf="undefined !== receipt.policyCollective"> (Colectivo {{ receipt.policyCollective }} ) </span>
                        </div>
                        <div class="sup-izq" *ngIf="receipt.docCobroNumber">
                            <p>Doc. Cobro:</p>
                            <span>{{ receipt.docCobroNumber }}</span> <span style="color:gray; font-size: 13px;" *ngIf="undefined !== receipt.policyCollective"> (Colectivo {{ receipt.policyCollective }} ) </span>
                        </div>
                        <div class="sup-der">
                            <p>Nº recibo:</p>
                            <span>{{ receipt.receiptNumber }}</span>
                        </div>
                    </div>
                    <div class="inf">
                        <div class="inf-izq">
                            <p>Vigencia:</p>
                            <span>{{ receipt.receiptStartDate | date: 'dd/MM/yyyy' }} -
                                {{receipt.receiptEndDate | date: 'dd/MM/yyyy' }}</span>
                        </div>
                        <div class="inf-der">
                            <p>Periocidad:</p>
                            <span>{{ receipt.policyPeriodicity }}</span>
                        </div>
                    </div>
                    <div class="infDoc" *ngIf="receipt.docCobroNumber">
                        <span class="docCobro">Documento de Cobro</span>
                    </div>
                </div>
                <div class="receipt-form-product">
                    <div class="sup">
                        <p>Producto:</p>
                        <span>{{ receipt.policyProduct }}</span>
                    </div>
                    <div class="inf">
                        <p>Tipo de cobro:</p>
                        <span>{{ receipt.receiptPaymentMethod }}</span>
                    </div>
                </div>
                <div class="receipt-form-import">
                    <div class="receipt-form-import-number">
                        <p>Importe</p>
                        <span>{{ receipt.receiptAmount | currency:'EUR' }}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="undefined !== receipt && this.paymentResponse !== 'ok' && this.paymentResponse !== 'np'" class="payment-container">

        <div class="title">
            <p>Selecciona su medio de pago</p>
        </div>

        <div class="content">
            <div class="row row-credit-card">
                <div class="select">
                    <i class="spinner" style="display: none"></i>
                    <p style="display:none" class="loading">Cargando<span>.</span><span>.</span><span>.</span></p>
                    <label for="">CUENTA BANCARIA</label>
                    <select (change)="onChangeAccount($event)" [disabled]="undefined === accountList && !runningPayWithNewAccount && !runningPayWithAccount ">
                        <option value="" disabled selected hidden>Seleccione una cuenta bancária...</option>
                        <option *ngFor="let c of accountList" value="{{c.itemName}}">{{c.itemName}}</option>
                        <option value="NEW ACCOUNT">Nueva cuenta bancaria</option>
                    </select>
                </div>

                <div class="btn-account">
                    <app-spinner-button [label]="getPayWithAccountButtonLabel()" [enabled]="enablePayWithAccountButton()" [running]="runningPayWithAccount" (click)="onPayWithAccountNumber()">
                    </app-spinner-button>
                </div>
                <div class="btn-card">
                    <app-spinner-button label="prefiero pagar con tarjeta de crédito" enabled="true" [running]="false" (click)="pay()">
                    </app-spinner-button>
                </div>
            </div>

            <div class="row row-bank" *ngIf="selectedAccount === 'NEW ACCOUNT'" [formGroup]="payWithNewAccountForm">

                <div class="row row-bank-account">
                    <app-input-box type="text" label="IBAN" formControlName="iban" mask="SS00" errorDescription="Formato (ES00)" (onPaste)="captureIban($event)"></app-input-box>
                    <app-input-box type="text" label="Entidad" formControlName="entity" mask="0000" errorDescription="4 números" (onPaste)="captureIban($event)"></app-input-box>
                    <app-input-box type="text" label="Sucursal" formControlName="office" mask="0000" errorDescription="4 números"></app-input-box>
                    <app-input-box type="text" label="DC" formControlName="digit" mask="00" errorDescription="2 números" (onPaste)="captureIban($event)">
                    </app-input-box>
                    <app-input-box type="text" label="Cuenta" formControlName="accountNumber" mask="0000000000" errorDescription="10 números" (onPaste)="captureIban($event)">
                    </app-input-box>
                </div>

                <div class="row row-bank-titular">
                    <div class="row row-bank-titular-cont">
                        <label>Titular de la cuenta:</label>
                        <app-tooltip class="row row-bank-titular-cont-tooltip" text="La titularidad de la cuenta bancaria debe coincidir con el tomador del seguro"></app-tooltip>
                        <p>{{receipt.name}}</p>
                    </div>
                    <div class="row row-bank-titular-select">
                        <label>Tipo de documento:</label>
                        <p>{{receipt.documentType}}</p>
                    </div>
                    <div class="row row-bank-titular-num">
                        <label>Nº de documento:</label>
                        <p>{{receipt.documentNumber}}</p>
                    </div>

                </div>

                <div class="row row-bank-upload-confirm">
                    <div class="fileUpload">
                        <ngfFormData [files]="files" postName="file" [(FormData)]="uploadFormData"></ngfFormData>
                        <div [hidden]="files.length > 0">
                            <div ngfDrop multiple="0" selectable="1" [(validDrag)]="validComboDrag" [(files)]="files" accept="image/*,application/pdf" [(dragFiles)]="dragFiles" class="well my-drop-zone" [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
                                (filesChange)="lastFileAt = getDate()">
                                <div class="img"></div>
                                <p>Haga clic o arrastre para adjuntar un documento que acredite la propiedad de la cuenta bancaria.
                                    <span style="color:red"> (*) Obligatorio</span>
                                </p>
                            </div>
                        </div>
                        <div *ngIf="files.length > 0" class="files">
                            <div class="files-main" *ngFor="let item of files; let i = index">
                                <div class="name">
                                    <div class="img">
                                        <div class="img-inner"></div>
                                    </div>
                                    <p>{{ item.name }}</p>
                                </div>
                                <div class="size">
                                    <p>{{ item.size / 1024 / 1024 | number: ".2" }} MB</p>
                                </div>
                                <div class="erase">
                                    <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i, 1)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-spinner-button [label]="getPayWithNewAccountButtonLabel()" [enabled]="enablePayWithNewAccountButton()" [running]="runningPayWithNewAccount" (click)="onPayWithNewAccount()">
                    </app-spinner-button>
                </div>

                <div class="row row-bank-message-red" *ngIf="undefined !== payWithNewAccountMessages">
                    {{payWithNewAccountMessages}}
                </div>

            </div>
        </div>

    </div>

</div>

<app-footer></app-footer>