import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForgotPasswordDTO } from '../../model/ForgotPasswordDTO';
import { UserService } from '../../../services/user-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { CommonsText } from '../../../commons/CommonsText';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html',
    styleUrls: ['./forgot-password-form.component.scss']
})

export class ForgotPasswordFormComponent implements OnInit {

    @Output() onForgotPasswordSubmit = new EventEmitter();

    forgotPasswordForm: FormGroup;
    forgotPasswordDTO: ForgotPasswordDTO;
    messages: String[] = null;
    recaptchaResolved: Boolean = false;
    running: Boolean;

    constructor(private formBuilder: FormBuilder, private userService: UserService) { }

    ngOnInit() {

        localStorage.clear();

        this.forgotPasswordDTO = new ForgotPasswordDTO();
        this.forgotPasswordForm = this.formBuilder.group({
            email: [this.forgotPasswordDTO.email, [Validators.required, Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]]
        })

        this.forgotPasswordForm.valueChanges.subscribe(data => { this.messages = null; });
    }

    onForgotPassword() {

        this.messages = [];
        if (this.submitEnabled()) {
            this.running = true;
            this.userService.onForgotPassword(this.forgotPasswordForm.value)
                .then(success => {
                    this.onForgotPasswordSubmit.emit(true);
                }).catch(error => {
                    this.running = false;
                    this.messages = error.error.response;
                    if (undefined === error.error.response) {
                        this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }
                })
        }

    }

    onRecaptcha($event) {
        this.recaptchaResolved = true;
        if($event == null){
            this.recaptchaResolved = false;
        }
    }

    submitEnabled() {
        return this.forgotPasswordForm.valid && this.recaptchaResolved && (!this.running);
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : CommonsText.CONFIRMAR;
    }
}
