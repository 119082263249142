<div class="sbs-back">

    <div class="container sbs">

        <section>
            <app-welcome-text></app-welcome-text>
        </section>

        <section *ngIf="showEntities">
            <app-selected-entity></app-selected-entity>
        </section>

    </div>

</div>