import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-assistance-image',
  templateUrl: './event-assistance-image.component.html',
  styleUrls: ['./event-assistance-image.component.scss']
})
export class EventAssistanceImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
