import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonsModule } from 'app/commons/commons.module';

import { ClubPropietarioRoutingModule } from './club-propietario-routing.module';
import { HomeComponent } from './components/home/home.component';
import { DescubreComponent } from './components/descubre/descubre.component';

import { CoreModule } from 'app/core/core.module';
import { SubCategoriaComponent } from './components/subcategoria/subcategoria.component';
import { DetalleComponent } from './components/detalle/detalle.component';
import { RecomendacionComponent } from './components/recomendacion/recomendacion.component';
import { HomeCategoriaComponent } from './components/home-categoria/home-categoria.component';
import { InicioClubPropietarioComponent } from './components/inicio/inicio-club-propietario.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule, MatToolbarModule, MatGridListModule, MatTabsModule } from '@angular/material';
import { CategoriaComponent } from './components/categoria/categoria.component';
import {MatIconModule} from '@angular/material/icon';
import { CheckModalClubComponent } from 'app/modules/club-propietario/components/check-modal-club/check-modal-club.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HomeComponent, DescubreComponent, 
     SubCategoriaComponent, DetalleComponent,
    RecomendacionComponent, HomeCategoriaComponent, InicioClubPropietarioComponent,
    CategoriaComponent, CategoriaComponent, CheckModalClubComponent],
  imports: [
    CommonModule,
    ClubPropietarioRoutingModule,
    CoreModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatGridListModule,
    MatTabsModule,
    MatIconModule,
    CommonsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ClubPropietarioModule { }
