/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-main-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header-profile-progress-bar/header-profile-progress-bar.component.ngfactory";
import * as i3 from "../header-profile-progress-bar/header-profile-progress-bar.component";
import * as i4 from "./header-main-profile.component";
import * as i5 from "../../../../security/auth.service";
import * as i6 from "../../../../services/client-service";
import * as i7 from "ngx-toastr";
var styles_HeaderMainProfileComponent = [i0.styles];
var RenderType_HeaderMainProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMainProfileComponent, data: {} });
export { RenderType_HeaderMainProfileComponent as RenderType_HeaderMainProfileComponent };
export function View_HeaderMainProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "profile-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Hola ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-header-profile-progress-bar", [], null, null, null, i2.View_HeaderProfileProgressBarComponent_0, i2.RenderType_HeaderProfileProgressBarComponent)), i1.ɵdid(5, 49152, null, 0, i3.HeaderProfileProgressBarComponent, [], { percent: [0, "percent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.percent; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userName; _ck(_v, 3, 0, currVal_0); }); }
export function View_HeaderMainProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-main-profile", [], null, null, null, View_HeaderMainProfileComponent_0, RenderType_HeaderMainProfileComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderMainProfileComponent, [i5.AuthService, i6.ClientService, i7.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMainProfileComponentNgFactory = i1.ɵccf("app-header-main-profile", i4.HeaderMainProfileComponent, View_HeaderMainProfileComponent_Host_0, {}, {}, []);
export { HeaderMainProfileComponentNgFactory as HeaderMainProfileComponentNgFactory };
