<form [formGroup]="resendRegisterForm" (ngSubmit)="onResendRegister()" class="no-text-select">

    <div class="title row">
        <p>Registro del Área de Cliente Onlygal</p>
        <p>Por favor, solicita un nuevo código de confirmación.</p>
    </div>
    <div class="row steps">
        <p class="paso">Paso 2 de 3
        </p>
    </div>

    <div class="row cont-select">
        <div class="inner-select">
            <angular2-multiselect [data]="prefixData" [settings]="prefixSettings" formControlName="mobilePhonePrefix">
                <c-badge>
                    <ng-template let-item="item">
                        <label class="phoneBadge">
                            <span *ngIf="undefined !== item.id" class="flags flag{{item.id}}"></span>
                            <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                            <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                        </label>
                    </ng-template>
                </c-badge>

                <c-item>
                    <ng-template let-item="item">
                        <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                            <span class="flags flag{{item.id}}"></span>
                            <span>+{{item.id}}</span>
                            <span>{{item.itemName}}</span>
                        </label>
                    </ng-template>
                </c-item>
            </angular2-multiselect>
        </div>

        <div class="inner-input">
            <app-input-box type="text" label="TELÉFONO MOVIL" formControlName="mobilePhone" maxlength="128"
                mask="000000000000000000" isValidPhoneErrorDescription="Número no válido"
                description="Introduzca un número de teléfono válido"
                errorDescription="Introduzca un número de teléfono válido">
            </app-input-box>
        </div>

    </div>

    <div class="row">
        <app-error-messages [(messages)]=messages></app-error-messages>
    </div>

    <div class="row row-button">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running">
        </app-spinner-button>
    </div>

    <div class="row">
        <app-contact-line></app-contact-line>
    </div>

</form>