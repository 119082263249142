/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./payment/payment-loading/payment-loading.component.ngfactory";
import * as i3 from "./payment/payment-loading/payment-loading.component";
import * as i4 from "./services/receipt-service";
import * as i5 from "./commons/components/add-account-to-policy/add-account-to-policy.component.ngfactory";
import * as i6 from "./commons/components/add-account-to-policy/add-account-to-policy.component";
import * as i7 from "./services/client-service";
import * as i8 from "./commons/components/change-policy-receipts/change-policy-receipts.component.ngfactory";
import * as i9 from "./commons/components/change-policy-receipts/change-policy-receipts.component";
import * as i10 from "./modal/complete-profile-modal/complete-profile-modal.component.ngfactory";
import * as i11 from "./modal/complete-profile-modal/complete-profile-modal.component";
import * as i12 from "ngx-toastr";
import * as i13 from "./commons/components/pop-up-legaliboo/pop-up-legaliboo.component.ngfactory";
import * as i14 from "./commons/components/pop-up-legaliboo/pop-up-legaliboo.component";
import * as i15 from "./app.component";
import * as i16 from "ngx-wow";
import * as i17 from "@angular/common";
import * as i18 from "./shared/services/wordpress/club-propietario.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-payment-loading", [], null, null, null, i2.View_PaymentLoadingComponent_0, i2.RenderType_PaymentLoadingComponent)), i0.ɵdid(3, 114688, null, 0, i3.PaymentLoadingComponent, [i4.ReceiptService], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-add-account-to-policy", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_AddAccountToPolicyComponent_0, i5.RenderType_AddAccountToPolicyComponent)), i0.ɵdid(5, 114688, null, 0, i6.AddAccountToPolicyComponent, [i7.ClientService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "change-policy-receipts", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ChangePolicyReceiptsComponent_0, i8.RenderType_ChangePolicyReceiptsComponent)), i0.ɵdid(7, 114688, null, 0, i9.ChangePolicyReceiptsComponent, [i7.ClientService], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-complete-profile-modal", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CompleteProfileModalComponent_0, i10.RenderType_CompleteProfileModalComponent)), i0.ɵdid(9, 114688, null, 0, i11.CompleteProfileModalComponent, [i1.Router, i7.ClientService, i12.ToastrService, i0.ElementRef], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-pop-up-legaliboo", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_PopUpLegaliboo_0, i13.RenderType_PopUpLegaliboo)), i0.ɵdid(11, 114688, null, 0, i14.PopUpLegaliboo, [i7.ClientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 11, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i15.AppComponent, [i16.NgwWowService, i0.ViewContainerRef, i4.ReceiptService, i1.Router, i17.LocationStrategy, i18.ClubPropietarioService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
