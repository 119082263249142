<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Partes"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container">

    <div class="cont-check" #contCheck>
        <app-event-register-check-balls [page]=page></app-event-register-check-balls>
    </div>

    <div class="tabs">
        <div class="content">
            <section *ngIf="(page === 0)">
                <app-event-register-first-step (selected)="onSelectCallback($event)" [policy]="data"
                    [formGroup]="firstStepForm">
                </app-event-register-first-step>
            </section>
            <section *ngIf="(page === 1)">
                <app-event-register-second-step [formGroup]="secondStepForm" (onInsuredSelected)="onInsuredSelected($event)" [insuredList]="insuredList" [policy]="data" [prefixData]="prefixData">
                </app-event-register-second-step>
            </section>
            <section *ngIf="(page === 2)">
                <app-event-register-third-step [formGroup]="thirdStepForm" [policy]="data" [prefixData]="prefixData">
                </app-event-register-third-step>
            </section>
        </div>
    </div>



    <div class="buttons">
        
        <div class="message" *ngIf="undefined !== messages">{{messages}}</div>

        <div class="buttons-inner">
            <app-spinner-button label="Paso anterior" *ngIf="(page !== 0)" [enabled]="((page !== 0) && (!running))"
                [running]="false" (click)="onPreviousPage()">
            </app-spinner-button>
            <app-spinner-button [label]="getButtonLabel()" *ngIf="(undefined !== data)" [enabled]="!disableNextPage()"
                [running]="running" (click)="onNextPage()">
            </app-spinner-button>
        </div>

    </div>

</div>

<app-blog-container></app-blog-container>

<app-footer></app-footer>