<div class="selector no-text-select" [@fadeInUp]>
    <p class=title>
        Selecciona la aseguradora que deseas consultar
    </p>
    <div class="company">
        <div class="company-ind" *ngFor="let entity of entities">
            <a (click)="onSelect(entity.key)">
                <div class="logo" [ngStyle]=getStyle(entity.key)></div>
                <div class="text">
                    <p>{{entity.value}}</p>
                </div>
            </a>
        </div>
        
    </div>
    <app-contact-line></app-contact-line>
</div>