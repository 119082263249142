/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-profile-progress-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header-profile-progress-bar.component";
var styles_HeaderProfileProgressBarComponent = [i0.styles];
var RenderType_HeaderProfileProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderProfileProgressBarComponent, data: {} });
export { RenderType_HeaderProfileProgressBarComponent as RenderType_HeaderProfileProgressBarComponent };
function View_HeaderProfileProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "progress-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Completa tu perfil"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "progress-inner "]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "progress-inner-bar "]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "bar "]], null, null, null, null, null)), i1.ɵdid(9, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { "width": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "progress-inner-percent "]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", "%"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, "/modify"); _ck(_v, 3, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, (_co.percent + "%")); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.percent; _ck(_v, 13, 0, currVal_4); }); }
export function View_HeaderProfileProgressBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderProfileProgressBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.percent < 100); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderProfileProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-profile-progress-bar", [], null, null, null, View_HeaderProfileProgressBarComponent_0, RenderType_HeaderProfileProgressBarComponent)), i1.ɵdid(1, 49152, null, 0, i4.HeaderProfileProgressBarComponent, [], null, null)], null, null); }
var HeaderProfileProgressBarComponentNgFactory = i1.ɵccf("app-header-profile-progress-bar", i4.HeaderProfileProgressBarComponent, View_HeaderProfileProgressBarComponent_Host_0, { percent: "percent" }, {}, []);
export { HeaderProfileProgressBarComponentNgFactory as HeaderProfileProgressBarComponentNgFactory };
