import { FormControl } from '@angular/forms';

export class CustomDateValidator {

    static correctDayAndMonth(control: FormControl): { [key: string]: any } {
        if (control.value == null || control.value == "") {
            return null;
        }
        if (control.value !== null && control.value.length < 10) {
            return { correctDayAndMonth: true };
        }


        const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
        const converted = new Date(control.value.replace(pattern, '$3-$2-$1'));
        return (converted.toDateString() === 'Invalid Date') ? { correctDayAndMonth: true } : null;
    }

    static correctDayAndMonthFilterVersion(control: FormControl): { [key: string]: any } {
        if (control.value == null) {
            return null;
        }
        if (control.value == "") {
            return null;
        }
        if (control.value !== null && control.value.length < 10) {
            return { correctDayAndMonth: true };
        }


        const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
        const converted = new Date(control.value.replace(pattern, '$3-$2-$1'));
        return (converted.toDateString() === 'Invalid Date') ? { correctDayAndMonth: true } : null;
    }

    static beforeEqualsCurrentDate(control: FormControl): { [key: string]: any } {
        if (control.value == null) {
            return null;
        }

        const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
        const converted = new Date(control.value.replace(pattern, '$3-$2-$1'));
        const now: Date = new Date();

        return (converted > now) ? { beforeCurrentDate: true } : null;
    }


    static afterEqualCurrentDate(control: FormControl): { [key: string]: any } {
        if (control.value == null) {
            return null;
        }

        const pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
        const converted = new Date(control.value.replace(pattern, '$3-$2-$1'));
        const now: Date = new Date();

        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);

        return (converted < now) ? { afterCurrentDate: true } : null;

    }

    static minAge(control: FormControl): { [key: string]: any } {
        if (control.value == null) {
            return null;
        }
        
        const convertAge = new Date(control.value);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);

        return (age < 18) ? { minAge: true } : null;

    }

    static maxAge(control: FormControl): { [key: string]: any } {
        if (control.value == null) {
            return null;
        }
        
        const convertAge = new Date(control.value);
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365);

        return (age > 119) ? { maxAge: true } : null;

    }
}
