var EventCheckBallsComponent = /** @class */ (function () {
    function EventCheckBallsComponent() {
        this.step_1 = undefined;
        this.step_2 = undefined;
        this.step_3 = undefined;
        this.step_4 = undefined;
        this.step_5 = undefined;
        this.initialData = undefined;
    }
    Object.defineProperty(EventCheckBallsComponent.prototype, "data", {
        set: function (value) {
            this.initialData = value;
            if (undefined !== value) {
                for (var _i = 0, _a = value.status; _i < _a.length; _i++) {
                    var s = _a[_i];
                    if (s.order === 1) {
                        this.step_1 = s;
                        this.step_1.status = 'Pendiente de Referencia';
                    }
                    else if (s.order === 2) {
                        this.step_2 = s;
                    }
                    else if (s.order === 3) {
                        this.step_3 = s;
                    }
                    else if (s.order === 4 && !this.step_4) {
                        this.step_4 = s;
                    }
                    else if (s.order === 5) {
                        this.step_5 = s;
                    }
                }
                if (null === this.step_1.date) {
                    this.step_1.description = this.step_1.status;
                    this.step_1.date = value.eventStartDate;
                }
                if ((this.step_5) && (null !== this.initialData.eventStatus) && ('EXPI' === this.initialData.eventStatus)) {
                    this.step_4 = this.step_5;
                }
                if (null === this.step_3.date && null !== this.step_2.date) {
                    this.step_3.date = this.step_2.date;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    EventCheckBallsComponent.prototype.getProgressStepOld = function () {
        var actualProgress = 1;
        if ((undefined !== this.step_4) && (null !== this.step_4.date)) {
            actualProgress = 4;
        }
        else if ((undefined !== this.step_3) && (null !== this.step_3.date)) {
            actualProgress = 4;
        }
        else if ((undefined !== this.step_2) && (null !== this.step_2.date)) {
            actualProgress = 3;
        }
        else if ((undefined !== this.step_1) && (null !== this.step_1.date) && (this.step_1.description === this.step_1.status)) {
            actualProgress = 1;
        }
        else if ((undefined !== this.step_1) && (null !== this.step_1.date)) {
            actualProgress = 2;
        }
        return 'progress-step-' + actualProgress;
    };
    EventCheckBallsComponent.prototype.getProgressStep = function () {
        if (this.initialData) {
            switch (this.initialData.eventStatus) {
                case 'EXPI':
                case 'CERR':
                    return 'progress-step-' + 4;
                case 'PDTE':
                    return 'progress-step-' + 3;
                case 'ABIE':
                    return 'progress-step-' + 3;
                case 'GRAB':
                default:
                    return 'progress-step-' + 1;
            }
        }
        return 'progress-step-' + 1;
    };
    EventCheckBallsComponent.prototype.getStatus1 = function () {
        if (this.step_1) {
            if (this.initialData.eventStatus === 'GRAB' || this.initialData.eventStatus === 'EXPI') {
                return 'start';
            }
            else if (this.initialData.eventStatus === 'PDTE'
                || this.initialData.eventStatus === 'ABIE'
                || this.initialData.eventStatus === 'CERR') {
                return 'finished';
            }
            else {
                return '';
            }
        }
        return '';
    };
    EventCheckBallsComponent.prototype.getStatus2 = function () {
        if (this.step_2) {
            if (this.initialData.eventStatus === 'ABIE') {
                return 'start';
            }
            else if (this.initialData.eventStatus === 'PDTE'
                || this.initialData.eventStatus === 'CERR') {
                return 'finished';
            }
            else {
                return '';
            }
        }
        return '';
    };
    EventCheckBallsComponent.prototype.getStatus3 = function () {
        if (this.step_2) {
            if (this.initialData.eventStatus === 'PDTE' || this.initialData.eventStatus === 'ABIE') {
                return 'start';
            }
            else if (this.initialData.eventStatus === 'CERR' || this.initialData.eventStatus === 'ABIE') {
                return 'finished';
            }
            else {
                return '';
            }
        }
        return '';
    };
    EventCheckBallsComponent.prototype.getStatus4 = function () {
        if (this.step_4) {
            if (this.initialData.eventStatus === 'CERR') {
                return 'finished';
            }
            else if (this.initialData.eventStatus === 'EXPI') {
                return 'start';
            }
            else if ((undefined !== this.step_3) && (null !== this.step_3.date)) {
                return 'start';
            }
        }
        return '';
    };
    return EventCheckBallsComponent;
}());
export { EventCheckBallsComponent };
