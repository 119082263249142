<app-header>
    <div class="inner-content-header container ">
        <div class="profile">
            <app-header-breadcrumb text="Mis contactos"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<div class="container" [@fadeInUp]>
    <div class="body no-text-select">
        <app-callme-title></app-callme-title>
        <form [formGroup]="contactForm" (ngSubmit)="onFormSubmit()">
            <div class="body-main">
                <div class="body-main-title">
                    <p>Déjanos tu teléfono y uno de nuestros asesores te contactará en breve.</p>
                </div>
                <div class="body-main-form">

                    <div class="nameContact">
                        <app-input-box type="text" label="Nombre" formControlName="name"
                            errorDescription="(*) Campo obligatorio" extraStyle="extraStyle-background-white">
                        </app-input-box>
                    </div>

                    <div class="row-25-50-25 telTime">
                        <div class="telPrefix">
                            <div class="prefix-container">
                                <angular2-multiselect [settings]="prefixSettings" [data]="prefixData"
                                    formControlName="mobilePhonePrefix">
                                    <c-badge>
                                        <ng-template let-item="item">
                                            <label class="phoneBadge">
                                                <span *ngIf="undefined !== item.id"
                                                    class="flags flag{{item.id}}"></span>
                                                <span *ngIf="undefined !== item.id">+{{item.id}}</span>
                                                <span *ngIf="undefined !== item.id">{{item.itemName}}</span>
                                            </label>
                                        </ng-template>
                                    </c-badge>
                                    <c-item>
                                        <ng-template let-item="item">
                                            <label class="phoneItem" style="min-width: 220px; max-width: 100px; font-family: 'AVENIR';">
                                                <span class="flags flag{{item.id}}"></span>
                                                <span>+{{item.id}}</span>
                                                <span>{{item.itemName}}</span>
                                            </label>
                                        </ng-template>
                                    </c-item>
                                </angular2-multiselect>
                            </div>
                        </div>
                        <div class="telContact">
                            <app-input-box type="text" label="Teléfono móvil" formControlName="mobilePhone"
                                mask="000000000000000000" isValidPhoneErrorDescription="Número no válido"
                                extraStyle="extraStyle-background-white">
                            </app-input-box>
                        </div>
                        <div class="timeContact">
                            <app-time-picker label="¿Qué hora te llamamos?" formControlName="hour"
                                [disabledHours]="disabledHours" errorDescription="(*) Campo obligatorio">
                            </app-time-picker>
                        </div>
                    </div>
                    <div class="text">
                        <label for="">Motivo</label>
                        <textarea cols="30" rows="10" maxlength="500" formControlName="message"></textarea>
                    </div>

                    <div class="check">
                        <app-check-box formControlName="accept">
                            <div class="inner-check">
                                <p>He leído y acepto la <a href="http://www.onlygal.es/aviso-legal" target="_blank">Política
                                        de protección</a> de Grupo Onlygal.</p>
                            </div>
                        </app-check-box>
                    </div>

                </div>
                <div class="button">
                    <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running">
                    </app-spinner-button>
                </div>
            </div>
        </form>
    </div>

    <app-contact-boxes></app-contact-boxes>

</div>

<app-footer></app-footer>