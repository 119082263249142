import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-documentation',
    templateUrl: './event-documentation.component.html',
    styleUrls: ['./event-documentation.component.scss'],
    providers: [DatePipe]
})
export class EventDocumentationComponent implements OnInit {

    FormData = require('formdata-polyfill')

    @Input() data: any;

    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    uploadFormData = new FormData();
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;
    running: any;
    orderDesc = false;
    mobile: Boolean;
    desktopSize: Boolean = false;
    mobileSize: Boolean = false;

    documents = [];

    constructor(private toastr: ToastrService, 
        private eventService: EventService, 
        private datepipe: DatePipe, 
        private mobileService: IsMobileService
        ) { }

    ngOnInit() {
        if (this.data && this.data.document) {
            this.getDocuments();
        }
        this.mobile = this.mobileService.isMobile;
        if(this.mobile){
            this.mobileSize = true;
        }
        if(!this.mobile){
            this.desktopSize = true;
        }

    }

    getDocuments() {
        this.documents = Object.assign([], this.data.document);
        this.orderDocuments(false);
    }

    orderDocuments(change) {
        if (change) { this.orderDesc = !this.orderDesc; }
        if (this.orderDesc) {
            this.documents.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
        } else {
            this.documents.sort((a, b) => b.creationDate.localeCompare(a.creationDate));
        }
    }

    onUpload() {
        this.running = true;

        const file: any = this.uploadFormData.get('file');

        if (file.size > 10485760 || !this.isValidFileType(file.type)) {
            this.running = false;
            this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO,
                CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
        } else {
            return this.httpEmitter = this.eventService.uploadFileData(this.data, this.uploadFormData)
                .subscribe(
                    event => {
                        this.httpEvent = event
                        if (event instanceof HttpResponse) {
                            delete this.httpEmitter;
                            this.files.length = 0;
                            this.running = false;

                            if ((undefined === this.data.document) || (null === this.data.document)) {
                                this.data.document = [];
                            }

                            this.data.document.push({
                                id: event.body.response.id,
                                name: event.body.response.name,
                                format: file.type.split("/")[1].toUpperCase(),
                                creationDate: this.datepipe.transform(this.getDate(), 'yyyy-MM-dd')
                            });

                            this.getDocuments();
                            this.toastr.success(CommonsText.DOCUMENTO_ADJUNTADO_EXITO, CommonsText.SERVICIO_DE_DOCUMENTOS);
                        }
                    },
                    error => {
                        this.running = false;
                        this.toastr.error(error.error.response, CommonsText.ERROR_SERVICIO_DOCUMENTOS);
                    }
                )
        }

    }

    isValidFileType(type: string): boolean {
        let valid = false;
        if (type && (type.startsWith('image/') || type === CommonsText.IMG_TYPE)) {
            valid = true;
        }

        return valid;
    }

    getDate() {
        return new Date()
    }

    onGetEventFile(document: any) {
        this.eventService.onGetEventFile(document);
    }

    checkFile() {

        const file: any = this.uploadFormData.get('file');

        if (file) {
            if (file.size > 10485760 || !this.isValidFileType(file.type) || !this.isValidFileName(file.name) || !this.isValidMaxName(file.name)) {                
                this.files.splice(0, 1);

                if (file.size > 10485760 || !this.isValidFileType(file.type)) {
                    this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO,
                        CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }

                if (!this.isValidFileName(file.name)) {
                    this.toastr.error(CommonsText.ERROR_FORMATO_NOMBRE_ARCHIVO, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }

                if (!this.isValidMaxName(file.name)) {
                    this.toastr.error(CommonsText.ERROR_TAMAYO_NOMBRE_ARCHIVO_120, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                }

                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }

    }

    isValidFileName(name: string): boolean {
        let valid = false;
        let regex = /([^-\w.\s]*)/gm;
        let newName = name.replace(regex, '')
        if (newName === name) {
            valid = true;
        }
        return valid;
    }

    isValidMaxName(name: string): boolean {
        let valid = true;
        if (name.length > 120) {
            valid = false;
        }

        return valid;
    }

    checkFiles(i) {
        if (i > 0 && this.files.length > 1) {
            this.files.splice(i, 1);
            return false;
        }
        return true;
    }

    getButtonLabel() {
        return this.running ? CommonsText.ADJUNTANDO_ARCHIVO : CommonsText.ENVIAR_ARCHIVO;
    }

}
