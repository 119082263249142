import { Component, OnInit } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'app/services/client-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-receipt-pay-page',
    templateUrl: './receipt-pay-page.component.html',
    styleUrls: ['./receipt-pay-page.component.scss']
})
export class ReceiptPayPageComponent implements OnInit {

    FormData = require('formdata-polyfill')

    runningPayWithAccount: Boolean = false;
    runningPayWithNewAccount: Boolean = false;

    receipt: any = undefined;
    receiptNumber: any;
    receiptYear: any;
    paymentResponse: any;
    accountList: any;
    payWithNewAccountMessages: any;

    selectedAccount: any = undefined;

    documentTypeSettings: any;
    documentTypeList: any[] = [
        { 'id': 'NIF', 'itemName': 'NIF' },
        { 'id': 'CIF', 'itemName': 'CIF' },
        { 'id': 'NIE', 'itemName': 'NIE' }
    ];

    payWithNewAccountForm: FormGroup;

    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    uploadFormData = new FormData();
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private receiptService: ReceiptService,
        private toastr: ToastrService,
        private clientService: ClientService) { }

    ngOnInit() {
        this.receiptYear = this.route.snapshot.params['receiptYear'];
        this.receiptNumber = this.route.snapshot.params['receiptNumber'];
        this.paymentResponse = this.route.snapshot.params['paymentResponse'];

        this.documentTypeSettings = {
            singleSelection: true,
            text: 'Documento',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.receiptService.getReceiptByNumber(this.receiptYear, this.receiptNumber)
            .then(success => {
                this.receipt = success.response;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });


        this.clientService.getPaymentAccounts()
            .then(value => {
                this.accountList = value.response;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPOS_CUENTAS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    getDate() {
        return new Date()
    }

    onChangeAccount(event) {
        this.selectedAccount = event.target.value;
        if (this.selectedAccount === 'NEW ACCOUNT') {
            this.preparePayWithNewAccountForm();
        }
    }

    preparePayWithNewAccountForm() {

        this.payWithNewAccountForm = this.formBuilder.group({
            iban: [undefined, [Validators.required, Validators.minLength(4)]],
            entity: [undefined, [Validators.required, Validators.minLength(4)]],
            office: [undefined, [Validators.required, Validators.minLength(4)]],
            digit: [undefined, [Validators.required, Validators.minLength(2)]],
            accountNumber: [undefined, [Validators.required, Validators.minLength(10)]],
            titular: [this.receipt && this.receipt.name ? this.receipt.name : name],
            documentType: [[{ id: this.receipt.documentType, itemName: this.receipt.documentType }]],
            documentNumber: [this.receipt && this.receipt.documentNumber ? this.receipt.documentNumber : undefined]
        });

        this.payWithNewAccountForm.valueChanges.subscribe(data => {
            if (((this.payWithNewAccountForm.touched || this.payWithNewAccountForm.dirty) && this.payWithNewAccountForm.invalid) ||
                (this.payWithNewAccountForm.valid && (this.files.length === 0))) {
                this.payWithNewAccountMessages = CommonsText.TODOS_CAMPOS_OBLIGATORIOS;
            } else {
                this.payWithNewAccountMessages = null;
            }
        });

    }

    enablePayWithNewAccountButton() {
        return (!this.runningPayWithNewAccount) && (this.payWithNewAccountForm.valid) && (this.files.length > 0);
    }

    getPayWithNewAccountButtonLabel() {
        return !this.runningPayWithNewAccount ? CommonsText.CONFIRMAR_Y_PAGAR : CommonsText.CARGANDO;
    }

    onPayWithNewAccount() {
        if (this.payWithNewAccountForm.valid) {
            this.runningPayWithNewAccount = true;
            return this.httpEmitter = this.receiptService.onPayWithNewAccount(this.receiptNumber, this.receiptYear,
                this.payWithNewAccountForm.value, this.uploadFormData)
                .subscribe(
                    event => {
                        this.httpEvent = event
                        if (event instanceof HttpResponse) {
                            delete this.httpEmitter;
                            this.files.length = 0;
                            this.accountList = event.body.response
                            this.runningPayWithNewAccount = false;
                            
                            this.paymentResponse = 'np';
                            this.router.navigate(['/receipt/np/' + this.receiptYear + '/' + this.receiptNumber]).then(() => {
                                this.reloadReceipt();
                            });
                            this.toastr.success(CommonsText.METODO_PAGO_CONFIGURADO_CORRECTAMENTE, CommonsText.SERVICIO_DE_RECIBOS, {
                                timeOut: 10000
                            });
                        }
                    },
                    error => {
                        this.runningPayWithNewAccount = false;
                        this.payWithNewAccountMessages = error.error.response;
                        this.toastr.error(error.error.response, CommonsText.ERROR_SERVICIO_RECIBOS);
                    }
                )
        }
    }

    enablePayWithAccountButton(): boolean {
        const selectedAccount = ((this.selectedAccount !== undefined) && (this.selectedAccount !== null) &&
            (this.selectedAccount !== '') && (this.selectedAccount !== 'NEW ACCOUNT'));
        return selectedAccount && !this.runningPayWithAccount;
    };

    getPayWithAccountButtonLabel() {
        return !this.runningPayWithAccount ? CommonsText.CONFIRMAR_Y_PAGAR : CommonsText.CARGANDO;
    }

    onPayWithAccountNumber() {
        if ((this.selectedAccount !== undefined) && (this.selectedAccount !== null) &&
            (this.selectedAccount !== '') && (this.selectedAccount !== 'NEW ACCOUNT')) {

            this.runningPayWithAccount = true;
            this.receiptService.onPayWithAccountNumber(this.selectedAccount, this.receiptYear, this.receiptNumber).then(success => {
                this.runningPayWithAccount = false;
                if (success.response) {
                    

                    this.paymentResponse = 'np';
                    this.router.navigate(['/receipt/np/' + this.receiptYear + '/' + this.receiptNumber])  .then(() => {
                        this.reloadReceipt();
                    });
                    this.toastr.success(CommonsText.METODO_PAGO_CONFIGURADO_CORRECTAMENTE, CommonsText.SERVICIO_DE_RECIBOS, {
                        timeOut: 10000
                    });

                }
            }).catch(error => {
                this.runningPayWithAccount = false;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
        }
    }

    getColorByOperation(): String {
        return this.paymentResponse === 'ok' ? 'blue' : 'red';
    }

    pay() {
        this.receiptService.payment(this.receipt);
    }

    captureIban(event: ClipboardEvent) {
        let pastedText = event.clipboardData.getData('text'); // ES39 9876 6543 12 0123456789
        if ((undefined !== pastedText) && (null !== pastedText)) {
            pastedText = pastedText.replace(/\s/g, '');
            if (pastedText.length === 24) {
                this.payWithNewAccountForm.patchValue({ iban: pastedText.substring(0, 4) });
                this.payWithNewAccountForm.patchValue({ entity: pastedText.substring(4, 8) });
                this.payWithNewAccountForm.patchValue({ office: pastedText.substring(8, 12) });
                this.payWithNewAccountForm.patchValue({ digit: pastedText.substring(12, 14) });
                this.payWithNewAccountForm.patchValue({ accountNumber: pastedText.substring(14, 24) });
            }
        }
    }

    reloadReceipt(){
        this.receiptService.getReceiptByNumber(this.receiptYear, this.receiptNumber)
        .then(success => {
            this.receipt = success.response;
        }).catch(error => {
            this.toastr.error(CommonsText.PROBLEMA_SERVICIO_RECIBOS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
    }

}
