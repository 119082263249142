import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-contact-callme-page',
    templateUrl: './contact-callme-page.component.html',
    styleUrls: ['./contact-callme-page.component.scss'],
    animations: [
        trigger('fadeInUp', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class ContactCallmePageComponent implements OnInit {

    running: Boolean = false;
    messages: String[] = null;
    contactForm: FormGroup;
    prefixData: any[];
    prefixSettings: any;

    public disabledHours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '14', '15', '20', '21', '22', '23'];

    constructor(private formBuilder: FormBuilder,
        private clientService: ClientService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.prefixData = CommonsUtils.phonePrefix();

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

        const prefix = [{ itemName: 'España', id: '34' }];
        
        this.contactForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            mobilePhonePrefix: [prefix, [Validators.required]],
            mobilePhone: [null, [CommonsUtils.validMobilePhone('mobilePhonePrefix')]],
            hour: ['12:00', [Validators.required]],
            message: ['', [Validators.required]],
            accept: [null, [Validators.required]]
        });

        this.contactForm.valueChanges.subscribe(data => {
            this.messages = null;
        });

    }

    submitEnabled() {
        return !this.running && this.contactForm.valid;
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO_MINUSCULA : CommonsText.ENVIAR_MINUSCULAS;
    }

    onFormSubmit() {
        this.messages = [];
        if (this.submitEnabled()) {
            if (this.contactForm.valid) {
                this.running = true;
                this.clientService.onContact(this.contactForm.value)
                    .then(success => {
                        this.running = false;
                        this.toastr.success(CommonsText.DATOS_ENVIADOS_EXITO, CommonsText.OPERACION_COMPLETADA, {
                            timeOut: 5000
                        });

                        this.contactForm.reset();
                        Object.keys(this.contactForm.controls).forEach(key => {
                            this.contactForm.get(key).setErrors(null) ;
                        });

                    }).catch(error => {
                        this.running = false;
                        this.messages = error.response;
                        if (undefined === this.messages) {
                            this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                        }
                        this.toastr.error(this.messages.toString(), CommonsText.ERROR_DE_OPERACION, {
                            timeOut: 5000
                        });
                    })
            }
        }
    }

}
