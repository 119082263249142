import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-event-messenger-message',
    templateUrl: './event-messenger-message.component.html',
    styleUrls: ['./event-messenger-message.component.scss']
})
export class EventMessengerMessageComponent implements OnInit {

    FormData = require('formdata-polyfill')

    @Input() message: any;
    @Input() data: any;

    currentDate = new Date();
    attachedDocument: any;

    constructor(private toastr: ToastrService, private eventService: EventService) { }

    ngOnInit() {
        if (this.message
            && this.message.idDocument && this.message.idDocument > 0
            && (
                (this.data.document && this.data.document.length > 0)
                || (this.data.correspondence && this.data.correspondence.length > 0)
            )
        ) {
            this.attachedDocument = this.data.document.find(d => d.id == this.message.idDocument);
            if (!this.attachedDocument) {
                this.attachedDocument = this.data.correspondence.find(d => d.id == this.message.idDocument);
            }
        }
    }

    onGetEventFile() {
        this.eventService.onGetEventFile(this.attachedDocument);
    }


}
