<div class="body-title">
    <div class="body-title-left">
        <p>Teléfonos de contacto por seguros</p>
    </div>
    <div class="body-title-right" [routerLink]="['/contact/callme']">
        <div class="body-title-right-inner">
            <p>¿Te llamamos?</p>
            <div class="img"></div>
        </div>
    </div>
</div>