/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../carousel/carousel.component.ngfactory";
import * as i3 from "../carousel/carousel.component";
import * as i4 from "@angular/animations";
import * as i5 from "../../../../commons/components/isMobile.service";
import * as i6 from "../carousel-items/carousel-card-wp-integration/carousel-card-wp-integration.component.ngfactory";
import * as i7 from "../carousel-items/carousel-card-wp-integration/carousel-card-wp-integration.component";
import * as i8 from "../../../../services/domain-service";
import * as i9 from "@angular/common";
import * as i10 from "./carousel-container.component";
import * as i11 from "../../../../services/receipt-service";
import * as i12 from "../../../../services/policy-service";
import * as i13 from "../../../../services/event-service";
import * as i14 from "ngx-toastr";
import * as i15 from "../../../../services/wordpress-service";
import * as i16 from "../../../../services/daslex-service";
import * as i17 from "../../../../security/auth.service";
import * as i18 from "../../../../services/client-service";
var styles_CarouselContainerComponent = [i0.styles];
var RenderType_CarouselContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselContainerComponent, data: {} });
export { RenderType_CarouselContainerComponent as RenderType_CarouselContainerComponent };
function View_CarouselContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [["titleText", "Mis recibos pendientes"], ["type", "RECEPT"], ["url", "/receipts"]], null, null, null, i2.View_CarouselComponent_0, i2.RenderType_CarouselComponent)), i1.ɵdid(1, 4243456, null, 0, i3.CarouselComponent, [i4.AnimationBuilder, i1.ElementRef, i5.IsMobileService], { titleText: [0, "titleText"], type: [1, "type"], url: [2, "url"], loading: [3, "loading"], items: [4, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Mis recibos pendientes"; var currVal_1 = "RECEPT"; var currVal_2 = "/receipts"; var currVal_3 = _co.loadingReceipts; var currVal_4 = _co.receipts; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CarouselContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [["titleText", "Partes abiertos"], ["type", "OPEN_EVENT"], ["url", "/events/history"]], null, null, null, i2.View_CarouselComponent_0, i2.RenderType_CarouselComponent)), i1.ɵdid(1, 4243456, null, 0, i3.CarouselComponent, [i4.AnimationBuilder, i1.ElementRef, i5.IsMobileService], { titleText: [0, "titleText"], type: [1, "type"], url: [2, "url"], loading: [3, "loading"], items: [4, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Partes abiertos"; var currVal_1 = "OPEN_EVENT"; var currVal_2 = "/events/history"; var currVal_3 = _co.loadingOpenEvents; var currVal_4 = _co.openEvents; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CarouselContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [["paramValue", "products"], ["titleText", "Mis seguros en vigor"], ["type", "PRODUCT"], ["url", "/products"]], null, null, null, i2.View_CarouselComponent_0, i2.RenderType_CarouselComponent)), i1.ɵdid(1, 4243456, null, 0, i3.CarouselComponent, [i4.AnimationBuilder, i1.ElementRef, i5.IsMobileService], { titleText: [0, "titleText"], type: [1, "type"], url: [2, "url"], paramValue: [3, "paramValue"], loading: [4, "loading"], items: [5, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Mis seguros en vigor"; var currVal_1 = "PRODUCT"; var currVal_2 = "/products"; var currVal_3 = "products"; var currVal_4 = _co.loadingProducts; var currVal_5 = _co.products; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CarouselContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel", [["paramValue", "services"], ["titleText", "Mis servicios contratados"], ["type", "SERVICE"], ["url", "/products"]], null, null, null, i2.View_CarouselComponent_0, i2.RenderType_CarouselComponent)), i1.ɵdid(1, 4243456, null, 0, i3.CarouselComponent, [i4.AnimationBuilder, i1.ElementRef, i5.IsMobileService], { titleText: [0, "titleText"], type: [1, "type"], url: [2, "url"], paramValue: [3, "paramValue"], loading: [4, "loading"], items: [5, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Mis servicios contratados"; var currVal_1 = "SERVICE"; var currVal_2 = "/products"; var currVal_3 = "services"; var currVal_4 = _co.loadingonlygalServices; var currVal_5 = _co.onlygalServices; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_CarouselContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-card-wp-integration", [], null, null, null, i6.View_CarouselCardWpIntegrationComponent_0, i6.RenderType_CarouselCardWpIntegrationComponent)), i1.ɵdid(1, 49152, null, 0, i7.CarouselCardWpIntegrationComponent, [i8.DomainService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.integration; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CarouselContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "carousel-container container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselContainerComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselContainerComponent_2)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselContainerComponent_3)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselContainerComponent_4)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselContainerComponent_5)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.showDirectAreas && (_co.receipts.length > 0)) && !_co.loadingReceipts); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.openEvents.length > 0) && !_co.loadingOpenEvents); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.showDirectAreas && (_co.products.length > 0)) && !_co.loadingProducts); _ck(_v, 6, 0, currVal_2); var currVal_3 = (((_co.showDirectAreas && _co.onlygalServices) && (_co.onlygalServices.length > 0)) && !_co.loadingonlygalServices); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.integrationVisible() && _co.showDirectAreas); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_CarouselContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carousel-container", [], null, null, null, View_CarouselContainerComponent_0, RenderType_CarouselContainerComponent)), i1.ɵdid(1, 4243456, null, 0, i10.CarouselContainerComponent, [i11.ReceiptService, i12.PolicyService, i13.EventService, i14.ToastrService, i15.WordpressService, i16.DasLexService, i17.AuthService, i18.ClientService], null, null)], null, null); }
var CarouselContainerComponentNgFactory = i1.ɵccf("app-carousel-container", i10.CarouselContainerComponent, View_CarouselContainerComponent_Host_0, {}, {}, []);
export { CarouselContainerComponentNgFactory as CarouselContainerComponentNgFactory };
