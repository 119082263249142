import { UserLastConnectionDTO } from "./UserLastConnectionDTO";

/**
 * Información del usuario
 * @author Iecisa
 */
export class UserDTO {
    personId: String;
    documentNumber: Number;
    documentType: String;
    language: String;
    lastConnection: String;
    lastName: String;
    name: String;
    email: String;
    entities: any[];
    productsIds: any[];
}

