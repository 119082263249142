import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'app/services/event-service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-page',
    templateUrl: './event-page.component.html',
    styleUrls: ['./event-page.component.scss']
})
export class EventsPageComponent implements OnInit {

    openEvents = [{}];
    loadingOpenEvents: Boolean = true;

    constructor(private eventService: EventService,
        private toastr: ToastrService) { }

    ngOnInit() {

        this.eventService.getOpenEvents()
            .then(success => {
                this.loadingOpenEvents = false;
                this.openEvents = success.response;
            }).catch(error => {
                this.loadingOpenEvents = false;
                this.openEvents = [];
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_PARTES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

}
