import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { AuthService } from 'app/security/auth.service';
import { Observable, Subject } from 'rxjs';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { DasLexParamDTO } from 'app/header-footer-template/model/DasLexParamDTO';

@Injectable({
    providedIn: 'root'
})

/**
 * Servicio de servicios de daslex
 * @author Iecisa
 */
export class DasLexService {

    private pageSizeChange;
    private pageOffsetChange;
    private pageFilterChange;

    constructor(
        private http: HttpClient, private authService: AuthService,
        private toastr: ToastrService, private domainService: DomainService) {
    }

    public setPageSize(value: any) {
        this.pageSizeChange.next(value);
    }

    public setPageOffset(value: any) {
        this.pageOffsetChange.next(value);
    }

    public setPageFilter(value: any) {
        this.pageFilterChange.next(value);
    }

    observerPageSizeChange(): Observable<any> {
        this.pageSizeChange = new Subject<any>();
        return this.pageSizeChange.asObservable();
    }

    observerPageOffset(): Observable<any> {
        this.pageOffsetChange = new Subject<any>();
        return this.pageOffsetChange.asObservable();
    }

    observerPageFilter(): Observable<any> {
        this.pageFilterChange = new Subject<any>();
        return this.pageFilterChange.asObservable();
    }

    getDasLexServices(): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/dasLex/apiListadoServicios/';
        return this.http.get(url).toPromise();
    }

    getDetailService(numEspediente: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/dasLex/apiDetalleServicio/' + numEspediente + '/';
        return this.http.get(url).toPromise();
    }

    getService(numEspediente: any): Promise<any> {
        const url = this.domainService.backendUrlWithContext + '/dasLex/apiServicio/' + numEspediente + '/';
        return this.http.get(url).toPromise();
    }

    search(params: DasLexParamDTO): Promise<any> {
        let httpParams = new HttpParams();

        if (undefined !== params.pageOffset) {
            httpParams = httpParams.append('pageOffset', params.pageOffset.toString());
        }

        if (undefined !== params.pageSize) {
            httpParams = httpParams.append('pageSize', params.pageSize.toString());
        }

        if (undefined !== params.statusType) {
            for (let i = 0; i < params.statusType.length; i++) {
                httpParams = httpParams.append('statusType', params.statusType[i].id);
            }
        }

        if (undefined !== params.service) {
            for (let i = 0; i < params.service.length; i++) {
                httpParams = httpParams.append('product', params.service[i].id);
            }
        }

        if (undefined !== params.contractItems) {
            for (let i = 0; i < params.contractItems.length; i++) {
                httpParams = httpParams.append('policyItems', params.contractItems[i].id);
            }
        }

        const url = this.domainService.backendUrlWithContext + '/dasLex/search/';
        return this.http.get(url, { params: httpParams }).toPromise();
    }

    view(){
        const url = this.domainService.backendUrlWithContext + '/dasLex/view/'
        const form = new FormData();

        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post<any>(url, form).toPromise();
    }

    serviceAccess(){
        const url = this.domainService.backendUrlWithContext + '/dasLex/access/'
        const form = new FormData();

        form.append('documentNumber', this.authService.getUser().documentNumber.toString());
        return this.http.post<any>(url, form).toPromise();
    }


}
