import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, ControlContainer } from '@angular/forms';
import { UserService } from '../../../services/user-service';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { AudityService } from 'app/services/audity-service';
import { AudityDTO } from 'app/header-footer-template/model/AudityDTO';
import { AuthService } from 'app/security/auth.service';
import { CommonsText } from '../../../commons/CommonsText';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {

    @Output() onRegisterSubmit = new EventEmitter();
    @Output() onTakerBlock = new EventEmitter();
    @Input() isReinsured: boolean;
    @Input() isInnominado: boolean;

    registerForm: FormGroup;
    messages: string[] = null;
    recaptchaResolved: Boolean = false;
    running: Boolean;
    started: Boolean = false;
    recoveryHtml: any = null;

    prefixData: any[];
    prefixSettings: any;
    audity: AudityDTO = new AudityDTO();
    ipAddress: any;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private audityService: AudityService,
        private controlContainer: ControlContainer) {

        this.userService.setCounterVisibility(false);

        this.prefixData = CommonsUtils.phonePrefix();

        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };

    }

    ngOnInit() {
        
        localStorage.clear();

        this.authService.getIPAddress().subscribe((res:any)=>{  
            this.ipAddress=res._body;  
          }); 

        this.userService.setCounterVisibility(false);

        this.started = true;

        this.registerForm = <FormGroup>this.controlContainer.control;
        this.registerForm.valueChanges.subscribe(data => { this.messages = null; this.recoveryHtml = null; });

    }

    onRegister() {
        this.started = true;
        this.messages = [];
        this.recoveryHtml = null;
        if (this.submitEnabled()) {
            this.running = true;
            this.userService.onRegister(this.registerForm.value, this.isReinsured)
                .then(success => {
                    this.running = false;
                    this.userService.setCounterVisibility(true);
                    this.userService.setLatestRegister(this.registerForm.value);
                    this.userService.setCode(success.response.code);
                    this.onRegisterSubmit.emit(success);
                }).catch(error => {
                    this.audity.audityDocument = this.registerForm.value.documentNumber;
                    this.audity.audityIp = this.ipAddress;
                    this.audityService.saveUserErrorRegister(this.audity);
                    this.running = false;
                    this.messages = error.error.response;
                    if (undefined === error.error.response) {
                        this.messages = [CommonsText.PROBLEMA_DE_CONECTIVIDAD];
                    }

                    if (this.messages[0].includes('{login=')) {

                        const v = this.messages[0];
                        const loginRegex = v.match('\{login=(.*?)\}');
                        const recoveryRegex = v.match('\{recovery=(.*?)\}');

                        this.messages = [];
                        this.recoveryHtml = v
                            .replace(loginRegex[0], '<a href="/">' + loginRegex[1] + '</a>')
                            .replace(recoveryRegex[0], '<a href="/forgot-password">' + recoveryRegex[1] + '</a>');

                    } else if (this.messages[0].includes('Taker blocked')) {
                        this.onTakerBlock.emit(true);
                    } else if (this.messages[0].includes('Se ha producido un problema al procesar la solicitud')) {
                        // modify bad_request message CLI-299
                        this.messages[0] = CommonsText.PROCESO_ALTA_NO_PUDO_COMPLETARSE;
                    }

                })
        }
    }

    onRecaptcha($event) {
        this.recaptchaResolved = true;
        if($event == null){
            this.recaptchaResolved = false;
        }
    }

    submitEnabled() {
        return (!this.started) || ((this.started) && this.registerForm.valid && this.recaptchaResolved && (!this.running));
    }

    getButtonLabel() {
        return (this.running) ? CommonsText.CARGANDO : (this.started) ? CommonsText.CONTINUAR : CommonsText.CONTINUAR;
    }

}
