import { Component, OnInit, Input, Self, Optional, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-input-box',
    templateUrl: './input-box.component.html',
    styleUrls: ['./input-box.component.scss']
})

export class InputBoxComponent implements OnInit, ControlValueAccessor {

    @Input() disabled: boolean;
    @Input() label: String;
    @Input() mask: String;
    @Input() placeholder: String = '';
    @Input() maxlength: Number = 255;
    @Input() tooltip: String = null;
    @Input() tooltipDirection: String = null;
    @Input() extraStyle: String;

    @Input() type: 'text' | 'email' | 'password' = 'text';
    @Input() description: String;
    @Input() errorDescription: String;
    @Input() isValidPhoneErrorDescription: String;
    @Input() focus: boolean;

    @Output() onPaste = new EventEmitter<ClipboardEvent>();

    @ViewChild('edit') edit: ElementRef;

    value: any = '';
    show: boolean = false;

    constructor(@Self() @Optional() public ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    capturePaste(event: ClipboardEvent) {
        this.onPaste.emit(event);
    }

    ngOnInit() {
        if(this.type === 'password'){
            this.show = true;
        };
        if(this.focus){
            this.edit.nativeElement.focus();
        }
    }

    writeValue(value: any): void {
        this.value = value;
        this.edit.nativeElement.value = value;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onChange(event) { }
    onTouched() { }

    password(event){
        if(this.type === 'password'){
            this.type = 'text';
            if (event != null) {
                event.target.className = 'noEyeLabel';
            }
        } else if( this.type === 'text'){
            this.type = 'password';
            if (event != null) {
                event.target.className = 'eyeLabel';
            }
        }
    }


}
