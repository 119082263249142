<div *ngIf="!showModal">
    <app-check-modal-club></app-check-modal-club>
</div>


<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb crumb="{{crumb}}" text="{{crumbText}}"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
  </app-header>
  
  <div class=" container " style="min-height: 400px;"> 
      <div class="modifyFormContainer" style="min-height: 600px;">
        <div *ngIf="loadingProfile" class="container no-text-select pre-loading">
            <p>Cargando<span>.</span><span>.</span><span>.</span></p>
        </div>
        <div *ngIf="!loadingProfile">
            <div class="container no-text-select">
                <div class="tabs menuHorizontal">
                    <div *ngFor="let menu of menus">
                        <span (click)="submit(menu.getCodMenu())" class="{{ menu.getEstilo() }}">{{ menu?.getLiteralMenu() }}</span>
                    </div>
                </div>
            </div>
            <div class="content">
                <section>
                    <app-inicio-club-propietario *ngIf="showInicio" (refrescarHomeDos)="refrescarHomeDos($event)" delay="5"></app-inicio-club-propietario>
                    <app-home-categoria *ngIf="!showInicio" [categoria]="categoriaSubmit"></app-home-categoria>
                </section>
            </div>
        </div>
      </div>
  </div>
  <app-footer></app-footer>
  