import { Component, Input } from '@angular/core';
import { IsMobileService } from 'app/commons/components/isMobile.service';

@Component({
    selector: 'app-event-expedient',
    templateUrl: './event-expedient.component.html',
    styleUrls: ['./event-expedient.component.scss']
})
export class EventExpedientComponent {

    @Input() data: any;
    mobile: Boolean;
    desktopSize: Boolean = false;
    mobileSize: Boolean = false;

    constructor(
        private mobileService: IsMobileService
    ) { 
    }

    ngOnInit(){
        this.mobile = this.mobileService.isMobile;
        if(this.mobile){
            this.mobileSize = true;
        }
        if(!this.mobile){
            this.desktopSize = true;
        }
    }

}
