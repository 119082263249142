export abstract class CommonsText {

    public static ADJUNTANDO_ARCHIVO = 'ADJUNTANDO ARCHIVO';
    public static APLICAR_FILTRO = 'APLICAR FILTRO';
    public static ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO = 'El archivo a adjuntar debe tener como máximo 10MB y los formatos permitidos son .pdf e imágenes';
    public static ARCHIVO_DESCARGADO_CORRECTAMENTE = 'Archivo descargado correctamente.';
    public static BLOCKED = 'Usuario bloqueado, te hemos enviado un correo de recuperación';
    public static BLOCKED_BY_RENEWAL = 'Su contraseña ha caducado. Introduzca una nueva mediante la opción de olvido de contraseña';
    public static BLOCKED_BY_RGPD = 'Acceso bloqueado por protección de datos. Cualquier consulta al respecto, contacta con nosotros';
    public static CAMBIAR_CONTRASEÑA = 'CAMBIAR CONTRASEÑA';
    public static CARGANDO = 'CARGANDO';
    public static CARGANDO_MINUSCULA = 'Cargando';
    public static COMUNICACIONES_CON_EL_TRAMITADOR = 'Comunicaciones con el Tramitador';
    public static CONFIRMAR = 'CONFIRMAR';
    public static CONFIRMAR_MINUSCULA = 'Confirmar';
    public static CONFIRMAR_Y_PAGAR = 'CONFIRMAR Y PAGAR';
    public static CONTINUAR = 'CONTINUAR';
    public static CONTRASENIA_NO_COINCIDEN = 'La nueva contraseña no puede coincidir con la anterior';
    public static CUENTA_AYADIDA_EXITO = 'Cuenta añadida con éxito.';
    public static CUENTA_CAMBIADA_EXITO= 'Cuenta cambiada con éxito.';
    public static CUENTA_REMOVIDA_EXITO = 'Cuenta removida con éxito.';
    public static DATOS_ACCESO_ACTUALIZADOS_EXITO = 'Datos de acceso actualizados con éxito.';
    public static DATOS_ENVIADOS_EXITO = 'Datos enviados con éxito.';
    public static DISABLED = 'Usuario deshabilitado';
    public static DOCUMENTO_ADJUNTADO_EXITO = 'Documento adjuntado con éxito';
    public static ENTRAR = 'ENTRAR';
    public static ENVIAR_ARCHIVO = 'ENVIAR ARCHIVO';
    public static ENVIAR_MINUSCULAS = 'Enviar';
    public static ERROR_ADJUNTANDO_ARCHIVO = 'Error adjuntando archivo';
    public static ERROR_CAMBIO_PASS = 'Se ha producido un error en el cambio de contraseña. Contacte con el administrador del sistema.';
    public static ERROR_DE_COMUNICACION_SERVICIO_PAGOS = 'Error de comunicación con el servicio de pagos.';
    public static ERROR_DE_FORMATO = 'Error de formato.';
    public static ERROR_DE_OPERACION = 'Error de operación';
    public static ERROR_FORMATO_NOMBRE_ARCHIVO = 'El nombre del archivo tiene un formato incorrecto';
    public static ERROR_OPERACION_CORREO_EN_USO = 'Error de operación: Este correo electrónico ya está en uso. Por favor, introduce otro';
    public static ERROR_RECUPERAR_CLIENTE = 'Error al recuperar el cliente';
    public static ERROR_SERVICIO_AUDITORIA = 'Error en el servicio de auditoría.';
    public static ERROR_SERVICIO_CLIENTES = 'Error en el servicio de clientes';
    public static ERROR_SERVICIO_CUENTAS = 'Error en el servicio de cuentas';
    public static ERROR_SERVICIO_DEYDE = 'Error en el servicio de Deyde';
    public static ERROR_SERVICIO_DOCUMENTOS = 'Error en el servicio de documentos';
    public static ERROR_SERVICIO_POLIZAS = 'Error en el servicio de pólizas';
    public static ERROR_SERVICIO_RECIBOS = 'Error en el servicio de recibos';
    public static ERROR_TAMAYO_NOMBRE_ARCHIVO_120 = 'El tamaño máximo permitido para el nombre del fichero es de 120 caracteres';
    public static ERROR_TAMAYO_NOMBRE_ARCHIVO_200 = 'El tamaño máximo permitido para el nombre del fichero es de 200 caracteres';
    public static FALLO_AL_DESCARGAR = 'Fallo al descargar.';
    public static FALLO_EN_WORDPRESS = 'Fallo en Wordpress.';
    public static GUARDAR_CAMBIOS_MINUSCULA = 'Guardar cambios';
    public static GUARDAR_CUENTA_MINUSCULA = 'Guardar cuenta';
    public static GUARDAR_PREFERENCIAS = 'GUARDAR PREFERENCIAS';
    public static IMG_TYPE = 'application/pdf';
    public static MENSAJE_ENVIADO_EXITO = 'Mensaje enviado con éxito';
    public static METODO_PAGO_CONFIGURADO_CORRECTAMENTE = 'Método de pago configurado correctamente.';
    public static OPERACION_COMPLETADA = 'Operación completada.';
    public static PARAMETROS_INCORRECTOS = 'Nombre de usuario o contraseña incorrectos';
    public static PERFIL_ACTUALIZADO_EXITO = 'Perfil actualizado con éxito.';
    public static PREFERENCIAS_ACTUALIZADAS_EXITO = 'Preferencias actualizadas con éxito.';
    public static PREPARANDO_ARCHIVO_DESCARGA = 'Preparando archivo para descargar.';
    public static PROBLEMA_DATOS_PARTE = 'Tenemos un problema con los datos del parte.';
    public static PROBLEMA_DE_CONECTIVIDAD = 'Tuvimos un problema de conectividad';
    public static PROBLEMA_ENVIAR_MENSAJE_EXPEDIENTE = 'Tenemos un problema enviando el mensaje al expediente.';
    public static PROBLEMA_OBTENER_MENSAJE_EXPENDIENTE = 'Tenemos un problema obteniendo mensajes de expediente.';
    public static PROBLEMA_SERVICIO_CLIENTE = 'Tenemos un problema con el servicio de clientes.';
    public static PROBLEMA_SERVICIO_DASLEX = 'Tenemos un problema con el servicio de DasLex.';
    public static PROBLEMA_SERVICIO_FANDIT = 'Tenemos un problema con el servicio de Fandit.';
    public static PROBLEMA_SERVICIO_INDEMNZACIONES= 'Tenemos un problema con el servicio de indemnizaciones.';
    public static PROBLEMA_SERVICIO_LEGALIBOO = 'Tenemos un problema con el servicio de Legaliboo.';
    public static PROBLEMA_SERVICIO_MEDIADORES = 'Tenemos un problema con el servicio de mediadores.';
    public static PROBLEMA_SERVICIO_NOTI_RECI = 'Tenemos un problema con el servicio de notificaciones de recibos.';
    public static PROBLEMA_SERVICIO_NOTIFICACIONES = 'Tenemos un problema con el servicio de notificaciones.';
    public static PROBLEMA_SERVICIO_PAGOS = 'Tenemos un problema con el servicio de pagos.';
    public static PROBLEMA_SERVICIO_PARTES = 'Tenemos un problema con el servicio de partes.';
    public static PROBLEMA_SERVICIO_POLIZAS = 'Tenemos un problema con el servicio de pólizas.';
    public static PROBLEMA_SERVICIO_RECIBOS = 'Tenemos un problema con el servicio de recibos.';
    public static PROBLEMA_SERVICIO_TIPO_D_VIA = 'Tenemos un problema con el servicio de tipos de via.';
    public static PROBLEMA_SERVICIO_TIPOS_CUENTAS = 'Tenemos un problema con el servicio de tipos de cuentas.';
    public static PROCESO_ALTA_NO_PUDO_COMPLETARSE = 'El proceso de alta no ha podido completarse. Por favor, revisar los datos introducidos.';
    public static SERVICIO_DE_CUENTAS = 'Servicio de cuentas';
    public static SERVICIO_DE_DOCUMENTOS = 'Servicio de documentos';
    public static SERVICIO_DE_PARTES = 'Servicio de partes';
    public static SERVICIO_DE_RECIBOS = 'Servicio de recibos';
    public static SESION_USUARIO_CADUCADA = 'Sesión de usuario caducada';
    public static SOLICITAR_NUEVO_CODIGO_CONFIRMACION = 'SOLICITAR NUEVO CÓDIGO DE CONFIRMACIÓN';
    public static SOLICITUD_ENVIADA_EXITO = 'Solicitud enviada con éxito.';
    public static TODOS_CAMPOS_OBLIGATORIOS = '(*) Todos los campos son obligatorios.';
    public static VUELVA_INICAR_SESION = 'Vuelva a iniciar sesión.';
    public static WITHOUT_POLICIES = 'Para gestionar sus pólizas o partes, dirigirse directamente a su aseguradora con la que contrató el seguro';

}
