/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wp-empty-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../commons/safe-html-pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./wp-empty-page.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/wordpress-service";
import * as i7 from "@angular/common";
import * as i8 from "ngx-toastr";
import * as i9 from "../../../services/domain-service";
var styles_WpEmptyPageComponent = [i0.styles];
var RenderType_WpEmptyPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WpEmptyPageComponent, data: {} });
export { RenderType_WpEmptyPageComponent as RenderType_WpEmptyPageComponent };
export function View_WpEmptyPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 0, "link", [["rel", "stylesheet"]], [[1, "href", 5]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "header-ext"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "inner-cont"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getWordpressStylesheet(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.page)); _ck(_v, 5, 0, currVal_2); }); }
export function View_WpEmptyPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-wp-empty-page", [], null, null, null, View_WpEmptyPageComponent_0, RenderType_WpEmptyPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.WpEmptyPageComponent, [i5.ActivatedRoute, i5.Router, i6.WordpressService, i7.Location, i3.DomSanitizer, i8.ToastrService, i9.DomainService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WpEmptyPageComponentNgFactory = i1.ɵccf("app-wp-empty-page", i4.WpEmptyPageComponent, View_WpEmptyPageComponent_Host_0, {}, {}, []);
export { WpEmptyPageComponentNgFactory as WpEmptyPageComponentNgFactory };
