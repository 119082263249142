<form [formGroup]="filterForm" (ngSubmit)="onFilter()" class="no-text-select">

    <div class="col col-padding">
        <angular2-multiselect [data]="productList" [settings]="productSettings" formControlName="product">
        </angular2-multiselect>
    </div>

    <div class="col col-padding">
        <angular2-multiselect [data]="statusTypeList" [settings]="statusTypeSettings" formControlName="statusType">
        </angular2-multiselect>
    </div>

    <div class="col col-padding">
        <angular2-multiselect [data]="policyList" [settings]="policySettings" formControlName="policyItems">
        </angular2-multiselect>
    </div>

    <!-- <div class="col">
        <app-input-box type="text" label="Nº de Póliza" formControlName="policyNumber" mask="9999999999" maxlength=10></app-input-box> 
    </div>-->

    <div class="col">
        <app-input-box type="text" label="Nº de Colectivo" formControlName="policyCollective" mask="9999999999" maxlength=10></app-input-box>
    </div>
    
    <div class="col">
        <app-date-picker label="Desde" (ngModelChange)="comprobarDesde($event)" formControlName="startDate"  correctDayAndMonthErrorDescription="El día, mes o año es erróneo"></app-date-picker>

    </div>

    <div class="col">
        <app-date-picker label="Hasta" (ngModelChange)="comprobarHasta($event)" formControlName="endDate" correctDayAndMonthErrorDescription="El día, mes o año es erróneo"></app-date-picker>
        
    </div>
    
    <div class="col col-padding col-padding-button">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="!loading" [running]="loading"></app-spinner-button>
        
    </div>

</form>