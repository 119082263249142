import {Component, OnInit} from '@angular/core';
import { AuthService } from 'app/security/auth.service';
import {WpBlogService} from "../../../../services/wp-blog-service";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-blog-container',
    templateUrl: './blog-container.component.html',
    styleUrls: ['./blog-container.component.scss']
})
export class BlogContainerComponent implements OnInit {

    blog: any;
    showDirectAreas: boolean;

    constructor(
        private blogService: WpBlogService,
        private authService: AuthService,
        private toastr: ToastrService) {
    }

    ngOnInit() {
        if ((this.authService.isLogged())) {
            this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        }

        this.blogService.load()
            .then(success => {
                this.blog = success;
            }).catch(error => {
        })
    }

}
