<div class=" container " style="min-height: 400px;">

  <div *ngIf="loadingProfile" class="container no-text-select pre-loading">
      <p>Cargando<span>.</span><span>.</span><span>.</span></p>
  </div>

  <div *ngIf="!loadingProfile && showCategoria" class="container-contact">
      <app-recomendacion [lstNoticiasDestacadas]="lstNoticiasDestacadas" delay="5"></app-recomendacion>
       <div *ngFor="let item of categoriasInicio">
        <app-categoria [categoria]="item" delay="5" (refrescarHome)="refrescarHome($event)"></app-categoria>
      </div> 
     <br>
  </div>
</div>