<div class="input-group">
    <div class="input-edit">

        <label>
            <div class="side-left"> <input type="checkbox" (click)="onClick($event)" [checked]="value === true" [value]="value" [disabled]="disabled" (blur)="onTouched()">
            </div>

            <div class="side-right"
                [ngClass]="!(ngControl.invalid && (ngControl.touched || ngControl.dirty)) ? 'text-black' : 'text-red'">
                <ng-content></ng-content>
            </div>
        </label>

    </div>

</div>