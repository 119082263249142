import { Component, Input } from '@angular/core';
import { ReceiptService } from 'app/services/receipt-service';

@Component({
    selector: 'app-carousel-receipt',
    templateUrl: './carousel-receipt.component.html',
    styleUrls: ['./carousel-receipt.component.scss']
})
export class CarouselReceiptComponent {

    @Input() data;
    @Input() loading;

    constructor(private receiptService: ReceiptService) { }

    pay() {
        this.receiptService.payment(this.data);
    }
    
    change() {
        this.loading = !this.loading;
    }

}
