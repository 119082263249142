<form [formGroup]="filterForm" (ngSubmit)="onFilter()" class="no-text-select">
    <div class="col col-padding">
        <angular2-multiselect [data]="serviceList" [settings]="serviceSettings" formControlName="service">
        </angular2-multiselect>
    </div>

    <div class="col col-padding">
        <angular2-multiselect [data]="statusTypeList" [settings]="statusTypeSettings" formControlName="statusType">
        </angular2-multiselect>
    </div>

    <div class="col col-padding">
        <angular2-multiselect [data]="contractList" [settings]="contractSettings" formControlName="contractItems">
        </angular2-multiselect>
    </div>
    
    <div class="col col-padding col-padding-button">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="!loading" [running]="loading"></app-spinner-button>
    </div>

</form>