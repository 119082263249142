<app-header>
    <div class="inner-content-header container">
        <div class="profile">
            <app-header-breadcrumb text="{{title}}"></app-header-breadcrumb>
        </div>
        <div class="last-conect">
            <app-header-last-conection></app-header-last-conection>
        </div>
    </div>
</app-header>

<link rel="stylesheet" [attr.href]="getWordpressStylesheet()">
<div class="wordpress-container container">
    <div class="text-wp"  [innerHtml]="page | safeHtml" ></div>
</div>

<app-footer></app-footer>