<div class="data no-text-select" [ngClass]="{'borderTop' : !first}">
    <div class="data-form">
        <div class="cont1">
            <div class="name">
                <span>nombre completo</span>
                <p>{{insured.name}}</p>
            </div>
            <div class="doc">
                <span>nº de documento</span>
                <p>{{insured.documentNumber}}</p>
            </div>
        </div>
        <div class="cont2">
            <div class="birth">
                <span>fecha de nacimiento</span>
                <p>{{insured.bornDate | date: 'dd/MM/yyyy' }}</p>
            </div>
            <!-- <div class="civil">
                <span>estado civil</span>
                <p>{{insured.maritalStatus}}</p>
            </div>
            <div class="profesion">
                <span>profesión</span>
                <p>{{insured.profession}}</p>
            </div> -->
        </div>
        <div class="cont3">
            <div class="email">
                <span>e-mail</span>
                <p>{{insured.email}}</p>
            </div>
            <div class="tel">
                <span>teléfono móvil</span>
                <p>{{insured.mobilePhone}}</p>
            </div>
        </div>
        <div class="cont4">
            <div class="dir">
                <span>dirección completa</span>
                <p>{{insured.address}}</p>
            </div>
            <div class="state">
                <span>provincia</span>
                <p>{{insured.province}}</p>
            </div>
        </div>
        <div class="cont5">
            <div class="dir">
                <span>dirección fiscal</span>
                <p>{{insured.fiscalAddress}}</p>
            </div>
            <div class="state">
                <span>provincia</span>
                <p>{{insured.fiscalProvince}}</p>
            </div>
        </div>
    </div>
</div>