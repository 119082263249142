import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from '../../../../../commons/CommonsText';

@Component({
    selector: 'app-event-correspondence',
    templateUrl: './event-correspondence.component.html',
    styleUrls: ['./event-correspondence.component.scss']
})
export class EventCorrespondenceComponent implements OnInit {

    FormData = require('formdata-polyfill')

    @Input() data: any;

    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    uploadFormData = new FormData();
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;
    running: any;
    orderDesc = false;
    correspondence = [];

    constructor(private toastr: ToastrService, 
        private eventService: EventService) { }

    ngOnInit() {
        if (this.data && this.data.correspondence) {
            this.getCorrespondence();
        }
    }

    getCorrespondence() {
        this.correspondence = Object.assign([], this.data.correspondence);
        this.orderCorrespondence(false);
    }

    orderCorrespondence(change) {
        if (change) { this.orderDesc = !this.orderDesc; }
        if (this.orderDesc) {
            this.correspondence.sort((a, b) => a.creationDate.localeCompare(b.creationDate));
        } else {
            this.correspondence.sort((a, b) => b.creationDate.localeCompare(a.creationDate));
        }
    }

    onUpload() {

        this.running = true;

        const file: any = this.uploadFormData.get('file');

        if (file.size > 10485760 || !this.isValidFileType(file.type)) {
            this.running = false;
            this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO, CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
        } else {
            return this.httpEmitter = this.eventService.uploadFileData(this.data, this.uploadFormData)
                .subscribe(
                    event => {
                        this.httpEvent = event
                        if (event instanceof HttpResponse) {
                            delete this.httpEmitter;
                            this.files.length = 0;
                            this.running = false;

                            if ((undefined === this.data.correspondence) || (null === this.data.correspondence)) {
                                this.data.correspondence = [];
                            }

                            this.data.correspondence.push({
                                id: event.body.response.id,
                                name: event.body.response.name,
                                creationDate: event.body.response.creationDate
                            });

                            this.getCorrespondence();
                            this.toastr.success(CommonsText.DOCUMENTO_ADJUNTADO_EXITO, CommonsText.SERVICIO_DE_DOCUMENTOS);
                        }
                    },
                    error => {
                        this.running = false;
                        this.toastr.error(error.error.response, CommonsText.ERROR_SERVICIO_DOCUMENTOS);
                    }
                )
        }

    }

    isValidFileType(type: string): boolean {
        let valid = false;
        if (type && (type.startsWith('image/') || type === CommonsText.IMG_TYPE)) {
            valid = true;
        }

        return valid;
    }

    getDate() {
        return new Date()
    }

    onGetEventFile(document: any) {
        this.eventService.onGetEventFile(document);
    }

    getButtonLabel() {
        return this.running ? CommonsText.ADJUNTANDO_ARCHIVO : CommonsText.ENVIAR_ARCHIVO;
    }

}
