/**
 *
 * @author Iecisa
 */
export class DasLexParamDTO {

    pageOffset: Number;
    pageSize: Number;

    statusType: any[];
    service: any[];
    contractItems: any[];

}
