<div class="correspondence" *ngIf="correspondence && correspondence.length > 0">
    <div class="correspondence-title">
        <p>Comunicaciones</p>
    </div>
    <div class="correspondence-main">
        <div class="correspondence-main-sup">
            <div class="left">
                <span></span>
            </div>
            <div class="center" (click)="orderCorrespondence(true)">
                <span>Fecha inserción &nbsp;</span><div [ngClass]="orderDesc ? 'order-correspondence-up': 'order-correspondence-down'"></div>
            </div>
            <div class="right">
                <span>Comunicación:</span>
            </div>
            <div class="right-format">
                <span>Formato:</span>
            </div>
        </div>
        <div class="correspondence-main-inf " *ngFor="let cor of correspondence">
            <div class="left">
                <div class="img-inner"></div>
            </div>
            <div class="center">
                <a (click)="onGetEventFile(cor)">{{cor.creationDate}}</a>
            </div>
            <div class="right">               
                <a (click)="onGetEventFile(cor)">{{cor.name}}</a>
            </div>
            <div class="right-format">               
                <a (click)="onGetEventFile(cor)">{{cor.format}}</a>
            </div>
        </div>
    </div>
</div>