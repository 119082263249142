import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { WordpressService } from 'app/services/wordpress-service';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from '../../../services/domain-service';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-wordpress-page',
    templateUrl: './wordpress-page.component.html',
    styleUrls: ['./wordpress-page.component.scss']
})
export class WordpressPageComponent implements OnInit {

    page: any = null;
    title: any = null;
    resource: SafeResourceUrl;

    constructor(private route: ActivatedRoute, private router: Router,
        private wordpressService: WordpressService,
        private location: Location, private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private domainService: DomainService) { }

    ngOnInit() {

        this.wordpressService.loadPage().then(any => {
            const page = this.wordpressService.getPage(this.route.snapshot.data.page);
            const pos = this.router.url.indexOf('#');
            let url = (pos > 0) ? this.router.url.substring(0, pos) : this.router.url;
            url = this.location.prepareExternalUrl(url);
            const pageContent: string = page.content.rendered;
            this.page = pageContent.split('href="#').join('href="' + url + '#');
            this.title = page.title.rendered;

        }).catch(error => {
            this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        })

    }

    getWordpressStylesheet(): SafeResourceUrl {
        if (undefined === this.resource) {
            this.resource = this.sanitizer
                    .bypassSecurityTrustResourceUrl(this.domainService.wordpressUrl + '/wp-content/themes/frontend/theme-wp.css');
        }
        return this.resource;
    }

}
