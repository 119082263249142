import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/security/auth.service';
import { PolicyService } from 'app/services/policy-service';
import { PolicyParamDTO } from 'app/header-footer-template/model/PolicyParamDTO';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';




@Component({
  selector: 'app-header-main-center-navigation',
  templateUrl: './header-main-center-navigation.component.html',
  styleUrls: ['./header-main-center-navigation.component.scss']
})
export class HeaderMainCenterNavigationComponent implements OnInit {
  showDirectAreas: Boolean = true;
  showLegaliboo: Boolean = false;
  showClubPropietario: boolean;
  isMobile: boolean = false;

  constructor(
    private authService: AuthService,
    private policyService: PolicyService,
    private isMobileService: IsMobileService,
    private toastr: ToastrService
  ) {
    this.isMobile = isMobileService.isMobile;
  }

  ngOnInit() {
    this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
    if (this.showDirectAreas) { this.isLegalibooAvailable(); }

    if(this.showDirectAreas){
      this.showClubPropietario = this.authService.getProductos();
    }
  }


  /* 
    HARDCODED METHOD TO CHECK IF LEGALIBOO LINK IS VISIBLE
    Only available por users with PP09 and PP11 policies
  */
  isLegalibooAvailable() {

    const params = new PolicyParamDTO();
    params.pageOffset = 0;
    params.pageSize = 9999;

    this.policyService.search(params)
      .then(success => {
        for (let prod of success.product) {
          if (prod && prod.id && (prod.id == 'PP09' || prod.id == 'PP11')) {
            this.showLegaliboo = true;
            break;
          }
        }
      }).catch(error => {
        this.toastr.error(CommonsText.ERROR_DE_OPERACION, CommonsText.ERROR_SERVICIO_POLIZAS, {
          timeOut: 5000
      }); 
      });

  }

}
