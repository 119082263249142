import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    HttpClient, HttpRequest,
    HttpResponse, HttpEvent
} from '@angular/common/http'
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-event-messenger-send',
    templateUrl: './event-messenger-send.component.html',
    styleUrls: ['./event-messenger-send.component.scss'],
    providers: [DatePipe]
})
export class EventMessengerSendComponent implements OnInit {

    FormData = require('formdata-polyfill')

    @Input() data: any;

    attaching = false;
    sending = false;
    availableDocuments = [];
    attachedDocuments = [];
    sendMessageForm: FormGroup;
    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;

    uploadFormData = new FormData();
    files: File[] = []
    lastFileAt: Date
    dragFiles: any;
    validComboDrag: any;
    fileDropDisabled: any;
    contadorInit = 0;

    constructor(
        private toastr: ToastrService,
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private datepipe: DatePipe
    ) { }


    ngOnInit() {
        this.sendMessageForm = this.formBuilder.group({
            message: [null, [Validators.required]],
            documentId: [null]
        });

        if (this.data && this.data.document) {
            this.availableDocuments = Object.assign([], this.data.document);
        }

    }

    contador($event){
        this.contadorInit = $event.target.value.length
    }

    getDate() {
        return new Date()
    }
    
    checkFile() {

        const file: any = this.uploadFormData.get('file');

        if (file) {
            if (file.size > 10485760 || !this.isValidFileType(file.type)) {
                this.files.splice(0, 1);
                this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO,
                    CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }

    }

    sendMessage() {

        if (this.sendMessageForm.valid) {
            this.sending = true;
            if (this.uploadFormData.get('file') != null) {
                this.onUpload();
            } else {
                this.send();
            }

        }
    }

    send() {
        const eventNumber = this.data.eventNumber.substring(5, this.data.eventNumber.length);
        const message = {
            message: this.sendMessageForm.controls['message'].value,
            documentId: this.sendMessageForm.controls['documentId'].value
        };



        this.eventService.sendExpedientMessage(eventNumber, message)
            .then(success => {
                this.sending = false;
                this.sendMessageForm.reset();
                this.contadorInit = 0;
                this.files = [];
                this.toastr.success(CommonsText.MENSAJE_ENVIADO_EXITO, CommonsText.COMUNICACIONES_CON_EL_TRAMITADOR);
                this.eventService.emitSentMessage();
            }).catch(error => {
                this.sending = false;
                this.toastr.error(CommonsText.PROBLEMA_ENVIAR_MENSAJE_EXPEDIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });
    }

    checkFiles(i) {
        if (i > 0 && this.files.length > 1) {
            this.files.splice(i, 1);
            return false;
        }
        return true;
    }

    onUpload() {
        const file: any = this.uploadFormData.get('file');

        if (file.size > 10485760 || !this.isValidFileType(file.type)) {
            this.sending = false;
            this.toastr.error(CommonsText.ARCHIVO_ADJUNTO_TAMAÑO_MAXIMO_FORMATO_PERMITIDO,
                CommonsText.ERROR_ADJUNTANDO_ARCHIVO);
        } else {
            return this.httpEmitter = this.eventService.uploadFileData(this.data, this.uploadFormData)
                .subscribe(
                    event => {
                        this.httpEvent = event
                        if (event instanceof HttpResponse) {
                            delete this.httpEmitter;
                            this.files.length = 0;

                            if ((undefined === this.data.document) || (null === this.data.document)) {
                                this.data.document = [];
                            }

                            this.data.document.push({
                                id: event.body.response.id,
                                name: event.body.response.name,
                                creationDate: this.datepipe.transform(this.getDate(), 'yyyy-MM-dd')
                            });

                            this.sendMessageForm.controls['documentId'].setValue(event.body.response.id);

                            this.toastr.success(CommonsText.DOCUMENTO_ADJUNTADO_EXITO, CommonsText.SERVICIO_DE_DOCUMENTOS);
                            this.send();
                        }
                    },
                    error => {
                        this.sending = false;
                        this.toastr.error(error.error.response, CommonsText.ERROR_SERVICIO_DOCUMENTOS);
                    }
                )
        }

    }

    isValidFileType(type: string): boolean {
        let valid = false;
        if (type && (type.startsWith('image/') || type === CommonsText.IMG_TYPE)) {
            valid = true;
        }

        return valid;
    }

}
