import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from '../../environments/environment'

import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { InputBoxComponent } from './components/input-box/input-box.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { ErrorMessagesComponent } from './components/error-messages/error-messages.component';
import { GoogleRecaptchaComponent } from './components/google-recaptcha/google-recaptcha.component';
import { ContactLineComponent } from './components/contact-line/contact-line.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { LabelTextComponent } from './components/label-text/label-text.component';
import { NgxMaskModule } from 'ngx-mask';
import { IconTypeComponent } from './components/icon-type/icon-type.component';
import { SafeHtmlPipe } from './safe-html-pipe';
import { IsMobileService } from './components/isMobile.service';
import { TimePickerComponent } from './components/timePicker.component/timePicker.component';
import { TimeComponent } from './components/time.component/time.component';
import { DateComponent } from './components/date.component/date.component';
import { DatePickerComponent } from './components/datePicker.component/datePicker.component';
import { CheckBrowserModalComponent } from './check-browser-modal/check-browser-modal.component';
import { InputDatepickerBoxComponent } from './components/input-datepicker-box/input-datepicker-box.component';
import { MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule } from '@angular/material';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddAccountToPolicyComponent } from './components/add-account-to-policy/add-account-to-policy.component';
import { PopUpLegaliboo } from './components/pop-up-legaliboo/pop-up-legaliboo.component'
import { ChangePolicyReceiptsComponent } from './components/change-policy-receipts/change-policy-receipts.component';


@NgModule({
    declarations: [SpinnerButtonComponent, InputBoxComponent, ErrorMessagesComponent,
        GoogleRecaptchaComponent, CheckBoxComponent, ContactLineComponent, TooltipComponent,
        LabelTextComponent, IconTypeComponent, SafeHtmlPipe,
        TimeComponent, DateComponent, DatePickerComponent, TimePickerComponent, CheckBrowserModalComponent,
        InputDatepickerBoxComponent, AddAccountToPolicyComponent,ChangePolicyReceiptsComponent, PopUpLegaliboo],
    imports: [
        CommonModule,
        FormsModule,

        RecaptchaModule.forRoot(),
        NgxMaskModule.forRoot(/*options*/),
        
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
    ],
    exports: [SpinnerButtonComponent, InputBoxComponent, ErrorMessagesComponent,
        GoogleRecaptchaComponent, CheckBoxComponent, ContactLineComponent, TooltipComponent,
        LabelTextComponent, IconTypeComponent, SafeHtmlPipe,
        TimeComponent, DateComponent, DatePickerComponent, TimePickerComponent, CheckBrowserModalComponent,
        InputDatepickerBoxComponent, AddAccountToPolicyComponent, ChangePolicyReceiptsComponent, PopUpLegaliboo],
    providers: [

        IsMobileService,

        { provide: 'environment', useValue: environment },

        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.reCaptchaKey,
            } as RecaptchaSettings,
        }
    ]
})

export class CommonsModule { }
