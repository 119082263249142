<div class="tenant">
    <div class="tenant-main" *ngFor="let o of occupier">
        <div class="tenant-main-sup">
            <div class="type row">
                <span>Tipo figura</span>
                <p>Inquilino</p>
            </div>
            <div class="numberDoc row">
                <span>Nº de documento</span>
                <p>{{o.documentNumber}}</p>
            </div>
            <div class="name row">
                <span>Nombre completo</span>
                <p>{{o.name}}</p>
            </div>
        </div>
    </div>

    <div class="guarantor-main" *ngFor="let o of guarantor">
        <div class="tenant-main-sup">
            <div class="type row">
                <span>Tipo figura</span>
                <p>Avalista</p>
            </div>
            <div class="numberDoc row">
                <span>Número de documento</span>
                <p>{{o.documentNumber}}</p>
            </div>
            <div class="name row">
                <span>Nombre completo</span>
                <p>{{o.name}}</p>
            </div>
        </div>
    </div>
</div>