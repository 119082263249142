import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonsText } from 'app/commons/CommonsText';
import { DasLexService } from 'app/services/daslex-service';

@Component({
    selector: 'app-product-service-filter',
    templateUrl: './product-service-filter.component.html',
    styleUrls: ['./product-service-filter.component.scss']
})
export class ProductServiceFilterComponent implements OnInit {

    filterForm: FormGroup;

    statusTypeList = [];
    serviceList = [];
    contractList = [];
    statusTypeSettings = {};
    serviceSettings = {};
    contractSettings = {};

    @Input() set response(value: any) {
        if (value) {
            this.statusTypeList = value.statusType;
            this.serviceList = value.product;
            this.contractList = value.policyItems;
        }
    }

    @Input() loading: Boolean = false;

    constructor(private dasLexService: DasLexService,
        private formBuilder: FormBuilder) { }

    ngOnInit(): void {

        this.statusTypeSettings = {
            singleSelection: false,
            text: 'Todos los estados',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.serviceSettings = {
            singleSelection: false,
            text: 'Todos los servicios',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.contractSettings = {
            singleSelection: false,
            text: 'Todos los contratos',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.filterForm = this.formBuilder.group({
            startDate: ['', []],
            endDate: ['', []],
            statusType: [[], []],
            service: [[], []],
            contractItems: [[], []]
        });

    }

    getButtonLabel() {
        return this.loading ? CommonsText.CARGANDO : CommonsText.APLICAR_FILTRO;
    }

    onFilter() {
        this.dasLexService.setPageFilter(this.filterForm.value);
    }

}
