import { ValidationErrors, AbstractControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';

export abstract class CommonsUtils {

    public static PASSWORD_REGEX = '^(?=.*\\d)(?=.*[\\u20ac\\u002d\\u002e\\u002c\\u00b4\\u0060\\u002b\\u00a1\\u0027\\u007d\\u007b\\u005d\\u005b\\u00ac\\u007e\\u20ac\\u0023\\u0040\\u007c\\u005c\\u003b\\u003a\\u00a8\\u005f\\u005e\\u002a\\u00bf\\u003f\\u003d\\u0029\\u0028\\u002f\\u0026\\u0025\\u0024\\u00b7\\u0022\\u0021])(?=.*[A-Z])(?=.*[a-z])\\S{8,16}$';
    public static EMAIL_REGEX = '^\\w+([\\.-]*\\w+)*@\\w+([\\.-]*\\w+)*(\\.\\w+)+$';
    public static SPAIN_PHONES = ['91', '92', '93', '94', '95', '96', '97', '98', '81', '82', '83', '84', '85', '86', '87', '88'];
    public static SPAIN_MOBILE_PHONES = ['6', '7'];

    public static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return !!control.parent &&
                !!control.parent.value &&
                control.value === control.parent.controls[matchTo].value
                ? null
                : { isMatching: false };
        };
    }


    public static validPhone(prefix: string): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {

            if (!!control.parent && !!control.parent.controls[prefix].value) {
                const pref = control.parent.controls[prefix].value[0].id;
                /* El nº de teléfono debe tener 9 dígitos.
                   Los teléfonos fijos deben empezar por:
                   "91", "92", "93", "94", "95", "96", "97", "98", "81", "82", "83", "84", "85", "86", "87", "88" */
                if ((null !== control.value) && (control.value.length > 0) && (pref === '34')) {
                    if ((control.value.length !== 9) ||
                        ((control.value.length === 9) &&
                            (!CommonsUtils.SPAIN_PHONES.some(v => v === control.value.substring(0, 2))))) {
                        return { isValidPhone: false };
                    }
                } else {
                    return null;
                }
            }
        };
    }

    public static validMobilePhone(prefix: string): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {

            if (!!control.parent && !!control.parent.controls[prefix].value) {
                const pref = control.parent.controls[prefix].value[0].id;
                /* El nº de teléfono debe tener 9 dígitos.
                   Los teléfonos móviles deben empezar por 6 o 7. */
                if ((null !== control.value) && (control.value.length > 0) && (pref === '34')) {
                    if ((control.value.length !== 9) ||
                        ((control.value.length === 9) &&
                            (!CommonsUtils.SPAIN_MOBILE_PHONES.some(v => v === control.value.substring(0, 1))))) {
                        return { isValidPhone: false };
                    }
                } else {
                    return null;
                }
            }
        };
    }

    public static headers(): Headers {
        const h = new Headers();
        h.append('Authorization', 'basic ' + environment.authorization);
        h.append('Accept', 'application/json;charset=UTF-8');
        return h;
    }

    public static headersBearer(): HttpHeaders {
        let h = new HttpHeaders();
        h = h.set('Authorization', 'basic ' + environment.authorization);
        h = h.set('Accept', 'application/json;charset=UTF-8');
        return h;
    }

    public static phonePrefix(): any[] {

        return [
            { itemName: 'Afganistán', id: '93' },
            { itemName: 'Albania', id: '355' },
            { itemName: 'Andorra', id: '376' },
            { itemName: 'Angola', id: '244' },
            { itemName: 'Argelia', id: '213' },
            { itemName: 'Argentina', id: '54' },
            { itemName: 'Armenia', id: '374' },
            { itemName: 'Aruba', id: '297' },
            { itemName: 'Ascension', id: '247' },
            { itemName: 'Australia', id: '61' },
            { itemName: 'Austria', id: '43' },
            { itemName: 'Azerbaijan', id: '994' },
            { itemName: 'Bahrain', id: '973' },
            { itemName: 'Bangladesh', id: '880' },
            { itemName: 'Bélgica', id: '32' },
            { itemName: 'Belice', id: '501' },
            { itemName: 'Benin', id: '229' },
            { itemName: 'Bielorrusia', id: '375' },
            { itemName: 'Bolivia', id: '591' },
            { itemName: 'Bosnia Herzegovina', id: '387' },
            { itemName: 'Botswana', id: '267' },
            { itemName: 'Brasil', id: '55' },
            { itemName: 'Brunei', id: '673' },
            { itemName: 'Bulgaria', id: '359' },
            { itemName: 'Burkina Faso', id: '226' },
            { itemName: 'Burundi', id: '257' },
            { itemName: 'Bután', id: '975' },
            { itemName: 'Camboya', id: '855' },
            { itemName: 'Camerún', id: '237' },
            { itemName: 'Cabo Verde', id: '238' },
            { itemName: 'Chad', id: '235' },
            { itemName: 'Chile', id: '56' },
            { itemName: 'China', id: '86' },
            { itemName: 'Chipre', id: '357' },
            { itemName: 'Colombia', id: '57' },
            { itemName: 'Comores', id: '269' },
            { itemName: 'Congo', id: '242' },
            { itemName: 'Costa Rica', id: '506' },
            { itemName: 'Croacia', id: '385' },
            { itemName: 'Cuba', id: '53' },
            { itemName: 'España', id: '34' },
            { itemName: 'Estados Unidos', id: '1' },
            { itemName: 'Francia', id: '33' },
            { itemName: 'Gran Bretaña', id: '44' },
            { itemName: 'Islas Cook', id: '682' },
            { itemName: 'Italia', id: '39' },
            { itemName: 'República Centroafricana', id: '236' },
            { itemName: 'República Checa', id: '420' },
            { itemName: 'República Democrática del Congo', id: '243' },
            { itemName: 'Rusia', id: '7' },
            { itemName: 'Dinamarca', id: '45' },
            { itemName: 'Diego Garcia', id: '246' },
            { itemName: 'Djibouti', id: '253' },
            { itemName: 'Timor Oriental', id: '670' },
            { itemName: 'Ecuador', id: '593' },
            { itemName: 'Egipto', id: '20' },
            { itemName: 'El Salvador', id: '503' },
            { itemName: 'Guinea Ecuatorial', id: '240' },
            { itemName: 'Eritrea', id: '291' },
            { itemName: 'Estonia', id: '372' },
            { itemName: 'Etiopía', id: '251' },
            { itemName: 'Islas Malvinas', id: '500' },
            { itemName: 'Islas Faroe', id: '298' },
            { itemName: 'Fiji', id: '679' },
            { itemName: 'Finlandia', id: '358' },
            { itemName: 'Guayana Francesa', id: '594' },
            { itemName: 'Polinesia Francesa', id: '689' },
            { itemName: 'Gabón', id: '241' },
            { itemName: 'Gambia', id: '220' },
            { itemName: 'Georgia', id: '995' },
            { itemName: 'Alemania', id: '49' },
            { itemName: 'Ghana', id: '233' },
            { itemName: 'Gibraltar', id: '350' },
            { itemName: 'Grecia', id: '30' },
            { itemName: 'Groelandia', id: '299' },
            { itemName: 'Guadeloupe', id: '590' },
            { itemName: 'Guatemala', id: '502' },
            { itemName: 'Guinea', id: '224' },
            { itemName: 'Guinea-Bissau', id: '245' },
            { itemName: 'Guyana', id: '592' },
            { itemName: 'Haiti', id: '509' },
            { itemName: 'Honduras', id: '504' },
            { itemName: 'Hong Kong', id: '852' },
            { itemName: 'Hungría', id: '36' },
            { itemName: 'Islandia', id: '354' },
            { itemName: 'India', id: '91' },
            { itemName: 'Indonesia', id: '62' },
            { itemName: 'Satelite Inmarsat', id: '870' },
            { itemName: 'Iraq', id: '964' },
            { itemName: 'Irlanda', id: '353' },
            { itemName: 'Israel', id: '972' },
            { itemName: 'Costa de Marfil', id: '225' },
            { itemName: 'Japón', id: '81' },
            { itemName: 'Jordania', id: '962' },
            { itemName: 'Kenya', id: '254' },
            { itemName: 'Kiribati', id: '686' },
            { itemName: 'Kuwait', id: '965' },
            { itemName: 'Kyrgyzstan', id: '996' },
            { itemName: 'Laos', id: '856' },
            { itemName: 'Letonia', id: '371' },
            { itemName: 'Líbano', id: '961' },
            { itemName: 'Lesotho', id: '266' },
            { itemName: 'Liberia', id: '231' },
            { itemName: 'Libia', id: '218' },
            { itemName: 'Liechtenstein', id: '423' },
            { itemName: 'Lituania', id: '370' },
            { itemName: 'Luxemburgo', id: '352' },
            { itemName: 'Macao', id: '853' },
            { itemName: 'Macedonia', id: '389' },
            { itemName: 'Madagascar', id: '261' },
            { itemName: 'Malawi', id: '265' },
            { itemName: 'Malasia', id: '60' },
            { itemName: 'Maldivas', id: '960' },
            { itemName: 'Mali', id: '223' },
            { itemName: 'Malta', id: '356' },
            { itemName: 'Islas Marshall', id: '692' },
            { itemName: 'Martinica', id: '596' },
            { itemName: 'Mauritania', id: '222' },
            { itemName: 'Mauricio', id: '230' },
            { itemName: 'Mexico', id: '52' },
            { itemName: 'Micronesia', id: '691' },
            { itemName: 'Moldavia', id: '373' },
            { itemName: 'Mónaco', id: '377' },
            { itemName: 'Mongolia', id: '976' },
            { itemName: 'Montenegro', id: '382' },
            { itemName: 'Marruecos', id: '212' },
            { itemName: 'Mozambique', id: '258' },
            { itemName: 'Myanmar', id: '95' },
            { itemName: 'Namibia', id: '264' },
            { itemName: 'Naurú', id: '674' },
            { itemName: 'Nepal', id: '977' },
            { itemName: 'Holanda', id: '31' },
            { itemName: 'Antillas Holandesas', id: '599' },
            { itemName: 'Nueva Caledonia', id: '687' },
            { itemName: 'Nueva Zelanda', id: '64' },
            { itemName: 'Nicaragua', id: '505' },
            { itemName: 'Níger', id: '227' },
            { itemName: 'Nigeria', id: '234' },
            { itemName: 'Niué', id: '683' },
            { itemName: 'Corea del Norte', id: '850' },
            { itemName: 'Noruega', id: '47' },
            { itemName: 'Omán', id: '968' },
            { itemName: 'Pakistán', id: '92' },
            { itemName: 'Palau', id: '680' },
            { itemName: 'Panamá', id: '507' },
            { itemName: 'Papua Nueva Guinea', id: '675' },
            { itemName: 'Paraguay', id: '595' },
            { itemName: 'Perú', id: '51' },
            { itemName: 'Fiilipinas', id: '63' },
            { itemName: 'Polonia', id: '48' },
            { itemName: 'Portugal', id: '351' },
            { itemName: 'Qatar', id: '974' },
            { itemName: 'Reunión', id: '262' },
            { itemName: 'Rumanía', id: '40' },
            { itemName: 'Ruanda', id: '250' },
            { itemName: 'Santa Helena', id: '290' },
            { itemName: 'Saint Pierre and Miquelon', id: '508' },
            { itemName: 'Samoa', id: '685' },
            { itemName: 'San Marino', id: '378' },
            { itemName: 'Santo Tomé y Principe', id: '239' },
            { itemName: 'Arabia Saudí', id: '966' },
            { itemName: 'Senegal', id: '221' },
            { itemName: 'Serbia', id: '381' },
            { itemName: 'Seychelles', id: '248' },
            { itemName: 'Sierra Leona', id: '232' },
            { itemName: 'Singapur', id: '65' },
            { itemName: 'Eslovaquia', id: '421' },
            { itemName: 'Eslovenia', id: '386' },
            { itemName: 'Islas Salomón', id: '677' },
            { itemName: 'Somalia', id: '252' },
            { itemName: 'Sudáfrica', id: '27' },
            { itemName: 'Corea del Sur', id: '82' },
            { itemName: 'Sri Lanka', id: '94' },
            { itemName: 'Sudán', id: '249' },
            { itemName: 'Surinam', id: '597' },
            { itemName: 'Swazilandia', id: '268' },
            { itemName: 'Suecia', id: '46' },
            { itemName: 'Suiza', id: '41' },
            { itemName: 'Siria', id: '963' },
            { itemName: 'Taiwan', id: '886' },
            { itemName: 'Tajikistan', id: '992' },
            { itemName: 'Tanzania', id: '255' },
            { itemName: 'Tailandia', id: '66' },
            { itemName: 'Togo', id: '228' },
            { itemName: 'Tokelau', id: '690' },
            { itemName: 'Túnez', id: '216' },
            { itemName: 'Turquía', id: '90' },
            { itemName: 'Turkmenistán', id: '993' },
            { itemName: 'Tuvalu', id: '688' },
            { itemName: 'Uganda', id: '256' },
            { itemName: 'Ucrania', id: '380' },
            { itemName: 'Emiratos Árabes Unidos', id: '971' },
            { itemName: 'Uruguay', id: '598' },
            { itemName: 'Uzbekistán', id: '998' },
            { itemName: 'Vanuatu', id: '678' },
            { itemName: 'Vaticano', id: '379' },
            { itemName: 'Venezuela', id: '58' },
            { itemName: 'Vietnam', id: '84' },
            { itemName: 'Wallis y Futuna', id: '681' },
            { itemName: 'Yemen', id: '967' },
            { itemName: 'Zambia', id: '260' },
            { itemName: 'Zimbabwe', id: '263' }];
    }

    public static documentType(): any[] {
        return [
            { 'id': 'NIF', 'itemName': 'NIF' },
            { 'id': 'CIF', 'itemName': 'CIF' },
            { 'id': 'NIE', 'itemName': 'NIE' }
        ];
    }

    public static newProducs(): any[]{
        return [
            {
                icon: 'legal',
                title: 'onLygal Abogado Personal Todo Riesgo',
                description: 'Un seguro de protección jurídica para particulares que quieren asesoramiento en sus asuntos cotidianos y desean contar con un equipo de profesionales para defender o reclamar sus derechos y los de su familia.',
                link: 'https://www.onlygal.es/seguros/onlygal-abogado-personal-todo-riesgo/#item-2'
            },
            {
                icon: 'home',
                title: 'onLygal Impago de Alquiler',
                description: 'Garantiza la defensa de los derechos de los arrendadores de viviendas frente al contrato de alquiler y a la propiedad alquilada en caso de impago de rentas de alquiler, así como la protección de su patrimonio en caso de actos vandálicos',
                link: 'https://www.onlygal.es/seguro-de-impago-de-alquiler/?utm_campaign=Web_SEO&external_id=link_landing_old-impago-web'
            },
            {
                icon: 'home',
                title: 'onLygal Arrendador',
                description: 'Garantiza a los arrendadores de viviendas o locales de negocio la prevención y solución de conflictos jurídicos vinculados con el contrato de alquiler como arrendador y con sus derechos de propiedad o usufructo del inmueble alquilado.',
                link: 'https://www.onlygal.es/seguros/onlygal-arrendador/'
            },
            {
                icon: 'community',
                title: 'onLygal Comunidad de Propietarios',
                description: 'Protege los derechos y los bienes de las comunidades en régimen de propiedad horizontal, así como la defensa penal del Presidente, los Vicepresidentes, el Secretario, el Secretario-Administrador y el Administrador si son copropietarios y no actúan como profesionales.',
                link: 'https://www.onlygal.es/seguros/onlygal-comunidad-de-propietarios/'
            },
            {
                icon: 'car',
                title: 'onLygal Conducción',
                description: 'Una protección jurídica a los conductores frente a los conflictos y trámites legales que puedan tener durante la conducción del vehículo asegurado.',
                link: 'https://www.onlygal.es/seguros/onlygal-conduccion/'
            },
            {
                icon: 'car',
                title: 'onLygal Retirada de Carné',
                description: 'Protección tanto jurídica y económica ante la posible pérdida de puntos o retirada del carné de conducir de los conductores particulares o profesionales que necesitan su vehículo como medio básico de desplazamiento o trabajo.',
                link: 'https://www.onlygal.es/seguros/onlygal-retirada-de-carne/'
            },
        ]
    }
    
}
