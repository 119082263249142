import { Component, Input } from '@angular/core';
import { CommonsText } from 'app/commons/CommonsText';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { EventService } from 'app/services/event-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-indemnizaciones-parte',
    templateUrl: './indemnizaciones-parte.component.html',
    styleUrls: ['./indemnizaciones-parte.component.scss']
})
export class IndemnizacionesParteComponent {

    @Input() data: any;
    indemnizaciones: any[] = undefined;
    flag: boolean = false;
    mobile: Boolean;
    desktopSize: Boolean = false;
    mobileSize: Boolean = false;
    constructor(
        private eventService: EventService,
        private toastr: ToastrService,
        private mobileService: IsMobileService
    ){}

    traerIndemnizaciones(){
        if(!this.flag){
            this.flag = true;
            this.eventService.getIndemnizaciones(this.data.eventNumber)
            .then(value=>{
                this.indemnizaciones = value.response;
            })
            .catch(error=>{
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_INDEMNZACIONES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            })
        }
    }
    ngOnInit(){
        this.mobile = this.mobileService.isMobile;
        if(this.mobile){
            this.mobileSize = true;
        }
        if(!this.mobile){
            this.desktopSize = true;
        }
    }
}
