var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AuthService } from 'app/security/auth.service';
import { Subject } from 'rxjs';
import { DomainService } from './domain-service';
import 'rxjs/add/operator/toPromise';
import { ResetService } from './reset-service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../security/auth.service";
import * as i3 from "./domain-service";
import * as i4 from "./reset-service";
import * as i5 from "ngx-toastr";
var ClientService = /** @class */ (function () {
    function ClientService(http, authService, domainService, resetService, toastr) {
        var _this = this;
        this.http = http;
        this.authService = authService;
        this.domainService = domainService;
        this.resetService = resetService;
        this.toastr = toastr;
        this.clientData = null;
        this.contactFactor = 25;
        this.addressFactor = 17;
        this.resetService.observerLogout().subscribe(function (result) {
            _this.clientData = null;
            if (result !== null) {
                var url = _this.domainService.backendUrlWithContext + '/clients/logout';
                _this.http.get(url).toPromise().catch(function (error) {
                    if (error.error.error === 'invalid_token') {
                        console.log('Token Invalido');
                    }
                });
            }
        });
    }
    ClientService.prototype.getClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localStorage.removeItem('clientData');
                        url = this.domainService.backendUrlWithContext + '/clients';
                        if (!(this.clientData === null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.get(url).toPromise()];
                    case 1:
                        response = _a.sent();
                        this.clientData = response.response;
                        this.loadingClientPercentage.next(((this.getAddressPercent(this.clientData) + this.getContactPercent(this.clientData)) / 2));
                        localStorage.setItem('clientData', JSON.stringify(this.clientData));
                        return [2 /*return*/, Promise.resolve(this.clientData)];
                    case 2:
                        localStorage.setItem('clientData', JSON.stringify(this.clientData));
                        return [2 /*return*/, Promise.resolve(this.clientData)];
                }
            });
        });
    };
    ClientService.prototype.getRoadTypes = function () {
        var url = this.domainService.backendUrlWithContext + '/clients/roadtypes';
        return this.http.get(url).toPromise();
    };
    ClientService.prototype.getPaymentAccounts = function () {
        var url = this.domainService.backendUrlWithContext + '/clients/paymentAccounts';
        return this.http.get(url).toPromise();
    };
    ClientService.prototype.getBannersInfo = function () {
        var url = this.domainService.backendUrlWithContext + '/clients/customBanner';
        return this.http.get(url).toPromise();
    };
    ClientService.prototype.onModifyContact = function (param) {
        var _this = this;
        var url = this.domainService.backendUrlWithContext + '/clients';
        var form = new FormData();
        if (null !== param.name) {
            form.append('name', param.name);
        }
        if (null !== param.surname) {
            form.append('surname', param.surname);
        }
        if (null !== param.lastName && "" !== param.lastName) {
            form.append('lastName', param.lastName);
        }
        if (null !== param.birthDate) {
            form.append('birthDate', param.birthDate.replace(/-/g, "/"));
        }
        if (null !== param.email) {
            form.append('email', param.email);
        }
        if (null !== param.phone && "" !== param.phone) {
            form.append('phone', param.phone);
        }
        if ((null !== param.phonePrefix) && (undefined !== param.phonePrefix)) {
            if (null !== param.phone && "" !== param.phone) {
                form.append('phonePrefix', param.phonePrefix[0].id);
            }
        }
        if (null !== param.mobilePhone) {
            form.append('mobilePhone', param.mobilePhone);
        }
        if ((null !== param.mobilePhonePrefix) && (undefined !== param.mobilePhonePrefix)) {
            form.append('mobilePhonePrefix', param.mobilePhonePrefix[0].id);
        }
        if (null !== param.province) {
            form.append('province', param.province);
        }
        if (null !== param.postalCode) {
            form.append('postalCode', param.postalCode);
        }
        form.append('city', param.cityCb[0].id);
        form.append('roadTypeId', param.roadType[0].id);
        if (null !== param.address) {
            form.append('address', param.address);
        }
        if (null !== param.number) {
            form.append('number', param.number);
        }
        if (null !== param.portal && "" !== param.portal) {
            form.append('portal', param.portal);
        }
        if (null !== param.stair && "" !== param.stair) {
            form.append('stair', param.stair);
        }
        if (null !== param.floor && "" !== param.floor) {
            form.append('floor', param.floor);
        }
        if (null !== param.door && "" !== param.door) {
            form.append('door', param.door);
        }
        if (null !== param.notificationChannel) {
            form.append('notifications', param.notificationChannel);
        }
        return this.http.post(url, form).toPromise().then(function (response) {
            if (response.response) {
                localStorage.setItem('token_replacement', param.name);
                _this.authService.getUser().name = param.name;
                _this.clientData.name = param.name;
                _this.clientData.surname = param.surname;
                _this.clientData.lastName = param.lastName;
                _this.clientData.birthDate = param.birthDate;
                _this.clientData.email = param.email;
                _this.clientData.phone = param.phone;
                _this.clientData.phonePrefix = param.phonePrefix[0].id;
                _this.clientData.mobilePhone = param.mobilePhone;
                _this.clientData.mobilePhonePrefix = param.mobilePhonePrefix[0].id;
                _this.clientData.province = param.province;
                _this.clientData.postalCode = param.postalCode;
                _this.clientData.city = param.cityCb[0].id;
                _this.clientData.cityDescription = param.cityCb[0].itemName;
                _this.clientData.cityCb = param.cityCb;
                _this.clientData.roadType = param.roadType;
                _this.clientData.address = param.address;
                _this.clientData.number = param.number;
                _this.clientData.portal = param.portal;
                _this.clientData.stair = param.stair;
                _this.clientData.floor = param.floor;
                _this.clientData.door = param.door;
                _this.clientData.notificationChannel = param.notificationChannel;
                _this.clientData.notifications = [];
                if (_this.clientData.notificationChannel) {
                    _this.clientData.notifications.push(param.notificationChannel);
                }
            }
            return response;
        });
    };
    ClientService.prototype.onModifyAccess = function (param) {
        var url = this.domainService.backendUrlWithContext + '/clients/modifyAccess';
        var form = new FormData();
        form.append('actualPassword', param.actual);
        form.append('newPassword', param.new);
        form.append('confirmation', param.confirmation);
        return this.http.post(url, form).toPromise();
    };
    ClientService.prototype.onModifyPreferences = function (param) {
        var _this = this;
        var url = this.domainService.backendUrlWithContext + '/clients/modifyPreference';
        var form = new FormData();
        form.append('insuranceAdvertising', param.insuranceAdvertising ? 'false' : 'true');
        form.append('legalServicesAdvertising', param.legalServicesAdvertising ? 'true' : 'false');
        form.append('politicaClub', param.politicaClub ? 'true' : 'false');
        form.append('condicionesClub', param.condicionesClub ? 'true' : 'false');
        return this.http.post(url, form).toPromise().then(function (response) {
            if (response.response) {
                _this.clientData.insuranceAdvertising = !param.insuranceAdvertising;
                _this.clientData.legalServicesAdvertising = param.legalServicesAdvertising;
                _this.clientData.politicaClub = param.politicaClub;
                _this.clientData.condicionesClub = param.condicionesClub;
            }
            return response;
        });
    };
    ClientService.prototype.onAddNewAccount = function (account, upload) {
        var url = this.domainService.backendUrlWithContext + '/clients/createNewAccount';
        var form = new FormData();
        form.append('iban', account.iban);
        form.append('office', account.office);
        form.append('titular', account.titular);
        form.append('entity', account.entity);
        form.append('documentType', account.documentType[0].id);
        form.append('documentNumber', account.documentNumber);
        form.append('digit', account.digit);
        form.append('accountNumber', account.accountNumber);
        if (undefined !== upload) {
            var file = upload.get('file');
            if (null !== file) {
                form.append('file', file);
            }
        }
        var req = new HttpRequest('POST', url, form, { reportProgress: true });
        return this.http.request(req);
    };
    ClientService.prototype.onRemoveAccount = function (account) {
        var url = this.domainService.backendUrlWithContext + '/clients/removePaymentAccount';
        var form = new FormData();
        form.append('accountId', account.id);
        return this.http.post(url, form).toPromise();
    };
    ClientService.prototype.onChangeAccount = function (accountNumber, id) {
        var url = this.domainService.backendUrlWithContext + '/clients/changePaymentAccount';
        var form = new FormData();
        form.append('accountNumber', accountNumber);
        form.append('id', id);
        return this.http.post(url, form).toPromise();
    };
    ClientService.prototype.onContact = function (param) {
        var url = this.domainService.backendUrlWithContext + '/clients/contact';
        var form = new FormData();
        form.append('name', param.name);
        form.append('mobilePhone', param.mobilePhonePrefix[0].id + ' ' + param.mobilePhone);
        form.append('hour', param.hour);
        form.append('message', param.message);
        return this.http.post(url, form).toPromise();
    };
    ClientService.prototype.getPayment = function () {
        var url = this.domainService.backendUrlWithContext + '/clients/payment/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    ClientService.prototype.getContactPercent = function (clientData) {
        var percent = 0;
        percent += (this.testValue(clientData.name, this.contactFactor));
        percent += (this.testValue(clientData.surname, this.contactFactor));
        percent += (this.testValue(clientData.email, this.contactFactor));
        percent += (this.testValue(clientData.mobilePhone, this.contactFactor));
        return (percent > 100) ? 100 : percent;
    };
    ClientService.prototype.getContactFactor = function () {
        return this.contactFactor;
    };
    ClientService.prototype.getAddressPercent = function (clientData) {
        var percent = 0;
        percent += (this.testValue(clientData.province, this.addressFactor));
        percent += (this.testValue(clientData.postalCode, this.addressFactor));
        percent += (this.testValue(clientData.city, this.addressFactor));
        percent += (this.testSelectValue(clientData.roadType, this.addressFactor));
        percent += (this.testValue(clientData.address, this.addressFactor));
        percent += (this.testValue(clientData.number, this.addressFactor));
        return (percent > 100) ? 100 : percent;
    };
    ClientService.prototype.getAddressFactor = function () {
        return this.addressFactor;
    };
    ClientService.prototype.testValue = function (value, percent) {
        var response = percent;
        if (null === value || undefined === value || '' === value.trim()) {
            response = 0;
        }
        return response;
    };
    ClientService.prototype.testSelectValue = function (value, percent) {
        var response = percent;
        if (null === value || undefined === value || value.length === 0) {
            response = 0;
        }
        return response;
    };
    ClientService.prototype.onSearchCity = function (postalCode) {
        var url = this.domainService.backendUrlWithContext + '/clients/cities/' + postalCode;
        return this.http.get(url).toPromise();
    };
    ClientService.prototype.observerClientPercentageObserved = function () {
        this.loadingClientPercentage = new Subject();
        return this.loadingClientPercentage.asObservable();
    };
    ClientService.prototype.observerAddNewAcount = function () {
        this.loadingModalAddAccound = new Subject();
        return this.loadingModalAddAccound.asObservable();
    };
    ClientService.prototype.showModal = function () {
        this.loadingModalAddAccound.next(true);
    };
    ClientService.prototype.observerChangeReceipts = function () {
        this.loadingModalChangeReceipts = new Subject();
        return this.loadingModalChangeReceipts.asObservable();
    };
    ClientService.prototype.showChangeReceipts = function () {
        this.loadingModalChangeReceipts.next(true);
    };
    ClientService.prototype.observerLegalibooPopUp = function () {
        this.loadingLegalibooModal = new Subject();
        return this.loadingLegalibooModal.asObservable();
    };
    ClientService.prototype.showLegalibooModal = function () {
        this.loadingLegalibooModal.next(true);
    };
    ClientService.ngInjectableDef = i0.defineInjectable({ factory: function ClientService_Factory() { return new ClientService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.DomainService), i0.inject(i4.ResetService), i0.inject(i5.ToastrService)); }, token: ClientService, providedIn: "root" });
    return ClientService;
}());
export { ClientService };
