import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-product-tenant',
    templateUrl: './product-tenant.component.html',
    styleUrls: ['./product-tenant.component.scss']
})
export class ProductTenantComponent implements OnInit {

    @Input() occupier: any[];
    @Input() guarantor: any[];

    constructor() { }

    ngOnInit() {
    }

}
