import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { ClientService } from 'app/services/client-service';
import { AuthService } from 'app/security/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ClientDataDTO } from 'app/header-footer-template/model/ClientDataDTO';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { CustomDateValidator } from 'app/commons/custom-date-validator';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-modify-page',
    templateUrl: './modify-page.component.html',
    styleUrls: ['./modify-page.component.scss']
})
export class ModifyPageComponent implements OnInit {

    modifyContactForm: FormGroup;
    modifyAccessForm: FormGroup;
    modifyPreferencesForm: FormGroup;
    showDirectAreas: Boolean = true;
    loading: Boolean = true;
    client: ClientDataDTO;

    roadTypeList: any[] = [];
    isCompany: boolean;
    showInsuredAreas: boolean;

    @ViewChild('contactTab') contactTab: ElementRef;
    @ViewChild('paymentTab') paymentTab: ElementRef;
    @ViewChild('accessTab') accessTab: ElementRef;
    @ViewChild('preferenceTab') preferenceTab: ElementRef;

    constructor(private location: Location,
        private formBuilder: FormBuilder,
        private clientService: ClientService,
        private authService: AuthService,
        private toastr: ToastrService) { }

    ngOnInit() {

        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
        const user = this.authService.getUser();
        user.documentType && user.documentType == 'CIF' ? this.isCompany = true : this.isCompany = false;
        this.authService.getRelation() === 'TOMA' ? this.showInsuredAreas = true : this.showInsuredAreas = false;


        if (this.location.path() === '/modify/preferences') {
            this.preferenceTab.nativeElement.checked = 'checked';
        } else if (this.location.path() === '/modify/contact') {
            this.contactTab.nativeElement.checked = 'checked';
        } else if (this.location.path() === '/modify/access') {
            this.accessTab.nativeElement.checked = 'checked';
        } else if (this.location.path() === '/modify/payment') {
            this.paymentTab.nativeElement.checked = 'checked';
        } else {
            this.contactTab.nativeElement.checked = 'checked';
        }

        this.modifyContactForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            surname: ['', [Validators.required]],
            lastName: ['', []],
            birthDate: ['', this.isCompany ? [] : [Validators.required, Validators.minLength(10), CustomDateValidator.minAge, CustomDateValidator.maxAge]],
            phonePrefix: ['', []],
            phone: ['', [CommonsUtils.validPhone('phonePrefix')]],
            mobilePhonePrefix: ['', [Validators.required]],
            mobilePhone: ['', [Validators.required, CommonsUtils.validMobilePhone('mobilePhonePrefix')]],
            email: ['', [Validators.required, Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]],
            province: ['', []],
            postalCode: ['', [Validators.required]],
            cityCb: ['', [Validators.required]],
            roadType: ['', [Validators.required]],
            address: ['', [Validators.required]],
            number: ['', [Validators.required]],
            portal: ['', []],
            stair: ['', []],
            floor: ['', []],
            door: ['', []],
            notificationChannel: ['', this.authService.getSelectedEntity() === 'ONLYGAL' ? [Validators.required] : []]
        });

        this.modifyAccessForm = this.formBuilder.group({
            actual: ['', [Validators.required, Validators.pattern(CommonsUtils.PASSWORD_REGEX)]],
            new: ['', [Validators.required, Validators.pattern(CommonsUtils.PASSWORD_REGEX)]],
            confirmation: ['', [Validators.required, Validators.pattern(CommonsUtils.PASSWORD_REGEX), CommonsUtils.matchValues('new')]]
        });

        this.modifyPreferencesForm = this.formBuilder.group({
            insuranceAdvertising: ['', []],
            legalServicesAdvertising: ['', []],
            politicaClub: ['', []],
            condicionesClub: ['', []]
        });

        this.clientService.getRoadTypes()
            .then(value => {
                this.roadTypeList = value.response;
            }).catch(error => {
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_TIPO_D_VIA, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

        this.loading = true;
        this.clientService.getClient()
            .then(value => {
                this.loading = false;
                this.client = value;
            }).catch(error => {
                this.loading = false;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_CLIENTE, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    changeUrl(sender) {
        this.location.replaceState('/modify/' + sender.id);
    }

}
