<form (submit)="onConfirmRegister()" class="no-text-select">

    <div class="title row">
        <p>Registro del Área Cliente</p>
        <p>Te hemos enviado un SMS a tu teléfono móvil con el código de 6 cifras que debes introducir a continuación.</p>
    </div>
    <div class="row steps">
        <p>Paso 2 de 3
        </p>
    </div>

    <div class="number-container">
        <div class="row row-numbers">
            <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="6"  #number />
        </div>
    </div>

    <div class="row row-counter" *ngIf="counterVisible === true">
        ¿No has recibido ningún código por SMS? Por favor, espera <span class="clock">{{clock}}</span> para solicitar un nuevo código.
    </div>

    <div class="row">
        <app-error-messages [(messages)]=messages></app-error-messages>
    </div>

    <div class="row">
        <app-spinner-button [label]="getButtonLabel()" [enabled]="submitEnabled()" [running]="running">
        </app-spinner-button>
    </div>

    <div class="row">
        <app-contact-line></app-contact-line>
    </div>

</form>