import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit {

    registerForm: FormGroup;
    takerBlocked: Boolean = false;
    reinsured;
    innominado;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
    ) {
        this.route.queryParams.subscribe(params => {
            if(params['nameless']){
                this.innominado = params['nameless'];
            } else {
                this.reinsured = params['reinsured'];
            }
        });
    }

    ngOnInit(): void {
        const prefix = [{ itemName: 'España', id: '34' }];
        let policyValidators: Validators[];
        let eventValidators: Validators[];

        if (this.reinsured || this.innominado) {
            policyValidators = [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')];
            eventValidators = [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')];
        } else {
            eventValidators = [Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')];
            policyValidators = [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]*$')];
        }

        this.registerForm = this.formBuilder.group({
            documentNumber: [null, [Validators.required]],
            documentType: ['NIF', [Validators.required]],
            email: [null, [Validators.required, Validators.email, Validators.pattern(CommonsUtils.EMAIL_REGEX)]],
            policy: [null, policyValidators],
            event: [null, eventValidators],
            legalServicesAdvertising: [false],
            insuranceAdvertising: [false],
            mobilePhonePrefix: [prefix, [Validators.required]],
            mobilePhone: [null, [CommonsUtils.validMobilePhone('mobilePhonePrefix'), Validators.required]],
        })
    }

    getEntityClass() {
        if(this.innominado){
            switch (this.innominado) {
                case 'yes':
                    return 'yes';    
                default:
                    return '';
            }
        } else {
            switch (this.reinsured) {
                case 'C0494':
                    return 'meridiano';
                case 'L0639':
                    return 'euroins';
                case 'L1454':
                    return 'prima';
                case 'C0616':
                    return 'seguro-rga';
                case 'C0012':
                    return 'eterna-aseguradora';
                case 'C0037':
                    return 'santander';
                case 'C0072':
                    return 'generali';
                case 'C0121':
                    return 'metropolis';      
                case 'C0124':
                    return 'segurcaixa';
                case 'C0133':
                    return 'ocaso';
                case 'C0139':
                    return 'patria-hispana';
                case 'C0157':
                    return 'helvetia';
                case 'C0161':
                    return 'dkv';
                case 'C0572':
                    return 'lagun-aro';
                case 'C0786':
                    return 'mutua';
                case 'DLA03':
                    return 'aegon';
                case 'DLA04':
                    return 'ergo';
                case 'DLA06':
                    return 'amic';
                case 'DLA07':
                    return 'allianz';
                case 'DLA09':
                    return 'vidacaixa';
                case 'E0217':
                    return 'erv';
                case 'L0614':
                    return 'nationale-suisse';
                case 'L0639':
                    return 'euroins';
                case 'M0084':
                    return 'mmt';
                case 'M0199':
                    return 'mutua-propietarios'; 
                case 'E0250':
                        return 'iptiq'; 
                case 'C0247':
                    return 'divina';  
                default:
                    return '';
            }
        }
    }
    onRegisterSubmit(event) {
        if (event) {
            if ((undefined !== event.response.code) && (null !== event.response.code)) {
                if(this.reinsured){
                    this.router.navigate(['/confirm/' + event.response.code + '/reinsured/' + this.reinsured]);
                }else{
                    this.router.navigate(['/confirm/' + event.response.code]);
                }
            }
        }
    }

    onTakerBlock(event) {
        this.takerBlocked = true;
    }

}
