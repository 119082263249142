import { Component, OnInit} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PolicyService } from 'app/services/policy-service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonsText } from 'app/commons/CommonsText';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss'],
    animations: [
        trigger('fadeInUp', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0, transform: 'translateY(5%)' }), animate(450)]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ]
})
export class ContactPageComponent implements OnInit {

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    running: Boolean = false;
    loadingMediators: Boolean = false;

    mediator: any = undefined;
    resource: SafeResourceUrl;

    constructor(private policyService: PolicyService, private toastr: ToastrService, private sanitizer: DomSanitizer) { }

    ngOnInit() {

        this.dropdownSettings = {
            singleSelection: true,
            text: 'Selecciona un Seguro',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };

        this.loadingMediators = true;
        this.policyService.getMediators()
            .then(success => {
                this.loadingMediators = false;
                this.dropdownList = success.response;
            }).catch(error => {
                this.loadingMediators = false;
                this.dropdownList = [];
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_MEDIADORES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });


    }

    onItemSelect(item: any) {

        this.mediator = undefined;
        this.running = true;
        this.policyService.getMediator(item.id)
            .then(success => {
                this.running = false;
                this.mediator = success.response;
                if ('' === this.mediator.name) {
                    this.mediator = undefined;
                }
            }).catch(error => {
                this.running = false;
                this.mediator = undefined;
                this.toastr.error(CommonsText.PROBLEMA_SERVICIO_MEDIADORES, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            });

    }

    prepareImage(picture: any): SafeResourceUrl {
        const value = 'data:image/jpg;base64, ' + picture;
        return this.resource = this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

}
