var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SubCategoriaDataDTO } from 'app/shared/models/wordpress/SubCategoriaDataDTO';
import { ImagenDataDTO } from 'app/shared/models/wordpress/ImagenDataDTO';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NoticiaDataDTO } from 'app/shared/models/wordpress/NoticiaDataDTO';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';
import { UrlService } from '../dominio/url.service';
import { environment } from 'environments/environment';
import { Http } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from 'app/commons/CommonsText';
import * as i0 from "@angular/core";
import * as i1 from "@angular/http";
import * as i2 from "../dominio/url.service";
import * as i3 from "ngx-toastr";
var ClubPropietarioService = /** @class */ (function () {
    function ClubPropietarioService(httpclient, urlService, toastr) {
        this.httpclient = httpclient;
        this.urlService = urlService;
        this.toastr = toastr;
        this.listaCategoriaDTO = [];
    }
    ClubPropietarioService.prototype.getCategoriasCompletas = function () {
        return JSON.parse(localStorage.getItem('categorias'));
    };
    ClubPropietarioService.prototype.getCategoriaToSave = function () {
        return this.listaCategoriaDTO;
    };
    ClubPropietarioService.prototype.getCategoriaWordPress = function () {
        return __awaiter(this, void 0, void 0, function () {
            var urlCategoria, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlCategoria = this.urlService.wordpressUrl + '/wp-json/wp/v2/categories?per_page=100';
                        return [4 /*yield*/, this.httpclient.get(urlCategoria).toPromise()];
                    case 1:
                        response = _a.sent();
                        this.getListaNoticiasWordPress(this.mapCategoria(response));
                        return [2 /*return*/, of(this.listaCategoriaDTO)];
                }
            });
        });
    };
    ClubPropietarioService.prototype.mapCategoria = function (data) {
        var datos = data.json();
        var listaCategoriaDTO = [];
        var listaCategorias = datos.filter(function (x) { return x.parent == 0 && x.slug != "sin-categoria"; });
        var _loop_1 = function (i) {
            var categoria = new CategoriaDataDTO();
            categoria.id = listaCategorias[i].id;
            categoria.categoria = listaCategorias[i].id;
            categoria.titulo = listaCategorias[i].name;
            categoria.identificador = listaCategorias[i].slug;
            categoria.destacado = false;
            /*Tenemos las subCategorias*/
            var listaSubCategorias = datos.filter(function (x) { return x.parent == categoria.id && x.slug != "sin-categoria"; });
            for (var j = 0; j < listaSubCategorias.length; j++) {
                var subCategoria = new SubCategoriaDataDTO();
                subCategoria.id = listaSubCategorias[j].id;
                subCategoria.subcategoria = listaSubCategorias[j].id;
                subCategoria.categoria = categoria.id;
                subCategoria.titulo = listaSubCategorias[j].name;
                subCategoria.identificador = listaSubCategorias[j].slug;
                subCategoria.icono = this_1.setIcono(subCategoria.identificador);
                categoria.subCategoriaList.push(subCategoria);
            }
            listaCategoriaDTO.push(categoria);
        };
        var this_1 = this;
        /*Tenemos las categorias*/
        for (var i = 0; i < listaCategorias.length; i++) {
            _loop_1(i);
        }
        this.listaCategoriaDTO = listaCategoriaDTO;
        return listaCategoriaDTO;
    };
    ClubPropietarioService.prototype.getListaNoticiasWordPress = function (listaCategoriaDTO) {
        var _this = this;
        listaCategoriaDTO.forEach(function (categoria) {
            _this.getNoticiaWordPress(categoria);
        });
        return listaCategoriaDTO;
    };
    ClubPropietarioService.prototype.getNoticiaWordPress = function (categoria) {
        var _this = this;
        var urlCategoria = this.urlService.wordpressUrl + '/wp-json/wp/v2/posts?categories=' + categoria.id;
        var promise = this.httpclient.get(urlCategoria).toPromise().then(function (data) {
            _this.mapNoticia(data, categoria);
        }).catch(function (error) {
            _this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
        ;
    };
    ;
    ClubPropietarioService.prototype.mapNoticia = function (data, categoria) {
        var datos = data.json();
        for (var i = 0; i < datos.length; i++) {
            this.setCategoria(datos[i], categoria);
        }
        return categoria;
    };
    ClubPropietarioService.prototype.getUrlImagen = function (idImagen, imagen) {
        var _this = this;
        var urlImagen = this.urlService.wordpressUrl + '/wp-json/wp/v2/media/' + idImagen;
        var promise = this.httpclient.get(urlImagen).toPromise().then(function (data) {
            var datos = data.json();
            imagen.id = datos.id;
            imagen.source_url = datos.source_url;
        }).catch(function (error) {
            _this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                timeOut: 5000
            });
        });
        ;
    };
    ClubPropietarioService.prototype.getNoticia = function (idNoticia, idCategoria) {
        var _this = this;
        var urlNoticia = this.urlService.wordpressUrl + '/wp-json/wp/v2/posts/' + idNoticia;
        return this.httpclient.get(urlNoticia).pipe(map(function (res) { return _this.mapNoticiaList(res, idCategoria); }));
    };
    ClubPropietarioService.prototype.mapNoticiaList = function (data, idCategoria) {
        var noticiaDTO = new NoticiaDataDTO();
        var datos = data.json();
        noticiaDTO.id = datos.id;
        noticiaDTO.categoria = idCategoria;
        var found = datos.categories.find(function (element) { return element != idCategoria; });
        noticiaDTO.subcategoria = found;
        noticiaDTO.titulo = datos.title.rendered;
        noticiaDTO.detalle = datos.content.rendered;
        noticiaDTO.idImagen = datos.featured_media;
        noticiaDTO.imagen = new ImagenDataDTO();
        this.getUrlImagen(noticiaDTO.idImagen, noticiaDTO.imagen);
        return noticiaDTO;
    };
    ClubPropietarioService.prototype.setCategoria = function (data, categoriaDTO) {
        if (data.categories.length == 1) {
            // Estamos en la categoria
            categoriaDTO.categoria = categoriaDTO.id;
            if (data.tags.length > 0 && data.tags.find(function (element) { return element > 0; })) {
                categoriaDTO.destacado = true;
            }
            /*Noticia Categoria por ser Destacado*/
            var noticiaCate = new NoticiaDataDTO();
            noticiaCate.id = data.id;
            noticiaCate.titulo = data.title.rendered;
            noticiaCate.detalle = data.content.rendered;
            noticiaCate.categoria = categoriaDTO.id;
            noticiaCate.subcategoria = "";
            noticiaCate.idImagen = data.featured_media;
            noticiaCate.destacado = categoriaDTO.destacado;
            noticiaCate.imagen = new ImagenDataDTO();
            this.getUrlImagen(noticiaCate.idImagen, noticiaCate.imagen);
            categoriaDTO.noticasListDestacadas.push(noticiaCate);
            categoriaDTO.noticiasListAll.push(noticiaCate);
        }
        else {
            // Estamos en una subcategoria          
            var found_1 = data.categories.find(function (element) { return element != categoriaDTO.id; });
            var subCategoria = categoriaDTO.subCategoriaList.find(function (x) { return x.subcategoria == found_1; });
            /*Noticia Categoria por ser Destacado*/
            var noticiaCate = new NoticiaDataDTO();
            noticiaCate.id = data.id;
            noticiaCate.titulo = data.title.rendered;
            noticiaCate.detalle = data.content.rendered;
            noticiaCate.categoria = categoriaDTO.id;
            noticiaCate.subcategoria = found_1;
            noticiaCate.idImagen = data.featured_media;
            noticiaCate.imagen = new ImagenDataDTO();
            this.getUrlImagen(noticiaCate.idImagen, noticiaCate.imagen);
            if (data.tags.length > 0 && data.tags.find(function (element) { return element > 0; })) {
                categoriaDTO.destacado = true;
                categoriaDTO.noticasListDestacadas.push(noticiaCate);
            }
            categoriaDTO.noticiasListAll.push(noticiaCate);
            subCategoria.noticiaList.push(noticiaCate);
        }
        return categoriaDTO;
    };
    ClubPropietarioService.prototype.getCategoria = function (idCategoria) {
        return this.listaCategoriaDTO.find(function (element) { return element.categoria == idCategoria; });
    };
    ClubPropietarioService.prototype.getCategoriaStorage = function (idCategoria) {
        var cat = JSON.parse(localStorage.getItem('categorias'));
        return cat.find(function (element) { return element.categoria == idCategoria; });
    };
    ClubPropietarioService.prototype.setIcono = function (identificador) {
        var icono;
        var url;
        if (identificador.includes("preguntas")) {
            icono = "/assets/images/clubPropietario/ic_consejos.svg";
            ;
        }
        else if (identificador.includes("documentos")) {
            icono = "/assets/images/clubPropietario/ic_documentos.svg";
            ;
        }
        else if (identificador.includes("formacion")) {
            icono = "/assets/images/clubPropietario/ic_formacion.svg";
            ;
        }
        else if (identificador.includes("proveedor")) {
            icono = "/assets/images/clubPropietario/ic_proveedores.svg";
            ;
        }
        else {
            icono = "/assets/images/clubPropietario/ic_formacion.svg";
        }
        //Comenta para dejar de cargar iconos de INT.
        url = environment.wordpressUrl + '/wp-content/themes/frontend/resources/' + icono;
        //Descomenta para cargar las iconos en local.
        //url = environment.production ? '/wp-content/themes/frontend/resources/' + icono: icono;
        return url;
    };
    ClubPropietarioService.ngInjectableDef = i0.defineInjectable({ factory: function ClubPropietarioService_Factory() { return new ClubPropietarioService(i0.inject(i1.Http), i0.inject(i2.UrlService), i0.inject(i3.ToastrService)); }, token: ClubPropietarioService, providedIn: "root" });
    return ClubPropietarioService;
}());
export { ClubPropietarioService };
