import 'rxjs/add/operator/toPromise';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var ResetService = /** @class */ (function () {
    function ResetService() {
    }
    ResetService.prototype.observerLogout = function () {
        this.logoutChange = new Subject();
        return this.logoutChange.asObservable();
    };
    ResetService.prototype.logout = function (token) {
        if (undefined !== this.logoutChange) {
            this.logoutChange.next(token);
        }
    };
    ResetService.ngInjectableDef = i0.defineInjectable({ factory: function ResetService_Factory() { return new ResetService(); }, token: ResetService, providedIn: "root" });
    return ResetService;
}());
export { ResetService };
