import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-insured-vehicle',
  templateUrl: './product-insured-vehicle.component.html',
  styleUrls: ['./product-insured-vehicle.component.scss']
})
export class ProductInsuredVehicleComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
