/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-recaptcha.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-recaptcha/recaptcha/recaptcha.component.ngfactory";
import * as i3 from "ng-recaptcha/recaptcha/recaptcha.component";
import * as i4 from "ng-recaptcha/recaptcha/recaptcha-loader.service";
import * as i5 from "ng-recaptcha/recaptcha/recaptcha-settings";
import * as i6 from "./google-recaptcha.component";
var styles_GoogleRecaptchaComponent = [i0.styles];
var RenderType_GoogleRecaptchaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleRecaptchaComponent, data: {} });
export { RenderType_GoogleRecaptchaComponent as RenderType_GoogleRecaptchaComponent };
export function View_GoogleRecaptchaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "re-captcha", [], [[1, "id", 0]], [[null, "resolved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resolved" === en)) {
        var pd_0 = (_co._resolved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RecaptchaComponent_0, i2.RenderType_RecaptchaComponent)), i1.ɵdid(1, 4374528, null, 0, i3.RecaptchaComponent, [i1.ElementRef, i4.RecaptchaLoaderService, i1.NgZone, [2, i5.RECAPTCHA_SETTINGS]], { siteKey: [0, "siteKey"] }, { resolved: "resolved" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getSiteKey(); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
export function View_GoogleRecaptchaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-google-recaptcha", [], null, null, null, View_GoogleRecaptchaComponent_0, RenderType_GoogleRecaptchaComponent)), i1.ɵdid(1, 49152, null, 0, i6.GoogleRecaptchaComponent, [], null, null)], null, null); }
var GoogleRecaptchaComponentNgFactory = i1.ɵccf("app-google-recaptcha", i6.GoogleRecaptchaComponent, View_GoogleRecaptchaComponent_Host_0, {}, { onRecaptcha: "onRecaptcha" }, []);
export { GoogleRecaptchaComponentNgFactory as GoogleRecaptchaComponentNgFactory };
