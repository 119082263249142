import { EventEmitter } from '@angular/core';
var DateComponent = /** @class */ (function () {
    function DateComponent() {
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril',
            'Mayo', 'Junio', 'Julio', 'Agosto',
            'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.years = [];
        this.selected = new EventEmitter();
        this.chargedYears();
    }
    Object.defineProperty(DateComponent.prototype, "value", {
        set: function (value) {
            var converted = null;
            if ((undefined === value) || (null === value)) {
                converted = new Date();
            }
            else {
                var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
                converted = new Date(value.replace(pattern, '$3-$2-$1'));
                this.chargedYears();
            }
            this.date = converted;
            this.month = converted.getMonth();
            this.year = converted.getFullYear();
            this.showCalendar();
        },
        enumerable: true,
        configurable: true
    });
    DateComponent.prototype.chargedYears = function () {
        var converted = null;
        var temp = new Date();
        this.currentMonth = temp.getMonth();
        this.currentDay = temp.getDate();
        if (!this.minYear) {
            this.currentYear = temp.getFullYear();
            this.years = [];
        }
        else {
            var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
            converted = new Date(this.minYear.replace(pattern, '$3-$2-$1'));
            this.currentYear = converted.getFullYear();
            this.years = [];
        }
        var startYear = this.currentYear - 10;
        this.min = startYear;
        while (startYear <= (this.currentYear + 10)) {
            this.years.push(startYear);
            startYear = startYear + 1;
        }
        this.max = startYear - 1;
    };
    DateComponent.prototype.onShowMonth = function () {
        this.showingYear = false;
        this.showingMonth = !this.showingMonth;
    };
    DateComponent.prototype.onShowYear = function () {
        this.showingMonth = false;
        this.showingYear = !this.showingYear;
    };
    DateComponent.prototype.onPreviousMonth = function () {
        this.date.setMonth(this.date.getMonth() - 1);
        if (this.date.getFullYear() < this.min) {
            this.date.setMonth(this.date.getMonth() + 1);
            return;
        }
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showCalendar();
    };
    DateComponent.prototype.onNextMonth = function () {
        this.date.setMonth(this.date.getMonth() + 1);
        if (this.date.getFullYear() > this.max) {
            this.date.setMonth(this.date.getMonth() - 1);
            return;
        }
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showCalendar();
    };
    DateComponent.prototype.monthName = function () {
        return this.months[this.month];
    };
    DateComponent.prototype.onSetMonth = function (value) {
        this.date.setMonth(value);
        this.month = this.date.getMonth();
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showCalendar();
    };
    DateComponent.prototype.onSetYear = function (value) {
        this.date.setFullYear(value);
        this.year = this.date.getFullYear();
        this.selected.emit(this.date);
        this.showingYear = false;
        this.showCalendar();
    };
    DateComponent.prototype.onSetNow = function () {
        this.date = new Date();
        this.year = this.date.getFullYear();
        this.month = this.date.getMonth();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showingYear = false;
        this.showCalendar();
    };
    DateComponent.prototype.onSetDate = function (day) {
        this.date = day.date;
        this.year = this.date.getFullYear();
        this.month = this.date.getMonth();
        this.selected.emit(this.date);
        this.showingMonth = false;
        this.showingYear = false;
        this.showCalendar();
    };
    DateComponent.prototype.showCalendar = function () {
        var temp = new Date();
        var newCalendar = [];
        var i;
        temp.setDate(1);
        temp.setMonth(this.month);
        temp.setFullYear(this.year);
        var pn = temp.getDay() === 0 ? 7 : temp.getDay();
        for (i = 1; i < pn; i++) {
            var prevDay = new Date(temp);
            prevDay.setDate(prevDay.getDate() - i);
            var dayToAdd = {
                day: prevDay.getDate(),
                month: prevDay.getMonth(),
                year: prevDay.getFullYear(),
                date: prevDay
            };
            newCalendar.push(dayToAdd);
        }
        newCalendar.reverse();
        var monthDay = new Date(temp);
        while (monthDay.getMonth() === temp.getMonth()) {
            var dayToAdd = {
                day: monthDay.getDate(),
                month: monthDay.getMonth(),
                year: monthDay.getFullYear(),
                date: new Date(monthDay)
            };
            newCalendar.push(dayToAdd);
            monthDay.setDate(monthDay.getDate() + 1);
        }
        temp.setMonth(temp.getMonth() + 1);
        temp.setDate(temp.getDate() - 1);
        var nn = 7 - temp.getDay();
        for (i = 0; i < nn; i++) {
            var nextDay = new Date(temp);
            nextDay.setDate(nextDay.getDate() + i + 1);
            var dayToAdd = {
                day: nextDay.getDate(),
                month: nextDay.getMonth(),
                year: nextDay.getFullYear(),
                date: nextDay
            };
            newCalendar.push(dayToAdd);
        }
        this.calendarDays = newCalendar;
    };
    return DateComponent;
}());
export { DateComponent };
