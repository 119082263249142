import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-complete-profile-modal',
    templateUrl: './complete-profile-modal.component.html',
    styleUrls: ['./complete-profile-modal.component.scss']
})
export class CompleteProfileModalComponent implements OnInit {

    show: Boolean = false;

    constructor(
        private router: Router,
        private clientService: ClientService,
        private toastr: ToastrService,
        private elementRef: ElementRef
    ) { }

    ngOnInit() {

        this.clientService.observerClientPercentageObserved().subscribe((result: any) => {
            if (result !== 100) {
                this.show = true;
            }
        });


    }

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (event.target.innerHTML.includes('loading-container-cont wow fadeInUp')) {
            // clicked outside => close modal
            this.show = false;
        }
    }

    closeModal() {
        this.show = false;
    }

    goToModifyProfile() {
        this.router.navigate(['/modify/contact']);
        this.closeModal();
    }

}
