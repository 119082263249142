import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IsMobileService } from 'app/commons/components/isMobile.service';
import { CategoriaDataDTO } from 'app/shared/models/wordpress/CategoriaDataDTO';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CategoriaComponent implements OnInit {

  loading: Boolean = true;
  showArrow: boolean = false;
  @Input() delay;
  @Input() categoria: CategoriaDataDTO;
  expan: boolean = false;
  isMobilePhone: boolean;
  irCatUno: string  = "";
  @Output() refrescarHome = new EventEmitter();

  constructor(private isMobileService: IsMobileService,
              private router: Router) {
              this.isMobilePhone = isMobileService.isMobile;
  }

  ngOnInit() {
    this.irCatUno = this.categoria.categoria;
  }

  ngAfterViewInit (){
        this.loading = true;       
    }

  irDetalleCategoria(){
    this.refrescarHome.emit(this.irCatUno);
  }

  expandir(){
      if(!this.expan){
          this.expan = true;
      } else {
          this.expan = false;
      }
  }

  getDelay(): String {
      return (this.delay * 0.2) + 's';
  }

  getStyle(imagen: string) {
      if (imagen !== null) {
          return {
              'background-image': 'url(' + imagen + ')'
          }
      }
      return '';
  }

  detallePost(categoria:string ,id: string){
      this.router.navigate(['/club-del-propietario/detalle/' + categoria + '/' + id]);
  }

  loadArrow(item: any){
      this.showArrow = false;
      if(item.length >= 5){
          this.showArrow = true;
      }
  }
}
