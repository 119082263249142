import { AfterViewInit, ElementRef } from '@angular/core';
import { animate, AnimationBuilder, style } from "@angular/animations";
import { IsMobileService } from 'app/commons/components/isMobile.service';
var CarouselComponent = /** @class */ (function () {
    function CarouselComponent(builder, elementRef, isMobileService) {
        this.builder = builder;
        this.elementRef = elementRef;
        this.visibleItems = [{}];
        this.timing = '250ms ease-in';
        this.showControls = true;
        this.currentSlide = 0;
        this.moving = false;
        this.isMobile = false;
        this.carouselWrapperStyle = {};
        this.isMobile = isMobileService.isMobile;
    }
    Object.defineProperty(CarouselComponent.prototype, "items", {
        get: function () {
            return this._items;
        },
        set: function (value) {
            this._items = value;
            this.visibleItems = [this._items[0]];
        },
        enumerable: true,
        configurable: true
    });
    CarouselComponent.prototype.ngAfterViewInit = function () {
        this.detect();
    };
    CarouselComponent.prototype.detect = function () {
        var _this = this;
        setTimeout(function () {
            _this.itemWidth = _this.elementRef.nativeElement.offsetWidth - 2;
            if (_this.itemWidth < 300) {
                _this.itemWidth = 300;
            }
            _this.carouselWrapperStyle = { width: _this.itemWidth + "px" };
        });
    };
    CarouselComponent.prototype.next = function () {
        var _this = this;
        if (this.moving) {
            return;
        }
        this.moving = true;
        if (this.currentSlide + 1 === this._items.length) {
            return;
        }
        this.visibleItems.push(this.items[this.currentSlide + 1]);
        this.currentSlide = (this.currentSlide + 1) % this.items.length;
        var offset = this.currentSlide * this.itemWidth;
        var myAnimation = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        setTimeout(function () {
            _this.moving = false;
        }, 250);
    };
    CarouselComponent.prototype.prev = function () {
        var _this = this;
        if (this.moving) {
            return;
        }
        if (this.currentSlide === 0) {
            return;
        }
        this.moving = true;
        var temp = ((this.currentSlide - 1) + this.visibleItems.length) % this.visibleItems.length;
        var offset = temp * this.itemWidth;
        var myAnimation = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        setTimeout(function () {
            _this.moving = false;
            _this.currentSlide = temp;
            _this.visibleItems.pop();
        }, 250);
    };
    CarouselComponent.prototype.buildAnimation = function (offset) {
        return this.builder.build([
            animate(this.timing, style({ transform: "translateX(-" + offset + "px)" }))
        ]);
    };
    CarouselComponent.prototype.resolveRouterLink = function (param) {
        return param;
    };
    CarouselComponent.prototype.onSwipeLeft = function ($event) {
        this.next();
    };
    CarouselComponent.prototype.onSwipeRight = function ($event) {
        this.prev();
    };
    CarouselComponent.prototype.getStyle = function () {
        if (!this.isMobile) {
            return {
                'width': (this.itemWidth * (this.currentSlide + 1)) + 'px'
            };
        }
        else {
            return;
        }
    };
    return CarouselComponent;
}());
export { CarouselComponent };
