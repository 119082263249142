/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-tenant.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./product-tenant.component";
var styles_ProductTenantComponent = [i0.styles];
var RenderType_ProductTenantComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductTenantComponent, data: {} });
export { RenderType_ProductTenantComponent as RenderType_ProductTenantComponent };
function View_ProductTenantComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "tenant-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "tenant-main-sup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "type row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tipo figura"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inquilino"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "numberDoc row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00BA de documento"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "name row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nombre completo"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.documentNumber; _ck(_v, 11, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 16, 0, currVal_1); }); }
function View_ProductTenantComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "guarantor-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "tenant-main-sup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "type row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tipo figura"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Avalista"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "numberDoc row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N\u00FAmero de documento"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "name row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nombre completo"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.documentNumber; _ck(_v, 11, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 16, 0, currVal_1); }); }
export function View_ProductTenantComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tenant"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductTenantComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductTenantComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.occupier; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.guarantor; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProductTenantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-tenant", [], null, null, null, View_ProductTenantComponent_0, RenderType_ProductTenantComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProductTenantComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductTenantComponentNgFactory = i1.ɵccf("app-product-tenant", i3.ProductTenantComponent, View_ProductTenantComponent_Host_0, { occupier: "occupier", guarantor: "guarantor" }, {}, []);
export { ProductTenantComponentNgFactory as ProductTenantComponentNgFactory };
