<div class="contracted">
    <div class="contracted-main">
        <div class="left">
            <p>{{data.description}}</p>
        </div>
        <div class="center">
            <p>{{data.capital}}</p>
        </div>
        <div class="right">
            <p>{{data.capitalFree}}</p>
        </div>
    </div>
</div>