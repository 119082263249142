import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DomainService } from './domain-service';
import { AuthService } from 'app/security/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../security/auth.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-toastr";
import * as i4 from "./domain-service";
var NotificationService = /** @class */ (function () {
    function NotificationService(authService, http, toastr, domainService) {
        this.authService = authService;
        this.http = http;
        this.toastr = toastr;
        this.domainService = domainService;
        this.parentRoute = '/notifications';
    }
    // MARK AS READ NOTIFICATIONS BY ID
    NotificationService.prototype.markNotificationAsRead = function (notificationIds) {
        var form = new FormData();
        form.append('agenNotIds', notificationIds);
        var url = this.domainService.backendUrlWithContext + this.parentRoute + '/read';
        return this.http.post(url, form).toPromise();
    };
    // MARK AS DELETE NOTIFICATIONS BY ID
    NotificationService.prototype.markNotificationAsDelete = function (notificationIds) {
        var form = new FormData();
        form.append('agenNotIds', notificationIds);
        var url = this.domainService.backendUrlWithContext + this.parentRoute + '/delete';
        return this.http.post(url, form).toPromise();
    };
    // GET NOTIFICATIONS
    NotificationService.prototype.getNotifications = function () {
        var url = this.domainService.backendUrlWithContext + this.parentRoute + '/notifications/' + this.authService.getSelectedEntity();
        return this.http.get(url).toPromise();
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.AuthService), i0.inject(i2.HttpClient), i0.inject(i3.ToastrService), i0.inject(i4.DomainService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
