<div class="blog no-text-select wow fadeInUp" data-wow-duration="1s" [attr.data-wow-delay]="getDelay()">
    <div class="clubPropietario">
        <div class="container no-text-select">
            <div class="positionVer">
                <div class="tituloClub">
                    <h1>El Club del Propietario</h1>
                </div>
            </div>
            <div class="pestania">           
                <mat-tab-group  id="categoria" mat-align-tabs="left">
                    <mat-tab *ngFor="let item of categoria; let i = index" [attr.data-index]="i"  title="subcategoria{{item.id}}">
                        <ng-template mat-tab-label>
                            <label for="subcategoria{{item.id}}" role="button" class="mat-tab-labe">
                                <span>{{item.titulo}}</span>
                            </label>
                        </ng-template>
                        <div class="content" id="parent" [ngClass]="{'minTamanio': !expan}">
                            <div *ngIf="item.noticiasListAll.length == 0">
                                <div class="grid">
                                    <div></div>
                                    <div class="parrafo">
                                        <p>¡Lo sentimos! Ahora mismo no hay ningún consejo sobre este tema.</p>
                                        <p>Prueba con otra pestaña</p>
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div *ngIf="item.noticiasListAll.length > 0" class="gridNoticia">
                                <div *ngFor="let noticias of item.noticiasListAll | slice:0:4;" class="noticia">
                                    <div class="imagenPostDiv">
                                        <img class="imagenPost" [ngStyle]="getStyle(noticias.imagen ? noticias.imagen.source_url : null)">
                                    </div>
                                    <div class="enlacePost">
                                        <span (click)="detallePost(noticias.categoria, noticias.id)" [innerHtml]="noticias.titulo"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>            
            </div>
        </div>
    </div>
</div>