import { Component, Input } from '@angular/core';
import { DasLexService } from 'app/services/daslex-service';

@Component({
    selector: 'app-collapse-product-container',
    templateUrl: './collapse-product-container.component.html',
    styleUrls: ['./collapse-product-container.component.scss']
})
export class CollapseProductContainerComponent {
    clicked: boolean = true;

    @Input() checked: Boolean = false;
    @Input() slimStyle: Boolean = false;
    @Input() caption: String;
    @Input() subcaption: String;
    @Input() loading: Boolean = false;

    constructor(
        private dasLexService: DasLexService){}

    onClick() {
        this.checked = !this.checked;
        if(this.clicked){
            this.dasLexService.view();
            this.clicked = false;
        } else {
            this.clicked = true;
        }
    }

}
