import { OnInit, AfterViewInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ClientService } from 'app/services/client-service';
import { ToastrService } from 'ngx-toastr';
import { DeydeService } from 'app/services/deyde-service';
import { CommonsText } from 'app/commons/CommonsText';
var EventRegisterThirdStepComponent = /** @class */ (function () {
    function EventRegisterThirdStepComponent(controlContainer, clientService, toastr, deydeService) {
        this.controlContainer = controlContainer;
        this.clientService = clientService;
        this.toastr = toastr;
        this.deydeService = deydeService;
        this.loadingCities = false;
        this.patched = false;
        this.cityList = [];
        this.prefixSettings = {
            singleSelection: true,
            text: 'Prefijo',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            showCheckbox: true,
            classes: 'multi-select-phone',
            clearAll: false
        };
    }
    Object.defineProperty(EventRegisterThirdStepComponent.prototype, "policy", {
        set: function (data) {
            this.data = data;
        },
        enumerable: true,
        configurable: true
    });
    EventRegisterThirdStepComponent.prototype.ngOnInit = function () {
        this.thirdStepForm = this.controlContainer.control;
        this.roadTypeSettings = {
            singleSelection: true,
            text: 'Tipo de vía',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.citySettings = {
            singleSelection: true,
            text: 'Introduzca un código postal y seleccione una población',
            enableCheckAll: false,
            enableSearchFilter: true,
            enableFilterSelectAll: false,
            searchPlaceholderText: 'Filtro',
            classes: 'multi-select',
            clearAll: false
        };
        this.searchCity();
    };
    EventRegisterThirdStepComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.patched) {
            if (undefined !== this.data) {
                this.patched = true;
                setTimeout(function () {
                    _this.patchForm(_this.data.contact);
                }, 500);
            }
        }
    };
    EventRegisterThirdStepComponent.prototype.errorDescription = function (controlName) {
        var control = this.thirdStepForm.controls[controlName];
        return control.invalid && (control.touched || control.dirty);
    };
    EventRegisterThirdStepComponent.prototype.patchForm = function (person) {
        if (undefined !== person) {
            this.province = this.parseValue(person.province);
            this.cityList.push({
                id: person.city, itemName: person.cityDescription, province: person.province
            });
        }
    };
    EventRegisterThirdStepComponent.prototype.searchCity = function () {
        var _this = this;
        var postalCode = this.thirdStepForm.controls['postalCode'].value;
        if ((postalCode !== undefined) && (postalCode !== null) &&
            (postalCode !== '') && (postalCode.length === 5) && (!this.loadingCities)) {
            this.loadingCities = true;
            this.clientService.onSearchCity(postalCode)
                .then(function (success) {
                _this.actualPostalCode = postalCode;
                _this.loadingCities = false;
                _this.cityList = [];
                _this.thirdStepForm.controls['city'].patchValue([]);
                for (var _i = 0, _a = success.response; _i < _a.length; _i++) {
                    var city = _a[_i];
                    _this.cityList.push({
                        id: city.id, itemName: city.name, province: city.province, idprovince: city.idprovince
                    });
                }
                if (success.response.length === 1) {
                    var city = success.response[0];
                    _this.thirdStepForm.patchValue({ city: [{ id: city.id, itemName: city.name, province: city.province }] });
                    _this.thirdStepForm.patchValue({ province: city.province });
                    _this.thirdStepForm.patchValue({ idprovince: city.idprovince });
                    _this.idprovince = city.idprovince;
                    _this.province = city.province;
                }
            }).catch(function (error) {
                _this.loadingCities = false;
                _this.toastr.error(CommonsText.ERROR_DE_OPERACION, CommonsText.ERROR_SERVICIO_DEYDE, {
                    timeOut: 5000
                });
            });
        }
    };
    EventRegisterThirdStepComponent.prototype.onChangePostalCode = function (data) {
        var v = data.target.value;
        if ((v.length === 5) && (v !== this.actualPostalCode)) {
            this.searchCity();
        }
    };
    EventRegisterThirdStepComponent.prototype.onCitySelect = function (item) {
        this.thirdStepForm.patchValue({ province: item.province });
        this.province = item.province;
    };
    EventRegisterThirdStepComponent.prototype.isInvalid = function (controlName) {
        var control = this.thirdStepForm.controls[controlName];
        return (control.invalid && (control.touched || control.dirty));
    };
    EventRegisterThirdStepComponent.prototype.parseSelectValue = function (value) {
        if (null === value || undefined === value) {
            return [];
        }
        else {
            return value;
        }
    };
    EventRegisterThirdStepComponent.prototype.parseValue = function (value) {
        if (null === value || undefined === value || '' === value.trim()) {
            return null;
        }
        else {
            return value;
        }
    };
    EventRegisterThirdStepComponent.prototype.onChangeEmail = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.thirdStepForm.get("email").status;
        if (v2 === "VALID") {
            if (((v.length >= 6) && v.includes("@")) && v.includes(".")) {
                this.deydeService.onCheckDeydeEmail(v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.thirdStepForm.get("email").setErrors(null);
                    }
                    else {
                        _this.thirdStepForm.get("email").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    EventRegisterThirdStepComponent.prototype.onChangeMobile = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.thirdStepForm.get("mobilePhone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydeMobile("+" + this.thirdStepForm.get("mobilePhonePrefix").value[0].id + " " + v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.thirdStepForm.get("mobilePhone").setErrors(null);
                    }
                    else {
                        _this.thirdStepForm.get("mobilePhone").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    EventRegisterThirdStepComponent.prototype.onChangePhone = function (data) {
        var _this = this;
        var v = data.target.value;
        var v2 = this.thirdStepForm.get("phone").status;
        if (v2 === "VALID") {
            if ((v.length >= 9) && v.length <= 9) {
                this.deydeService.onCheckDeydePhone("+" + this.thirdStepForm.get("phonePrefix").value[0].id + " " + v)
                    .then(function (success) {
                    if (success[0].normalizado === 'S') {
                        _this.thirdStepForm.get("phone").setErrors(null);
                    }
                    else {
                        _this.thirdStepForm.get("phone").setErrors({ 'incorrect': true });
                    }
                }).catch(function (error) {
                    _this.toastr.error(error.response, CommonsText.ERROR_DE_OPERACION, {
                        timeOut: 5000
                    });
                });
            }
        }
    };
    return EventRegisterThirdStepComponent;
}());
export { EventRegisterThirdStepComponent };
