/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header-breadcrumb.component";
var styles_HeaderBreadcrumbComponent = [i0.styles];
var RenderType_HeaderBreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderBreadcrumbComponent, data: {} });
export { RenderType_HeaderBreadcrumbComponent as RenderType_HeaderBreadcrumbComponent };
export function View_HeaderBreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "profile-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "profile-title-bread"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["Inicio\u00A0\u00A0 "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", "\u00A0\u00A0", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "profile-title-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/main"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = ((undefined !== _co.crumb) ? ">" : ""); var currVal_4 = _co.crumb; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.text; _ck(_v, 10, 0, currVal_5); }); }
export function View_HeaderBreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-breadcrumb", [], null, null, null, View_HeaderBreadcrumbComponent_0, RenderType_HeaderBreadcrumbComponent)), i1.ɵdid(1, 49152, null, 0, i4.HeaderBreadcrumbComponent, [], null, null)], null, null); }
var HeaderBreadcrumbComponentNgFactory = i1.ɵccf("app-header-breadcrumb", i4.HeaderBreadcrumbComponent, View_HeaderBreadcrumbComponent_Host_0, { crumb: "crumb", crumb2: "crumb2", text: "text", policy: "policy" }, {}, []);
export { HeaderBreadcrumbComponentNgFactory as HeaderBreadcrumbComponentNgFactory };
