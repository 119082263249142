import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/security/auth.service';

@Component({
  selector: 'app-event-links',
  templateUrl: './event-links.component.html',
  styleUrls: ['./event-links.component.scss']
})
export class EventLinksComponent implements OnInit {

  showDirectAreas: Boolean = true;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    if ((this.authService.isLogged())) {
      this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
    }
  }

}
